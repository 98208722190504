import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';

import { useParams, url } from 'routes';
import CreateRiskTreatmentAction from 'routes/risk-treatment-actions/create';

import { list } from 'store/common';

const AddRiskTreatmentAction = () => {
  const { riskId } = useParams('risk:add-risk-treatment-action') as {
    riskId: string;
  };
  const [visible, setVisible] = useState(true);
  const history = useHistory();

  return (
    <Modal
      visible={visible}
      width={800}
      footer={null}
      afterClose={() => history.push(url('risk', { riskId }))}
      onCancel={() => setVisible(false)}>
      <CreateRiskTreatmentAction
        initialValues={{ risks: [riskId] }}
        successActions={[
          list({
            resolver: 'riskTreatmentActions',
            paginationKey: 'all',
          }),
        ]}
        successRedirect={url('risk', {
          riskId,
        })}
        showHeader={false}
      />
    </Modal>
  );
};

export default AddRiskTreatmentAction;
