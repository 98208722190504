import React from 'react';

import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/schedules/form';
import { useParams } from 'routes';

import { useSelector } from 'store/hooks';
import { DenormalizedSchedule } from 'store/data';
import { selectDetail } from 'store/common';

const Detail: React.FC = () => {
  const { scheduleId } = useParams('schedule');
  const entity = useSelector(state =>
    selectDetail<DenormalizedSchedule>(state, 'schedule', scheduleId as string)
  );

  const initialValues = {
    identifier: entity.identifier,
  };

  return (
    <GenericDetail
      resolver="schedule"
      listRoute="schedules"
      createRoute="schedules:create"
      detailIdParam="scheduleId"
      listTitle="Schedules"
      listItemTitle={entity => entity.identifier}
      itemName="schedule">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericDetail>
  );
};

export default Detail;
