import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/labels/form';

const CreateLabel: React.FC = () => {
  const initialValues = {
    scope: '',
    color: '',
    value: '',
  };

  return (
    <GenericCreate
      resolver="label"
      listResolver="labels"
      listRoute="labels"
      detailIdParam="labelId"
      detailRoute="label"
      createRoute="labels:create"
      listTitle="Labels"
      listItemTitle={entity => `${entity.scope}:${entity.value}`}
      itemName="label">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default CreateLabel;
