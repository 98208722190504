import React from 'react';
import { Typography, Row, Spin, List, Space } from 'antd';
import {
  UserOutlined,
  MailOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  LoginOutlined,
} from '@ant-design/icons';

import Header from 'components/header';
import DateTooltip, { formatDate } from 'components/date';

import { useParams, url } from 'routes';

import { useSelector, useValidateEntityExistence } from 'store/hooks';
import { selectEntity } from 'store/data';

import { isLoaded, userName } from 'utils';

const { Title } = Typography;

const Detail: React.FC = () => {
  const { userId } = useParams('user');
  const user = useSelector(state =>
    selectEntity(state, 'user', userId as string)
  );

  useValidateEntityExistence({
    entity: user,
    entityType: 'user',
    redirectUrl: url('users'),
  });

  const entries = [
    {
      name: 'Name',
      value: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
      icon: <UserOutlined />,
    },
    {
      name: 'Username',
      value: user.username,
      icon: <LoginOutlined />,
    },
    {
      name: 'Email',
      value: user.email,
      icon: <MailOutlined />,
    },
    {
      name: 'Date joined',
      value: user.date_joined ? (
        <DateTooltip date={user.date_joined}>
          {formatDate(user.date_joined)}
        </DateTooltip>
      ) : undefined,
      icon: <CalendarOutlined />,
    },
    {
      name: 'Last login',
      value: user.last_login ? (
        <DateTooltip date={user.last_login}>
          {formatDate(user.last_login)}
        </DateTooltip>
      ) : (
        'Never'
      ),
      icon: <FieldTimeOutlined />,
    },
  ];

  return (
    <>
      <Header documentTitle={`${userName(user)} - Users`} />
      <Title level={2}>Profile</Title>
      {isLoaded(user) ? (
        <List
          bordered
          dataSource={entries}
          renderItem={entry =>
            entry.value ? (
              <List.Item>
                <span style={{ display: 'inline-block', minWidth: '125px' }}>
                  <Space>
                    {entry.icon}
                    <Typography.Text strong>{entry.name}</Typography.Text>
                  </Space>
                </span>
                <span style={{ marginRight: 'auto' }}>{entry.value}</span>
              </List.Item>
            ) : null
          }
        />
      ) : (
        <Row justify="center" style={{ height: '50vh' }} align="middle">
          <Spin size="large" />
        </Row>
      )}
    </>
  );
};

export default Detail;
