import React from 'react';
import { Link } from 'react-router-dom';

import { url } from 'routes';

import { DenormalizedPolicy } from 'store/data';

interface Props {
  policy: DenormalizedPolicy;
}

const VersionsDropdown: React.FC<Props> = ({ policy }) => {
  return policy.latest_version || policy.current_version ? (
    <Link
      className="ant-btn"
      to={url('policy:versions', { policyId: policy?.uuid })}>
      Versions...
    </Link>
  ) : null;
};

export default VersionsDropdown;
