import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedEvidence } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedEvidence>
      resolver="evidences"
      itemName="evidence"
      listItemTitle={entity => entity.title}
      listTitle="Evidence"
      detailRoute="evidence"
      detailIdParam="evidenceId"
      createRoute={null}
      dependencies={['roles', 'assetClassifications', 'users']}
      routes={routes}
    />
  );
};

export default List;
