import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/asset-types/form';

const Create: React.FC = () => {
  const initialValues = {
    name: '',
    slug: '',
    description: '',
  };

  return (
    <GenericCreate
      resolver="assetType"
      listResolver="assetTypes"
      createRoute="asset-types:create"
      listRoute="asset-types"
      detailRoute="asset-type"
      detailIdParam="assetTypeId"
      listTitle="Asset types"
      listItemTitle={entity => entity.name}
      itemName="asset type">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
