import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedRole } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedRole>
      resolver="roles"
      itemName="role"
      listItemTitle={entity => entity.name}
      listTitle="Roles"
      detailRoute="role"
      detailIdParam="roleId"
      createRoute="roles:create"
      dependencies={[]}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default List;
