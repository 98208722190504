/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AssetClassListing = {
    uuid: string;
    slug: string;
    name: string;
    asset_type: string;
    /**
     * Highest confidentiality which assets in this asset class can have.
     */
    confidentiality_rating?: AssetClassListing.confidentiality_rating;
    integrity_rating?: AssetClassListing.integrity_rating;
    availability_rating?: AssetClassListing.availability_rating;
    retention_policy?: string | null;
    deletion_policy?: string | null;
}

export namespace AssetClassListing {

    /**
     * Highest confidentiality which assets in this asset class can have.
     */
    export enum confidentiality_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }

    export enum integrity_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }

    export enum availability_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }


}
