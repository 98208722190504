import React from 'react';
import ReactJSON from 'react-json-view';

interface Props {
  src: Record<string, any>;
}

const Debug: React.FC<Props> = ({ src }) => {
  return (
    <ReactJSON
      style={{ padding: '20px 0' }}
      enableClipboard={false}
      collapseStringsAfterLength={100}
      quotesOnKeys={false}
      src={src}
    />
  );
};

export default Debug;
