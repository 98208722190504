import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography, Row, Spin, Button, Popconfirm } from 'antd';
import { push } from 'connected-react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';

import Header from 'components/header';

import { url, RouteArgs } from 'routes';

import {
  useDispatch,
  useSelector,
  useValidateEntityExistence,
} from 'store/hooks';
import { selectDetail, update, destroy, DetailResolver } from 'store/common';
import { selectLoadingUpdate, selectLoadingDestroy } from 'store/loading';

import { isLoaded } from 'utils';

const { Title } = Typography;

interface Props {
  resolver: DetailResolver;
  listRoute: keyof RouteArgs;
  createRoute: keyof RouteArgs | null;
  itemName: string;
  listTitle: string;
  detailIdParam: string;
  listItemTitle: (x: any) => string;
  children: (props: any) => React.ReactNode;
  showHeader?: boolean;
  backTo?: string;
  detailTitle?: React.ReactNode;
}

const Detail: React.FC<Props> = ({
  resolver,
  listRoute,
  itemName,
  listTitle,
  detailIdParam,
  detailTitle,
  listItemTitle,
  children,
  createRoute,
  showHeader = true,
  backTo,
}) => {
  const dispatch = useDispatch();
  const params = useParams<any>();
  const entityId = params[detailIdParam];
  const entity = useSelector(state => selectDetail(state, resolver, entityId));
  const loading = useSelector(state =>
    selectLoadingUpdate(state, resolver as any, entityId)
  );
  const deleting = useSelector(state =>
    selectLoadingDestroy(state, resolver as any, entityId)
  );

  useValidateEntityExistence({
    entity: entity,
    entityType: resolver,
    redirectUrl: url(listRoute, undefined),
  });

  return (
    <div>
      {showHeader ? (
        <Header documentTitle={`${listItemTitle(entity)} - ${listTitle}`}>
          {createRoute ? (
            <Link className="ant-btn" to={url(createRoute, undefined)}>
              Create {itemName}
            </Link>
          ) : null}
        </Header>
      ) : null}
      <Title level={2}>
        {backTo ? (
          <Link className="header__back" to={backTo}>
            <ArrowLeftOutlined />
          </Link>
        ) : null}
        {detailTitle ? detailTitle : <>Edit {itemName}</>}
      </Title>
      {isLoaded(entity) ? (
        children({
          loading: loading.isFetching,
          entityId: (entity as any).uuid,
          onSubmit: (values: any) =>
            dispatch(
              update({
                resolver: resolver as any,
                uuid: entityId,
                patch: values,
              })
            ),
          children: (
            <Popconfirm
              title={`Are you sure you want to delete this ${itemName}?`}
              okButtonProps={{ danger: true }}
              okText="Yes, delete"
              cancelText="No"
              placement="topRight"
              onConfirm={() =>
                dispatch(
                  destroy({
                    resolver: resolver as any,
                    uuid: entityId,
                    successActions: [
                      push(backTo ? backTo : url(listRoute, undefined)),
                    ],
                  })
                )
              }>
              <Button
                style={{ marginLeft: 'auto' }}
                danger
                loading={deleting.isFetching}>
                Delete
              </Button>
            </Popconfirm>
          ),
        })
      ) : (
        <Row justify="center" style={{ height: '50vh' }} align="middle">
          <Spin size="large" />
        </Row>
      )}
    </div>
  );
};

export default Detail;
