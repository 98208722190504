import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/schedules/form';

const Create: React.FC = () => {
  const initialValues = {
    identifier: '',
  };

  return (
    <GenericCreate
      resolver="schedule"
      listResolver="schedules"
      createRoute="schedules:create"
      listRoute="schedules"
      detailRoute="schedule"
      detailIdParam="scheduleId"
      listTitle="Schedules"
      listItemTitle={entity => entity.identifier}
      itemName="schedule">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
