import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedActivity } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedActivity>
      resolver="activities"
      itemName="activity"
      listItemTitle={entity => entity.title}
      listTitle="Activities"
      detailRoute="activity"
      detailIdParam="activityId"
      createRoute="activities:create"
      dependencies={['roles', 'schedules', 'policies']}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default List;
