/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LabelCreation } from '../models/LabelCreation';
import type { LabelListing } from '../models/LabelListing';
import type { LabelRetrieval } from '../models/LabelRetrieval';
import type { LabelUpdate } from '../models/LabelUpdate';
import { request as __request } from '../core/request';

export class LabelsService {

    /**
     * List labels
     * @returns any
     * @throws ApiError
     */
    public static async listLabels({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<LabelListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/labels/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new label object
     * @returns LabelCreation
     * @throws ApiError
     */
    public static async createLabel({
        data,
    }: {
        data: LabelCreation,
    }): Promise<LabelCreation> {
        const result = await __request({
            method: 'POST',
            path: `/labels/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single label
     * @returns LabelRetrieval
     * @throws ApiError
     */
    public static async getLabel({
        uuid,
    }: {
        /** A UUID string identifying this label. **/
        uuid: string,
    }): Promise<LabelRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/labels/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified label
     * @returns LabelUpdate
     * @throws ApiError
     */
    public static async updateLabel({
        uuid,
        data,
    }: {
        /** A UUID string identifying this label. **/
        uuid: string,
        data: LabelUpdate,
    }): Promise<LabelUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/labels/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified label
     * @returns LabelUpdate
     * @throws ApiError
     */
    public static async partialUpdateLabel({
        uuid,
        data,
    }: {
        /** A UUID string identifying this label. **/
        uuid: string,
        data: LabelUpdate,
    }): Promise<LabelUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/labels/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified label
     * @returns void
     * @throws ApiError
     */
    public static async destroyLabel({
        uuid,
    }: {
        /** A UUID string identifying this label. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/labels/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}