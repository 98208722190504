/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RiskTreatmentActionCreation } from '../models/RiskTreatmentActionCreation';
import type { RiskTreatmentActionListing } from '../models/RiskTreatmentActionListing';
import type { RiskTreatmentActionRetrieval } from '../models/RiskTreatmentActionRetrieval';
import type { RiskTreatmentActionUpdate } from '../models/RiskTreatmentActionUpdate';
import { request as __request } from '../core/request';

export class RiskTreatmentActionsService {

    /**
     * List risk treatment actions
     * @returns any
     * @throws ApiError
     */
    public static async listRiskTreatmentActions({
        risk,
        page,
        pageSize,
    }: {
        risk?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<RiskTreatmentActionListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/risk-treatment-actions/`,
            query: {
                'risk': risk,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new risk treatment action object
     * @returns RiskTreatmentActionCreation
     * @throws ApiError
     */
    public static async createRiskTreatmentAction({
        data,
    }: {
        data: RiskTreatmentActionCreation,
    }): Promise<RiskTreatmentActionCreation> {
        const result = await __request({
            method: 'POST',
            path: `/risk-treatment-actions/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single risk treatment action
     * @returns RiskTreatmentActionRetrieval
     * @throws ApiError
     */
    public static async getRiskTreatmentAction({
        uuid,
    }: {
        /** A UUID string identifying this risk treatment action. **/
        uuid: string,
    }): Promise<RiskTreatmentActionRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/risk-treatment-actions/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified risk treatment action
     * @returns RiskTreatmentActionUpdate
     * @throws ApiError
     */
    public static async updateRiskTreatmentAction({
        uuid,
        data,
    }: {
        /** A UUID string identifying this risk treatment action. **/
        uuid: string,
        data: RiskTreatmentActionUpdate,
    }): Promise<RiskTreatmentActionUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/risk-treatment-actions/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified risk treatment action
     * @returns RiskTreatmentActionUpdate
     * @throws ApiError
     */
    public static async partialUpdateRiskTreatmentAction({
        uuid,
        data,
    }: {
        /** A UUID string identifying this risk treatment action. **/
        uuid: string,
        data: RiskTreatmentActionUpdate,
    }): Promise<RiskTreatmentActionUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/risk-treatment-actions/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified risk treatment action
     * @returns void
     * @throws ApiError
     */
    public static async destroyRiskTreatmentAction({
        uuid,
    }: {
        /** A UUID string identifying this risk treatment action. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/risk-treatment-actions/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}