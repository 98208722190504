import React from 'react';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Tooltip, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import DateTooltip from 'components/date';

import { url } from 'routes';
import MapRiskTreatmentActions from 'routes/risks/map-rtas';

import { useDispatch, useSelector } from 'store/hooks';
import { unmapRiskTreatmentActionFromRisk } from 'store/data';
import { DenormalizedRiskTreatmentAction } from 'store/data';
import { selectLoading } from 'store/loading';

interface Props {
  riskTreatmentActions: DenormalizedRiskTreatmentAction[];
  riskId: string;
  upcoming?: boolean;
  onUnmap?: () => void;
  disableAdding?: boolean;
}

const Table: React.FC<Props> = ({
  riskTreatmentActions,
  riskId,
  onUnmap = () => {},
  upcoming = false,
  disableAdding = false,
}) => {
  const dispatch = useDispatch();
  const unmapping = useSelector(state =>
    selectLoading(state, unmapRiskTreatmentActionFromRisk.TYPE, riskId)
  );

  return (
    <>
      <div>
        {orderBy(riskTreatmentActions, ['human_id'], ['asc']).length ? (
          <div className="risk-heatmap ant-table ant-table-small ant-table-bordered ant-table-layout-fixed pb-2">
            <div className="ant-table-container">
              <div className="ant-table-content">
                <table style={{ tableLayout: 'fixed' }}>
                  <thead className="ant-table-thead">
                    <tr className="ant-table-row">
                      <th className="ant-table-cell">Title</th>
                      <th className="ant-table-cell" style={{ width: 110 }}>
                        Owner
                      </th>
                      <th className="ant-table-cell">Description</th>
                      <th className="ant-table-cell" style={{ width: 110 }}>
                        Deadline
                      </th>
                      <th className="ant-table-cell" style={{ width: 110 }}>
                        Status
                      </th>
                      <th className="ant-table-cell" style={{ width: 46 }}></th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    {orderBy(riskTreatmentActions, ['human_id'], ['asc']).map(
                      riskTreatmentAction => (
                        <tr
                          className="ant-table-row"
                          key={riskTreatmentAction.uuid}>
                          <td className="ant-table-cell">
                            <Link
                              to={url('risk-treatment-action', {
                                riskTreatmentActionId: riskTreatmentAction.uuid,
                              })}>
                              {riskTreatmentAction.human_id}.{' '}
                              {riskTreatmentAction.title}
                              <br />
                            </Link>
                          </td>
                          <td className="ant-table-cell">
                            {riskTreatmentAction.owner?.name}
                          </td>
                          <Tooltip title={riskTreatmentAction.description}>
                            <td className="ant-table-cell ant-table-cell-ellipsis">
                              {riskTreatmentAction.description}
                            </td>
                          </Tooltip>
                          <td className="ant-table-cell">
                            {riskTreatmentAction.deadline ? (
                              <DateTooltip date={riskTreatmentAction.deadline}>
                                {riskTreatmentAction.deadline}
                              </DateTooltip>
                            ) : null}
                          </td>
                          <td className="ant-table-cell">
                            {riskTreatmentAction.status}
                          </td>
                          <td className="ant-table-cell">
                            <Tooltip
                              title={
                                riskTreatmentAction?.risks?.length &&
                                riskTreatmentAction?.risks?.length === 1
                                  ? 'Cannot unmap RTA since this is the only risk it applies to.'
                                  : null
                              }>
                              <Button
                                key="unlink"
                                disabled={
                                  riskTreatmentAction.risks?.length === 1 ||
                                  unmapping.isFetching
                                }
                                size="small"
                                onClick={() => {
                                  dispatch(
                                    unmapRiskTreatmentActionFromRisk({
                                      riskTreatmentActionId:
                                        riskTreatmentAction.uuid,
                                      riskId,
                                    })
                                  );
                                  onUnmap();
                                }}>
                                <CloseOutlined />
                              </Button>
                            </Tooltip>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
        {!disableAdding ? (
          <MapRiskTreatmentActions upcoming={upcoming} riskId={riskId} />
        ) : null}
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        className="pr-1">
        {!disableAdding ? (
          <Link to={url('risk:add-risk-treatment-action', { riskId })}>
            + Add new
          </Link>
        ) : (
          'Adding is disabled, cause risk treatment type is not mitigate'
        )}
      </div>
    </>
  );
};

export default Table;
