/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TokenObtainPair } from '../models/TokenObtainPair';
import type { TokenRefresh } from '../models/TokenRefresh';
import { request as __request } from '../core/request';

export class JwtService {

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web
     * token pair to prove the authentication of those credentials.
     * @returns TokenObtainPair
     * @throws ApiError
     */
    public static async jwtTokenCreate({
        data,
    }: {
        data: TokenObtainPair,
    }): Promise<TokenObtainPair> {
        const result = await __request({
            method: 'POST',
            path: `/jwt/token/`,
            body: data,
        });
        return result.body;
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web
     * token if the refresh token is valid.
     * @returns TokenRefresh
     * @throws ApiError
     */
    public static async jwtTokenRefreshCreate({
        data,
    }: {
        data: TokenRefresh,
    }): Promise<TokenRefresh> {
        const result = await __request({
            method: 'POST',
            path: `/jwt/token/refresh/`,
            body: data,
        });
        return result.body;
    }

}