import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedLabel } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const Labels: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedLabel>
      resolver="labels"
      itemName="label"
      listItemTitle={entity =>
        `${entity.scope ? `${entity.scope}:` : ''}${entity.value}`
      }
      listTitle="Labels"
      detailRoute="label"
      detailIdParam="labelId"
      createRoute="labels:create"
      dependencies={[]}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default Labels;
