import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/definitions/form';

const Create: React.FC = () => {
  const initialValues = {
    name: '',
    slug: '',
    explanation: '',
  };

  return (
    <GenericCreate
      resolver="definition"
      listResolver="definitions"
      createRoute="definitions:create"
      listRoute="definitions"
      detailRoute="definition"
      detailIdParam="definitionId"
      listTitle="Definitions"
      listItemTitle={entity => entity.name}
      itemName="definition">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
