import { combineReducers } from 'redux';
// import { persistReducer } from "redux-persist";
import { connectRouter } from 'connected-react-router';
import entities from 'store/entities';
import { list, detail, update, create, destroy } from 'store/common';
import data, {
  exportToGit,
  downloadBuild,
  mapActivityToSection,
  unmapActivityFromSection,
  mapRiskTreatmentActionToRisk,
  unmapRiskTreatmentActionFromRisk,
  promoteVersion,
  requestApproval,
} from 'store/data';
import loading, { mapUpdateActionToKey } from 'store/loading';

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    entities, // persistReducer(entitiesPersistConfig, entitiesReducer),
    data,
    loading: combineReducers({
      [list.TYPE]: loading({
        types: list.TYPES,
        mapActionToKey: action =>
          mapUpdateActionToKey(action.meta.resolver, [
            action.meta.paginationKey,
          ]),
      }),
      [detail.TYPE]: loading({
        types: detail.TYPES,
        mapActionToKey: action =>
          mapUpdateActionToKey(action.meta.resolver, [action.meta.loadingKey]),
      }),
      [update.TYPE]: loading({
        types: update.TYPES,
        mapActionToKey: action =>
          mapUpdateActionToKey(
            `${action.meta.resolver}_${action.meta.loadingKey}`,
            action.meta.fields
          ),
      }),
      [create.TYPE]: loading({
        types: create.TYPES,
        mapActionToKey: action =>
          mapUpdateActionToKey(action.meta.resolver, [action.meta.loadingKey]),
      }),
      [destroy.TYPE]: loading({
        types: destroy.TYPES,
        mapActionToKey: action =>
          mapUpdateActionToKey(action.meta.resolver, [action.meta.loadingKey]),
      }),
      [downloadBuild.TYPE]: loading({
        types: downloadBuild.TYPES,
        mapActionToKey: action => action.payload.uuid,
      }),
      [exportToGit.TYPE]: loading({
        types: exportToGit.TYPES,
        mapActionToKey: action => action.meta.loadingKey,
      }),
      [mapActivityToSection.TYPE]: loading({
        types: mapActivityToSection.TYPES,
        mapActionToKey: action => action.meta.loadingKey,
      }),
      [unmapActivityFromSection.TYPE]: loading({
        types: unmapActivityFromSection.TYPES,
        mapActionToKey: action => action.meta.loadingKey,
      }),
      [mapRiskTreatmentActionToRisk.TYPE]: loading({
        types: mapRiskTreatmentActionToRisk.TYPES,
        mapActionToKey: action => action.meta.loadingKey,
      }),
      [unmapRiskTreatmentActionFromRisk.TYPE]: loading({
        types: unmapRiskTreatmentActionFromRisk.TYPES,
        mapActionToKey: action => action.meta.loadingKey,
      }),
      [requestApproval.TYPE]: loading({
        types: requestApproval.TYPES,
        mapActionToKey: action => action.payload.uuid,
      }),
      [promoteVersion.TYPE]: loading({
        types: promoteVersion.TYPES,
        mapActionToKey: action => action.payload.uuid,
      }),
    }),
  });

export default createRootReducer;
