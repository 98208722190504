import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import EditTitleAndContent from 'components/edit-title-and-content';

import { url } from 'routes';

import { update } from 'store/common';
import { useDispatch, useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';
import { selectLoadingUpdate } from 'store/loading';

interface Props
  extends RouteComponentProps<{ policyId: string; sectionId: string }> {}

const Section: React.FC<Props> = ({ match }) => {
  const dispatch = useDispatch();
  const section = useSelector(state =>
    selectEntity(state, 'section', match.params.sectionId)
  );
  const updating = useSelector(state =>
    selectLoadingUpdate(state, 'section', match.params.sectionId, 'content')
  );

  return (
    <>
      <div className="section section--active">
        <EditTitleAndContent
          upload={true}
          title={section.title || ''}
          content={section.content || ''}
          titlePrefix={`${section.numbering}. `}
          isSaving={updating.isFetching}
          onSave={({ content, title }) =>
            dispatch(
              update({
                uuid: section.uuid,
                resolver: 'section',
                patch: { content, title },
              })
            )
          }>
          <Link
            className="section__close"
            to={url('policy', { policyId: match.params.policyId })}>
            &times;
          </Link>
        </EditTitleAndContent>
      </div>
    </>
  );
};

export default Section;
