import React from 'react';

import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/labels/form';

import { useSelector } from 'store/hooks';
import { DenormalizedLabel } from 'store/data';
import { selectDetail } from 'store/common';

const Label: React.FC = () => {
  const { labelId } = useParams('label');
  const label = useSelector(state =>
    selectDetail<DenormalizedLabel>(state, 'label', labelId as string)
  );

  const initialValues = {
    scope: label.scope,
    color: label.color,
    value: label.value,
  };

  return (
    <GenericDetail
      resolver="label"
      listRoute="labels"
      createRoute="labels:create"
      detailIdParam="labelId"
      listTitle="Labels"
      listItemTitle={entity =>
        `${entity.scope ? `${entity.scope}:` : ''}${entity.value}`
      }
      itemName="label">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericDetail>
  );
};

export default Label;
