import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedThreatClass } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedThreatClass>
      resolver="threatClasses"
      itemName="threat class"
      listItemTitle={entity => entity.name}
      listTitle="Threat classes"
      detailRoute="threat-class"
      detailIdParam="threatClassId"
      createRoute="threat-classes:create"
      dependencies={[]}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default List;
