/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PolicySectionCreation } from '../models/PolicySectionCreation';
import type { PolicySectionListing } from '../models/PolicySectionListing';
import type { PolicySectionMove } from '../models/PolicySectionMove';
import type { PolicySectionRetrieval } from '../models/PolicySectionRetrieval';
import type { PolicySectionUpdate } from '../models/PolicySectionUpdate';
import { request as __request } from '../core/request';

export class SectionsService {

    /**
     * List sections
     * @returns any
     * @throws ApiError
     */
    public static async listSections({
        policy,
        title,
        page,
        pageSize,
    }: {
        policy?: string,
        title?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<PolicySectionListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/sections/`,
            query: {
                'policy': policy,
                'title': title,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new section object
     * @returns PolicySectionCreation
     * @throws ApiError
     */
    public static async createSection({
        data,
    }: {
        data: PolicySectionCreation,
    }): Promise<PolicySectionCreation> {
        const result = await __request({
            method: 'POST',
            path: `/sections/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single section
     * @returns PolicySectionRetrieval
     * @throws ApiError
     */
    public static async getSection({
        uuid,
    }: {
        /** A UUID string identifying this section. **/
        uuid: string,
    }): Promise<PolicySectionRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/sections/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified section
     * @returns PolicySectionUpdate
     * @throws ApiError
     */
    public static async updateSection({
        uuid,
        data,
    }: {
        /** A UUID string identifying this section. **/
        uuid: string,
        data: PolicySectionUpdate,
    }): Promise<PolicySectionUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/sections/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified section
     * @returns PolicySectionUpdate
     * @throws ApiError
     */
    public static async partialUpdateSection({
        uuid,
        data,
    }: {
        /** A UUID string identifying this section. **/
        uuid: string,
        data: PolicySectionUpdate,
    }): Promise<PolicySectionUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/sections/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified section
     * @returns void
     * @throws ApiError
     */
    public static async destroySection({
        uuid,
    }: {
        /** A UUID string identifying this section. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/sections/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicySectionMove
     * @throws ApiError
     */
    public static async moveAfterSection({
        uuid,
        data,
    }: {
        /** A UUID string identifying this section. **/
        uuid: string,
        data: PolicySectionMove,
    }): Promise<PolicySectionMove> {
        const result = await __request({
            method: 'POST',
            path: `/sections/${uuid}/move_after/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicySectionMove
     * @throws ApiError
     */
    public static async moveBeforeSection({
        uuid,
        data,
    }: {
        /** A UUID string identifying this section. **/
        uuid: string,
        data: PolicySectionMove,
    }): Promise<PolicySectionMove> {
        const result = await __request({
            method: 'POST',
            path: `/sections/${uuid}/move_before/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicySectionMove
     * @throws ApiError
     */
    public static async moveInsideSection({
        uuid,
        data,
    }: {
        /** A UUID string identifying this section. **/
        uuid: string,
        data: PolicySectionMove,
    }): Promise<PolicySectionMove> {
        const result = await __request({
            method: 'POST',
            path: `/sections/${uuid}/move_inside/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}