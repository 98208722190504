import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/references/form';

const Create: React.FC = () => {
  const initialValues = {
    name: '',
    url: '',
  };

  return (
    <GenericCreate
      resolver="reference"
      listResolver="references"
      createRoute="references:create"
      listRoute="references"
      detailRoute="reference"
      detailIdParam="referenceId"
      listTitle="References"
      listItemTitle={entity => entity.name}
      itemName="reference">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
