import { uniq, without, mapValues } from 'lodash';

interface PaginateArgs {
  types: string[];
  resolver?: string;
}

export interface Page {
  ids: string[];
  isFetching: boolean;
  nextPage: number | undefined;
}
export interface PaginationState {
  [key: string]: {
    [p: number]: Page;
  };
}

/**
 * Gets ids of all paginated entities starting from page 1
 */
export const getIds = (
  pagination: PaginationState,
  key: string,
  startFrom = 1
) => {
  if (!pagination || !key || !pagination[key]) {
    return [];
  }

  let ids: string[] = [];
  let currentPage: Page | null = pagination[key][startFrom];

  while (currentPage) {
    ids = ids.concat(currentPage.ids);
    currentPage = currentPage.nextPage
      ? pagination[key][currentPage.nextPage]
      : null;
  }

  return uniq(ids);
};

export const getLastPage = (
  pagination: PaginationState,
  key: string,
  startFrom = 1
) => {
  let lastPageNumber = startFrom;
  let currentPage: Page | undefined = (pagination[key] || [])[lastPageNumber];

  while (
    currentPage &&
    currentPage.nextPage &&
    pagination[key][currentPage.nextPage]
  ) {
    lastPageNumber = currentPage.nextPage;
    currentPage = pagination[key][currentPage.nextPage];
  }

  return lastPageNumber;
};

export const selectLastPage = (
  pagination: PaginationState,
  key: string,
  startFrom = 1
) => {
  return pagination[key]
    ? pagination[key][getLastPage(pagination, key, startFrom)]
    : null;
};

export const deleteIdFromPages = (pagination: PaginationState, id: string) => {
  return mapValues(pagination, paginationKey =>
    mapValues(paginationKey, page => {
      return {
        ...page,
        ids: without(page.ids, id),
      };
    })
  );
};

// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.
const paginate = ({ types, resolver }: PaginateArgs) => {
  const [requestType, successType, failureType] = types;

  const updatePagination = (
    state: {
      isFetching: boolean;
      nextPage: number | undefined;
      ids: string[];
    } = {
      isFetching: false,
      nextPage: undefined,
      ids: [],
    },
    action: any
  ): Page => {
    switch (action.type) {
      case requestType: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case successType: {
        return {
          ...state,
          isFetching: false,
          ids: action.payload.result,
          nextPage: action.payload.nextPage,
        };
      }
      case failureType: {
        return {
          ...state,
          isFetching: false,
        };
      }
      // istanbul ignore next
      default: {
        return state;
      }
    }
  };

  return (state: PaginationState = {}, action: any) => {
    // Update pagination by key
    switch (action.type) {
      case requestType:
      case successType:
      case failureType: {
        if (resolver && action.meta.resolver !== resolver) {
          return state;
        }

        const key = action.meta.paginationKey;
        const page = Number(
          typeof action.payload.offset !== 'undefined'
            ? action.payload.offset
            : action.payload.page || 1
        );

        if (typeof key !== 'string') {
          throw new Error(
            `action.meta.paginationKey must be a string, got this instead: \n${JSON.stringify(
              action,
              null,
              2
            )}`
          );
        }

        const stateByKey = { ...(state[key] || {}) };

        return {
          ...state,
          [key]: {
            ...stateByKey,
            [page]: updatePagination(stateByKey[page], action),
          },
        };
      }

      default: {
        return state;
      }
    }
  };
};

export default paginate;
