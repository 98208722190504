import React from 'react';
import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/violations/form';

import { useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';

const Detail: React.FC = () => {
  const { violationId } = useParams('violation') as {
    violationId: string;
  };
  const entity = useSelector(state =>
    selectEntity(state, 'violation', violationId)
  );

  const initialValues = {
    label: entity.label,
    explanation: entity.explanation,
  };

  return (
    <>
      <GenericDetail
        resolver="violation"
        listRoute="violations"
        detailIdParam="violationId"
        listTitle="Violations"
        createRoute="violations:create"
        listItemTitle={entity => entity.label}
        itemName="violation">
        {props => (
          <>
            <EditForm initialValues={initialValues} {...props} />
          </>
        )}
      </GenericDetail>
    </>
  );
};

export default Detail;
