import React, { useEffect } from 'react';
import { List, Tag } from 'antd';
import { Link } from 'react-router-dom';

import DateTooltip, { formatDate } from 'components/date';

import { url } from 'routes';

import { list } from 'store/common';
import {
  DenormalizedPolicy,
  selectEntities,
  DenormalizedPolicyVersion,
} from 'store/data';
import { useDispatch, useSelector } from 'store/hooks';

interface Props {
  selectedVersion: DenormalizedPolicyVersion;
  policy: DenormalizedPolicy;
  onClick: () => void;
}

const Versions: React.FC<Props> = ({ policy, onClick, selectedVersion }) => {
  const dispatch = useDispatch();
  const versions = useSelector(state =>
    selectEntities(state, 'policyVersions', policy.uuid)
  );

  useEffect(() => {
    dispatch(
      list({
        resolver: 'policyVersions',
        paginationKey: policy.uuid,
        extra: {
          policy: policy.uuid,
        },
      })
    );
  }, [dispatch, policy.uuid]);

  return (
    <List>
      {versions.map(policyVersion => (
        <Link
          onClick={() => onClick()}
          to={url('policy:versions:builds', {
            policyId: policy.uuid,
            versionId: policyVersion.uuid,
          })}>
          <List.Item
            className="pl-2 pr-2"
            style={{
              backgroundColor:
                selectedVersion.uuid === policyVersion.uuid
                  ? '#00bbff40'
                  : '#fff',
            }}
            key={policyVersion.uuid}
            extra={
              policyVersion.uuid === policy.latest_version?.uuid ? (
                <Tag>latest version</Tag>
              ) : policyVersion.uuid === policy.current_version?.uuid ? (
                <Tag>current version</Tag>
              ) : null
            }>
            <List.Item.Meta
              title={
                <>
                  Version {policyVersion.version}
                  <Tag
                    style={{ marginLeft: 10 }}
                    color={
                      policyVersion.status === 'draft' ? 'error' : 'success'
                    }>
                    {policyVersion.status}
                  </Tag>
                </>
              }
              description={
                <>
                  Added at{' '}
                  {policyVersion.added_at ? (
                    <DateTooltip
                      placement="right"
                      date={policyVersion.added_at}>
                      {formatDate(policyVersion.added_at)}
                    </DateTooltip>
                  ) : null}
                </>
              }
            />
          </List.Item>
        </Link>
      ))}
    </List>
  );
};

export default Versions;
