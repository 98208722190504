import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedAcronym } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedAcronym>
      resolver="acronyms"
      itemName="acronym"
      listItemTitle={entity => entity.acronym}
      listTitle="Acronyms"
      detailRoute="acronym"
      detailIdParam="acronymId"
      createRoute="acronyms:create"
      dependencies={['definitions']}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default List;
