import React from 'react';
import { Form, Input, Slider } from 'antd';
import { AssetClassificationUpdate } from 'api';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';

import { convertRatingToMarks } from 'utils';

type Props = Omit<GenericFormProps, 'renderFields'>;

const EditForm: React.FC<Props> = props => {
  const level = convertRatingToMarks(AssetClassificationUpdate.level);

  level[0] = 0;

  return (
    <GenericForm
      {...props}
      renderFields={() => (
        <>
          <Form.Item required label="Label" name="label">
            <Input className="monospace" />
          </Form.Item>

          <Form.Item name="level" label="Level">
            <Slider max={4} step={null} marks={level} />
          </Form.Item>
        </>
      )}
    />
  );
};

export default EditForm;
