/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefinitionCreation } from '../models/DefinitionCreation';
import type { DefinitionListing } from '../models/DefinitionListing';
import type { DefinitionRetrieval } from '../models/DefinitionRetrieval';
import type { DefinitionUpdate } from '../models/DefinitionUpdate';
import { request as __request } from '../core/request';

export class DefinitionsService {

    /**
     * List definitions
     * @returns any
     * @throws ApiError
     */
    public static async listDefinitions({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<DefinitionListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/definitions/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new definition object
     * @returns DefinitionCreation
     * @throws ApiError
     */
    public static async createDefinition({
        data,
    }: {
        data: DefinitionCreation,
    }): Promise<DefinitionCreation> {
        const result = await __request({
            method: 'POST',
            path: `/definitions/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single definition
     * @returns DefinitionRetrieval
     * @throws ApiError
     */
    public static async getDefinition({
        uuid,
    }: {
        /** A UUID string identifying this definition. **/
        uuid: string,
    }): Promise<DefinitionRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/definitions/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified definition
     * @returns DefinitionUpdate
     * @throws ApiError
     */
    public static async updateDefinition({
        uuid,
        data,
    }: {
        /** A UUID string identifying this definition. **/
        uuid: string,
        data: DefinitionUpdate,
    }): Promise<DefinitionUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/definitions/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified definition
     * @returns DefinitionUpdate
     * @throws ApiError
     */
    public static async partialUpdateDefinition({
        uuid,
        data,
    }: {
        /** A UUID string identifying this definition. **/
        uuid: string,
        data: DefinitionUpdate,
    }): Promise<DefinitionUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/definitions/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified definition
     * @returns void
     * @throws ApiError
     */
    public static async destroyDefinition({
        uuid,
    }: {
        /** A UUID string identifying this definition. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/definitions/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}