import React from 'react';

import { Link, Switch } from 'react-router-dom';

import Header from 'components/header';
import Layout from 'components/layout';

import { RouteWithSubRoutes, RouteInterface, url } from 'routes';
import AssetsTable from 'routes/assets/table';

import { useDispatch } from 'store/hooks';
import { list } from 'store/common';

import { useFetchEntities } from 'utils';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  const dispatch = useDispatch();

  const deps = ['roles', 'assetClasses', 'assetTypes', 'locations'].join('|');

  useFetchEntities(() => {
    dispatch(
      list({
        resolver: 'assets',
        paginationKey: 'all',
      })
    );
  }, [dispatch]);

  useFetchEntities(() => {
    deps.split('|').forEach(dep =>
      dispatch(
        list({
          resolver: dep as any,
          paginationKey: 'all',
        })
      )
    );
  }, [dispatch, deps]);

  return (
    <Layout>
      <Header documentTitle="Assets">
        <Link className="ant-btn" to={url('assets:create')}>
          Create asset
        </Link>
      </Header>
      <AssetsTable />
      <Switch>
        {routes.map(r => (
          <RouteWithSubRoutes key={r.path} {...r} />
        ))}
      </Switch>
    </Layout>
  );
};

export default List;
