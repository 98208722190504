import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/vulnerability-classes/form';

const Create: React.FC = () => {
  const initialValues = {
    name: '',
  };

  return (
    <GenericCreate
      resolver="vulnerabilityClass"
      listResolver="vulnerabilityClasses"
      createRoute="vulnerability-classes:create"
      listRoute="vulnerability-classes"
      detailRoute="vulnerability-class"
      detailIdParam="vulnerabilityClassId"
      listTitle="Vulnerability classes"
      listItemTitle={entity => entity.name}
      itemName="vulnerability class">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
