import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { Row, Button, Typography, Select } from 'antd';
import { Switch, Route, Link, useLocation } from 'react-router-dom';

import PrivateRoute from 'components/private-route';
import ScrollIntoView from 'components/scroll-into-view';

import { url } from 'routes';

import { list, update } from 'store/common';
import { DenormalizedPolicy } from 'store/data';
import { selectLoadingUpdate } from 'store/loading';
import { useDispatch, useEntities, useSelector } from 'store/hooks';

interface Props {
  policy: DenormalizedPolicy;
  numbering: number;
}

const { Title } = Typography;

const EditViolation: React.FC<Props> = ({ policy, numbering }) => {
  const isSaving = useSelector(state =>
    selectLoadingUpdate(state, 'policy', policy.uuid, 'violation')
  ).isFetching;
  const dispatch = useDispatch();
  const location = useLocation();
  const [violation, setViolation] = useState(policy.violation?.uuid || null);

  const [violations] = useEntities('violations', 'all');
  const selectedViolation = find(violations, v => v.uuid === violation);
  const isSaveEnabled = violation !== policy.violation?.uuid;

  useEffect(() => {
    dispatch(
      list({
        resolver: 'violations',
        paginationKey: 'all',
      })
    );
  }, [dispatch]);

  const onSave = (violation: string | null) => {
    dispatch(
      update({ resolver: 'policy', uuid: policy.uuid, patch: { violation } })
    );
  };

  return (
    <>
      <Title level={2} className="mb-2">
        <span className="section__heading monospace">
          {numbering}. Violations
        </span>
      </Title>
      <Switch>
        <PrivateRoute
          path={url('policy:edit', {
            policyId: policy.uuid,
            field: 'violation',
          })}
          exact
          render={() => (
            <div className="section section--active">
              <div className="section__heading">
                <Select
                  value={violation as string}
                  onChange={value => {
                    setViolation(value);
                  }}
                  style={{ width: '100%' }}
                  className="monospace">
                  <Select.Option key="empty" value={null as any}>
                    None
                  </Select.Option>
                  {violations.map(v => (
                    <Select.Option key={v.uuid} value={v.uuid}>
                      {v.label}
                    </Select.Option>
                  ))}
                </Select>
                <Link
                  className="section__close"
                  to={url('policy', { policyId: policy.uuid })}>
                  &times;
                </Link>
              </div>
              <p className="monospace">{selectedViolation?.explanation}</p>
              <Row justify="space-between" style={{ marginTop: 15 }}>
                <Button
                  disabled={!isSaveEnabled}
                  onClick={() => {
                    setViolation(policy.violation?.uuid || null);
                  }}>
                  Discard changes
                </Button>
                <Button
                  style={{ marginLeft: 'auto' }}
                  loading={isSaving}
                  onClick={() => onSave(violation)}
                  type="primary"
                  disabled={!isSaveEnabled}>
                  Save
                </Button>
              </Row>
              <ScrollIntoView />
            </div>
          )}
        />
        <Route
          path={location.pathname}
          exact
          render={() => (
            <Link
              to={url('policy:edit', {
                policyId: policy.uuid,
                field: 'violation',
              })}
              className="monospace section section__edit">
              {policy.violation?.label ? (
                <Title level={3}>
                  <div className="section__heading">
                    {policy.violation?.label}
                  </div>
                </Title>
              ) : null}
              {policy.violation?.explanation || (
                <p>
                  <i>No content</i>
                </p>
              )}
            </Link>
          )}
        />
      </Switch>
    </>
  );
};

export default EditViolation;
