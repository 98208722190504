import React, { useEffect } from 'react';
import { take } from 'lodash';
import { Link } from 'react-router-dom';

import { url, useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/asset-classes/form';

import { useSelector, useDispatch } from 'store/hooks';
import { selectEntities, DenormalizedAssetClass } from 'store/data';
import { list, selectDetail } from 'store/common';

const Detail: React.FC = () => {
  const dispatch = useDispatch();
  const { assetClassId } = useParams('asset-class') as { assetClassId: string };
  const entity = useSelector(state =>
    selectDetail<DenormalizedAssetClass>(state, 'assetClass', assetClassId)
  );

  const assets = useSelector(state =>
    selectEntities(state, 'assets', assetClassId)
  );

  const initialValues = {
    name: entity.name,
    slug: entity.slug,
    description: entity.description,
    confidentiality_rating: entity.confidentiality_rating,
    integrity_rating: entity.integrity_rating,
    availability_rating: entity.availability_rating,
    asset_type: entity.asset_type?.uuid,
  };

  useEffect(() => {
    dispatch(
      list({
        resolver: 'assets',
        paginationKey: assetClassId,
        extra: {
          assetClass: assetClassId,
        },
      })
    );
  }, [assetClassId, dispatch]);

  return (
    <>
      <GenericDetail
        resolver="assetClass"
        listRoute="asset-classes"
        detailIdParam="assetClassId"
        listTitle="Asset classes"
        createRoute="asset-classes:create"
        listItemTitle={entity => entity.name}
        itemName="asset class">
        {props => (
          <>
            <EditForm initialValues={initialValues} {...props} />
            {assets.length ? (
              <div className="mb-3 pb-2">
                Example assets:
                <br />
                {take(assets, 5).map(asset => (
                  <Link to={url('asset', { assetId: asset.uuid })}>
                    {asset.name}
                    <br />
                  </Link>
                ))}
              </div>
            ) : null}
          </>
        )}
      </GenericDetail>
    </>
  );
};

export default Detail;
