import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Popover } from 'antd';
import { truncate } from 'lodash';

import { useDispatch, useSelector } from 'store/hooks';
import { selectEntities, selectEntity } from 'store/data';
import { list } from 'store/common';
import { usePrevious } from 'utils';

interface Props {
  onChange?: any;
  value?: any;
}

const SectionsSelect: React.FC<Props> = ({ onChange, value }) => {
  const dispatch = useDispatch();
  const policies = useSelector(state =>
    selectEntities(state, 'policies', 'all')
  );
  const selectedSection = useSelector(state =>
    selectEntity(state, 'section', value)
  );

  const [policy, setPolicy] = useState(selectedSection?.policy);
  const sections = useSelector(state =>
    policy ? selectEntities(state, 'sections', policy) : []
  );

  const previousPolicy = usePrevious(policy);

  useEffect(() => {
    if (selectedSection?.policy) {
      setPolicy(selectedSection?.policy);
    }
  }, [selectedSection?.policy]);

  useEffect(() => {
    if (policy) {
      dispatch(
        list({
          resolver: 'sections',
          paginationKey: policy,
          extra: {
            policy,
          },
        })
      );
    }
  }, [policy, dispatch]);

  useEffect(() => {
    if (previousPolicy && policy && previousPolicy !== policy) {
      onChange(undefined);
    }
  }, [policy, onChange, previousPolicy]);

  return (
    <Row gutter={20}>
      <Col xs={12}>
        <Select
          optionFilterProp="children"
          showSearch
          value={policy}
          onChange={v => setPolicy(v)}>
          {policies.map(p => (
            <Select.Option value={p.uuid} key={p.uuid}>
              {p.title}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col xs={12}>
        <Select
          showSearch
          optionFilterProp="label"
          value={value}
          onChange={v => onChange(v)}>
          {sections.map(p => (
            <Select.Option
              key={p.uuid}
              value={p.uuid}
              label={`${p.numbering}. ${p.title}`}>
              {p.content ? (
                <Popover
                  overlayStyle={{ width: 400 }}
                  key={p.uuid}
                  placement="right"
                  title={p.title}
                  content={
                    <div className="monospace">
                      {truncate(p.content, { length: 300 })}
                    </div>
                  }>
                  <div>
                    {p.numbering}. {p.title}
                  </div>
                </Popover>
              ) : (
                <>
                  {p.numbering}. {p.title}
                </>
              )}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default SectionsSelect;
