import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Descriptions, Modal } from 'antd';

import DateTooltip, { formatDate } from 'components/date';

import { url, useParams } from 'routes';

import { useSelector, useValidateEntityExistence } from 'store/hooks';
import { selectEntity } from 'store/data';

import { userName, formatBytes } from 'utils';

const CollectionRun: React.FC = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(true);
  const { evidenceId, collectionRunId } = useParams(
    'evidence:collection-run'
  ) as {
    evidenceId: string;
    collectionRunId: string;
  };

  const collectionRun = useSelector(state =>
    selectEntity(state, 'collectionRun', collectionRunId)
  );

  useValidateEntityExistence({
    entity: collectionRun,
    entityType: 'collectionRun',
    redirectUrl: url('evidence', { evidenceId }),
  });

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      width={1000}
      title={
        <>
          Collection run from{' '}
          {collectionRun.collected_at ? (
            <DateTooltip date={collectionRun.collected_at}>
              {formatDate(collectionRun.collected_at)}
            </DateTooltip>
          ) : null}
        </>
      }
      visible={visible}
      onCancel={onClose}
      forceRender={true}
      footer={null}
      afterClose={() => {
        history.push(url('evidence', { evidenceId }));
      }}>
      <Descriptions
        className="mb-3"
        labelStyle={{ width: 200, verticalAlign: 'top' }}
        column={1}
        bordered>
        <Descriptions.Item label="Evidence">
          {collectionRun?.evidence?.title}
        </Descriptions.Item>
        <Descriptions.Item label="Collected at">
          {collectionRun.collected_at ? (
            <DateTooltip date={collectionRun.collected_at}>
              {collectionRun.collected_at}
            </DateTooltip>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="Exhibits">
          <Descriptions column={1} style={{ margin: '-17px -25px' }}>
            {collectionRun.exhibits?.map(exhibit => {
              return (
                <Descriptions.Item
                  className="pb-0"
                  label={exhibit.label}
                  key={exhibit.uuid}>
                  <div>
                    File: {exhibit.filename}
                    <br />
                    Size:{' '}
                    {exhibit.filesize
                      ? formatBytes(Number(exhibit.filesize))
                      : null}
                    <br />
                    Added by:{' '}
                    <Link to={url('user', { userId: exhibit.added_by.uuid })}>
                      {userName(exhibit.added_by)}
                    </Link>
                  </div>
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default CollectionRun;
