import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedRiskTreatmentAction } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedRiskTreatmentAction>
      resolver="riskTreatmentActions"
      itemName="risk treatment action"
      listItemTitle={entity => `${entity.human_id}. ${entity.title}`}
      listTitle="Risk treatment actions"
      detailRoute="risk-treatment-action"
      detailIdParam="riskTreatmentActionId"
      createRoute="risk-treatment-actions:create"
      orderBy={'human_id'}
      dependencies={['roles', 'risks']}
      routes={routes}
      showListTitle={true}
      allPages={true}
    />
  );
};

export default List;
