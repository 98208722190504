/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SectionRequirementCoverageListing = {
    uuid: string;
    requirement_set: string;
    requirement: string;
    requirement_identifier: string;
    coverage?: SectionRequirementCoverageListing.coverage;
    gap_description?: string;
}

export namespace SectionRequirementCoverageListing {

    export enum coverage {
        UNKNOWN = 'unknown',
        NONE = 'none',
        PARTIAL = 'partial',
        FULL = 'full',
    }


}
