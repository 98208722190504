import React, { useState } from 'react';
import { Popconfirm, Button, Input, Form } from 'antd';

import { DenormalizedPolicyVersion, promoteVersion } from 'store/data';
import { useDispatch, useSelector } from 'store/hooks';
import { selectLoading } from 'store/loading';

interface Props {
  version: DenormalizedPolicyVersion;
}

const Action: React.FC<Props> = ({ version }) => {
  const dispatch = useDispatch();
  const [desiredVersion, setDesiredVersion] = useState(
    version.version?.replace(/\.dev.*$/g, '') || ''
  );
  const loading = useSelector(state =>
    selectLoading(state, promoteVersion.TYPE, version.uuid)
  );

  return (
    <Popconfirm
      title={
        <Form.Item label="Version">
          <Input
            onChange={e => setDesiredVersion(e.target.value)}
            value={desiredVersion}
          />
        </Form.Item>
      }
      onConfirm={() =>
        dispatch(
          promoteVersion({ uuid: version.uuid, version: desiredVersion })
        )
      }
      onCancel={() =>
        setDesiredVersion(version.version?.replace(/\.dev.*$/g, '') || '')
      }>
      <Button loading={loading.isFetching} style={{ marginLeft: 10 }}>
        Promote
      </Button>
    </Popconfirm>
  );
};

export default Action;
