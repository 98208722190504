import React from 'react';

import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/asset-classifications/form';

import { useSelector } from 'store/hooks';
import { DenormalizedAssetClassification } from 'store/data';
import { selectDetail } from 'store/common';

const Detail: React.FC = () => {
  const { assetClassificationId } = useParams('asset-classification');
  const entity = useSelector(state =>
    selectDetail<DenormalizedAssetClassification>(
      state,
      'assetClassification',
      assetClassificationId as string
    )
  );

  const initialValues = {
    label: entity.label,
    level: entity.level,
  };

  return (
    <GenericDetail
      resolver="assetClassification"
      listRoute="asset-classifications"
      createRoute="asset-classifications:create"
      detailIdParam="assetClassificationId"
      listTitle="Asset classifications"
      listItemTitle={entity => entity.label}
      itemName="asset classification">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericDetail>
  );
};

export default Detail;
