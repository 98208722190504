/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetCreation } from '../models/AssetCreation';
import type { AssetListing } from '../models/AssetListing';
import type { AssetRetrieval } from '../models/AssetRetrieval';
import type { AssetUpdate } from '../models/AssetUpdate';
import { request as __request } from '../core/request';

export class AssetsService {

    /**
     * List assets
     * @returns any
     * @throws ApiError
     */
    public static async listAssets({
        parent,
        assetClass,
        name,
        automaticallyManaged,
        collectedBy,
        isArchived,
        archived,
        page,
        pageSize,
    }: {
        parent?: string,
        assetClass?: string,
        name?: string,
        automaticallyManaged?: boolean,
        collectedBy?: string,
        isArchived?: boolean,
        archived?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<AssetListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/assets/`,
            query: {
                'parent': parent,
                'asset_class': assetClass,
                'name': name,
                'automatically_managed': automaticallyManaged,
                'collected_by': collectedBy,
                'is_archived': isArchived,
                'archived': archived,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new asset object
     * @returns AssetCreation
     * @throws ApiError
     */
    public static async createAsset({
        data,
    }: {
        data: AssetCreation,
    }): Promise<AssetCreation> {
        const result = await __request({
            method: 'POST',
            path: `/assets/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single asset
     * @returns AssetRetrieval
     * @throws ApiError
     */
    public static async getAsset({
        uuid,
    }: {
        /** A UUID string identifying this asset. **/
        uuid: string,
    }): Promise<AssetRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/assets/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified asset
     * @returns AssetUpdate
     * @throws ApiError
     */
    public static async updateAsset({
        uuid,
        data,
    }: {
        /** A UUID string identifying this asset. **/
        uuid: string,
        data: AssetUpdate,
    }): Promise<AssetUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/assets/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified asset
     * @returns AssetUpdate
     * @throws ApiError
     */
    public static async partialUpdateAsset({
        uuid,
        data,
    }: {
        /** A UUID string identifying this asset. **/
        uuid: string,
        data: AssetUpdate,
    }): Promise<AssetUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/assets/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified asset
     * @returns void
     * @throws ApiError
     */
    public static async destroyAsset({
        uuid,
    }: {
        /** A UUID string identifying this asset. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/assets/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}