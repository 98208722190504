import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TypedUseSelectorHook,
  useDispatch as untypedUseDispatch,
  useSelector as untypedUseSelector,
} from 'react-redux';

import { AppStateInterface, AppDispatch } from 'store';
import {
  selectEntity,
  DetailResolverToDenormalizedEntity,
  ListResolverToDenormalizedEntity,
  selectEntities,
} from 'store/data';
import {
  selectLoadingDetail,
  selectLoadingUpdate,
  selectLoadingDestroy,
  LoadingItemState,
  selectLoadingList,
} from 'store/loading';
import {
  DestroyResolver,
  detail,
  DetailResolver,
  ListResolver,
  UpdateResolver,
} from 'store/common';

import { isLoaded } from 'utils';

export const useSelector: TypedUseSelectorHook<AppStateInterface> =
  untypedUseSelector;
export const useDispatch = () => untypedUseDispatch<AppDispatch>();

export const useEntities = <T extends ListResolver>(
  resolver: T,
  paginationKey: string
): [ListResolverToDenormalizedEntity[T][], LoadingItemState] => {
  const list = useSelector(state =>
    selectEntities(state, resolver, paginationKey)
  );
  const loadingList = useSelector(state =>
    selectLoadingList(state, resolver, paginationKey)
  );

  return [list, loadingList];
};

export const useEntity = <T extends DetailResolver>(
  resolver: T,
  uuid: string
): [
  DetailResolverToDenormalizedEntity[T],
  {
    loadingDetail: LoadingItemState;
    loadingUpdate: LoadingItemState;
    loadingDestroy: LoadingItemState;
  }
] => {
  const entity = useSelector(state =>
    selectEntity(state, resolver, uuid)
  ) as DetailResolverToDenormalizedEntity[T];
  const loadingDetail = useSelector(state =>
    selectLoadingDetail(state, resolver, uuid)
  );
  const loadingUpdate = useSelector(state =>
    selectLoadingUpdate(state, resolver as UpdateResolver, uuid)
  );
  const loadingDestroy = useSelector(state =>
    selectLoadingDestroy(state, resolver as DestroyResolver, uuid)
  );

  return [
    entity,
    {
      loadingDetail,
      loadingUpdate,
      loadingDestroy,
    },
  ];
};

// fetch an entity and if it doesn't exist - redirect
export const useValidateEntityExistence = ({
  entity,
  entityType,
  redirectUrl,
  idProperty = 'uuid',
}: {
  entity: any;
  entityType: DetailResolver;
  redirectUrl: string;
  idProperty?: string;
}) => {
  const entityId = entity ? entity[idProperty] : undefined;
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingEntity = useSelector(state =>
    selectLoadingDetail(state, entityType, entityId)
  );
  const isEntityLoaded = isLoaded(entity);

  useEffect(() => {
    if (entityId) {
      dispatch(
        detail({
          resolver: entityType,
          uuid: entityId,
          failSilently: true,
        })
      );
    }
  }, [entityId, dispatch, entityType]);

  useEffect(() => {
    if (!isEntityLoaded && loadingEntity.error) {
      history.replace(redirectUrl);
    }
    // eslint-disable-next-line
  }, [loadingEntity.error, isEntityLoaded, redirectUrl]);
};
