import React, { useEffect } from 'react';
import { Button, Row, Col, Popconfirm, Typography, Divider } from 'antd';
import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { repeat } from 'lodash';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import { url, urlPattern, useParams } from 'routes';
import Activity from 'routes/activities/detail';
import CreateActivity from 'routes/activities/create';
import MapRequirements from 'routes/section/map-requirements';
import MapActivities from 'routes/section/map-activities';

import {
  useSelector,
  useDispatch,
  useValidateEntityExistence,
} from 'store/hooks';
import { list, destroy } from 'store/common';
import { selectEntity, selectEntities, mapActivityToSection } from 'store/data';
import { selectLoadingDestroy } from 'store/loading';

import { createTree, flattenByKey, findInTree } from 'utils';

const { Title } = Typography;

const Sidebar = () => {
  const dispatch = useDispatch();
  const { policyId, sectionId } = useParams('section');
  const section = useSelector(state =>
    selectEntity(state, 'section', sectionId as string)
  );

  useEffect(() => {
    dispatch(
      list({
        resolver: 'schedules',
        paginationKey: 'all',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    try {
      // try to scroll all the way to the right
      document.querySelector('.layout__row')?.scrollTo(999, 0);
    } catch (e) {}
  }, []);

  useValidateEntityExistence({
    entity: section,
    entityType: 'section',
    redirectUrl: url('policy', { policyId: policyId as string }),
  });

  const deleting = useSelector(state =>
    selectLoadingDestroy(state, 'section', sectionId)
  );

  const policySections = useSelector(state =>
    selectEntities(state, 'sections', section.policy || '')
  );

  const sectionAsTree = findInTree(
    { children: createTree(policySections) },
    section.uuid
  );

  return (
    <div className="layout__right-sidebar">
      {sectionId && section?.policy ? (
        <Switch>
          <Route
            render={props => (
              <CreateActivity
                {...props}
                showHeader={false}
                successActions={[
                  uuid =>
                    mapActivityToSection({
                      activityId: uuid,
                      sectionId,
                    }),
                ]}
                successRedirect={url('section', {
                  sectionId,
                  policyId: section.policy as string,
                })}
                backTo={url('section', {
                  sectionId,
                  policyId: section.policy as string,
                })}
              />
            )}
            path={urlPattern('section:activity:create', {
              sectionId,
              policyId: section.policy,
            })}
          />
          <Route
            render={props => (
              <Activity
                {...props}
                showHeader={false}
                backTo={url('section', {
                  sectionId,
                  policyId: section.policy as string,
                })}
              />
            )}
            path={urlPattern('section:activity', {
              sectionId,
              policyId: section.policy,
            })}
          />
          <>
            <Row
              wrap={false}
              style={{ marginBottom: 10 }}
              justify="space-between">
              <Col>
                <Title level={3}>
                  {section?.numbering}. {section?.title}
                </Title>
              </Col>
              <Col>
                <Popconfirm
                  title={
                    <>
                      Are you sure you want to delete a section{' '}
                      <b>
                        &quot;
                        {sectionAsTree?.numbering}. {sectionAsTree?.title}&quot;
                      </b>
                      ?
                      <br />
                      {sectionAsTree?.children?.length ? (
                        <>
                          This will also delete following subsections:
                          <br />
                          <br />
                          {flattenByKey(sectionAsTree.children, 'children').map(
                            (s: any) => (
                              <div key={s.uuid}>
                                <b>
                                  {repeat('  ', s.numbering?.length || 0)}
                                  {s.numbering}. {s.title}
                                </b>
                              </div>
                            )
                          )}
                        </>
                      ) : null}
                    </>
                  }
                  okButtonProps={{ danger: true }}
                  placement="bottomLeft"
                  onConfirm={() => {
                    if (section.uuid && section.policy && policyId) {
                      dispatch(
                        destroy({
                          uuid: section.uuid,
                          resolver: 'section',
                          successActions: [
                            list({
                              resolver: 'sections',
                              paginationKey: section.policy,
                              extra: {
                                policy: section.policy,
                              },
                            }),
                            push(url('policy', { policyId })),
                          ],
                        })
                      );
                    }
                  }}
                  okText="Yes, delete"
                  cancelText="No">
                  <Button
                    title="Delete section"
                    style={{ marginTop: 3 }}
                    disabled={deleting.isFetching}
                    danger>
                    {deleting.isFetching ? (
                      <LoadingOutlined />
                    ) : (
                      <DeleteOutlined />
                    )}
                  </Button>
                </Popconfirm>
              </Col>
            </Row>

            <MapRequirements sectionId={sectionId} />
            <Divider />

            <MapActivities sectionId={sectionId} />
          </>
        </Switch>
      ) : null}
    </div>
  );
};

export default Sidebar;
