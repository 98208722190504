import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedAssetClass } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedAssetClass>
      resolver="assetClasses"
      itemName="asset class"
      listItemTitle={entity => entity.name}
      listTitle="Asset classes"
      detailRoute="asset-class"
      detailIdParam="assetClassId"
      createRoute="asset-classes:create"
      dependencies={['assetTypes']}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default List;
