/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AssetClassificationRetrieval = {
    uuid: string;
    label: string;
    level: AssetClassificationRetrieval.level;
}

export namespace AssetClassificationRetrieval {

    export enum level {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }


}
