import React from 'react';
import { Row, Col, Statistic } from 'antd';
import { Link } from 'react-router-dom';
import { filter, sortBy, find } from 'lodash';
import moment from 'moment-timezone';
import { RiskTreatmentActionListing } from 'api';

import DateTooltip from 'components/date';

import { url } from 'routes';
import Heatmap, { ratingFilters } from 'routes/risks/dashboard/heatmap';

import { useEntities } from 'store/hooks';

import './dashboard.scss';

const Dashboard: React.FC = () => {
  const [rtas, loading] = useEntities('riskTreatmentActions', 'all');
  const [risks, loadingRisks] = useEntities('risks', 'all');

  const plannedRtas = filter(
    rtas,
    r => r.status === RiskTreatmentActionListing.status.PLANNED
  );

  const progressRtas = filter(
    rtas,
    r => r.status === RiskTreatmentActionListing.status.PROGRESS
  );

  const openRtas = filter(
    rtas,
    r => r.status !== RiskTreatmentActionListing.status.IMPLEMENTED
  );

  const sortedByDeadline = sortBy(openRtas, ['deadline'], ['asc']);
  const closestDeadlineRTA = find(sortedByDeadline, rta =>
    moment(rta.deadline).isAfter(moment())
  );
  const missedDeadlineRTA = find(sortedByDeadline, rta =>
    moment(rta.deadline).isBefore(moment())
  );

  const [low, medium, high] = ratingFilters;

  const initialHighRisks = filter(
    risks,
    r =>
      Number(r.initial_risk_rating || 0) >= high.value[0] &&
      Number(r.initial_risk_rating || 0) < high.value[1]
  );
  const initialMediumRisks = filter(
    risks,
    r =>
      Number(r.initial_risk_rating || 0) >= medium.value[0] &&
      Number(r.initial_risk_rating || 0) < medium.value[1]
  );
  const initialLowRisks = filter(
    risks,
    r =>
      Number(r.initial_risk_rating || 0) >= low.value[0] &&
      Number(r.initial_risk_rating || 0) < low.value[1]
  );
  const highRisks = filter(
    risks,
    r =>
      Number(r.residual_risk_rating || 0) >= high.value[0] &&
      Number(r.residual_risk_rating || 0) < high.value[1]
  );
  const mediumRisks = filter(
    risks,
    r =>
      Number(r.residual_risk_rating || 0) >= medium.value[0] &&
      Number(r.residual_risk_rating || 0) < medium.value[1]
  );
  const lowRisks = filter(
    risks,
    r =>
      Number(r.residual_risk_rating || 0) >= low.value[0] &&
      Number(r.residual_risk_rating || 0) < low.value[1]
  );

  return (
    <Row gutter={20}>
      <Col xl={16}>
        <Heatmap />
      </Col>
      <Col>
        <Statistic
          className="mb-2"
          title="High risks / Initial / Total"
          value={highRisks.length}
          suffix={`/ ${initialHighRisks.length} / ${risks.length}`}
        />
        <Statistic
          className="mb-2"
          title="Medium risks / Initial / Total"
          loading={!risks.length && loadingRisks.isFetching}
          value={mediumRisks.length}
          suffix={`/ ${initialMediumRisks.length} / ${risks.length}`}
        />
        <Statistic
          className="mb-2"
          title="Low risks / Initial / Total"
          loading={!risks.length && loadingRisks.isFetching}
          value={lowRisks.length}
          suffix={`/ ${initialLowRisks.length} / ${risks.length}`}
        />
        <Statistic
          className="mb-2"
          title="Planned risk treatment actions"
          loading={!rtas.length && loading.isFetching}
          value={plannedRtas.length}
          suffix={`/ ${rtas.length}`}
        />
        <Statistic
          className="mb-2"
          title="In progress risk treatment actions"
          loading={!rtas.length && loading.isFetching}
          value={progressRtas.length}
          suffix={`/ ${rtas.length}`}
        />
        <Statistic
          className="mb-2"
          title="Implemented risk treatment actions"
          loading={!rtas.length && loading.isFetching}
          value={rtas.length - openRtas.length}
          suffix={`/ ${rtas.length}`}
        />
        <Row gutter={30}>
          <Col>
            {closestDeadlineRTA && closestDeadlineRTA.deadline ? (
              <Link
                to={url('risk-treatment-action', {
                  riskTreatmentActionId: closestDeadlineRTA.uuid,
                })}>
                <DateTooltip
                  title={closestDeadlineRTA.title}
                  date={closestDeadlineRTA.deadline}>
                  <Statistic
                    className="mb-2"
                    title="Upcoming deadline"
                    loading={!rtas.length && loading.isFetching}
                    value={closestDeadlineRTA.deadline}
                  />
                </DateTooltip>
              </Link>
            ) : null}
          </Col>
          <Col>
            {missedDeadlineRTA && missedDeadlineRTA.deadline ? (
              <Link
                to={url('risk-treatment-action', {
                  riskTreatmentActionId: missedDeadlineRTA.uuid,
                })}>
                <DateTooltip
                  title={missedDeadlineRTA.title}
                  date={missedDeadlineRTA.deadline}>
                  <Statistic
                    className="mb-2"
                    title="Missed deadline"
                    valueStyle={{ color: 'red' }}
                    loading={!rtas.length && loading.isFetching}
                    value={missedDeadlineRTA.deadline}
                  />
                </DateTooltip>
              </Link>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Dashboard;
