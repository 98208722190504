import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Row,
  Spin,
  Select,
  List,
  Button,
  Typography,
  Divider,
  Form,
} from 'antd';
import { Link } from 'react-router-dom';
import { differenceBy } from 'lodash';

import { url } from 'routes';
import { useSelector, useDispatch } from 'store/hooks';
import { list } from 'store/common';
import {
  selectEntity,
  selectEntities,
  mapActivityToSection,
  unmapActivityFromSection,
} from 'store/data';
import { selectLoadingList, selectLoading } from 'store/loading';

import { isLoaded } from 'utils';

const { Title } = Typography;

interface Props {
  sectionId: string;
}

const MapActivities: React.FC<Props> = ({ sectionId }) => {
  const dispatch = useDispatch();
  const section = useSelector(state =>
    selectEntity(state, 'section', sectionId)
  );
  const activities = useSelector(state =>
    selectEntities(state, 'activities', sectionId)
  );
  const loadingActivities = useSelector(state =>
    selectLoadingList(state, 'activities', sectionId)
  );

  const allActivities = useSelector(state =>
    selectEntities(state, 'activities', 'all')
  );
  const unmappedActivities = differenceBy(allActivities, activities, 'uuid');
  const mappingActivity = useSelector(state =>
    selectLoading(state, mapActivityToSection.TYPE, sectionId)
  );
  const unmappingActivity = useSelector(state =>
    selectLoading(state, unmapActivityFromSection.TYPE, sectionId)
  );

  const [selectedActivityId, setSelectedActivity] = useState<string | null>(
    null
  );
  const selectedActivity = useSelector(state =>
    selectEntity(state, 'activity', selectedActivityId || '')
  );

  useEffect(() => {
    dispatch(
      list({
        resolver: 'activities',
        paginationKey: sectionId,
        extra: {
          section: sectionId,
        },
      })
    );
  }, [sectionId, dispatch]);

  // dependencies of activity editing
  useEffect(() => {
    dispatch(
      list({
        resolver: 'activities',
        paginationKey: 'all',
      })
    );
    dispatch(
      list({
        resolver: 'roles',
        paginationKey: 'all',
      })
    );
  }, [dispatch]);

  return sectionId && section?.policy ? (
    <>
      <Title level={4}>Activities</Title>
      {activities.length ? (
        <List
          style={{ margin: '0 -24px' }}
          size="large"
          dataSource={activities}
          renderItem={activity => (
            <List.Item
              actions={[
                <Link
                  key="edit"
                  className="ant-btn ant-btn-sm"
                  to={url('section:activity', {
                    sectionId,
                    policyId: section.policy as string,
                    activityId: activity.uuid,
                  })}>
                  Edit
                </Link>,
                <Button
                  key="unlink"
                  disabled={unmappingActivity.isFetching}
                  size="small"
                  onClick={() =>
                    dispatch(
                      unmapActivityFromSection({
                        activityId: activity.uuid,
                        sectionId,
                      })
                    )
                  }>
                  Unlink
                </Button>,
              ]}
              key={activity.uuid}>
              {activity.title}
            </List.Item>
          )}
        />
      ) : loadingActivities.isFetching ? (
        <Row justify="center" style={{ height: '50px' }} align="middle">
          <Spin size="large" />
        </Row>
      ) : (
        <p>
          <i>No activities mapped</i>
        </p>
      )}
      <Divider />

      <Form.Item>
        <div style={{ display: 'flex' }}>
          <Select
            onChange={v => setSelectedActivity(v as string)}
            placeholder="Select activity to map to this section"
            allowClear
            showSearch
            optionFilterProp="label"
            value={selectedActivityId as string}
            style={{ flexGrow: 1, marginRight: 10 }}>
            {unmappedActivities.map(activity => (
              <Select.Option
                key={activity.uuid}
                value={activity.uuid}
                label={activity.title}>
                {activity.title}
              </Select.Option>
            ))}
          </Select>
          <Button
            loading={mappingActivity.isFetching}
            onClick={() => {
              if (isLoaded(selectedActivity) && selectedActivityId) {
                dispatch(
                  mapActivityToSection({
                    activityId: selectedActivityId,
                    sectionId,
                  })
                );

                setSelectedActivity(null);
              }
            }}>
            Add
          </Button>
        </div>
      </Form.Item>

      <Divider />
      <Link
        to={url('section:activity:create', {
          policyId: section.policy,
          sectionId: sectionId,
        })}
        className="ant-btn ant-btn-primary">
        <PlusOutlined style={{ marginRight: 5 }} />
        Create new activity
      </Link>
    </>
  ) : null;
};

export default MapActivities;
