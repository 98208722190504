import React from 'react';

import './form-footer.scss';

const FormFooter: React.FC = ({ children }) => {
  return (
    <>
      <div className="form-footer">
        <div className="form-footer__inner">{children}</div>
      </div>
      <div className="form-footer__placeholder" />
    </>
  );
};

export default FormFooter;
