import React, { useRef, useEffect } from 'react';

const ScrollIntoView: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, []);

  return <div ref={ref} />;
};

export default ScrollIntoView;
