/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RiskCreation } from '../models/RiskCreation';
import type { RiskListing } from '../models/RiskListing';
import type { RiskRetrieval } from '../models/RiskRetrieval';
import type { RiskUpdate } from '../models/RiskUpdate';
import { request as __request } from '../core/request';

export class RisksService {

    /**
     * List risks
     * @returns any
     * @throws ApiError
     */
    public static async listRisks({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<RiskListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/risks/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new risk object
     * @returns RiskCreation
     * @throws ApiError
     */
    public static async createRisk({
        data,
    }: {
        data: RiskCreation,
    }): Promise<RiskCreation> {
        const result = await __request({
            method: 'POST',
            path: `/risks/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single risk
     * @returns RiskRetrieval
     * @throws ApiError
     */
    public static async getRisk({
        uuid,
    }: {
        /** A UUID string identifying this risk. **/
        uuid: string,
    }): Promise<RiskRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/risks/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified risk
     * @returns RiskUpdate
     * @throws ApiError
     */
    public static async updateRisk({
        uuid,
        data,
    }: {
        /** A UUID string identifying this risk. **/
        uuid: string,
        data: RiskUpdate,
    }): Promise<RiskUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/risks/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified risk
     * @returns RiskUpdate
     * @throws ApiError
     */
    public static async partialUpdateRisk({
        uuid,
        data,
    }: {
        /** A UUID string identifying this risk. **/
        uuid: string,
        data: RiskUpdate,
    }): Promise<RiskUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/risks/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified risk
     * @returns void
     * @throws ApiError
     */
    public static async destroyRisk({
        uuid,
    }: {
        /** A UUID string identifying this risk. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/risks/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}