import React, { useState } from 'react';
import { filter, includes, orderBy } from 'lodash';
import { RiskTreatmentActionListing } from 'api';
import { Select, Button, Form } from 'antd';

import { useEntities, useDispatch, useSelector } from 'store/hooks';
import { mapRiskTreatmentActionToRisk } from 'store/data';
import { selectLoading } from 'store/loading';

interface Props {
  riskId: string;
  upcoming: boolean;
}

const MapRiskTreatmentActions: React.FC<Props> = ({ riskId, upcoming }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string | null>(null);
  const mapping = useSelector(state =>
    selectLoading(state, mapRiskTreatmentActionToRisk.TYPE, riskId)
  );

  const [riskTreatmentActions] = useEntities('riskTreatmentActions', 'all');
  const unmappedRTAs = orderBy(
    filter(
      riskTreatmentActions,
      rta =>
        !includes(
          rta.risks?.map(r => r.uuid),
          riskId
        ) &&
        (upcoming
          ? rta.status !== RiskTreatmentActionListing.status.IMPLEMENTED
          : rta.status === RiskTreatmentActionListing.status.IMPLEMENTED)
    ),
    ['human_id'],
    ['asc']
  );

  return (
    <div className="mb-1">
      <Form.Item>
        <div style={{ display: 'flex' }}>
          <Select
            onChange={v => setSelected(v as string)}
            placeholder="Select RTA to map to this risk"
            allowClear
            showSearch
            optionFilterProp="children"
            value={selected as string}
            style={{ flexGrow: 1, marginRight: 10 }}>
            {unmappedRTAs.map(rta => (
              <Select.Option key={rta.uuid} value={rta.uuid}>
                {rta.human_id}. {rta.title}
              </Select.Option>
            ))}
          </Select>
          <Button
            loading={mapping.isFetching}
            onClick={() => {
              if (selected) {
                dispatch(
                  mapRiskTreatmentActionToRisk({
                    riskTreatmentActionId: selected,
                    riskId,
                  })
                );

                setSelected(null);
              }
            }}>
            Add
          </Button>
        </div>
      </Form.Item>
    </div>
  );
};

export default MapRiskTreatmentActions;
