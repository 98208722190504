import React from 'react';
import { Form, Input, Select, Tooltip } from 'antd';
import slugify from 'slugify';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';

import { useEntities } from 'store/hooks';

type Props = Omit<GenericFormProps, 'renderFields'>;

const EditForm: React.FC<Props> = props => {
  const [definitions] = useEntities('definitions', 'all');

  return (
    <GenericForm
      {...props}
      renderFields={({ form }) => (
        <>
          <Form.Item required label="Acronym" name="acronym">
            <Input className="monospace" />
          </Form.Item>
          <Form.Item
            required
            label="Slug"
            name="slug"
            dependencies={['acronym']}
            shouldUpdate={(prevValues, curValues) => {
              if (prevValues.acronym !== curValues.acronym) {
                form.setFieldsValue({
                  ...curValues,
                  slug: slugify(curValues.acronym).toLowerCase(),
                });
              }

              return prevValues.name !== curValues.name;
            }}>
            <Input readOnly className="monospace" />
          </Form.Item>
          <Form.Item label="Acronym plural" name="acronym_plural">
            <Input className="monospace" />
          </Form.Item>
          <Form.Item required label="Extended" name="extended">
            <Input className="monospace" />
          </Form.Item>
          <Form.Item label="Extended plural" name="extended_plural">
            <Input className="monospace" />
          </Form.Item>
          <Form.Item label="Definition" name="definition">
            <Select showSearch optionFilterProp="label">
              {definitions.map(r => (
                <Select.Option key={r.uuid} value={r.uuid} label={r.name}>
                  <Tooltip placement="right" title={r.explanation}>
                    <div>{r.name}</div>
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}
    />
  );
};

export default EditForm;
