import React from 'react';
import { RiskListing } from 'api';
import { includes, find, values } from 'lodash';
// import { compact, uniqBy } from 'lodash';
import { Table, Tooltip, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { url } from 'routes';
import { ratingFilters } from 'routes/risks/dashboard/heatmap';

import { useSelector } from 'store/hooks';
import {
  selectEntities,
  DenormalizedRisk,
  DenormalizedRole,
  DenormalizedAssetClass,
  DenormalizedThreatClass,
  DenormalizedVulnerabilityClass,
} from 'store/data';

import { presentableValue } from 'utils';

const RisksTable: React.FC = () => {
  const risks = useSelector(state => selectEntities(state, 'risks', 'all'));
  const assetClasses = useSelector(state =>
    selectEntities(state, 'assetClasses', 'all')
  );
  const threatClasses = useSelector(state =>
    selectEntities(state, 'threatClasses', 'all')
  );
  const vulnerabilityClasses = useSelector(state =>
    selectEntities(state, 'vulnerabilityClasses', 'all')
  );
  const roles = useSelector(state => selectEntities(state, 'roles', 'all'));
  const assetClassFilters = assetClasses.map(a => ({
    value: a.uuid,
    text: a.name,
  }));
  const threatClassFilters = threatClasses.map(a => ({
    value: a.uuid,
    text: a.name,
  }));
  const vulnerabilityClassFilters = vulnerabilityClasses.map(a => ({
    value: a.uuid,
    text: a.name,
  }));
  const treatmentTypeFilters = values(RiskListing.treatment_type).map(v => ({
    value: v,
    text: presentableValue(v),
  }));

  const roleFilters = roles.map(a => ({
    value: a.uuid,
    text: a.name,
  }));

  const columns = [
    {
      key: 'id',
      dataIndex: 'human_id',
      title: 'ID',
      width: 70,
      align: 'left',
      fixed: 'left',
      render: (id: string, node: DenormalizedRisk) => {
        return <Link to={url('risk', { riskId: node.uuid })}>{id}</Link>;
      },
    },
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Title',
      align: 'left',
      sorter: (a: DenormalizedRisk, b: DenormalizedRisk) =>
        a?.title && b?.title ? (a?.title > b?.title ? 1 : -1) : -1,
      render: (title: string, node: DenormalizedRisk) => {
        return <Link to={url('risk', { riskId: node.uuid })}>{title}</Link>;
      },
    },
    {
      key: 'owner',
      dataIndex: 'owner',
      title: 'Owner',
      width: 150,
      sorter: (a: DenormalizedRisk, b: DenormalizedRisk) =>
        a?.owner?.name && b?.owner?.name
          ? a?.owner?.name > b?.owner?.name
            ? 1
            : -1
          : a.owner
          ? -1
          : 1,
      filters: roleFilters,
      onFilter: (value: string, record: DenormalizedRisk) =>
        record?.owner?.uuid === value,
      ellipsis: true,
      render: (role: DenormalizedRole | null) => {
        return role ? (
          <Link to={url('role', { roleId: role.uuid })}>{role.name}</Link>
        ) : (
          '-'
        );
      },
    },
    {
      key: 'asset_classes',
      dataIndex: 'asset_classes',
      title: 'Asset classes',
      filters: assetClassFilters,
      filterSearch: true,
      onFilter: (value: string, record: DenormalizedRisk) =>
        includes(record?.affected_parties, value),
      ellipsis: true,
      // width: 450,
      render: (asset_classes: string[]) => {
        const firstAssetClass = (
          assetClasses.length
            ? find(assetClasses, {
                uuid: asset_classes[0],
              })
            : null
        ) as DenormalizedAssetClass | null;

        return firstAssetClass && asset_classes.length ? (
          <div>
            <Link
              to={url('asset-class', {
                assetClassId: firstAssetClass.uuid,
              })}>
              {firstAssetClass.name}
            </Link>

            {asset_classes.length > 1 ? (
              <Tooltip
                color="white"
                title={
                  <>
                    {asset_classes.map((id, i) => {
                      const assetClass = find(assetClasses, {
                        uuid: id,
                      }) as DenormalizedAssetClass | null;

                      return assetClass ? (
                        <React.Fragment key={id}>
                          <Link
                            to={url('asset-class', {
                              assetClassId: assetClass.uuid,
                            })}>
                            {assetClass.name}
                          </Link>
                          {i !== asset_classes.length - 1 ? <br /> : null}
                        </React.Fragment>
                      ) : null;
                    })}
                  </>
                }>
                <Tag className="ml-1">+{asset_classes.length - 1}</Tag>
              </Tooltip>
            ) : null}
          </div>
        ) : null;
      },
    },
    {
      key: 'affected_parties',
      width: 200,
      dataIndex: 'affected_parties',
      title: 'Affected parties',
      filters: roleFilters,
      onFilter: (value: string, record: DenormalizedRisk) =>
        includes(record?.affected_parties, value),
      ellipsis: true,
      render: (affected_parties: string[]) => {
        return (
          <div>
            {affected_parties.length
              ? affected_parties.map((id, i) => {
                  const role = find(roles, {
                    uuid: id,
                  }) as DenormalizedRole | null;

                  return role ? (
                    <React.Fragment key={id}>
                      <Link to={url('role', { roleId: role.uuid })}>
                        {role.name}
                      </Link>
                      {i !== affected_parties.length - 1 ? <br /> : null}
                    </React.Fragment>
                  ) : null;
                })
              : '-'}
          </div>
        );
      },
    },
    {
      key: 'threat_class',
      dataIndex: 'threat_class',
      title: 'Threat class',
      filters: threatClassFilters,
      // width: 300,
      onFilter: (value: string, record: DenormalizedRisk) =>
        record?.threat_class?.uuid === value,
      sorter: (a: DenormalizedRisk, b: DenormalizedRisk) =>
        a?.threat_class?.name && b?.threat_class?.name
          ? a?.threat_class?.name > b?.threat_class?.name
            ? 1
            : -1
          : a.threat_class
          ? -1
          : 1,
      ellipsis: true,
      render: (threat_class: DenormalizedThreatClass | null) => {
        return threat_class ? (
          <div style={{ whiteSpace: 'normal' }}>{threat_class.name}</div>
        ) : (
          '-'
        );
      },
    },
    {
      key: 'vulnerability_class',
      // width: 300,
      dataIndex: 'vulnerability_class',
      title: 'Vulnerability class',
      filters: vulnerabilityClassFilters,
      onFilter: (value: string, record: DenormalizedRisk) =>
        record?.vulnerability_class?.uuid === value,
      sorter: (a: DenormalizedRisk, b: DenormalizedRisk) =>
        a?.vulnerability_class?.name && b?.vulnerability_class?.name
          ? a?.vulnerability_class?.name > b?.vulnerability_class?.name
            ? 1
            : -1
          : a.vulnerability_class
          ? -1
          : 1,
      ellipsis: true,
      render: (vulnerability_class: DenormalizedVulnerabilityClass | null) => {
        return vulnerability_class ? (
          <div style={{ whiteSpace: 'normal' }}>{vulnerability_class.name}</div>
        ) : (
          '-'
        );
      },
    },
    {
      key: 'treatment_type',
      width: 120,
      dataIndex: 'treatment_type',
      title: 'Treatment',
      filters: treatmentTypeFilters,
      onFilter: (value: string, record: DenormalizedRisk) =>
        record?.treatment_type === value,
      sorter: (a: DenormalizedRisk, b: DenormalizedRisk) =>
        a?.treatment_type && b?.treatment_type
          ? a?.treatment_type > b?.treatment_type
            ? 1
            : -1
          : a.treatment_type
          ? -1
          : 1,
      ellipsis: true,
      render: (treatment_type: string) => {
        return (
          <div style={{ whiteSpace: 'normal' }}>
            {presentableValue(treatment_type)}
          </div>
        );
      },
    },
    {
      key: 'Initial',
      fixed: 'right',
      dataIndex: 'initial_risk_rating',
      title: 'Initial',
      align: 'center',
      filters: ratingFilters,
      onFilter: (value: number[], record: DenormalizedRisk) =>
        (record.initial_risk_rating || 0) >= value[0] &&
        (record.initial_risk_rating || 0) < value[1],
      sorter: (a: DenormalizedRisk, b: DenormalizedRisk) =>
        a?.initial_risk_rating && b?.initial_risk_rating
          ? a?.initial_risk_rating > b?.initial_risk_rating
            ? 1
            : -1
          : a.initial_risk_rating
          ? -1
          : 1,
      ellipsis: true,
      width: 110,
      onCell: (record: DenormalizedRisk) => {
        const rating = record?.initial_risk_rating || 0;
        const r = find(
          ratingFilters,
          ({ value }) => rating >= value[0] && rating < value[1]
        );

        return {
          style: {
            background: r?.color || 'transparent',
          },
        };
      },
      render: (rating: number) => {
        return rating;
      },
    },
    {
      key: 'Current',
      fixed: 'right',
      dataIndex: 'current_risk_rating',
      title: 'Current',
      align: 'center',
      filters: ratingFilters,
      onFilter: (value: number[], record: DenormalizedRisk) =>
        (record.current_risk_rating || 0) >= value[0] &&
        (record.current_risk_rating || 0) < value[1],
      sorter: (a: DenormalizedRisk, b: DenormalizedRisk) =>
        a?.current_risk_rating && b?.current_risk_rating
          ? a?.current_risk_rating > b?.current_risk_rating
            ? 1
            : -1
          : a.current_risk_rating
          ? -1
          : 1,
      ellipsis: true,
      width: 110,
      onCell: (record: DenormalizedRisk) => {
        const rating = record?.current_risk_rating || 0;
        const r = find(
          ratingFilters,
          ({ value }) => rating >= value[0] && rating < value[1]
        );

        return {
          style: {
            background: r?.color || 'transparent',
          },
        };
      },
      render: (rating: number) => {
        return rating;
      },
    },
    {
      fixed: 'right',
      key: 'Residual',
      dataIndex: 'residual_risk_rating',
      title: 'Residual',
      width: 110,
      align: 'center',
      filters: ratingFilters,
      onFilter: (value: number[], record: DenormalizedRisk) =>
        (record.residual_risk_rating || 0) >= value[0] &&
        (record.residual_risk_rating || 0) < value[1],
      sorter: (a: DenormalizedRisk, b: DenormalizedRisk) =>
        a?.residual_risk_rating && b?.residual_risk_rating
          ? a?.residual_risk_rating > b?.residual_risk_rating
            ? 1
            : -1
          : a.residual_risk_rating
          ? -1
          : 1,
      ellipsis: true,
      onCell: (record: DenormalizedRisk) => {
        const rating = record?.residual_risk_rating || 0;
        const r = find(
          ratingFilters,
          ({ value }) => rating >= value[0] && rating < value[1]
        );

        return {
          style: {
            background: r?.color || 'transparent',
          },
        };
      },
      render: (rating: number) => {
        return rating;
      },
    },
  ];

  return (
    <Table
      rowKey={record => record.uuid}
      tableLayout="fixed"
      bordered={false}
      size="small"
      indentSize={30}
      showHeader={true}
      defaultExpandAllRows={true}
      pagination={false}
      dataSource={risks}
      columns={columns as any}
      scroll={{ x: 1500 }}
      sticky={{ offsetHeader: -20, offsetScroll: -20 }}
    />
  );
};

export default RisksTable;
