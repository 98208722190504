/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetClassCreation } from '../models/AssetClassCreation';
import type { AssetClassListing } from '../models/AssetClassListing';
import type { AssetClassRetrieval } from '../models/AssetClassRetrieval';
import type { AssetClassUpdate } from '../models/AssetClassUpdate';
import { request as __request } from '../core/request';

export class AssetClassesService {

    /**
     * List asset classes
     * @returns any
     * @throws ApiError
     */
    public static async listAssetClasses({
        slug,
        page,
        pageSize,
    }: {
        slug?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<AssetClassListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/asset-classes/`,
            query: {
                'slug': slug,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new asset class object
     * @returns AssetClassCreation
     * @throws ApiError
     */
    public static async createAssetClass({
        data,
    }: {
        data: AssetClassCreation,
    }): Promise<AssetClassCreation> {
        const result = await __request({
            method: 'POST',
            path: `/asset-classes/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single asset class
     * @returns AssetClassRetrieval
     * @throws ApiError
     */
    public static async getAssetClass({
        uuid,
    }: {
        /** A UUID string identifying this asset class. **/
        uuid: string,
    }): Promise<AssetClassRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/asset-classes/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified asset class
     * @returns AssetClassUpdate
     * @throws ApiError
     */
    public static async updateAssetClass({
        uuid,
        data,
    }: {
        /** A UUID string identifying this asset class. **/
        uuid: string,
        data: AssetClassUpdate,
    }): Promise<AssetClassUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/asset-classes/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified asset class
     * @returns AssetClassUpdate
     * @throws ApiError
     */
    public static async partialUpdateAssetClass({
        uuid,
        data,
    }: {
        /** A UUID string identifying this asset class. **/
        uuid: string,
        data: AssetClassUpdate,
    }): Promise<AssetClassUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/asset-classes/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified asset class
     * @returns void
     * @throws ApiError
     */
    public static async destroyAssetClass({
        uuid,
    }: {
        /** A UUID string identifying this asset class. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/asset-classes/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}