import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { filter } from 'lodash';

import { useDispatch, useSelector } from 'store/hooks';
import { list } from 'store/common';
import { selectEntities } from 'store/data';

import { useDebounce } from 'utils';

interface Props {
  onChange?: any;
  value?: any;
  except?: string;
}

const AssetSelect: React.FC<Props> = ({ value, onChange, except }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');

  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    if (debouncedQuery) {
      dispatch(
        list({
          resolver: 'assets',
          paginationKey: debouncedQuery,
          extra: {
            name: debouncedQuery,
          },
        })
      );
    }
  }, [dispatch, debouncedQuery]);

  const searchResults = filter(
    useSelector(state => selectEntities(state, 'assets', query)),
    asset => asset.uuid !== except
  );
  const allAssets = filter(
    useSelector(state => selectEntities(state, 'assets', 'all')),
    asset => asset.uuid !== except
  );
  const assets = searchResults.length ? searchResults : allAssets;

  return (
    <Select
      showSearch
      onSearch={v => setQuery(v)}
      value={value}
      searchValue={query}
      filterOption={false}
      optionLabelProp="label"
      onChange={onChange}>
      {assets.map(asset => (
        <Select.Option key={asset.uuid} value={asset.uuid} label={asset.name}>
          {asset.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AssetSelect;
