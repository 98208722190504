import React, { useEffect, useState } from 'react';
import { Upload, Form, Button, message, Input } from 'antd';
import { useHistory } from 'react-router';
import { Modal } from 'antd';
import { find, filter, values, some } from 'lodash';
// import { Link } from 'react-router-dom';
import {
  InboxOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';

import { url, useParams } from 'routes';

import { useDispatch, useSelector } from 'store/hooks';
import { uploadFile } from 'store/api';
import { create, list } from 'store/common';
import { selectLoadingCreate } from 'store/loading';

import { getErrorMessage, useLoadingFinishedEffect } from 'utils';
import { UploadedFileCreation } from 'api';

const { Dragger } = Upload;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const CreateCollectionRun: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(true);
  const { evidenceId } = useParams('evidence:collection-run:create') as {
    evidenceId: string;
  };
  const [fileList, setFileList] = useState<any[]>([]);
  const [files, setFiles] = useState<UploadedFileCreation[]>([]);
  const [form] = Form.useForm();
  const [uploading, setUploading] = useState<Record<string, boolean>>({});
  const creating = useSelector(state =>
    selectLoadingCreate(state, 'collectionRun', evidenceId)
  );

  const onClose = () => {
    setVisible(false);
  };

  useLoadingFinishedEffect(() => {
    onClose();
  }, creating);

  useEffect(() => {
    const values = form.getFieldsValue(true);

    form.setFieldsValue({
      exhibits: files.map(file => {
        const existingFile = find(values.exhibits, { file: file.uuid });

        return (
          existingFile || {
            label: '',
            file: file.uuid,
            custom_metadata: '',
          }
        );
      }),
    });
    // eslint-disable-next-line
  }, [files.map(f => f.uuid).join(), form]);

  return (
    <Modal
      width={1000}
      title="Create collection run"
      visible={visible}
      onCancel={onClose}
      forceRender={true}
      footer={null}
      afterClose={() => {
        history.push(url('evidence', { evidenceId }));
      }}>
      <Dragger
        multiple={true}
        name="file"
        customRequest={async ({ file, onError, onSuccess }) => {
          try {
            const response = await uploadFile({
              data: file as any,
              headers: {
                'Content-Disposition': `attachment; filename=${
                  (file as any).name
                }`,
              },
            });
            (onSuccess as any)();
            setFiles(files => [...files, response]);
          } catch (e) {
            message.error(getErrorMessage(e));
            (onError as any)();
          }
        }}
        fileList={fileList}
        onChange={info => {
          setUploading(uploading => ({
            ...uploading,
            [info.file.uid]: info.file.status === 'uploading',
          }));
          setFileList(info.fileList);
        }}
        itemRender={() => {
          return null;
        }}>
        <p className="ant-upload-drag-icon">
          {some(values(uploading), x => x) ? (
            <Loading3QuartersOutlined spin />
          ) : (
            <InboxOutlined />
          )}
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>

      <Form
        {...layout}
        form={form}
        onFinish={values => {
          dispatch(
            create({
              resolver: 'collectionRun',
              loadingKey: evidenceId,
              data: {
                evidence: evidenceId,
                exhibits: values.exhibits,
              },
              successMessage: 'Collection run created.',
              successActions: [
                list({
                  resolver: 'collectionRuns',
                  paginationKey: evidenceId,
                  extra: { evidence: evidenceId },
                }),
              ],
            })
          );
        }}
        initialValues={{
          exhibits: [],
        }}>
        <Form.List name="exhibits">
          {fields => {
            return (
              <div>
                {fields.map(({ key, name, ...restField }, i) => {
                  if (!files[i]) {
                    return null;
                  }
                  const currentFileId = files[i].uuid;

                  return (
                    <Form.Item
                      className="pt-2 pb-1 mb-0"
                      style={{
                        borderBottom: '1px dashed #ccc',
                        borderTop: '1px dashed #ccc',
                        marginTop: -1,
                      }}
                      key={key}>
                      <Form.Item label="File" {...layout}>
                        <span className="ant-form-text pr-3">
                          {files[i].filename}
                        </span>
                      </Form.Item>
                      <Form.Item
                        {...layout}
                        {...restField}
                        name={[name, 'label']}
                        required
                        label="Label">
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...layout}
                        {...restField}
                        label="Custom metadata"
                        name={[name, 'custom_metadata']}
                        help="Must be a valid JSON"
                        rules={[
                          {
                            validator: (_, v) => {
                              if (!v.trim()) {
                                return Promise.resolve();
                              }
                              try {
                                JSON.parse(v);
                                return Promise.resolve();
                              } catch (e) {
                                return Promise.reject(
                                  new Error('Invalid JSON')
                                );
                              }
                            },
                          },
                        ]}>
                        <TextArea />
                      </Form.Item>
                      <button
                        style={{
                          border: 0,
                          background: 'transparent',
                          position: 'absolute',
                          cursor: 'pointer',
                          right: 0,
                          top: 0,
                          width: 32,
                          height: 32,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        type="button"
                        onClick={() =>
                          setFiles(files =>
                            filter(files, file => file.uuid !== currentFileId)
                          )
                        }>
                        <CloseOutlined />
                      </button>
                    </Form.Item>
                  );
                })}
              </div>
            );
          }}
        </Form.List>
        <div
          className="pt-2"
          style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={creating.isFetching}
            disabled={files.length === 0}>
            Create
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateCollectionRun;
