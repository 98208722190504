import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Row, Button, Typography, Select } from 'antd';
import { Switch, Route, Link, useLocation } from 'react-router-dom';

import PrivateRoute from 'components/private-route';
import ScrollIntoView from 'components/scroll-into-view';

import { url } from 'routes';

import { list, update } from 'store/common';
import { DenormalizedPolicy } from 'store/data';
import { selectLoadingUpdate } from 'store/loading';
import { useDispatch, useEntities, useSelector } from 'store/hooks';

interface Props {
  policy: DenormalizedPolicy;
}

const { Title } = Typography;

const EditReferences: React.FC<Props> = ({ policy }) => {
  const isSaving = useSelector(state =>
    selectLoadingUpdate(state, 'policy', policy.uuid, 'references')
  ).isFetching;
  const dispatch = useDispatch();
  const location = useLocation();
  const [references, setReferences] = useState(
    (policy.references || []).map(r => r.uuid) || null
  );

  const [allReferences] = useEntities('references', 'all');
  const isSaveEnabled = !isEqual(
    references,
    (policy.references || []).map(r => r.uuid)
  );

  useEffect(() => {
    dispatch(
      list({
        resolver: 'violations',
        paginationKey: 'all',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!references.length && policy.references?.length) {
      setReferences(policy.references.map(r => r.uuid));
    }
    // eslint-disable-next-line
  }, [references, (policy.references || []).map(r => r.uuid).join('')]);

  const onSave = (references: string[]) => {
    dispatch(
      update({ resolver: 'policy', uuid: policy.uuid, patch: { references } })
    );
  };

  return (
    <>
      <Switch>
        <PrivateRoute
          path={url('policy:edit', {
            policyId: policy.uuid,
            field: 'references',
          })}
          exact
          render={() =>
            allReferences ? (
              <div className="section section--active">
                <ScrollIntoView />
                <div className="section__heading">
                  <Title
                    level={4}
                    style={{ width: '100%' }}
                    className="monospace">
                    1.4. References
                  </Title>
                  <Link
                    className="section__close"
                    to={url('policy', { policyId: policy.uuid })}>
                    &times;
                  </Link>
                </div>
                <Select
                  mode="multiple"
                  showSearch
                  style={{ width: '100%' }}
                  value={references}
                  optionFilterProp="children"
                  onChange={v => {
                    setReferences(v);
                  }}>
                  {allReferences.map(r => (
                    <Select.Option value={r.uuid}>{r.name}</Select.Option>
                  ))}
                </Select>
                <Row justify="space-between" style={{ marginTop: 15 }}>
                  <Button
                    disabled={!isSaveEnabled}
                    onClick={() => {
                      setReferences((policy.references || []).map(r => r.uuid));
                    }}>
                    Discard changes
                  </Button>
                  <Button
                    style={{ marginLeft: 'auto' }}
                    loading={isSaving}
                    onClick={() => onSave(references)}
                    type="primary"
                    disabled={!isSaveEnabled}>
                    Save
                  </Button>
                </Row>
              </div>
            ) : null
          }
        />
        <Route
          path={location.pathname}
          exact
          render={() => (
            <Link
              to={url('policy:edit', {
                policyId: policy.uuid,
                field: 'references',
              })}
              className="monospace section section__edit">
              <Title level={2} className="mb-2">
                <span className="section__heading monospace">
                  1.4. References
                </span>
              </Title>
              {policy.references?.length ? (
                <ul>
                  {policy.references?.map(r => (
                    <li key={r.uuid}>
                      {r.name}
                      {r.url ? ` - ${r.url}` : null}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>
                  <i>No content</i>
                </p>
              )}
            </Link>
          )}
        />
      </Switch>
    </>
  );
};

export default EditReferences;
