/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PolicyBuildProfileRetrieval = {
    uuid: string;
    identifier: string;
    label: string;
    prepared_for?: string;
    requirement_sets?: Array<string>;
    classification?: string;
    layout?: PolicyBuildProfileRetrieval.layout;
}

export namespace PolicyBuildProfileRetrieval {

    export enum layout {
        SCREEN = 'screen',
        PRINT = 'print',
    }


}
