import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Tree } from 'antd';
import { find, findIndex, last } from 'lodash';
import { FileTextOutlined } from '@ant-design/icons';

import { url } from 'routes';

import { useDispatch, useSelector } from 'store/hooks';
import { DenormalizedPolicy, selectEntities, moveSection } from 'store/data';
import { update, list } from 'store/common';

import { createTree } from 'utils';

interface Props {
  policy: DenormalizedPolicy;
}

export const bumpNumbering = (previousSection: any) => {
  return Number(previousSection.numbering.split('.')[0]) + 1;
};

const addExtraTreeItems = (policy: DenormalizedPolicy, tree: any) => {
  const violations = {
    key: 'violation',
    title: 'Violations',
    type: 'policy',
    switcherIcon: <FileTextOutlined />,
  };
  const appendixLine = {
    key: 'appendixLine',
    switcherIcon: <></>,
  };

  if (!policy.first_appendix_section) {
    return [
      ...tree,
      {
        ...violations,
        numbering: bumpNumbering(last(tree)),
      },
      appendixLine,
    ];
  }

  let i = tree.length - 1;

  while (i > 0) {
    let section = tree[i];

    if (section.uuid === policy.first_appendix_section) {
      tree.splice(
        i,
        0,
        { ...violations, numbering: bumpNumbering(tree[i - 1]) },
        appendixLine
      );
      break;
    }
    i--;
  }

  return tree;
};

const SectionsTree: React.FC<Props> = ({ policy }) => {
  const dispatch = useDispatch();
  const sections = useSelector(state =>
    selectEntities(state, 'sections', policy.uuid)
  );
  const [expandedKeys, setExpandedKeys] = useState<string[]>([
    'introduction',
    ...sections.map(s => s.uuid),
  ]);
  const [autoExpand, setAutoExpand] = useState(true);

  const [draggedNode, setDraggedNode] = useState<any>(null);

  const tree = addExtraTreeItems(policy, createTree(sections));

  const treeData = [
    {
      title: '1. Introduction',
      type: 'policy-group',
      key: 'introduction',
      selectable: false,
      expanded: true,
      switcherIcon: <FileTextOutlined />,
      children: [
        {
          title: '1.1. Background',
          key: 'background',
          type: 'policy',
          switcherIcon: <FileTextOutlined />,
        },
        {
          title: '1.2. Purpose',
          key: 'purpose',
          type: 'policy',
          switcherIcon: <FileTextOutlined />,
        },
        {
          title: '1.3. Scope',
          key: 'scope',
          type: 'policy',
          switcherIcon: <FileTextOutlined />,
        },
        {
          title: 'References',
          numbering: '1.4',
          key: 'references',
          type: 'references',
          switcherIcon: <FileTextOutlined />,
        },
      ],
    },
    ...tree,
  ];

  useEffect(() => {
    setExpandedKeys(['introduction', ...sections.map(s => s.uuid)]);
    // eslint-disable-next-line
  }, [sections.map(s => s.uuid).join('')]);

  const onDrop = (info: any) => {
    setDraggedNode(null);
    console.group('Drop:');
    console.log(info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    if (dragKey === 'appendixLine') {
      const dropIndex = findIndex(treeData, { uuid: dropKey }) as any;
      const nextSection = treeData[dropIndex + 1];

      if (
        dropIndex !== -1 &&
        nextSection &&
        nextSection.key !== 'appendixLine'
      ) {
        dispatch(
          update({
            resolver: 'policy',
            uuid: policy.uuid,
            patch: {
              first_appendix_section: nextSection.uuid,
            },
            successMessage: 'Updated appendices',
            successActions: [
              list({
                resolver: 'sections',
                paginationKey: policy.uuid,
                extra: {
                  policy: policy.uuid,
                },
              }),
            ],
          })
        );
      } else if (!nextSection) {
        dispatch(
          update({
            resolver: 'policy',
            uuid: policy.uuid,
            patch: {
              first_appendix_section: null,
            },
            successMessage: 'Updated appendices',
            successActions: [
              list({
                resolver: 'sections',
                paginationKey: policy.uuid,
                extra: {
                  policy: policy.uuid,
                },
              }),
            ],
          })
        );
      }

      console.groupEnd();
      return;
    }

    console.log('Normal node');

    const drop = find(sections, { uuid: dropKey }) as any;

    console.log({ dropKey, dragKey, dropPos, dropPosition, drop });
    console.groupEnd();

    // TODO if position didn't change - don't make the move

    if (info.dropToGap) {
      if (dropPosition === -1) {
        console.log(`Move ${info.dragNode.title} to before ${drop.title}`);
        dispatch(
          moveSection({
            where: 'before',
            dragNode: dragKey,
            dropNode: dropKey,
            policy: policy.uuid,
          })
        );
      } else {
        console.log(`Move ${info.dragNode.title} after ${drop.title}`);
        dispatch(
          moveSection({
            where: 'after',
            dragNode: dragKey,
            dropNode: dropKey,
            policy: policy.uuid,
          })
        );
      }
    } else {
      // TODO there is a bug when you drop as first inside, we don't actually specify position in
      // the move-inside request
      console.log(
        `Move ${info.dragNode.title} inside ${drop.title} at position ${dropPosition}`
      );
      dispatch(
        moveSection({
          where: 'inside',
          dragNode: dragKey,
          dropNode: dropKey,
          policy: policy.uuid,
        })
      );
    }
  };

  return (
    <Tree
      blockNode
      draggable={{
        icon: false,
        nodeDraggable: (node: any) =>
          node.type !== 'policy-group' &&
          node.type !== 'policy' &&
          node.key !== 'violation' &&
          node.key !== 'references',
      }}
      selectable={false}
      showIcon
      className="tree"
      onDrop={onDrop}
      onDragStart={({ node }) => {
        setDraggedNode(node.key);
      }}
      allowDrop={({ dropPosition, dropNode }) => {
        if (draggedNode === 'appendixLine') {
          return (
            (dropNode as any).numbering?.length === 1 && dropPosition === 1
          );
        }

        if (
          dropNode.key === 'appendixLine' ||
          dropNode.key === 'violation' ||
          dropNode.key === 'references'
        ) {
          return false;
        }

        return true;
      }}
      showLine
      onExpand={keys => {
        setAutoExpand(false);
        setExpandedKeys(keys as string[]);
      }}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpand}
      treeData={treeData}
      titleRender={(node: any) => (
        <span>
          {node.key === 'appendixLine' ? (
            <div
              style={{
                marginLeft: -24,
                display: 'flex',
                height: 24,
                alignItems: 'center',
              }}>
              <div
                style={{ height: 2, width: '100%', background: '#e2e2e2' }}
              />
            </div>
          ) : node.type === 'policy' || node.type === 'references' ? (
            <NavLink
              className="tree-link"
              to={
                url('policy:edit', {
                  field: node.key as 'purpose' | 'scope',
                  policyId: policy.uuid,
                }) +
                '#' +
                node.key
              }>
              {node.numbering ? <>{node.numbering}. </> : null}
              {node.title}
            </NavLink>
          ) : (node as any).type === 'policy-group' ? (
            <span>{node.title}</span>
          ) : (
            <NavLink
              className="tree-link"
              to={
                url('section', {
                  sectionId: node.key as string,
                  policyId: policy.uuid,
                }) +
                '#' +
                node.key
              }>
              {(node as any).numbering}. {node.title}
            </NavLink>
          )}
        </span>
      )}
    />
  );
};

export default SectionsTree;
