import { put, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import { logout } from 'store/user';

function* handleError(action: any) {
  const errorStatus = get(action, 'error.response.status');

  /**
   * If the error doesn't come from axios (i.e. not a network error)
   * then it means it was some kind of another error, which means we have to
   * send it to Sentry, otherwise it will just be swallowed
   */
  if (
    action.error &&
    !action.error.url &&
    !action.error.body &&
    !action.error.doNotReportToSentry
  ) {
    Sentry.captureException(action.error);
  }

  if (errorStatus === 401) {
    yield put(logout());

    return;
  }
}

export function* errorsSaga() {
  yield takeEvery(
    (action: any) => action.error || (action.payload && action.payload.error),
    handleError
  );
}

const sagas = [errorsSaga];

export default sagas;
