/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ContractCreation = {
    readonly uuid?: string;
    name: string;
    type?: ContractCreation.type;
    description?: string;
    /**
     * Our approach to meet this requirement
     */
    approach?: string;
    notes?: string;
    /**
     * Owner or contact of the contract.
     */
    owner: string;
    review_schedule?: string | null;
    requirements?: Array<string>;
    /**
     * structured data about the contract like `uptime`, `response_time` or `resolution_time`
     */
    custom_metadata?: any;
    /**
     * Startdate of the contract
     */
    start_date?: string | null;
    /**
     * Enddate of the contract
     */
    end_date?: string | null;
    /**
     * Does the contract automatically renew close to the end?
     */
    automatic_renewal?: boolean | null;
}

export namespace ContractCreation {

    export enum type {
        UNKNOWN = 'unknown',
        CLIENT = 'client',
        VENDOR = 'vendor',
    }


}
