import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedAssetType } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedAssetType>
      resolver="assetTypes"
      itemName="asset type"
      listItemTitle={entity => entity.name}
      listTitle="Asset types"
      detailRoute="asset-type"
      detailIdParam="assetTypeId"
      createRoute="asset-types:create"
      dependencies={[]}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default List;
