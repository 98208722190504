import React, { useEffect, useState } from 'react';
import { Typography, Input, Button } from 'antd';

import { DenormalizedPolicy } from 'store/data';
import { useDispatch, useSelector } from 'store/hooks';
import { update } from 'store/common';
import { selectLoadingUpdate } from 'store/loading';

import { useLoadingFinishedEffect } from 'utils';

const { Title } = Typography;

interface Props {
  policy: DenormalizedPolicy;
}

const PolicyTitle: React.FC<Props> = ({ policy }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(policy?.title || '');
  const loading = useSelector(state =>
    selectLoadingUpdate(state, 'policy', policy?.uuid, 'title')
  );

  useEffect(() => {
    if (policy?.title) {
      setTitle(policy.title);
    }
  }, [policy?.title]);

  const save = () => {
    if (!policy.uuid) {
      return;
    }
    dispatch(
      update({
        resolver: 'policy',
        uuid: policy.uuid,
        patch: {
          title,
        },
      })
    );
  };

  useLoadingFinishedEffect(() => {
    setIsEditing(false);
  }, loading);

  return isEditing ? (
    <Input.Group style={{ marginBottom: '0.5rem' }}>
      <Input
        autoFocus
        style={{ width: 'calc(100% - 80px)' }}
        value={title}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            save();
          }
        }}
        onChange={e => setTitle(e.target.value)}
      />
      {title === policy?.title ? (
        <Button onClick={() => setIsEditing(false)}>Cancel</Button>
      ) : (
        <Button
          disabled={!title || title === policy?.title}
          loading={loading.isFetching}
          onClick={() => save()}
          style={{ width: 80 }}>
          Save
        </Button>
      )}
    </Input.Group>
  ) : (
    <div style={{ marginBottom: '0.5rem' }} onClick={() => setIsEditing(true)}>
      <Title level={2} style={{ cursor: 'pointer' }}>
        {policy?.title}
      </Title>
    </div>
  );
};

export default PolicyTitle;
