import React, { EffectCallback } from 'react';
import { matchPath } from 'react-router';
import { Route, Switch, useLocation } from 'react-router-dom';
import { find } from 'lodash';

import PrivateRoute from 'components/private-route';

import Login from 'routes/login';
import Policies from 'routes/policies';
import Policy from 'routes/policy';
import PolicyEdit from 'routes/policy/edit';
import PolicyExport from 'routes/policy/export';
import PolicyVersions from 'routes/policy/versions';
import PolicyBuild from 'routes/policy/versions/build';
import PolicyBuilds from 'routes/policy/versions/build/list';
import PolicyAddSection from 'routes/policy/add-section';
import Overview from 'routes/overview';
import Section from 'routes/section';
import Procedures from 'routes/procedures';
import Procedure from 'routes/procedures/detail';
import CreateProcedure from 'routes/procedures/create';
import CreateAsset from 'routes/assets/create';
import Assets from 'routes/assets';
import Asset from 'routes/assets/detail';
import CreateLabel from 'routes/labels/create';
import Labels from 'routes/labels';
import Label from 'routes/labels/detail';
import AssetClasses from 'routes/asset-classes';
import AssetClass from 'routes/asset-classes/detail';
import CreateAssetClass from 'routes/asset-classes/create';
import VulnerabilityClasses from 'routes/vulnerability-classes';
import VulnerabilityClass from 'routes/vulnerability-classes/detail';
import CreateVulnerabilityClass from 'routes/vulnerability-classes/create';
import Violations from 'routes/violations';
import Violation from 'routes/violations/detail';
import CreateViolation from 'routes/violations/create';
import References from 'routes/references';
import Reference from 'routes/references/detail';
import CreateReference from 'routes/references/create';
import Activities from 'routes/activities';
import Activity from 'routes/activities/detail';
import CreateActivity from 'routes/activities/create';
import Acronyms from 'routes/acronyms';
import Acronym from 'routes/acronyms/detail';
import CreateAcronym from 'routes/acronyms/create';
import Definitions from 'routes/definitions';
import Definition from 'routes/definitions/detail';
import CreateDefinition from 'routes/definitions/create';
import ThreatClasses from 'routes/threat-classes';
import ThreatClass from 'routes/threat-classes/detail';
import CreateThreatClass from 'routes/threat-classes/create';
import Risks from 'routes/risks';
import Risk from 'routes/risks/detail';
import CreateRisk from 'routes/risks/create';
import AddRiskTreatmentAction from 'routes/risks/add-risk-treatment-action';
import RiskTreatmentActions from 'routes/risk-treatment-actions';
import RiskTreatmentAction from 'routes/risk-treatment-actions/detail';
import CreateRiskTreatmentAction from 'routes/risk-treatment-actions/create';
import EvidenceList from 'routes/evidence';
import Evidence from 'routes/evidence/detail';
import Roles from 'routes/roles';
import RoleDetail from 'routes/roles/detail';
import CreateRole from 'routes/roles/create';
import Locations from 'routes/locations';
import LocationDetail from 'routes/locations/detail';
import CreateLocation from 'routes/locations/create';
import Schedules from 'routes/schedules';
import ScheduleDetail from 'routes/schedules/detail';
import CreateSchedule from 'routes/schedules/create';
import AssetTypes from 'routes/asset-types';
import AssetTypeDetail from 'routes/asset-types/detail';
import CreateAssetType from 'routes/asset-types/create';
import AssetClassifications from 'routes/asset-classifications';
import AssetClassificationDetail from 'routes/asset-classifications/detail';
import CreateAssetClassification from 'routes/asset-classifications/create';
import Users from 'routes/users';
import UserDetail from 'routes/users/detail';
import CreateCollectionRun from 'routes/evidence/create-collection-run';
import CollectionRun from 'routes/evidence/collection-run';

import { flattenByKey, useFetchEntities } from 'utils';

export interface RouteInterface {
  path: string;
  name: keyof RouteArgs;
  component: React.ElementType;
  header?: React.ElementType;
  private?: boolean;
  exact?: boolean;
  routes?: RouteInterface[];
  props?: object;
}

export interface RouteArgs {
  login: undefined;
  overview: undefined;
  policies: undefined;
  policy: { policyId: string };
  'policy:edit': {
    policyId: string;
    field: 'purpose' | 'scope' | 'background' | 'violation' | 'references';
  };
  'policy:export': { policyId: string };
  'policy:add-section': { policyId: string };
  'policy:versions': { policyId: string };
  'policy:versions:builds': {
    versionId: string;
    policyId: string;
  };
  'policy:versions:build': {
    versionId: string;
    policyId: string;
    buildId: string;
  };
  section: { policyId: string; sectionId: string };
  'section:activity:create': {
    policyId: string;
    sectionId: string;
  };
  'section:activity': {
    policyId: string;
    sectionId: string;
    activityId: string;
  };
  assets: undefined;
  'assets:create': undefined;
  asset: { assetId: string };
  evidences: undefined;
  evidence: { evidenceId: string };
  'evidence:collection-run:create': { evidenceId: string };
  'evidence:collection-run': { evidenceId: string; collectionRunId: string };
  'asset-classes': undefined;
  'asset-classes:create': undefined;
  'asset-class': { assetClassId: string };
  'vulnerability-classes': undefined;
  'vulnerability-classes:create': undefined;
  'vulnerability-class': { vulnerabilityClassId: string };
  'threat-classes': undefined;
  'threat-classes:create': undefined;
  'threat-class': { threatClassId: string };
  'asset-types': undefined;
  'asset-types:create': undefined;
  'asset-type': { assetTypeId: string };
  procedures: undefined;
  'procedures:create': undefined;
  procedure: { procedureId: string };
  risks: undefined;
  'risks:create': undefined;
  risk: { riskId: string };
  'risk:add-risk-treatment-action': { riskId: string };
  'risk-treatment-actions': undefined;
  'risk-treatment-actions:create': undefined;
  'risk-treatment-action': { riskTreatmentActionId: string };
  '404': undefined;
  roles: undefined;
  role: { roleId: string };
  'roles:create': undefined;
  activities: undefined;
  activity: { activityId: string };
  'activities:create': undefined;
  locations: undefined;
  location: { locationId: string };
  'locations:create': undefined;
  schedules: undefined;
  schedule: { scheduleId: string };
  'schedules:create': undefined;
  'asset-classifications': undefined;
  'asset-classification': { assetClassificationId: string };
  'asset-classifications:create': undefined;
  users: undefined;
  user: { userId: string };
  labels: undefined;
  'labels:create': undefined;
  label: { labelId: string };
  violations: undefined;
  'violations:create': undefined;
  violation: { violationId: string };
  references: undefined;
  'references:create': undefined;
  reference: { referenceId: string };
  acronyms: undefined;
  'acronyms:create': undefined;
  acronym: { acronymId: string };
  definitions: undefined;
  'definitions:create': undefined;
  definition: { definitionId: string };
}

export const RouteWithSubRoutes: React.FC<RouteInterface> = route => {
  const RouteComponent = route.private ? PrivateRoute : Route;

  return (
    <RouteComponent
      path={route.path}
      render={(props: any) => (
        // pass the sub-routes down to keep nesting
        <route.component
          {...props}
          {...route.props}
          route={route}
          routes={route.routes}
        />
      )}
    />
  );
};

export const routes: RouteInterface[] = [
  {
    name: 'login',
    path: '/login/',
    exact: true,
    component: Login,
  },
  {
    name: 'overview',
    path: '/',
    private: true,
    exact: true,
    component: Overview,
  },
  {
    name: 'policies',
    component: Policies,
    private: true,
    path: '/policies/',
    routes: [
      {
        name: 'policy:versions:builds',
        path: '/policies/:policyId/versions/:versionId/',
        component: PolicyBuilds,
        routes: [
          {
            name: 'policy:versions:build',
            path: '/policies/:policyId/versions/:versionId/build/:buildId/',
            component: PolicyBuild,
          },
        ],
      },
      {
        name: 'policy',
        path: '/policies/:policyId/',
        component: Policy,
        routes: [
          {
            name: 'policy:export',
            path: '/policies/:policyId/export/',
            component: PolicyExport,
          },
          {
            name: 'policy:versions',
            path: '/policies/:policyId/versions/',
            component: PolicyVersions,
          },
          {
            name: 'policy:add-section',
            path: '/policies/:policyId/add-section/',
            component: PolicyAddSection,
          },
          {
            name: 'policy:edit',
            path: '/policies/:policyId/edit/:field/',
            component: PolicyEdit,
          },
          {
            name: 'section',
            path: '/policies/:policyId/sections/:sectionId/',
            component: Section,
            routes: [
              {
                name: 'section:activity:create',
                component: CreateActivity,
                path: '/policies/:policyId/sections/:sectionId/activities/create/',
              },
              {
                name: 'section:activity',
                component: Activity,
                path: '/policies/:policyId/sections/:sectionId/activities/:activityId/',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'assets',
    component: Assets,
    private: true,
    path: '/assets/',
    routes: [
      {
        name: 'assets:create',
        component: CreateAsset,
        path: '/assets/create/',
      },
      {
        name: 'asset',
        component: Asset,
        path: '/assets/:assetId/',
      },
    ],
  },
  {
    name: 'asset-classes',
    component: AssetClasses,
    private: true,
    path: '/asset-classes/',
    routes: [
      {
        name: 'asset-classes:create',
        component: CreateAssetClass,
        path: '/asset-classes/create/',
      },
      {
        name: 'asset-class',
        component: AssetClass,
        path: '/asset-classes/:assetClassId/',
      },
    ],
  },
  {
    name: 'vulnerability-classes',
    component: VulnerabilityClasses,
    private: true,
    path: '/vulnerability-classes/',
    routes: [
      {
        name: 'vulnerability-classes:create',
        component: CreateVulnerabilityClass,
        path: '/vulnerability-classes/create/',
      },
      {
        name: 'vulnerability-class',
        component: VulnerabilityClass,
        path: '/vulnerability-classes/:vulnerabilityClassId/',
      },
    ],
  },
  {
    name: 'threat-classes',
    component: ThreatClasses,
    private: true,
    path: '/threat-classes/',
    routes: [
      {
        name: 'threat-classes:create',
        component: CreateThreatClass,
        path: '/threat-classes/create/',
      },
      {
        name: 'threat-class',
        component: ThreatClass,
        path: '/threat-classes/:threatClassId/',
      },
    ],
  },
  {
    name: 'procedures',
    component: Procedures,
    private: true,
    path: '/procedures/',
    routes: [
      {
        name: 'procedures:create',
        component: CreateProcedure,
        path: '/procedures/create/',
      },
      {
        name: 'procedure',
        component: Procedure,
        path: '/procedures/:procedureId/',
      },
    ],
  },
  {
    name: 'risks',
    component: Risks,
    private: true,
    path: '/risks/',
    routes: [
      {
        name: 'risks:create',
        component: CreateRisk,
        path: '/risks/create/',
      },
      {
        name: 'risk',
        component: Risk,
        path: '/risks/:riskId/',
        routes: [
          {
            name: 'risk:add-risk-treatment-action',
            path: '/risks/:riskId/add-risk-treatment-action/',
            component: AddRiskTreatmentAction,
          },
        ],
      },
    ],
  },
  {
    name: 'risk-treatment-actions',
    component: RiskTreatmentActions,
    private: true,
    path: '/risk-treatment-actions/',
    routes: [
      {
        name: 'risk-treatment-actions:create',
        component: CreateRiskTreatmentAction,
        path: '/risk-treatment-actions/create/',
      },
      {
        name: 'risk-treatment-action',
        component: RiskTreatmentAction,
        path: '/risk-treatment-actions/:riskTreatmentActionId/',
      },
    ],
  },
  {
    name: 'roles',
    component: Roles,
    private: true,
    path: '/roles/',
    routes: [
      {
        name: 'roles:create',
        component: CreateRole,
        path: '/roles/create/',
      },
      {
        name: 'role',
        component: RoleDetail,
        path: '/roles/:roleId/',
      },
    ],
  },
  {
    name: 'locations',
    component: Locations,
    private: true,
    path: '/locations/',
    routes: [
      {
        name: 'locations:create',
        component: CreateLocation,
        path: '/locations/create/',
      },
      {
        name: 'location',
        component: LocationDetail,
        path: '/locations/:locationId/',
      },
    ],
  },
  {
    name: 'schedules',
    component: Schedules,
    private: true,
    path: '/schedules/',
    routes: [
      {
        name: 'schedules:create',
        component: CreateSchedule,
        path: '/schedules/create/',
      },
      {
        name: 'schedule',
        component: ScheduleDetail,
        path: '/schedules/:scheduleId/',
      },
    ],
  },
  {
    name: 'asset-types',
    component: AssetTypes,
    private: true,
    path: '/asset-types/',
    routes: [
      {
        name: 'asset-types:create',
        component: CreateAssetType,
        path: '/asset-types/create/',
      },
      {
        name: 'asset-type',
        component: AssetTypeDetail,
        path: '/asset-types/:assetTypeId/',
      },
    ],
  },
  {
    name: 'asset-classifications',
    component: AssetClassifications,
    private: true,
    path: '/asset-classifications/',
    routes: [
      {
        name: 'asset-classifications:create',
        component: CreateAssetClassification,
        path: '/asset-classifications/create/',
      },
      {
        name: 'asset-classification',
        component: AssetClassificationDetail,
        path: '/asset-classifications/:assetClassificationId/',
      },
    ],
  },
  {
    name: 'evidences',
    component: EvidenceList,
    private: true,
    path: '/evidence/',
    routes: [
      {
        name: 'evidence',
        component: Evidence,
        path: '/evidence/:evidenceId/',
        routes: [
          {
            name: 'evidence:collection-run:create',
            component: CreateCollectionRun,
            path: '/evidence/:evidenceId/create-collection-run/',
          },
          {
            name: 'evidence:collection-run',
            component: CollectionRun,
            path: '/evidence/:evidenceId/collection-run/:collectionRunId/',
          },
        ],
      },
    ],
  },
  {
    name: 'labels',
    component: Labels,
    private: true,
    path: '/labels/',
    routes: [
      {
        name: 'labels:create',
        component: CreateLabel,
        path: '/labels/create/',
      },
      {
        name: 'label',
        component: Label,
        path: '/labels/:labelId/',
      },
    ],
  },
  {
    name: 'violations',
    component: Violations,
    private: true,
    path: '/violations/',
    routes: [
      {
        name: 'violations:create',
        component: CreateViolation,
        path: '/violations/create/',
      },
      {
        name: 'violation',
        component: Violation,
        path: '/violations/:violationId/',
      },
    ],
  },
  {
    name: 'references',
    component: References,
    private: true,
    path: '/references/',
    routes: [
      {
        name: 'references:create',
        component: CreateReference,
        path: '/references/create/',
      },
      {
        name: 'reference',
        component: Reference,
        path: '/references/:referenceId/',
      },
    ],
  },
  {
    name: 'activities',
    component: Activities,
    private: true,
    path: '/activities/',
    routes: [
      {
        name: 'activities:create',
        component: CreateActivity,
        path: '/activities/create/',
      },
      {
        name: 'activity',
        component: Activity,
        path: '/activities/:activityId/',
      },
    ],
  },
  {
    name: 'acronyms',
    component: Acronyms,
    private: true,
    path: '/acronyms/',
    routes: [
      {
        name: 'acronyms:create',
        component: CreateAcronym,
        path: '/acronyms/create/',
      },
      {
        name: 'acronym',
        component: Acronym,
        path: '/acronyms/:acronymId/',
      },
    ],
  },
  {
    name: 'definitions',
    component: Definitions,
    private: true,
    path: '/definitions/',
    routes: [
      {
        name: 'definitions:create',
        component: CreateDefinition,
        path: '/definitions/create/',
      },
      {
        name: 'definition',
        component: Definition,
        path: '/definitions/:definitionId/',
      },
    ],
  },
  {
    name: 'users',
    component: Users,
    private: true,
    path: '/users/',
    routes: [
      {
        name: 'user',
        component: UserDetail,
        path: '/users/:userId/',
      },
    ],
  },
];

/**
 * Gets the path of a route by name
 */
export const url = <T extends keyof RouteArgs>(
  routeName: T,
  ...paramsTuple: RouteArgs[T] extends undefined ? [undefined?] : [RouteArgs[T]]
): string => {
  const params = paramsTuple[0];
  const route = find(
    flattenByKey(routes, 'routes'),
    route => route.name === routeName
  );

  if (!route) {
    throw new Error(
      `Could not find the route with name ${routeName}. Make sure it does exist.`
    );
  }

  const path = route.path;

  if (params) {
    return path.replace(
      /(?::)([A-Za-z0-9-]+?)(?:\/)/g,
      (_, param: keyof RouteArgs[T]) => `${(params as RouteArgs[T])[param]}/`
    );
  }

  // this shouldn't be possible in TypeScript
  // istanbul ignore next
  return route.path;
};

export const urlPattern = <T extends keyof RouteArgs>(
  routeName: T,
  params?: Partial<RouteArgs[T]>
): string => {
  const route = find(
    flattenByKey(routes, 'routes'),
    route => route.name === routeName
  );

  if (!route) {
    throw new Error(
      `Could not find the route with name ${routeName}. Make sure it does exist.`
    );
  }

  const path = route.path;

  if (params) {
    return path.replace(
      /(?::)([A-Za-z0-9-]+?)(?:\/)/g,
      (full, param: keyof RouteArgs[T]) =>
        param && params[param] ? `${params[param]}/` : full
    );
  }

  return route.path;
};

export const useParams = <T extends keyof RouteArgs>(
  routeName: T
): Partial<RouteArgs[T]> => {
  const location = useLocation();

  const matchSubRoute = matchPath(location.pathname, {
    path: urlPattern(routeName),
  });

  return (matchSubRoute && matchSubRoute.params) || {};
};

const Routes: React.FC = () => {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={i} {...route} />;
      })}
    </Switch>
  );
};

export const useRouteVisit = (
  cb: EffectCallback,
  pathname: string,
  deps: any[]
) => {
  const currentPathname = useLocation().pathname;

  return useFetchEntities(() => {
    if (currentPathname === pathname) {
      return cb();
    }
    // eslint-disable-next-line
  }, [currentPathname, pathname, ...deps]);
};

export default Routes;
