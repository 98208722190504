/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExceptionReviewCreation = {
    readonly uuid?: string;
    exception: string;
    review_date?: string;
    /**
     * Reviewer of the exception.
     */
    reviewer: string;
    status?: ExceptionReviewCreation.status;
    notes?: string | null;
}

export namespace ExceptionReviewCreation {

    export enum status {
        UNKNOWN = 'unknown',
        APPROVED = 'approved',
        DENIED = 'denied',
        TENTATIVE = 'tentative',
    }


}
