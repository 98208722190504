import React from 'react';

import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/locations/form';

import { useSelector } from 'store/hooks';
import { DenormalizedLocation } from 'store/data';
import { selectDetail } from 'store/common';

const Detail: React.FC = () => {
  const { locationId } = useParams('location');
  const entity = useSelector(state =>
    selectDetail<DenormalizedLocation>(state, 'location', locationId as string)
  );

  const initialValues = {
    name: entity.name,
  };

  return (
    <GenericDetail
      resolver="location"
      listRoute="locations"
      createRoute="locations:create"
      detailIdParam="locationId"
      listTitle="locations"
      listItemTitle={entity => entity.name}
      itemName="location">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericDetail>
  );
};

export default Detail;
