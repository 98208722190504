import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/locations/form';

const Create: React.FC = () => {
  const initialValues = {
    name: '',
  };

  return (
    <GenericCreate
      resolver="location"
      listResolver="locations"
      createRoute="locations:create"
      listRoute="locations"
      detailRoute="location"
      detailIdParam="locationId"
      listTitle="Locations"
      listItemTitle={entity => entity.name}
      itemName="location">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
