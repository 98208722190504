/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PolicyBuildCreation } from '../models/PolicyBuildCreation';
import type { PolicyBuildDownloadURL } from '../models/PolicyBuildDownloadURL';
import type { PolicyBuildListing } from '../models/PolicyBuildListing';
import type { PolicyBuildRetrieval } from '../models/PolicyBuildRetrieval';
import { request as __request } from '../core/request';

export class PolicyBuildsService {

    /**
     * List policy builds
     * @returns any
     * @throws ApiError
     */
    public static async listPolicyBuilds({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<PolicyBuildListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/policy-builds/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new policy build object
     * @returns PolicyBuildCreation
     * @throws ApiError
     */
    public static async createPolicyBuild({
        data,
    }: {
        data: PolicyBuildCreation,
    }): Promise<PolicyBuildCreation> {
        const result = await __request({
            method: 'POST',
            path: `/policy-builds/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single policy build
     * @returns PolicyBuildRetrieval
     * @throws ApiError
     */
    public static async getPolicyBuild({
        uuid,
    }: {
        /** A UUID string identifying this policy build. **/
        uuid: string,
    }): Promise<PolicyBuildRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/policy-builds/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async downloadPolicyBuild({
        uuid,
    }: {
        /** A UUID string identifying this policy build. **/
        uuid: string,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/policy-builds/${uuid}/download/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicyBuildDownloadURL
     * @throws ApiError
     */
    public static async getPresignedDownloadUrlPolicyBuild({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy build. **/
        uuid: string,
        data: PolicyBuildDownloadURL,
    }): Promise<PolicyBuildDownloadURL> {
        const result = await __request({
            method: 'POST',
            path: `/policy-builds/${uuid}/get_presigned_download_url/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async viewPolicyBuild({
        uuid,
    }: {
        /** A UUID string identifying this policy build. **/
        uuid: string,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/policy-builds/${uuid}/view/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}