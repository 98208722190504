import React from 'react';
import { Action } from 'redux';
import { RiskTreatmentActionCreation } from 'api';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/risk-treatment-actions/form';

interface Props {
  showHeader?: boolean;
  successRedirect?: string;
  successActions?: (Action | ((uuid: string) => Action))[];
  initialValues?: any;
}

const Create: React.FC<Props> = ({
  showHeader = true,
  successRedirect,
  successActions,
  initialValues = {},
}) => {
  const values = {
    identifier: '',
    owner: null,
    deadline: null,
    type: RiskTreatmentActionCreation.type.IMPROVEMENT,
    description: '',
    risks: [],
    status: RiskTreatmentActionCreation.status.PLANNED,
    ...initialValues,
  };

  return (
    <GenericCreate
      showHeader={showHeader}
      successActions={successActions}
      successRedirect={successRedirect}
      resolver="riskTreatmentAction"
      listResolver="riskTreatmentActions"
      createRoute="risk-treatment-actions:create"
      listRoute="risk-treatment-actions"
      detailRoute="risk-treatment-action"
      detailIdParam="riskTreatmentActionId"
      listTitle="Risk Treatment Actions"
      listItemTitle={entity => `${entity.human_id}. ${entity.title}`}
      itemName="risk treatment action">
      {props => <EditForm initialValues={values} {...props} />}
    </GenericCreate>
  );
};

export default Create;
