import { applyMiddleware, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from 'store/reducers';
import rootSaga from 'store/sagas';
import { rootPersistConfig } from 'store/persist';

export const history = createBrowserHistory();

export function configureStore(
  {
    persist = false,
    enableSagas = true,
    initialState = {},
    history: passedHistory,
  }: {
    persist?: boolean;
    enableSagas?: boolean;
    initialState?: any;
    history: any;
  } = { history }
): { store: Store; persistor: any | null } {
  const rootReducer = createRootReducer(history);
  const persistedReducer = persistReducer(
    rootPersistConfig,
    rootReducer as any
  );

  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, routerMiddleware(passedHistory)];

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { createLogger } = require('redux-logger');

    middlewares.push(
      createLogger({
        collapsed: () => true,
        titleFormatter: (action: any, time: string) => {
          const parts = [];

          parts.push(
            `${String(action.type)}${
              action.meta?.resolver ? `(${action.meta.resolver})` : ''
            }${action.payload?.where ? `(${action.payload.where})` : ''}`
          );
          parts.push(`@ ${time}`);

          return parts.join(' ');
        },
      })
    );
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  if (persist) {
    const store = createStore(
      persistedReducer,
      initialState,
      composedEnhancers
    );
    const persistor = persistStore(store);

    if (enableSagas) {
      sagaMiddleware.run(rootSaga as any);
    }

    return { store, persistor };
  } else {
    const store = createStore(rootReducer, initialState, composedEnhancers);

    if (enableSagas) {
      sagaMiddleware.run(rootSaga as any);
    }

    return { store, persistor: null };
  }
}

const { store, persistor } = configureStore({ persist: true, history });

export type AppStateInterface = ReturnType<
  ReturnType<typeof createRootReducer>
>;
export type AppDispatch = typeof store.dispatch;
export default store;
export { persistor };
