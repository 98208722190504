import { ActionPattern, Task } from '@redux-saga/types';
import { call, fork, take, select } from 'redux-saga/effects';

import { AppStateInterface } from 'store';

export const takeEveryWithPreviousState = function* (
  pattern: ActionPattern,
  saga: any
) {
  let prevFullState: AppStateInterface | undefined;

  yield fork(function* () {
    while (true) {
      const action: any = yield take(pattern);

      yield fork(saga, action, { ...prevFullState });

      prevFullState = yield select(state => state);
    }
  });

  while (true) {
    yield take((action: any) => action.type !== pattern);

    prevFullState = yield select(state => state);
  }
};

export function takeLeadingPerKey<T extends (...args: any) => any>(
  patternOrChannel: ActionPattern,
  worker: T,
  keySelector: (action: Parameters<T>[0]) => string
) {
  return fork(function* () {
    const tasks: Record<string, Task> = {};

    while (true) {
      const action: any = yield take(patternOrChannel);
      const key: any = yield call(keySelector, action);

      if (!(tasks[key] && tasks[key].isRunning())) {
        tasks[key] = yield fork(worker as any, action);
      }
    }
  });
}
