/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PolicyGroupListing } from '../models/PolicyGroupListing';
import type { PolicyGroupMove } from '../models/PolicyGroupMove';
import type { PolicyGroupRetrieval } from '../models/PolicyGroupRetrieval';
import { request as __request } from '../core/request';

export class PolicyGroupsService {

    /**
     * List policy groups
     * @returns any
     * @throws ApiError
     */
    public static async listPolicyGroups({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<PolicyGroupListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/policy-groups/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single policy group
     * @returns PolicyGroupRetrieval
     * @throws ApiError
     */
    public static async getPolicyGroup({
        uuid,
    }: {
        /** A UUID string identifying this policy group. **/
        uuid: string,
    }): Promise<PolicyGroupRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/policy-groups/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicyGroupMove
     * @throws ApiError
     */
    public static async moveAfterPolicyGroup({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy group. **/
        uuid: string,
        data: PolicyGroupMove,
    }): Promise<PolicyGroupMove> {
        const result = await __request({
            method: 'POST',
            path: `/policy-groups/${uuid}/move_after/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicyGroupMove
     * @throws ApiError
     */
    public static async moveBeforePolicyGroup({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy group. **/
        uuid: string,
        data: PolicyGroupMove,
    }): Promise<PolicyGroupMove> {
        const result = await __request({
            method: 'POST',
            path: `/policy-groups/${uuid}/move_before/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}