import React from 'react';

import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/roles/form';

import { useSelector } from 'store/hooks';
import { DenormalizedRole } from 'store/data';
import { selectDetail } from 'store/common';

const Detail: React.FC = () => {
  const { roleId } = useParams('role');
  const role = useSelector(state =>
    selectDetail<DenormalizedRole>(state, 'role', roleId as string)
  );

  const initialValues = {
    name: role.name,
    description: role.description,
  };

  return (
    <GenericDetail
      resolver="role"
      listRoute="roles"
      detailIdParam="roleId"
      listTitle="Roles"
      createRoute="roles:create"
      listItemTitle={entity => entity.name}
      itemName="role">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericDetail>
  );
};

export default Detail;
