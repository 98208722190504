import React, { useState } from 'react';
import { Form, Button, Modal, Checkbox, Select, Input, Row } from 'antd';
import { values } from 'lodash';
import { useHistory } from 'react-router-dom';
import { ChangelogEntry, PolicyExportToGit } from 'api';

import { url, useParams } from 'routes';

import { exportToGit } from 'store/data';
import { selectLoading } from 'store/loading';
import { useLoadingFinishedEffect } from 'utils';
import { useSelector, useDispatch } from 'store/hooks';

const { TextArea } = Input;

const ExportModal: React.FC = () => {
  const dispatch = useDispatch();
  const { policyId } = useParams('policy') as { policyId: string };

  const loading = useSelector(state =>
    selectLoading(state, exportToGit.TYPE, policyId)
  );
  const [form] = Form.useForm();
  const history = useHistory();
  const [visible, setVisible] = useState(true);
  const [needsMergeRequest, setMergeRequest] = useState(false);
  const [needsChangelogEntry, setChangelogEntry] = useState(false);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = async ({
    branch_name,
    commit_message,
    ticket_id,
    message,
    change_type,
    title,
    target_branch,
  }: any) => {
    const data: PolicyExportToGit = {
      branch_name,
      commit_message,
    };

    if (needsMergeRequest && target_branch && title) {
      data.merge_request = {
        target_branch,
        title,
      };
    }

    if (needsChangelogEntry && ticket_id && message && change_type) {
      data.changelog_entry = {
        ticket_id,
        message,
        change_type,
      };
    }

    dispatch(
      exportToGit({
        policyId,
        data,
        successMessage: 'Export succeeded',
      })
    );
  };

  const onClose = () => {
    setVisible(false);
  };

  useLoadingFinishedEffect(() => {
    form.resetFields();
    onClose();
  }, loading);

  return (
    <>
      <Modal
        title="Export to git"
        visible={visible}
        onCancel={onClose}
        forceRender={true}
        afterClose={() => {
          history.push(url('policy', { policyId }));
        }}
        footer={
          <Row justify="space-between">
            <Button
              key="cancel"
              style={{ marginRight: 'auto' }}
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={false}
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    form.submit();
                  })
                  .catch(() => {});
              }}
              key="ok"
              type="primary"
              loading={loading.isFetching}>
              {loading.isFetching ? 'Exporting...' : 'Export'}
            </Button>
          </Row>
        }>
        <Form
          style={{ marginBottom: -24 }}
          initialValues={{
            branch_name: '',
            commit_message: '',
            target_branch: '',
            title: '',
            ticket_id: '',
            message: '',
            change_type: ChangelogEntry.change_type.CHANGE,
          }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical">
          <Form.Item
            name="branch_name"
            rules={[{ required: true, message: 'Branch name is required' }]}
            label="Branch name">
            <Input />
          </Form.Item>
          <Form.Item
            name="commit_message"
            label="Commit message"
            rules={[
              {
                required: true,
                message: "Commit message is required. Make sure it's good",
              },
            ]}>
            <TextArea
              autoSize={{
                minRows: 2,
              }}
            />
          </Form.Item>

          <Form.Item>
            <Checkbox
              checked={needsMergeRequest}
              onChange={(e: any) => setMergeRequest(e.target.checked)}>
              Create merge request
            </Checkbox>
          </Form.Item>

          {needsMergeRequest ? (
            <>
              <Form.Item
                rules={[{ required: needsMergeRequest }]}
                name="target_branch"
                label="Target branch">
                <Input />
              </Form.Item>
              <Form.Item
                rules={[{ required: needsMergeRequest }]}
                name="title"
                label="Title">
                <Input />
              </Form.Item>
            </>
          ) : null}

          <Form.Item>
            <Checkbox
              checked={needsChangelogEntry}
              onChange={(e: any) => setChangelogEntry(e.target.checked)}>
              Add changelog entry
            </Checkbox>
          </Form.Item>

          {needsChangelogEntry ? (
            <>
              <Form.Item
                rules={[{ required: needsChangelogEntry }]}
                name="ticket_id"
                label="Ticket ID">
                <Input />
              </Form.Item>

              <Form.Item
                rules={[{ required: needsChangelogEntry }]}
                name="change_type"
                label="Change type">
                <Select>
                  {values(ChangelogEntry.change_type).map(option => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                rules={[{ required: needsChangelogEntry }]}
                name="message"
                label="Message">
                <Input />
              </Form.Item>
            </>
          ) : null}
        </Form>
      </Modal>
    </>
  );
};

export default ExportModal;
