import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, Input, Row } from 'antd';
import { useHistory } from 'react-router-dom';

import { url, useParams } from 'routes';

import { useDispatch, useSelector } from 'store/hooks';
import { create, list } from 'store/common';
import { selectLoadingCreate } from 'store/loading';

import { useLoadingFinishedEffect } from 'utils';

const AddSectionModal: React.FC = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<any>();
  const [form] = Form.useForm();
  const history = useHistory();
  const [visible, setVisible] = useState(true);
  const { policyId } = useParams('policy') as { policyId: string };
  const loading = useSelector(state =>
    selectLoadingCreate(state, 'section', policyId)
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = async ({ title }: { title: string }) => {
    dispatch(
      create({
        resolver: 'section',
        loadingKey: policyId,
        data: {
          policy: policyId,
          title,
        },
        successMessage: 'Section added.',
        successActions: [
          list({
            resolver: 'sections',
            paginationKey: policyId,
            extra: {
              policy: policyId,
            },
          }),
        ],
      })
    );
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useLoadingFinishedEffect(() => {
    form.resetFields();
    onClose();
  }, loading);

  return (
    <>
      <Modal
        title="Add section"
        visible={visible}
        onCancel={onClose}
        forceRender={true}
        afterClose={() => {
          history.push(url('policy', { policyId }));
        }}
        footer={
          <Row justify="space-between">
            <Button
              key="cancel"
              style={{ marginRight: 'auto' }}
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={false}
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    form.submit();
                  })
                  .catch(() => {});
              }}
              key="ok"
              type="primary"
              loading={loading.isFetching}>
              {loading.isFetching ? 'Adding...' : 'Add'}
            </Button>
          </Row>
        }>
        <Form
          initialValues={{
            title: '',
          }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical">
          <Form.Item
            name="title"
            rules={[{ required: true, message: 'Title is required' }]}
            label="Title">
            <Input ref={inputRef} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddSectionModal;
