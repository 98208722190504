import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { includes, differenceBy, compact } from 'lodash';

import Coverage from 'components/coverage';
import AddRequirement from 'routes/section/add-requirement';

import { list, detail } from 'store/common';
import { useSelector, useDispatch } from 'store/hooks';
import { selectEntity } from 'store/data';
import { usePrevious } from 'utils';

const { Title } = Typography;

interface Props {
  sectionId: string;
}

const MapRequirements: React.FC<Props> = ({ sectionId }) => {
  const previousSectionId = usePrevious(sectionId);
  const dispatch = useDispatch();

  const section = useSelector(state =>
    selectEntity(state, 'section', sectionId)
  );
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const previousSectionCoverages = usePrevious(section.coverages);

  const requirements = compact(
    (section.coverages || []).map((c: any) => c.requirement?.uuid)
  );
  const requirementsAsString = requirements.join('');

  useEffect(() => {
    if (requirementsAsString.length) {
      dispatch(
        list({
          resolver: 'requirements',
          paginationKey: section.uuid,
          extra: {
            uuid: requirements,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [requirementsAsString, dispatch, section.uuid]);

  useEffect(() => {
    if (
      typeof section.coverages?.length === 'number' &&
      typeof previousSectionCoverages?.length === 'number' &&
      previousSectionId &&
      previousSectionId === sectionId
    ) {
      if (previousSectionCoverages.length < section.coverages.length) {
        const newKeys = differenceBy(
          section.coverages,
          previousSectionCoverages,
          'uuid'
        ).map(c => c.uuid);

        setExpandedKeys(newKeys);
      }
    }
    // eslint-disable-next-line
  }, [
    section.coverages?.length,
    previousSectionCoverages?.length,
    previousSectionId,
    sectionId,
  ]);

  return (
    <>
      <Title level={4}>Requirements</Title>
      {section.coverages?.length ? (
        <div className="section__requirements" style={{ marginBottom: 20 }}>
          {section.coverages?.map(coverage => (
            <Coverage
              expanded={includes(expandedKeys, coverage.uuid)}
              onDelete={() =>
                dispatch(detail({ resolver: 'section', uuid: section.uuid }))
              }
              key={coverage.uuid}
              coverage={coverage}
            />
          ))}
        </div>
      ) : (
        <p>
          <i>No requirements mapped</i>
        </p>
      )}
      <AddRequirement section={section} />
    </>
  );
};

export default MapRequirements;
