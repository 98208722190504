/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SectionRequirementCoverageCreation = {
    readonly uuid?: string;
    requirement: string;
    section: string;
    coverage?: SectionRequirementCoverageCreation.coverage;
    gap_description?: string;
}

export namespace SectionRequirementCoverageCreation {

    export enum coverage {
        UNKNOWN = 'unknown',
        NONE = 'none',
        PARTIAL = 'partial',
        FULL = 'full',
    }


}
