import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/roles/form';

const Create: React.FC = () => {
  const initialValues = {
    name: '',
    description: '',
  };

  return (
    <GenericCreate
      resolver="role"
      listResolver="roles"
      createRoute="roles:create"
      listRoute="roles"
      detailRoute="role"
      detailIdParam="roleId"
      listTitle="Roles"
      listItemTitle={entity => entity.name}
      itemName="role">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
