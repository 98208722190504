/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionRunCreation } from '../models/CollectionRunCreation';
import type { CollectionRunListing } from '../models/CollectionRunListing';
import type { CollectionRunRetrieval } from '../models/CollectionRunRetrieval';
import { request as __request } from '../core/request';

export class CollectionRunsService {

    /**
     * List collection runs
     * @returns any
     * @throws ApiError
     */
    public static async listCollectionRuns({
        evidence,
        page,
        pageSize,
    }: {
        evidence?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<CollectionRunListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/collection-runs/`,
            query: {
                'evidence': evidence,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new collection run object
     * @returns CollectionRunCreation
     * @throws ApiError
     */
    public static async createCollectionRun({
        data,
    }: {
        data: CollectionRunCreation,
    }): Promise<CollectionRunCreation> {
        const result = await __request({
            method: 'POST',
            path: `/collection-runs/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single collection run
     * @returns CollectionRunRetrieval
     * @throws ApiError
     */
    public static async getCollectionRun({
        uuid,
    }: {
        /** A UUID string identifying this collection run. **/
        uuid: string,
    }): Promise<CollectionRunRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/collection-runs/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}