import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedLocation } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const Locations: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedLocation>
      resolver="locations"
      itemName="location"
      listItemTitle={entity => entity.name}
      listTitle="Locations"
      detailRoute="location"
      detailIdParam="locationId"
      createRoute="locations:create"
      dependencies={[]}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default Locations;
