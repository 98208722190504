import React from 'react';
import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/references/form';

import { useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';

const Detail: React.FC = () => {
  const { referenceId } = useParams('reference') as {
    referenceId: string;
  };
  const entity = useSelector(state =>
    selectEntity(state, 'reference', referenceId)
  );

  const initialValues = {
    name: entity.name,
    url: entity.url,
  };

  return (
    <>
      <GenericDetail
        resolver="reference"
        listRoute="references"
        detailIdParam="referenceId"
        listTitle="References"
        createRoute="references:create"
        listItemTitle={entity => entity.name}
        itemName="reference">
        {props => (
          <>
            <EditForm initialValues={initialValues} {...props} />
          </>
        )}
      </GenericDetail>
    </>
  );
};

export default Detail;
