import React from 'react';
import { values } from 'lodash';
import { RiskTreatmentActionUpdate } from 'api';
import { Form, Input, Select, DatePicker } from 'antd';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';

import { useSelector } from 'store/hooks';
import { selectEntities } from 'store/data';

import { presentableValue } from 'utils';

type Props = Omit<GenericFormProps, 'renderFields'>;

const { TextArea } = Input;

const EditForm: React.FC<Props> = props => {
  const roles = useSelector(state => selectEntities(state, 'roles', 'all'));
  const risks = useSelector(state => selectEntities(state, 'risks', 'all'));
  const { onSubmit } = props;

  return (
    <GenericForm
      {...props}
      onSubmit={(values: any) => {
        onSubmit({
          ...values,
          deadline: values.deadline.format('YYYY-MM-DD'),
        });
      }}
      renderFields={() => (
        <>
          <Form.Item required label="Title" name="title">
            <Input className="monospace" />
          </Form.Item>
          <Form.Item required name="owner" label="Owner">
            <Select showSearch optionFilterProp="children">
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item required label="Deadline" name="deadline">
            <DatePicker />
          </Form.Item>
          <Form.Item required name="status" label="Status">
            <Select>
              {values(RiskTreatmentActionUpdate.status).map(v => (
                <Select.Option key={v} value={v}>
                  {presentableValue(v)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item required name="type" label="Type">
            <Select>
              {values(RiskTreatmentActionUpdate.type).map(v => (
                <Select.Option key={v} value={v}>
                  {presentableValue(v)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item required name="risks" label="Risks">
            <Select showSearch mode="multiple" optionFilterProp="children">
              {risks.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.human_id}. {r.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item required label="Description" name="description">
            <TextArea autoSize className="monospace" />
          </Form.Item>
        </>
      )}
    />
  );
};

export default EditForm;
