import { merge, omit, assignWith, isUndefined } from 'lodash';

import {
  DetailResolver,
  ListResolver,
  list,
  detail,
  update,
  destroy,
} from 'store/common';

import {
  LabelListing,
  PolicyGroupListing,
  RequirementSetListing,
  PolicySectionListing,
  PolicyListing,
  PolicyBuildListing,
  PolicyVersionListing,
  RequirementListing,
  SectionRequirementCoverageListing,
  ProcedureListing,
  RoleListing,
  AssetListing,
  AssetClassListing,
  AssetTypeListing,
  LocationListing,
  AssetClassificationListing,
  RiskListing,
  ThreatClassListing,
  VulnerabilityClassListing,
  ScheduleListing,
  UserListing,
  EvidenceListing,
  CollectionRunListing,
  RiskTreatmentActionListing,
  ViolationListing,
  AcronymListing,
  DefinitionListing,
  ActivityListing,
  ReferenceListing,
  AttachmentListing,
} from 'api';

interface EntitiesState {
  labels: EntityList<LabelListing>;
  requirementSets: EntityList<RequirementSetListing>;
  policyGroups: EntityList<PolicyGroupListing>;
  policyVersions: EntityList<PolicyVersionListing>;
  policyBuilds: EntityList<PolicyBuildListing>;
  sections: EntityList<PolicySectionListing>;
  policies: EntityList<PolicyListing>;
  requirements: EntityList<RequirementListing>;
  coverages: EntityList<SectionRequirementCoverageListing>;
  procedures: EntityList<ProcedureListing>;
  roles: EntityList<RoleListing>;
  locations: EntityList<LocationListing>;
  assetTypes: EntityList<AssetTypeListing>;
  assetClasses: EntityList<AssetClassListing>;
  assets: EntityList<AssetListing>;
  assetClassifications: EntityList<AssetClassificationListing>;
  vulnerabilityClasses: EntityList<VulnerabilityClassListing>;
  threatClasses: EntityList<ThreatClassListing>;
  risks: EntityList<RiskListing>;
  schedules: EntityList<ScheduleListing>;
  users: EntityList<UserListing>;
  evidences: EntityList<EvidenceListing>;
  collectionRuns: EntityList<CollectionRunListing>;
  riskTreatmentActions: EntityList<RiskTreatmentActionListing>;
  violations: EntityList<ViolationListing>;
  acronyms: EntityList<AcronymListing>;
  definitions: EntityList<DefinitionListing>;
  activities: EntityList<ActivityListing>;
  references: EntityList<ReferenceListing>;
  attachments: EntityList<AttachmentListing>;
}

export const resolverToEntities: Record<DetailResolver, keyof EntitiesState> = {
  label: 'labels',
  requirement: 'requirements',
  policy: 'policies',
  section: 'sections',
  policyGroup: 'policyGroups',
  requirementSet: 'requirementSets',
  coverage: 'coverages',
  policyVersion: 'policyVersions',
  policyBuild: 'policyBuilds',
  procedure: 'procedures',
  role: 'roles',
  location: 'locations',
  assetClass: 'assetClasses',
  asset: 'assets',
  assetType: 'assetTypes',
  assetClassification: 'assetClassifications',
  threatClass: 'threatClasses',
  vulnerabilityClass: 'vulnerabilityClasses',
  risk: 'risks',
  schedule: 'schedules',
  user: 'users',
  evidence: 'evidences',
  collectionRun: 'collectionRuns',
  riskTreatmentAction: 'riskTreatmentActions',
  violation: 'violations',
  acronym: 'acronyms',
  definition: 'definitions',
  activity: 'activities',
  reference: 'references',
  attachment: 'attachments',
};

const entities = (
  state: EntitiesState = {
    labels: {},
    requirementSets: {},
    requirements: {},
    policyGroups: {},
    policyVersions: {},
    policyBuilds: {},
    sections: {},
    policies: {},
    coverages: {},
    procedures: {},
    roles: {},
    locations: {},
    assetClasses: {},
    assetTypes: {},
    assetClassifications: {},
    assets: {},
    vulnerabilityClasses: {},
    threatClasses: {},
    risks: {},
    schedules: {},
    users: {},
    evidences: {},
    collectionRuns: {},
    riskTreatmentActions: {},
    violations: {},
    acronyms: {},
    definitions: {},
    activities: {},
    references: {},
    attachments: {},
  },
  action: any
): EntitiesState => {
  switch (action.type) {
    case list.SUCCESS_TYPE: {
      const entities = action.meta.resolver as ListResolver;

      const updatedEntities = action.payload.result.reduce(
        (memo: Record<string, any>, uuid: string) => ({
          ...memo,
          // basically - Object.assign, but if it's an array - set it
          // but if it's undefined (meaning the list request is overriding stuff
          // from the detail request because list doesn't have same fields exposed) -
          // don't do it
          [uuid]: assignWith(
            {},
            (state[entities] || {})[uuid],
            (action.payload.entities[entities] || {})[uuid],
            (existingValue: any, newValue: any) => {
              if (isUndefined(newValue)) {
                return existingValue;
              }

              return newValue;
            }
          ),
        }),
        {} as Record<string, any>
      );

      return {
        ...merge({}, state, action.payload.entities),
        [entities]: {
          ...(state[entities] as any),
          ...updatedEntities,
        },
      };
    }

    case detail.SUCCESS_TYPE: {
      const entities =
        resolverToEntities[action.meta.resolver as DetailResolver];

      return {
        ...merge({}, state, action.payload.entities),
        [entities]: {
          ...(state[entities] as any),
          [action.payload.result]:
            // FORCE reset to new data, since otherwise nested objects
            // will be merged, like e.g. coverages
            action.payload.entities[entities][action.payload.result],
        },
      };
    }

    case update.SUCCESS_TYPE: {
      const entities =
        resolverToEntities[action.meta.resolver as DetailResolver];
      const payloadEntity =
        action.payload.entities[entities][action.payload.result];

      return {
        ...merge({}, state, action.payload.entities),
        [entities]: {
          ...(state[entities] as any),
          [action.payload.result]: {
            ...state[entities][action.payload.result],
            // force reset to new data
            ...payloadEntity,
          },
        },
      };
    }

    case destroy.SUCCESS_TYPE: {
      const entities =
        resolverToEntities[action.meta.resolver as DetailResolver];
      const entityId = action.payload.uuid;

      return {
        ...state,
        [entities]: omit(state[entities], entityId),
      };
    }

    default: {
      if (action.payload && action.payload.entities) {
        return merge({}, state, action.payload.entities);
      }

      return state;
    }
  }
};

export default entities;
