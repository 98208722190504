import React, { useEffect } from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import { Typography, Spin, Empty, Row, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { last } from 'lodash';

import Header from 'components/header';
import { Sidebar, Content, LayoutRow } from 'components/layout';
import Section from 'components/section';
import PrivateRoute from 'components/private-route';
import VersionsDropdown from 'components/versions-dropdown';

import { useParams, url, urlPattern, RouteInterface } from 'routes';
import SectionRoute from 'routes/section';
import EditSectionSidebar from 'routes/section/sidebar';
import ExportModal from 'routes/policy/export';
import SectionsTree, { bumpNumbering } from 'routes/policy/sections-tree';
import AddSectionModal from 'routes/policy/add-section';
import PolicyVersions from 'routes/policy/versions';
import PolicyTitle from 'routes/policy/title';
import Labels from 'routes/policy/labels';
import EditWrapper from 'routes/policy/edit-wrapper';
import EditViolation from 'routes/policy/edit-violation';
import EditReferences from 'routes/policy/edit-references';

import { useDispatch, useSelector } from 'store/hooks';
import { detail, list } from 'store/common';
import { selectEntity, selectEntities } from 'store/data';
import { selectLoadingList } from 'store/loading';

interface Props {
  routes: RouteInterface[];
}

const { Title } = Typography;

const Policy: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { policyId } = useParams('policy') as { policyId: string };

  const policy = useSelector(state => selectEntity(state, 'policy', policyId));
  const sections = useSelector(state =>
    selectEntities(state, 'sections', policyId)
  );
  const loadingSections = useSelector(state =>
    selectLoadingList(state, 'sections', policyId)
  );

  useEffect(() => {
    if (!policy.uuid) {
      return;
    }

    dispatch(detail({ uuid: policy.uuid, resolver: 'policy' }));
    dispatch(
      list({
        resolver: 'sections',
        paginationKey: policy.uuid,
        extra: {
          policy: policy.uuid,
        },
      })
    );
  }, [policy.uuid, dispatch]);

  useEffect(() => {
    dispatch(
      list({
        resolver: 'requirementSets',
        paginationKey: 'all',
      })
    );
    dispatch(
      list({
        resolver: 'labels',
        paginationKey: 'all',
      })
    );
    dispatch(
      list({
        resolver: 'references',
        paginationKey: 'all',
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (policy.violation?.uuid) {
      dispatch(detail({ resolver: 'violation', uuid: policy.violation.uuid }));
    }
  }, [policy.violation?.uuid, dispatch]);

  return (
    <>
      <Header documentTitle={`${policy?.title} - ${policy?.group?.name}`}>
        <VersionsDropdown policy={policy} />
        {policy?.has_git_repository ? (
          <Link
            className="ant-btn"
            to={url('policy:export', { policyId: policy?.uuid })}>
            Export to git...
          </Link>
        ) : null}
      </Header>
      <PrivateRoute
        path={urlPattern('policy:export')}
        render={(props: any) => <ExportModal {...props} />}
      />
      <PrivateRoute
        path={urlPattern('policy:add-section')}
        render={(props: any) => <AddSectionModal {...props} />}
      />

      {sections.length ? (
        <LayoutRow>
          <Sidebar>
            <PolicyTitle policy={policy} />
            <Labels labels={policy.labels || []} policyId={policyId} />
            <SectionsTree policy={policy} />

            <Row justify="end">
              <Link
                style={{ marginTop: 10 }}
                className="ant-btn ant-btn-primary"
                to={url('policy:add-section', {
                  policyId: policy?.uuid,
                })}>
                <PlusOutlined style={{ marginRight: 5 }} />
                Add section
              </Link>
            </Row>
          </Sidebar>
          <Content>
            <Title className="monospace" level={2} style={{ marginBottom: 25 }}>
              1. Introduction
            </Title>
            {(['background', 'purpose', 'scope'] as const).map(field => (
              <EditWrapper policy={policy} field={field} key={field} />
            ))}
            <div id="references">
              <EditReferences policy={policy} />
            </div>

            {sections.map((s, i) => (
              <>
                {s.uuid === policy.first_appendix_section ? (
                  <>
                    <div id="violation">
                      <EditViolation
                        numbering={bumpNumbering(sections[i - 1])}
                        policy={policy}
                      />
                    </div>
                    <Divider />
                  </>
                ) : null}
                <div key={s.uuid} id={s.uuid}>
                  <Switch>
                    <PrivateRoute
                      path={url('section', {
                        sectionId: s.uuid,
                        policyId: policy.uuid,
                      })}
                      render={(props: any) => (
                        <SectionRoute
                          {...props}
                          match={{
                            ...props.match,
                            params: {
                              sectionId: s.uuid,
                              policyId: policy.uuid,
                            },
                          }}
                        />
                      )}
                    />
                    <Section section={s} />
                  </Switch>
                </div>
              </>
            ))}
            {!policy.first_appendix_section ? (
              <div id="violation">
                <EditViolation
                  numbering={bumpNumbering(last(sections))}
                  policy={policy}
                />
              </div>
            ) : null}
          </Content>
          <Route path={urlPattern('section')} component={EditSectionSidebar} />
          <PrivateRoute
            path={urlPattern('policy:versions', undefined)}
            render={(props: any) => <PolicyVersions {...props} />}
          />
        </LayoutRow>
      ) : !loadingSections.isFetching ? (
        <Row justify="center" style={{ height: '50vh' }} align="middle">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No sections">
            <Link
              style={{ marginTop: 10 }}
              className="ant-btn ant-btn-primary"
              to={url('policy:add-section', { policyId: policy?.uuid })}>
              <PlusOutlined style={{ marginRight: 5 }} />
              Add section
            </Link>
          </Empty>
        </Row>
      ) : (
        <Row justify="center" style={{ height: '50vh' }} align="middle">
          <Spin size="large" />
        </Row>
      )}
    </>
  );
};

export default Policy;
