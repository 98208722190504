import React from 'react';
import { AssetClassificationCreation } from 'api';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/asset-classifications/form';

const Create: React.FC = () => {
  const initialValues = {
    label: '',
    level: AssetClassificationCreation.level._0,
  };

  return (
    <GenericCreate
      resolver="assetClassification"
      listResolver="assetClassifications"
      createRoute="asset-classifications:create"
      listRoute="asset-classifications"
      detailRoute="asset-classification"
      detailIdParam="assetClassificationId"
      listTitle="Asset classifications"
      listItemTitle={entity => entity.label}
      itemName="asset classification">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
