import {
  useCallback,
  useRef,
  useEffect,
  useState,
  Fragment,
  EffectCallback,
} from 'react';
import * as ls from 'local-storage';
import {
  capitalize,
  values,
  castArray,
  isObject,
  isArray,
  compact,
  mapValues,
} from 'lodash';
import { Sema } from 'async-sema';

import { LoadingItemState } from 'store/loading';
import { DenormalizedUser } from 'store/data';
import { logout } from 'store/user';

import { JwtService } from 'api';

/**
 * Converts this nested structure
 *
 *     [{
 *        routes: [
 *            { id: 2 },
 *            { id: 3, routes: [{ id: 4 }]}
 *        ]
 *        id: '1'
 *     }]
 *
 * to this flat structure
 *
 *     [{ id: 1, routes: [] }, { id: 2 }, { id: 3, routes: [] }, { id: 4 }]
 *
 * via flattenByKey(arr, 'routes')
 */
export function flattenByKey<T extends { [key in K]?: T[] }, K extends string>(
  arr: T[],
  key: K
): T[] {
  let flatRoutes: T[] = [];

  arr.forEach((originalItem: T) => {
    const item = { ...originalItem };

    flatRoutes.push(item);

    const subItems = item[key] as T[] | undefined;

    if (subItems) {
      flatRoutes = flatRoutes.concat(flattenByKey(subItems, key));

      item[key] = [] as unknown as T[K];
    }
  });

  return flatRoutes;
}

/**
 * Promise-like setTimeout
 */
// istanbul ignore next
export const wait = (ms: number): Promise<number> =>
  new Promise((resolve): number => setTimeout(resolve, ms));

/**
 * Extracts page number from given url's query string
 */
export function getPageFromUrl(
  url: string | undefined | null
): number | undefined {
  if (!url) {
    return undefined;
  }

  const page = (url.match(/page=(\d+)/) || [null, null])[1];

  if (!page) {
    return undefined;
  }

  return Number(page);
}

export const createTree = <T extends { uuid: string; numbering?: string }>(
  sections: T[]
): any[] => {
  const tree: any[] = [];
  let currentLevel = 0;
  let parents: any[] = [tree];
  let parent: any = tree;

  sections.forEach(s => {
    const level = ((s.numbering as string).match(/\./g) || []).length;

    if (level === currentLevel) {
      parent.push({
        ...s,
        key: s.uuid,
        selectable: false,
      });
    } else if (level > currentLevel) {
      parent[parent.length - 1].children = [
        {
          ...s,
          key: s.uuid,
          selectable: false,
        },
      ];
      parent = parent[parent.length - 1].children;
      parents.push(parent);
      currentLevel = level;
    } else if (level < currentLevel) {
      currentLevel = level;
      parents = parents.slice(0, level + 1);
      parent = parents[parents.length - 1];
      parent.push({
        ...s,
        key: s.uuid,
        selectable: false,
      });
    }
  });

  return tree;
};

// i tried to make uuid a generic as well but gave up
// if you can - talk to me please
export type TreeRecord<T extends Record<string, any>> = T & {
  children?: TreeRecord<T>[];
};

export function unflatten<T extends Record<string, any>>(
  items: T[],
  parentIdGetter: (item: T) => string | undefined
): TreeRecord<T>[] {
  const tree = [];
  const mappedArr: Record<string, TreeRecord<T>> = {};

  // Build a hash table and map items to objects
  items.forEach(function (item) {
    let id = item.uuid;
    if (!mappedArr.hasOwnProperty(id)) {
      // in case of duplicates
      mappedArr[id] = { ...item };
    }
  });

  // Loop over hash table
  for (let id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      const mappedElem = mappedArr[id];
      const parentId = parentIdGetter(mappedElem);
      const maybeParent = parentId ? mappedArr[parentId] : null;

      // If the element is not at the root level, add it to its parent array of children.
      // Note this will continue till we have only root level elements left
      if (parentId && maybeParent) {
        maybeParent.children = maybeParent.children || [];
        maybeParent.children.push(mappedElem);
      } else {
        tree.push(mappedElem);
      }
    }
  }

  return tree;
}

export const getParentKey = (key: string, tree: any[]): string => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }

  return parentKey;
};

export const loop = (data: any[], searchTerm: string): any[] =>
  data.map((item: any) => {
    const index = item.title.toLowerCase().indexOf(searchTerm.toLowerCase());
    const beforeStr = item.title.substr(0, index);
    const afterStr = item.title.substr(index + searchTerm.length);
    const title =
      index > -1 ? (
        <span>
          {beforeStr}
          <span className="tree-search-value">
            {item.title.substr(index, searchTerm.length)}
          </span>
          {afterStr}
        </span>
      ) : (
        <span>{item.title}</span>
      );
    if (item.children) {
      return {
        ...item,
        title,
        children: loop(item.children, searchTerm),
      };
    }

    return {
      ...item,
      title,
    };
  });

export const findInTree = (root: any, uuid: string) =>
  root.uuid === uuid
    ? root
    : root.children?.reduce(
        (result: any, n: any) => result || findInTree(n, uuid),
        undefined
      );

export const getErrorMessage = (error: any) => {
  if (error.body && isObject(error.body)) {
    return Object.keys(error.body)
      .map(
        key =>
          `${
            key !== 'detail' && key !== 'non_field_errors'
              ? `${capitalize(key)}: `
              : ''
          }${castArray(error.body[key]).join(' ')}`
      )
      .map(message => (
        <Fragment key={message}>
          {message} <br />
        </Fragment>
      ));
  }
  return error.message;
};

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

/**
 * Returns value from the previous cycle
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const useLoadingFinishedEffect = (
  cb: () => void,
  loadingState: LoadingItemState,
  otherDeps: any[] = []
) => {
  const previousLoadingState = usePrevious(loadingState);

  useEffect(() => {
    if (
      !loadingState.isFetching &&
      !loadingState.error &&
      previousLoadingState &&
      previousLoadingState.isFetching
    ) {
      // just finished loading and there were no errors
      cb();
    }
    // eslint-disable-next-line
  }, [loadingState, previousLoadingState, cb, ...otherDeps]);
};

export const useLoadingFailed = (
  cb: () => void,
  loadingState: LoadingItemState,
  otherDeps: any[] = []
) => {
  const previousLoadingState = usePrevious(loadingState);

  useEffect(() => {
    if (
      !loadingState.isFetching &&
      loadingState.error &&
      previousLoadingState &&
      previousLoadingState.isFetching
    ) {
      // just finished loading and there was an error!
      cb();
    }
    // eslint-disable-next-line
  }, [loadingState, previousLoadingState, cb, ...otherDeps]);
};

export const stringToHash = (str: string) => {
  const len = str.length;
  let hash = 0;
  if (len === 0) return hash;
  let i;
  for (i = 0; i < len; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const updatesLock = new Sema(1);

export const getCurrentIndexHash = async () => {
  await updatesLock.acquire();

  const link = window.location.origin;

  const response = await fetch(link);

  const html = await response.text();

  var upToDateHash = stringToHash(html);
  updatesLock.release();
  return upToDateHash;
};

export const checkForUpdates = async () => {
  const storedLastCheck = ls.get('last_checked_for_update');
  const lastCheck: number = Number(storedLastCheck || 0);

  if (Date.now() - lastCheck > 1 * 60 * 1000) {
    const versionHash = ls.get('version_hash');

    const upToDateHash = await getCurrentIndexHash();

    ls.set('version_hash', upToDateHash);
    ls.set('last_checked_for_update', Date.now());

    if (
      typeof storedLastCheck !== 'undefined' &&
      versionHash &&
      upToDateHash !== versionHash
    ) {
      return true;
    }
  }

  return false;
};

const refreshToken = async (token: string) => {
  const r = await JwtService.jwtTokenRefreshCreate({
    data: { refresh: token },
  });

  return { access: (r as any).access, tokenRefreshedAt: Date.now() };
};

const lock = new Sema(1);

const parseToken = (token: string) => {
  let expires_at = null;

  try {
    expires_at = new Date(
      Number(JSON.parse(window.atob(token.split('.')[1])).exp) * 1000
    );
  } catch (e) {
    expires_at = e;
  }
  return {
    token,
    expires_at,
  };
};
export async function maybeRefreshToken(options: any) {
  console.log(`${(options as any).id} Path: ${options.path}`);

  if (
    options.path === '/jwt/token/refresh/' ||
    options.path === '/jwt/token/'
  ) {
    return '';
  }

  await lock.acquire();

  const auth:
    | { accessToken: string; refreshToken: string; tokenRefreshedAt: number }
    | undefined = ls.get('auth');

  if (!auth) {
    const err = new Error('Logged out');
    (err as any).failSilently = true;

    lock.release();

    throw err;
  }

  const { accessToken, refreshToken: token, tokenRefreshedAt = 0 } = auth || {};

  let refreshedAccessToken = accessToken;

  // 2 mins, but should probably come from api
  if (Date.now() - tokenRefreshedAt > 2 * 60 * 1000 || !token || !accessToken) {
    console.log(`${options.id}: Refreshing token...`, {
      accessToken: parseToken(accessToken),
      refreshToken: parseToken(token),
      tokenRefreshedAt,
    });

    try {
      const {
        access,
        tokenRefreshedAt,
      }: { access: string; tokenRefreshedAt: number } = await refreshToken(
        token
      );
      console.log(`${options.id}: token refreshed`, {
        newToken: parseToken(access),
        tokenRefreshedAt,
      });

      ls.set('auth', {
        accessToken: access,
        tokenRefreshedAt,
        refreshToken: token,
      });

      refreshedAccessToken = access;
    } catch (e) {
      console.log(`${options.id}: Refresh failed`, e);
      const store = require('store').default;
      store.dispatch(logout());
    }
  } else {
    console.log(`${options.id}: No need to refresh token`, {
      now: Date.now(),
      tokenRefreshedAt,
      tokenRefreshedAtHuman: new Date(tokenRefreshedAt),
      nextTokenRefresh: new Date(tokenRefreshedAt + 2 * 60 * 1000),
      accessToken: parseToken(accessToken),
      token: parseToken(token),
    });
  }

  lock.release();
  return refreshedAccessToken;
}

/**
 * basiclly denormalized object with fallback looks like `{ uuid: 'x' }`
 * before rehydration, so we need to know if this is complete
 */
export const isLoaded = (denormalizedEntity?: any) =>
  denormalizedEntity && Object.keys(denormalizedEntity).length > 1;

export const cleanupFieldValues = (fields: any) =>
  mapValues(fields, (value: any) =>
    typeof value === 'string'
      ? value.replaceAll('\r', '')
      : isArray(value)
      ? compact(value)
      : value
  );

export const presentableValue = (v: string) =>
  capitalize(v.replaceAll('_', ' '));

export function convertRatingToMarks<T>(v: T) {
  return values(v).reduce((memo, value) => {
    if (typeof value === 'number') {
      memo[value] = value ? value : { value, label: 'Unset' };
    }

    return memo;
  }, {} as T);
}

export function userName(user: DenormalizedUser) {
  return (
    `${user.first_name || ''} ${user.last_name || ''}`.trim() || user.username
  );
}

const sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export const formatBytes = (value: number, decimals = 1) => {
  if (value === -1) {
    return 'No data available';
  }

  if (value === 0) {
    return '0 B';
  }

  const k = 1024;
  const dm = decimals ? decimals + 1 : 0;
  const i = Math.floor(Math.log(value) / Math.log(k));

  return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const isFocused = () => {
  // document global can be unavailable in react native
  if (typeof document === 'undefined') {
    return true;
  }

  return [undefined, 'visible', 'prerender'].includes(document.visibilityState);
};

export const useFetchEntities = (
  cb: EffectCallback,
  deps: any[],
  timeout = 3 * 60 * 1000
) => {
  const lastCall = useRef(Date.now());

  const listener = useCallback(() => {
    if (isFocused()) {
      if (Date.now() - (lastCall.current || 0) > timeout) {
        lastCall.current = Date.now();
        cb();
      }
    }
    // eslint-disable-next-line
  }, [timeout]);

  return useEffect(() => {
    cb();

    // Listen to visibillitychange and focus
    window.addEventListener('visibilitychange', listener, false);
    window.addEventListener('focus', listener, false);

    return () => {
      // Be sure to unsubscribe if a new handler is set
      window.removeEventListener('visibilitychange', listener);
      window.removeEventListener('focus', listener);
    };
    // eslint-disable-next-line
  }, [listener, timeout, ...deps]);
};

/**
 * It's very important that the regex passed has a capture group. Otherwise the split
 * matches aren't part of the array and the whole thing doesn't work
 *
 * Example:
 * replaceString(
 *   'match numbers 1111.',
 *   /([\d]+)/g,
 *   (number, i) => <strong key={i}>{number}</strong>
 * );
 * // => ['match numbers ', <strong>1111</strong>, '.'
 */
export function replaceString(
  str: string,
  regex: RegExp,
  fn: (match: string, i: number) => React.ReactNode
) {
  if (str === '') {
    return str;
  }

  let result: any[] = str.split(regex);

  // Apply fn to all odd elements
  for (let i = 1; i < result.length; i += 2) {
    result[i] = fn(result[i], i);
  }

  return result as React.ReactNode[];
}
