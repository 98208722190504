import React from 'react';
import { Layout, Col, Row } from 'antd';

import './layout.scss';

export const Sidebar: React.FC = ({ children }) => (
  <Col className="layout__sidebar">{children}</Col>
);

export const Content: React.FC = ({ children }) => (
  <Col className="layout__content">
    <Col className="layout__inner">{children}</Col>
  </Col>
);

export const LayoutRow: React.FC = ({ children }) => (
  <Row className="layout__row">{children}</Row>
);

const L: React.FC = ({ children }) => {
  return (
    <Layout>
      <Layout.Content className="layout">{children}</Layout.Content>
    </Layout>
  );
};

export default L;
