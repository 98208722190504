import React, { useState } from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import { url } from 'routes';
import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/assets/form';

const Create: React.FC = () => {
  const initialValues = {
    name: '',
    asset_class: null,
    description: '',
    location: null,
    owner: null,
    custodian: null,
    parent: null,
    confidentiality_rating: 0,
    automatically_managed: false,
  };

  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const onClose = () => setVisible(false);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="80vw"
      style={{
        maxWidth: 900,
      }}
      forceRender={true}
      footer={null}
      afterClose={() => {
        history.push(url('assets'));
      }}>
      <GenericCreate
        resolver="asset"
        listResolver="assets"
        createRoute="assets:create"
        listRoute="assets"
        detailRoute="asset"
        detailIdParam="assetId"
        listTitle="Assets"
        listItemTitle={entity => entity.name}
        itemName="asset">
        {props => <EditForm initialValues={initialValues} {...props} />}
      </GenericCreate>
    </Modal>
  );
};

export default Create;
