import React from 'react';
import { useParams } from 'react-router';

import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/activities/form';

import { useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';

interface Props {
  showHeader?: boolean;
  backTo?: string;
}

const Detail: React.FC<Props> = ({ showHeader = true, backTo }) => {
  // it's important that this is from router cause this
  // is rendered in different places
  const { activityId } = useParams() as {
    activityId: string;
  };
  const entity = useSelector(state =>
    selectEntity(state, 'activity', activityId)
  );

  const initialValues = {
    title: entity.title,
    responsible: entity.responsible?.uuid,
    informed: entity.informed?.map(s => s.uuid) || [],
    consulted: entity.consulted?.map(s => s.uuid) || [],
    assists: entity.assists?.map(s => s.uuid) || [],
    description: entity.description,
    schedule: entity.schedule?.uuid,
  };

  return (
    <>
      <GenericDetail
        backTo={backTo}
        showHeader={showHeader}
        resolver="activity"
        listRoute="activities"
        detailIdParam="activityId"
        listTitle="Activities"
        createRoute="activities:create"
        listItemTitle={entity => entity.title}
        itemName="activity">
        {props => (
          <>
            <EditForm initialValues={initialValues} {...props} />
          </>
        )}
      </GenericDetail>
    </>
  );
};

export default Detail;
