import React from 'react';
import { Table, Tag } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { Link, Switch } from 'react-router-dom';
import { filter, orderBy } from 'lodash';

import Header from 'components/header';
import Layout from 'components/layout';
import Builds from 'components/builds';

import { RouteInterface, useRouteVisit, url, RouteWithSubRoutes } from 'routes';

import { useDispatch, useSelector } from 'store/hooks';
import { list } from 'store/common';
import {
  DenormalizedPolicyVersion,
  selectEntities,
  DenormalizedPolicy,
  DenormalizedUser,
  DenormalizedLabel,
} from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const Policies: React.FC<Props> = ({ routes }) => {
  const dispatch = useDispatch();
  const policies = useSelector(state =>
    selectEntities(state, 'policies', 'all')
  );
  const policyGroups = useSelector(state =>
    selectEntities(state, 'policyGroups', 'all')
  );

  useRouteVisit(
    () => {
      dispatch(
        list({
          resolver: 'policyGroups',
          paginationKey: 'all',
        })
      );
      dispatch(
        list({
          resolver: 'policies',
          paginationKey: 'all',
        })
      );
      dispatch(
        list({
          resolver: 'labels',
          paginationKey: 'all',
        })
      );
      dispatch(
        list({
          resolver: 'users',
          paginationKey: 'all',
        })
      );
      dispatch(
        list({
          resolver: 'labels',
          paginationKey: 'all',
        })
      );
    },
    url('policies'),
    [dispatch]
  );

  const treeData = policyGroups.map(g => ({
    key: g.uuid,
    title: g.name || 'Loading...',
    group: true,
    children: filter(policies, p => (p.group as any).uuid === g.uuid).map(p => {
      return {
        uuid: p.uuid,
        key: p.uuid,
        title: p.title,
        current_version: p.current_version,
        latest_version: p.latest_version,
        approver: p.approver,
        labels: p.labels,
      };
    }),
  }));

  const columns = [
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Policies',
      align: 'left',
      render: (title: string, node: any) => {
        return node.group ? (
          node.title
        ) : (
          <div style={{ minHeight: 24, display: 'flex', alignItems: 'center' }}>
            <span className="ant-tree-switcher" style={{ marginRight: 4 }}>
              <FileOutlined />
            </span>
            <Link to={`/policies/${node.key}/`}>{title}</Link>
          </div>
        );
      },
    },
    {
      key: 'labels',
      dataIndex: 'labels',
      title: 'Labels',
      align: 'left',
      render: (labels: DenormalizedLabel[]) => {
        return labels?.length ? (
          <span style={{ margin: -3 }}>
            {orderBy(labels, ['scope', 'value']).map(tag => (
              <Tag
                style={{ margin: 3 }}
                color={tag.color}
                key={tag.uuid}
                closable={false}>
                {tag.scope ? `${tag.scope}:` : ''}
                {tag.value}
              </Tag>
            ))}
          </span>
        ) : null;
      },
    },
    {
      key: 'approver',
      dataIndex: 'approver',
      title: 'Approver',
      align: 'left',
      render: (user: DenormalizedUser) => {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {user
              ? `${user.first_name || ''} ${user.last_name || ''}`.trim() ||
                user.username
              : null}
          </span>
        );
      },
    },
    {
      key: 'version',
      dataIndex: 'current_version',
      title: 'Current version',
      align: 'left',
      render: (
        version: DenormalizedPolicyVersion | null,
        policy: DenormalizedPolicy
      ) => {
        return <Builds policyId={policy.uuid} version={version} />;
      },
    },
    {
      key: 'latest_version',
      dataIndex: 'latest_version',
      title: 'Latest build',
      align: 'left',
      render: (
        version: DenormalizedPolicyVersion | null,
        policy: DenormalizedPolicy
      ) => {
        return policy.latest_version?.uuid !== policy.current_version?.uuid ? (
          <Builds policyId={policy.uuid} version={version} />
        ) : null;
      },
    },
  ];

  return (
    <Layout>
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={i} {...route} />;
        })}
        <>
          <Header documentTitle="Policies" />
          {treeData.length ? (
            <Table
              className="table"
              bordered={false}
              size="small"
              defaultExpandAllRows={true}
              columns={columns as any}
              showHeader={true}
              pagination={false}
              dataSource={treeData}
            />
          ) : null}
        </>
      </Switch>
    </Layout>
  );
};

export default Policies;
