import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import * as ls from 'local-storage';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import 'antd/dist/antd.min.css';
import './global.scss';

import { OpenAPI } from 'api';

import store, { persistor, history } from 'store';

import Index from 'routes';

import { OPEN_API_BASE } from 'config';
import { maybeRefreshToken, getCurrentIndexHash } from 'utils';

OpenAPI.BASE = OPEN_API_BASE;
OpenAPI.TOKEN = maybeRefreshToken;
// OpenAPI.IS_BLOB_RESPONSE = async (options: ApiRequestOptions) => {
//   // download build pdf request
//   if (options.path.match(/get_presigned_download_url/)) {
//     return true;
//   }
//   return false;
// };

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://5c1454034700425a9ebbd80d805489b3@o1163.ingest.sentry.io/6033860',
    environment:
      window.location.hostname === 'grc.divio.biz' ||
      window.location.hostname === 'compliance-manager-frontend.us.aldryn.io'
        ? 'live'
        : 'test',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

console.log(`Running on ${OPEN_API_BASE}`);

(async () => {
  const hash = await getCurrentIndexHash();
  ls.set('version_hash', hash);
  ls.set('last_checked_for_update', Date.now());

  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <Index />
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
