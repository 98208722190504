/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcedureCreation } from '../models/ProcedureCreation';
import type { ProcedureExportToGit } from '../models/ProcedureExportToGit';
import type { ProcedureListing } from '../models/ProcedureListing';
import type { ProcedureRetrieval } from '../models/ProcedureRetrieval';
import type { ProcedureUpdate } from '../models/ProcedureUpdate';
import { request as __request } from '../core/request';

export class ProceduresService {

    /**
     * List procedures
     * @returns any
     * @throws ApiError
     */
    public static async listProcedures({
        section,
        page,
        pageSize,
    }: {
        section?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ProcedureListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/procedures/`,
            query: {
                'section': section,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new procedure object
     * @returns ProcedureCreation
     * @throws ApiError
     */
    public static async createProcedure({
        data,
    }: {
        data: ProcedureCreation,
    }): Promise<ProcedureCreation> {
        const result = await __request({
            method: 'POST',
            path: `/procedures/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single procedure
     * @returns ProcedureRetrieval
     * @throws ApiError
     */
    public static async getProcedure({
        uuid,
    }: {
        /** A UUID string identifying this procedure. **/
        uuid: string,
    }): Promise<ProcedureRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/procedures/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified procedure
     * @returns ProcedureUpdate
     * @throws ApiError
     */
    public static async updateProcedure({
        uuid,
        data,
    }: {
        /** A UUID string identifying this procedure. **/
        uuid: string,
        data: ProcedureUpdate,
    }): Promise<ProcedureUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/procedures/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified procedure
     * @returns ProcedureUpdate
     * @throws ApiError
     */
    public static async partialUpdateProcedure({
        uuid,
        data,
    }: {
        /** A UUID string identifying this procedure. **/
        uuid: string,
        data: ProcedureUpdate,
    }): Promise<ProcedureUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/procedures/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified procedure
     * @returns void
     * @throws ApiError
     */
    public static async destroyProcedure({
        uuid,
    }: {
        /** A UUID string identifying this procedure. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/procedures/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns ProcedureExportToGit
     * @throws ApiError
     */
    public static async exportToGitProcedure({
        uuid,
        data,
    }: {
        /** A UUID string identifying this procedure. **/
        uuid: string,
        data: ProcedureExportToGit,
    }): Promise<ProcedureExportToGit> {
        const result = await __request({
            method: 'POST',
            path: `/procedures/${uuid}/export_to_git/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}