import React from 'react';
import moment, { Moment } from 'moment-timezone';
import { Tooltip, TooltipProps } from 'antd';
import classNames from 'classnames';

import './date-tooltip.scss';

export const shortDate = 'DD MMM YYYY'; // 03 Aug 2020
export const longDateWithoutYearFormat = 'MMM Do, HH:mm'; // Aug 3rd, 00:30
export const longDateTimeFormat = 'MMM DD, YYYY HH:mm'; // Aug 03, 2020 00:30
export const longerDateTimeFormat = 'MMMM D, YYYY HH:mm'; // August 3, 2020 00:30
export const standardDateTimeFormat = 'DD.MM.YYYY HH:mm'; // 03.08.2020 00:30

export const fromNow = (date: string | number | Moment) =>
  moment(date).fromNow();

export const duration = (from: string | number, to: string | number) =>
  moment.duration(moment(to).diff(moment(from))).humanize();

export const formatDate = (
  dirtyDate: string | number,
  format = standardDateTimeFormat,
  timezone = moment.tz.guess()
) => moment.utc(dirtyDate).tz(timezone).format(format);

interface Props extends Omit<TooltipProps, 'variant' | 'title'> {
  date: string;
  title?: string;
  extra?: { label: string; value: string }[];
}

const DateTooltip: React.FC<Props> = ({
  date,
  className,
  children,
  title,
  extra = [],
  ...rest
}) => {
  return (
    <Tooltip
      {...rest}
      color="white"
      overlayClassName={classNames('date-tooltip', className)}
      title={
        <table className="date-tooltip__table">
          {title ? (
            <thead>
              <tr>
                <th colSpan={2}>{title}</th>
              </tr>
            </thead>
          ) : null}
          <tbody>
            <tr>
              <td>UTC</td>
              <td>{formatDate(date, longDateTimeFormat, 'UTC')}</td>
            </tr>
            <tr>
              <td>
                {moment.tz.guess().replace('_', ' ')} (
                {formatDate(date, 'Z', moment.tz.guess())}) - Your computer
              </td>
              <td>{formatDate(date, longDateTimeFormat, moment.tz.guess())}</td>
            </tr>
            <tr>
              <td>Relative to now</td>
              <td>{fromNow(date)}</td>
            </tr>
          </tbody>
          {extra.length ? (
            <tfoot>
              {extra.map(({ label, value }) => (
                <tr key={label + value}>
                  <td>{label}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tfoot>
          ) : null}
        </table>
      }>
      {children}
    </Tooltip>
  );
};

export default DateTooltip;
