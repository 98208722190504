import React from 'react';
import moment from 'moment-timezone';

import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/risk-treatment-actions/form';
import { useParams } from 'routes';

import { useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';

const Detail: React.FC = () => {
  const { riskTreatmentActionId } = useParams('risk-treatment-action');
  const entity = useSelector(state =>
    selectEntity(state, 'riskTreatmentAction', riskTreatmentActionId as string)
  );

  const initialValues = {
    title: entity.title,
    owner: entity.owner?.uuid,
    deadline: moment(entity.deadline),
    description: entity.description,
    risks: (entity.risks || []).map(r => r.uuid),
    type: entity.type,
    status: entity.status,
  };

  return (
    <GenericDetail
      resolver="riskTreatmentAction"
      listRoute="risk-treatment-actions"
      createRoute="risk-treatment-actions:create"
      detailIdParam="riskTreatmentActionId"
      listTitle="Risk Treatment Actions"
      detailTitle={`Edit risk treatment action ${entity.human_id}`}
      listItemTitle={entity => `${entity.human_id}. ${entity.title}`}
      itemName="risk treatment action">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericDetail>
  );
};

export default Detail;
