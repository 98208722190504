import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { notification, message } from 'antd';
import { without } from 'lodash';
import { saveAs } from 'file-saver';

import { list, detail, update } from 'store/common';
import {
  promoteVersion,
  promoteVersionSuccess,
  promoteVersionFailure,
  requestApproval,
  requestApprovalSuccess,
  requestApprovalFailure,
  moveSection,
  moveSectionSuccess,
  moveSectionFailure,
  exportToGit,
  exportToGitSuccess,
  exportToGitFailure,
  downloadBuild,
  downloadBuildSuccess,
  downloadBuildFailure,
  ExportToGitAction,
  MoveSectionAction,
  DownloadBuildAction,
  mapActivityToSection,
  mapActivityToSectionFailure,
  selectEntity,
  DenormalizedActivity,
  DenormalizedRiskTreatmentAction,
  mapActivityToSectionSuccess,
  unmapActivityFromSection,
  unmapActivityFromSectionSuccess,
  unmapActivityFromSectionFailure,
  mapRiskTreatmentActionToRisk,
  mapRiskTreatmentActionToRiskSuccess,
  mapRiskTreatmentActionToRiskFailure,
  unmapRiskTreatmentActionFromRisk,
  unmapRiskTreatmentActionFromRiskSuccess,
  unmapRiskTreatmentActionFromRiskFailure,
} from 'store/data';
import { detailSagaWorker } from 'store/sagas/common';

import {
  SectionsService,
  PoliciesService,
  PolicyBuildsService,
  PolicyVersionsService,
} from 'api';

import { checkForUpdates, getErrorMessage } from 'utils';

function* moveSectionSaga() {
  yield takeEvery(moveSection.TYPE, function* (action: MoveSectionAction) {
    try {
      const setters = {
        before: [SectionsService, 'moveBeforeSection'],
        after: [SectionsService, 'moveAfterSection'],
        inside: [SectionsService, 'moveInsideSection'],
      };

      const response: any = yield call(setters[action.payload.where] as any, {
        uuid: action.payload.dragNode,
        data: { target: action.payload.dropNode },
      });

      console.log(response);
      yield put(
        moveSectionSuccess({
          ...action.payload,
        })
      );
      yield all([
        put(
          list({
            resolver: 'sections',
            paginationKey: action.payload.policy,
            page: 1,
            extra: { policy: action.payload.policy },
          })
        ),
        put(
          detail({
            resolver: 'policy',
            uuid: action.payload.policy,
          })
        ),
      ]);
    } catch (e) {
      yield put(
        moveSectionFailure({
          ...action.payload,
          error: e,
        })
      );
      message.error(getErrorMessage(e));
    }
  });
}

function* exportToGitSaga() {
  yield takeEvery(exportToGit.TYPE, function* (action: ExportToGitAction) {
    try {
      yield call([PoliciesService, 'exportToGitPolicy'], {
        uuid: action.payload.policyId,
        data: action.payload.data,
      });

      yield put(
        exportToGitSuccess({
          policyId: action.payload.policyId,
        })
      );
      if (action.payload.successMessage) {
        message.success(action.payload.successMessage);
      }
    } catch (e) {
      yield put(
        exportToGitFailure({ error: e, policyId: action.payload.policyId })
      );
      message.error(getErrorMessage(e));
    }
  });
}

function* downloadBuildSaga() {
  yield takeEvery(downloadBuild.TYPE, function* (action: DownloadBuildAction) {
    try {
      const response: { url: string } = yield call(
        [PolicyBuildsService, 'getPresignedDownloadUrlPolicyBuild'],
        { uuid: action.payload.uuid, data: { download: true } }
      );

      saveAs(response.url);

      yield put(downloadBuildSuccess({ uuid: action.payload.uuid }));
    } catch (e) {
      message.error(getErrorMessage(e));
      yield put(downloadBuildFailure({ uuid: action.payload.uuid }));
    }
  });
}

function* requestApprovalSaga() {
  yield takeEvery(
    requestApproval.TYPE,
    function* (action: ReturnType<typeof requestApproval>) {
      try {
        yield call([PolicyVersionsService, 'requestApprovalPolicyVersion'], {
          uuid: action.payload.uuid,
          data: {},
        });

        yield call(
          detailSagaWorker,
          detail({
            uuid: action.payload.uuid,
            resolver: 'policyVersion',
            skipWorker: true,
          })
        );

        yield put(requestApprovalSuccess({ uuid: action.payload.uuid }));
        message.success('Request for approval submitted.');
      } catch (e) {
        message.error(getErrorMessage(e));
        yield put(requestApprovalFailure({ uuid: action.payload.uuid }));
      }
    }
  );
}

function* promoteVersionSaga() {
  yield takeEvery(
    promoteVersion.TYPE,
    function* (action: ReturnType<typeof promoteVersion>) {
      try {
        yield call([PolicyVersionsService, 'promotePolicyVersion'], {
          uuid: action.payload.uuid,
          data: { version: action.payload.version },
        });

        yield put(
          promoteVersionSuccess({
            uuid: action.payload.uuid,
            version: action.payload.version,
          })
        );
        message.success('Success.');
      } catch (e) {
        message.error(getErrorMessage(e));
        yield put(
          promoteVersionFailure({
            uuid: action.payload.uuid,
            version: action.payload.version,
          })
        );
      }
    }
  );
}

function* checkForUpdatesSaga() {
  yield takeEvery(LOCATION_CHANGE, function* () {
    try {
      const hasUpdates: boolean = yield call(checkForUpdates);

      if (hasUpdates) {
        notification.open({
          message: 'Update available',
          key: 'update',
          duration: null,
          description:
            'There is a new update of the application, please reload the page to update',
          onClick: () => window.location.reload(),
          placement: 'bottomRight',
        });
      }
    } catch (e) {
      console.log(e);
    }
  });
}

function* mapActivityToSectionSaga() {
  yield takeEvery(
    mapActivityToSection.TYPE,
    function* (action: ReturnType<typeof mapActivityToSection>) {
      const { activityId, sectionId } = action.payload;

      try {
        const detailAction = detail({
          resolver: 'activity',
          uuid: activityId,
          skipWorker: true,
        });
        yield put(detailAction);

        yield call(detailSagaWorker, detailAction);

        const activity: DenormalizedActivity = yield select(state =>
          selectEntity(state, 'activity', activityId)
        );

        // there is obviously a race condition here but Dennis said to blame him
        // once it happens, cause there is no time to implement it right now
        yield put(
          update({
            resolver: 'activity',
            uuid: activityId,
            patch: {
              sections: [
                ...(activity?.sections || []).map(s => s.uuid),
                sectionId,
              ],
            },
            successActions: [
              list({
                resolver: 'activities',
                paginationKey: sectionId,
                extra: {
                  section: sectionId,
                },
              }),
              mapActivityToSectionSuccess({
                sectionId,
                activityId,
              }),
            ],
          })
        );
      } catch (e) {
        yield put(
          mapActivityToSectionFailure({
            error: e,
            activityId,
            sectionId,
          })
        );
      }
    }
  );
}

function* unmapActivityFromSectionSaga() {
  yield takeEvery(
    unmapActivityFromSection.TYPE,
    function* (action: ReturnType<typeof unmapActivityFromSection>) {
      const { activityId, sectionId } = action.payload;

      try {
        const detailAction = detail({
          resolver: 'activity',
          uuid: activityId,
          skipWorker: true,
        });
        yield put(detailAction);

        yield call(detailSagaWorker, detailAction);

        const activity: DenormalizedActivity = yield select(state =>
          selectEntity(state, 'activity', activityId)
        );

        // there is obviously a race condition here but Dennis said to blame him
        // once it happens, cause there is no time to implement it right now
        yield put(
          update({
            resolver: 'activity',
            uuid: activityId,
            patch: {
              sections: without(
                (activity.sections || []).map(s => s.uuid),
                sectionId
              ),
            },
            successActions: [
              list({
                resolver: 'activities',
                paginationKey: sectionId,
                extra: {
                  section: sectionId,
                },
              }),
              unmapActivityFromSectionSuccess({
                sectionId,
                activityId,
              }),
            ],
          })
        );
      } catch (e) {
        yield put(
          unmapActivityFromSectionFailure({
            error: e,
            activityId,
            sectionId,
          })
        );
      }
    }
  );
}

function* mapRiskTreatmentActionToRiskSaga() {
  yield takeEvery(
    mapRiskTreatmentActionToRisk.TYPE,
    function* (action: ReturnType<typeof mapRiskTreatmentActionToRisk>) {
      const { riskTreatmentActionId, riskId } = action.payload;

      try {
        const detailAction = detail({
          resolver: 'riskTreatmentAction',
          uuid: riskTreatmentActionId,
          skipWorker: true,
        });
        yield put(detailAction);

        yield call(detailSagaWorker, detailAction);

        const riskTreatmentAction: DenormalizedRiskTreatmentAction =
          yield select(state =>
            selectEntity(state, 'riskTreatmentAction', riskTreatmentActionId)
          );

        // there is obviously a race condition here but Dennis said to blame him
        // once it happens, cause there is no time to implement it right now
        yield put(
          update({
            resolver: 'riskTreatmentAction',
            uuid: riskTreatmentActionId,
            patch: {
              risks: [
                ...(riskTreatmentAction?.risks || []).map(s => s.uuid),
                riskId,
              ],
            },
            successActions: [
              mapRiskTreatmentActionToRiskSuccess({
                riskId,
                riskTreatmentActionId,
              }),
              detail({
                resolver: 'riskTreatmentAction',
                uuid: riskTreatmentActionId,
              }),
            ],
          })
        );
      } catch (e) {
        yield put(
          mapRiskTreatmentActionToRiskFailure({
            error: e,
            riskTreatmentActionId,
            riskId,
          })
        );
      }
    }
  );
}

function* unmapRiskTreatmentActionFromRiskSaga() {
  yield takeEvery(
    unmapRiskTreatmentActionFromRisk.TYPE,
    function* (action: ReturnType<typeof unmapRiskTreatmentActionFromRisk>) {
      const { riskTreatmentActionId, riskId } = action.payload;

      try {
        const detailAction = detail({
          resolver: 'riskTreatmentAction',
          uuid: riskTreatmentActionId,
          skipWorker: true,
        });
        yield put(detailAction);

        yield call(detailSagaWorker, detailAction);

        const riskTreatmentAction: DenormalizedRiskTreatmentAction =
          yield select(state =>
            selectEntity(state, 'riskTreatmentAction', riskTreatmentActionId)
          );

        // there is obviously a race condition here but Dennis said to blame him
        // once it happens, cause there is no time to implement it right now
        yield put(
          update({
            resolver: 'riskTreatmentAction',
            uuid: riskTreatmentActionId,
            patch: {
              risks: without(
                (riskTreatmentAction.risks || []).map(s => s.uuid),
                riskId
              ),
            },
            successActions: [
              unmapRiskTreatmentActionFromRiskSuccess({
                riskId,
                riskTreatmentActionId,
              }),
              detail({
                resolver: 'riskTreatmentAction',
                uuid: riskTreatmentActionId,
              }),
            ],
          })
        );
      } catch (e) {
        yield put(
          unmapRiskTreatmentActionFromRiskFailure({
            error: e,
            riskTreatmentActionId,
            riskId,
          })
        );
      }
    }
  );
}

const sagas = [
  moveSectionSaga,
  exportToGitSaga,
  downloadBuildSaga,
  checkForUpdatesSaga,
  mapActivityToSectionSaga,
  unmapActivityFromSectionSaga,
  requestApprovalSaga,
  promoteVersionSaga,
  mapRiskTreatmentActionToRiskSaga,
  unmapRiskTreatmentActionFromRiskSaga,
];

export default sagas;
