import React from 'react';
import { Button } from 'antd';

import { DenormalizedPolicyVersion, requestApproval } from 'store/data';
import { selectLoading } from 'store/loading';
import { useDispatch, useSelector } from 'store/hooks';

interface Props {
  version: DenormalizedPolicyVersion;
}

const Action: React.FC<Props> = ({ version }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state =>
    selectLoading(state, requestApproval.TYPE, version.uuid)
  );

  return (
    <Button
      loading={loading.isFetching}
      style={{ marginLeft: 10 }}
      onClick={() => dispatch(requestApproval({ uuid: version.uuid }))}>
      Request approval
    </Button>
  );
};

export default Action;
