/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AssetClassificationListing = {
    uuid: string;
    label: string;
    level: AssetClassificationListing.level;
}

export namespace AssetClassificationListing {

    export enum level {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }


}
