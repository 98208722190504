import React, { useState } from 'react';
import { Tooltip, Checkbox } from 'antd';
import { find, filter, uniqBy, flatten, compact } from 'lodash';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ArcherContainer, ArcherElement } from 'react-archer';

import { url } from 'routes';

import { useEntities } from 'store/hooks';
import { DenormalizedRisk } from 'store/data';

import './dashboard.scss';

interface Props {
  risk: DenormalizedRisk;
  className?: string;
  residual?: boolean;
  current?: boolean;
  showResidualValues: boolean;
  showCurrentValues: boolean;
  showInitialValues: boolean;
}

interface ListProps {
  probabilityRating: number;
  impactRating: number;
  showResidualValues: boolean;
  showCurrentValues: boolean;
  showInitialValues: boolean;
}

export const ratingFilters = [
  {
    text: 'Low (1-10)',
    value: [1, 11],
    color: '#D9FBCF',
  },
  {
    text: 'Medium (11-40)',
    value: [11, 41],
    color: '#FFF9D0',
  },
  {
    text: 'High (41-100)',
    value: [41, Infinity],
    color: '#F7C6BE',
  },
];

const line = {
  targetAnchor: 'middle',
  sourceAnchor: 'middle',
  style: {
    startMarker: false,
    endMarker: false,
    strokeColor: 'red',
    strokeWidth: 2,
  },
};
const Risk: React.FC<Props> = ({
  risk,
  residual,
  current,
  className,
  showInitialValues,
  showCurrentValues,
  showResidualValues,
}) => {
  const [hover, setHover] = useState(false);
  const initial = !current && !residual;

  const relations = compact(
    uniqBy(
      flatten([
        residual
          ? [
              showInitialValues &&
              !showCurrentValues &&
              risk.initial_risk_rating
                ? { ...line, targetId: risk.human_id }
                : null,
              showCurrentValues && risk.current_risk_rating
                ? { ...line, targetId: `${risk.human_id}-current` }
                : null,
            ]
          : null,
        initial
          ? [
              showResidualValues &&
              !showCurrentValues &&
              risk.residual_risk_rating
                ? { ...line, targetId: `${risk.human_id}-residual` }
                : null,
              showCurrentValues && risk.current_risk_rating
                ? { ...line, targetId: `${risk.human_id}-current` }
                : null,
            ]
          : null,
        current
          ? [
              showResidualValues && risk.residual_risk_rating
                ? { ...line, targetId: `${risk.human_id}-residual` }
                : null,
              showInitialValues && risk.initial_risk_rating
                ? { ...line, targetId: risk.human_id }
                : null,
            ]
          : null,
      ]),
      'targetId'
    )
  );

  return (
    <ArcherElement
      id={`${risk.human_id}${
        residual ? '-residual' : current ? '-current' : ''
      }`}
      relations={hover ? (relations as any[]) : undefined}>
      <span
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        className={classNames('risk-dot', className, {
          'risk--residual':
            residual ||
            (!risk.residual_risk_rating &&
              risk.initial_risk_rating &&
              initial &&
              !showCurrentValues) ||
            (!risk.residual_risk_rating &&
              risk.current_risk_rating &&
              current &&
              showCurrentValues),
          'risk--current': current && risk.current_risk_rating,
        })}>
        <Tooltip
          title={
            <>
              {risk.human_id}. {risk.title}
            </>
          }>
          <Link to={url('risk', { riskId: risk.uuid })}>{risk.human_id}</Link>
        </Tooltip>
      </span>
    </ArcherElement>
  );
};

const Risks: React.FC<ListProps> = ({
  probabilityRating,
  impactRating,
  showInitialValues,
  showCurrentValues,
  showResidualValues,
}) => {
  const [risks, loading] = useEntities('risks', 'all');
  const impactRisks = filter(
    risks,
    r => r.initial_impact_rating === impactRating
  );
  const residualRisks = filter(
    risks,
    r => !!(r.residual_impact_rating === impactRating)
  );
  const currentRisks = filter(
    risks,
    r => !!(r.current_impact_rating === impactRating)
  );

  return (
    <div style={{ maxWidth: 15 * 5 + 6 * 5, margin: '0 auto' }}>
      {!risks.length && loading.isFetching ? null : (
        <>
          {showResidualValues
            ? filter(
                residualRisks,
                r => r.residual_probability_rating === probabilityRating
              ).map(r => (
                <Risk
                  key={r.uuid}
                  risk={r}
                  residual
                  showCurrentValues={showCurrentValues}
                  showResidualValues={showResidualValues}
                  showInitialValues={showInitialValues}
                />
              ))
            : null}
          {showCurrentValues
            ? filter(
                currentRisks,
                r => r.current_probability_rating === probabilityRating
              ).map(r => (
                <Risk
                  key={r.uuid}
                  risk={r}
                  current
                  showCurrentValues={showCurrentValues}
                  showResidualValues={showResidualValues}
                  showInitialValues={showInitialValues}
                />
              ))
            : null}
          {showInitialValues
            ? filter(
                impactRisks,
                r => r.initial_probability_rating === probabilityRating
              ).map(r => (
                <Risk
                  key={r.uuid}
                  risk={r}
                  showCurrentValues={showCurrentValues}
                  showResidualValues={showResidualValues}
                  showInitialValues={showInitialValues}
                />
              ))
            : null}
        </>
      )}
    </div>
  );
};

const getColor = (v: number) => {
  const maybeColor = find(
    ratingFilters,
    f => v >= f.value[0] && v < f.value[1]
  );

  if (maybeColor) {
    return maybeColor.color;
  }

  return 'transparent';
};

const Heatmap = () => {
  const [showInitialValues, setShowInitialValues] = useState(true);
  const [showCurrentValues, setShowCurrentValues] = useState(false);
  const [showResidualValues, setShowResidualValues] = useState(true);
  const probabilityRatings = [1, 3, 5, 7, 10];
  const table = [
    [
      {
        th: '10 Catastrophic',
      },
      ...probabilityRatings.map(v => ({
        color: getColor(v * 10),
        content: (
          <Risks
            showInitialValues={showInitialValues}
            showCurrentValues={showCurrentValues}
            showResidualValues={showResidualValues}
            impactRating={10}
            probabilityRating={v}
          />
        ),
      })),
    ],
    [
      {
        th: '7 High',
      },
      ...probabilityRatings.map(v => ({
        color: getColor(v * 7),
        content: (
          <Risks
            showInitialValues={showInitialValues}
            showCurrentValues={showCurrentValues}
            showResidualValues={showResidualValues}
            impactRating={7}
            probabilityRating={v}
          />
        ),
      })),
    ],
    [
      {
        th: '5 Medium',
      },
      ...probabilityRatings.map(v => ({
        color: getColor(v * 5),
        content: (
          <Risks
            showInitialValues={showInitialValues}
            showCurrentValues={showCurrentValues}
            showResidualValues={showResidualValues}
            impactRating={5}
            probabilityRating={v}
          />
        ),
      })),
    ],
    [
      {
        th: '3 Low',
      },
      ...probabilityRatings.map(v => ({
        color: getColor(v * 3),
        content: (
          <Risks
            showInitialValues={showInitialValues}
            showCurrentValues={showCurrentValues}
            showResidualValues={showResidualValues}
            impactRating={3}
            probabilityRating={v}
          />
        ),
      })),
    ],
    [
      {
        th: '1 Insignificant',
      },
      ...probabilityRatings.map(v => ({
        color: getColor(v * 1),
        content: (
          <Risks
            showInitialValues={showInitialValues}
            showCurrentValues={showCurrentValues}
            showResidualValues={showResidualValues}
            impactRating={1}
            probabilityRating={v}
          />
        ),
      })),
    ],
    [
      {
        th: (
          <>
            <label style={{ display: 'block' }}>
              initial{' '}
              <Checkbox
                checked={showInitialValues}
                onChange={v => setShowInitialValues(v.target.checked)}
              />
            </label>
            <label style={{ display: 'block' }}>
              current{' '}
              <Checkbox
                checked={showCurrentValues}
                onChange={v => setShowCurrentValues(v.target.checked)}
              />
            </label>
            <label style={{ display: 'block' }}>
              residual{' '}
              <Checkbox
                checked={showResidualValues}
                onChange={v => setShowResidualValues(v.target.checked)}
              />
            </label>
          </>
        ),
      },
      {
        th: '1 Rare',
      },
      {
        th: '3 Unlikely',
      },
      {
        th: '5 Possible',
      },
      {
        th: '7 Likely',
      },
      {
        th: '10 Certain',
      },
    ],
  ];

  return (
    <div className="risk-heatmap ant-table ant-table-small ant-table-bordered ant-table-layout-fixed pb-2">
      <div className="ant-table-container">
        <div className="ant-table-content">
          <ArcherContainer svgContainerStyle={{ zIndex: 10 }}>
            <table>
              <tbody className="ant-table-tbody">
                {table.map((row, i) => {
                  return (
                    <tr className="ant-table-row" key={i}>
                      {row.map((cell: any, i) => {
                        return cell.th ? (
                          <th
                            style={{
                              background: '#fafafa',
                              padding: '8px 16px',
                              textAlign: i === 0 ? 'right' : 'center',
                              width: i === 0 ? 200 : 'auto',
                              height: 70,
                            }}
                            className="ant-table-cell"
                            key={i}>
                            {cell.th}
                          </th>
                        ) : (
                          <td
                            style={{
                              backgroundColor: cell.color || 'transparent',
                              height: 100,
                            }}
                            className="ant-table-cell"
                            key={i}>
                            {cell.content}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </ArcherContainer>
        </div>
      </div>
    </div>
  );
};

export default Heatmap;
