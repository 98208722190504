import { all, call, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux';
import { message } from 'antd';
import { isFunction } from 'lodash';
import { push } from 'connected-react-router';

import { AppStateInterface } from 'store';
import {
  list,
  listSuccess,
  listFailure,
  ListAction,
  listGetters,
  detail,
  detailSuccess,
  detailFailure,
  DetailAction,
  detailGetters,
  selectDetail,
  create,
  createSuccess,
  createFailure,
  CreateAction,
  creators,
  destroy,
  destroySuccess,
  destroyFailure,
  DestroyAction,
  destroyers,
  update,
  updateSuccess,
  updateFailure,
  UpdateAction,
  updaters,
} from 'store/common';
import {
  takeLeadingPerKey,
  takeEveryWithPreviousState,
} from 'store/sagas/utils';

import { getErrorMessage, getPageFromUrl } from 'utils';

const ERROR_MESSAGE_TIMEOUT = 10; // seconds

const showError = (e: any) => {
  if (!e.failSilently) {
    message.error(getErrorMessage(e), ERROR_MESSAGE_TIMEOUT);
  }
};

export function* listSagaWorker(action: ListAction) {
  try {
    const getter = listGetters[action.meta.resolver];

    // istanbul ignore if
    if (!getter) {
      throw new Error(
        `No list getter for this request: ${action.meta.resolver}`
      );
    }

    const { results, next }: ThenArg<ReturnType<typeof getter>> = yield call(
      getter,
      {
        page: action.payload.page,
        ...action.payload.extra,
      }
    );

    yield put(
      listSuccess({
        resolver: action.meta.resolver,
        paginationKey: action.meta.paginationKey,
        response: results,
        page: action.payload.page,
        nextPage: getPageFromUrl(next),
      })
    );

    if (next && !action.payload.fetchOnlyOnePage) {
      yield put(
        list({
          resolver: action.meta.resolver,
          paginationKey: action.meta.paginationKey,
          page: getPageFromUrl(next),
          extra: action.payload.extra,
        })
      );
    }
  } catch (e) {
    yield put(
      listFailure({
        error: e,
        page: action.payload.page,
        resolver: action.meta.resolver,
        paginationKey: action.meta.paginationKey,
      })
    );
    showError(e);
  }
}

export function* detailSagaWorker(action: DetailAction) {
  try {
    const getter = detailGetters[action.meta.resolver];

    // istanbul ignore if
    if (!getter) {
      throw new Error(
        `No detail getter for this request: ${action.meta.resolver}`
      );
    }

    const response: ThenArg<ReturnType<typeof getter>> = yield call(getter, {
      uuid: action.payload.uuid,
      ...action.payload.extra,
    });

    yield put(
      detailSuccess({
        resolver: action.meta.resolver,
        uuid: action.payload.uuid,
        transformResponse: action.payload.transformResponse,
        response,
      })
    );

    return true;
  } catch (e) {
    yield put(
      detailFailure({
        resolver: action.meta.resolver,
        uuid: action.payload.uuid,
        failSilently: action.payload.failSilently,
        error: e,
      })
    );
    if (!action.payload.failSilently) {
      showError(e);
    }

    return false;
  }
}

function* createSagaWorker(action: CreateAction) {
  try {
    const creator = creators[action.meta.resolver];

    // istanbul ignore if
    if (!creator) {
      throw new Error(`No creator for this request: ${action.meta.resolver}`);
    }

    // reason setter here is any is because
    // typescript can't figure out which type setter is
    // (cause it doesn't understand the resolver <-> setter relationship
    // so it thinks that data is EntityCreate
    // which are obviously incompatible
    const response: ThenArg<ReturnType<typeof creator>> = yield call(
      creator as any,
      {
        data: action.payload.data,
      }
    );

    if (action.payload.successActions.length) {
      yield all(
        action.payload.successActions.map(maybeAction => {
          let action: Action = isFunction(maybeAction)
            ? maybeAction(response.uuid as string)
            : maybeAction;

          if (action.type === list.TYPE) {
            return call(listSagaWorker, action as ListAction);
          }

          if (action.type === detail.TYPE) {
            return call(detailSagaWorker, action as DetailAction);
          }

          // istanbul ignore next
          return put(action);
        })
      );
    }

    yield put(
      createSuccess({
        resolver: action.meta.resolver,
        loadingKey: action.meta.loadingKey,
        response,
      })
    );

    if (action.payload.successMessage) {
      message.success(action.payload.successMessage);
    }

    if (action.payload.redirectUrl && response.uuid) {
      yield put(
        push(action.payload.redirectUrl.replace(':uuid', response.uuid))
      );
    }
  } catch (e) {
    yield put(
      createFailure({
        error: e,
        resolver: action.meta.resolver,
        loadingKey: action.meta.loadingKey,
        failSilently: action.payload.failSilently,
      })
    );
    if (!action.payload.failSilently) {
      showError(e);
    }
  }
}

function* destroySagaWorker(action: DestroyAction) {
  try {
    const destroyer = destroyers[action.meta.resolver];

    // istanbul ignore if
    if (!destroyer) {
      throw new Error(`No destroyer for this request: ${action.meta.resolver}`);
    }

    yield call(destroyer, {
      uuid: action.payload.uuid,
    });

    if (action.payload.successActions.length) {
      yield all(
        action.payload.successActions.map(action => {
          if (action.type === list.TYPE) {
            return call(listSagaWorker, action as ListAction);
          }

          if (action.type === detail.TYPE) {
            return call(detailSagaWorker, action as DetailAction);
          }

          // istanbul ignore next
          return put(action);
        })
      );
    }

    yield put(
      destroySuccess({
        resolver: action.meta.resolver,
        uuid: action.payload.uuid,
      })
    );

    if (action.payload.successMessage) {
      message.success(action.payload.successMessage);
    }
  } catch (e) {
    yield put(
      destroyFailure({
        uuid: action.payload.uuid,
        error: e,
        resolver: action.meta.resolver,
      })
    );
    showError(e);
  }
}

export function* updateSagaWorker(
  action: UpdateAction,
  previousFullState: AppStateInterface
) {
  const previousState: any = selectDetail(
    previousFullState,
    action.meta.resolver,
    action.payload.uuid
  );

  try {
    const updater = updaters[action.meta.resolver];

    // istanbul ignore if
    if (!updater) {
      throw new Error(`No updater for this request: ${action.meta.resolver}`);
    }

    const response: ThenArg<ReturnType<typeof updater>> = yield call(
      updater as any,
      {
        uuid: action.payload.uuid,
        data: action.payload.patch,
      }
    );

    yield put(
      updateSuccess({
        uuid: action.payload.uuid,
        resolver: action.meta.resolver,
        response: {
          uuid: action.payload.uuid,
          ...response,
        } as any,
        patch: action.payload.patch,
        transformResponse: action.payload.transformResponse,
      })
    );

    if (action.payload.successActions.length) {
      yield all(
        action.payload.successActions.map(action => {
          if (action.type === list.TYPE) {
            return call(listSagaWorker, action as ListAction);
          }

          if (action.type === detail.TYPE) {
            return call(detailSagaWorker, action as DetailAction);
          }

          // istanbul ignore next
          return put(action);
        })
      );
    }

    if (action.payload.successMessage) {
      message.success(action.payload.successMessage);
    }
  } catch (e) {
    yield put(
      updateFailure({
        uuid: action.payload.uuid,
        resolver: action.meta.resolver,
        // it's possible there is a race condition when
        // the entity was updated between request start and request failure
        // ¯\_(ツ)_/¯
        previousState,
        patch: action.payload.patch,
        error: e,
      })
    );
    showError(e);
  }
}

export function* listSaga() {
  yield takeLeadingPerKey(
    (action: any) => action.type === list.TYPE && !action.meta.skipWorker,
    listSagaWorker,
    (action: any) =>
      `${action.meta.resolver}_${action.meta.paginationKey}_${action.payload.page}`
  );
}

export function* detailSaga() {
  yield takeLeadingPerKey(
    (action: any) => action.type === detail.TYPE && !action.meta.skipWorker,
    // detail.TYPE,
    detailSagaWorker,
    (action: any) => action.meta.resolveKey
  );
}

export function* createSaga() {
  yield takeEvery(create.TYPE, createSagaWorker);
}

export function* destroySaga() {
  yield takeEvery(destroy.TYPE, destroySagaWorker);
}

export function* updateSaga() {
  yield takeEveryWithPreviousState(update.TYPE, updateSagaWorker);
}

const sagas = [createSaga, listSaga, detailSaga, updateSaga, destroySaga];

export default sagas;
