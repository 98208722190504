/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcronymCreation } from '../models/AcronymCreation';
import type { AcronymListing } from '../models/AcronymListing';
import type { AcronymRetrieval } from '../models/AcronymRetrieval';
import type { AcronymUpdate } from '../models/AcronymUpdate';
import { request as __request } from '../core/request';

export class AcronymsService {

    /**
     * List acronyms
     * @returns any
     * @throws ApiError
     */
    public static async listAcronyms({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<AcronymListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/acronyms/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new acronym object
     * @returns AcronymCreation
     * @throws ApiError
     */
    public static async createAcronym({
        data,
    }: {
        data: AcronymCreation,
    }): Promise<AcronymCreation> {
        const result = await __request({
            method: 'POST',
            path: `/acronyms/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single acronym
     * @returns AcronymRetrieval
     * @throws ApiError
     */
    public static async getAcronym({
        uuid,
    }: {
        /** A UUID string identifying this acronym. **/
        uuid: string,
    }): Promise<AcronymRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/acronyms/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified acronym
     * @returns AcronymUpdate
     * @throws ApiError
     */
    public static async updateAcronym({
        uuid,
        data,
    }: {
        /** A UUID string identifying this acronym. **/
        uuid: string,
        data: AcronymUpdate,
    }): Promise<AcronymUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/acronyms/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified acronym
     * @returns AcronymUpdate
     * @throws ApiError
     */
    public static async partialUpdateAcronym({
        uuid,
        data,
    }: {
        /** A UUID string identifying this acronym. **/
        uuid: string,
        data: AcronymUpdate,
    }): Promise<AcronymUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/acronyms/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified acronym
     * @returns void
     * @throws ApiError
     */
    public static async destroyAcronym({
        uuid,
    }: {
        /** A UUID string identifying this acronym. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/acronyms/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}