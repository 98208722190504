import React, { useEffect } from 'react';
import { Menu, Typography } from 'antd';
import { Link, Switch } from 'react-router-dom';

import Header from 'components/header';
import Layout, { LayoutRow, Sidebar, Content } from 'components/layout';

import { RouteWithSubRoutes, RouteInterface, url, useParams } from 'routes';

import { useDispatch, useSelector } from 'store/hooks';
import { list } from 'store/common';
import { selectEntities } from 'store/data';

import { userName } from 'utils';

interface Props {
  routes: RouteInterface[];
}

const { Title } = Typography;

const Users: React.FC<Props> = ({ routes }) => {
  const dispatch = useDispatch();

  const { userId } = useParams('user');
  const users = useSelector(state => selectEntities(state, 'users', 'all'));

  useEffect(() => {
    dispatch(
      list({
        resolver: 'users',
        paginationKey: 'all',
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <Header documentTitle="Users" />
      <LayoutRow>
        <Sidebar>
          <Title level={5}>Users</Title>
          <Menu
            selectedKeys={userId ? [userId] : undefined}
            style={{ marginLeft: -20, marginRight: -20 }}>
            {users.length ? (
              users.map(user => {
                return (
                  <Menu.Item style={{ padding: '0 24px' }} key={user.uuid}>
                    <Link to={url('user', { userId: user.uuid })}>
                      {userName(user)}
                    </Link>
                  </Menu.Item>
                );
              })
            ) : (
              <Menu.Item disabled>
                <em>No entries</em>
              </Menu.Item>
            )}
          </Menu>
        </Sidebar>
        <Content>
          <Switch>
            {routes.map(r => (
              <RouteWithSubRoutes key={r.path} {...r} />
            ))}
          </Switch>
        </Content>
      </LayoutRow>
    </Layout>
  );
};

export default Users;
