import React, { useState, useEffect } from 'react';
import { PolicyBuildsService } from 'api';

import { useParams, url } from 'routes';

import { useSelector, useValidateEntityExistence } from 'store/hooks';
import { selectEntity } from 'store/data';

import './build.scss';

const PolicyBuild = () => {
  const { buildId, versionId, policyId } = useParams('policy:versions:build');
  const [pdf, setPdf] = useState<string | undefined>();

  const version = useSelector(state =>
    selectEntity(state, 'policyVersion', versionId as string)
  );

  useValidateEntityExistence({
    entity: version,
    entityType: 'policyVersion',
    redirectUrl: url('policy:versions', { policyId: policyId as string }),
  });

  useEffect(() => {
    let mounted = true;
    async function fetchPDF() {
      try {
        const { url } =
          await PolicyBuildsService.getPresignedDownloadUrlPolicyBuild({
            uuid: buildId as string,
            data: {
              download: false,
            },
          });
        if (mounted) {
          setPdf(url);
        }
      } catch (e) {}
    }

    if (buildId) {
      fetchPDF();
    }

    return () => {
      mounted = false;
    };
  }, [buildId]);

  return pdf ? (
    <iframe className="build__pdf" title="Build pdf" src={pdf} />
  ) : null;
};

export default PolicyBuild;
