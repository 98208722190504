/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SectionRequirementCoverageCreation } from '../models/SectionRequirementCoverageCreation';
import type { SectionRequirementCoverageListing } from '../models/SectionRequirementCoverageListing';
import type { SectionRequirementCoverageRetrieval } from '../models/SectionRequirementCoverageRetrieval';
import type { SectionRequirementCoverageUpdate } from '../models/SectionRequirementCoverageUpdate';
import { request as __request } from '../core/request';

export class SectionRequirementCoveragesService {

    /**
     * List section requirement coverages
     * @returns any
     * @throws ApiError
     */
    public static async listSectionRequirementCoverages({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<SectionRequirementCoverageListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/section-requirement-coverages/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new section requirement coverage object
     * @returns SectionRequirementCoverageCreation
     * @throws ApiError
     */
    public static async createSectionRequirementCoverage({
        data,
    }: {
        data: SectionRequirementCoverageCreation,
    }): Promise<SectionRequirementCoverageCreation> {
        const result = await __request({
            method: 'POST',
            path: `/section-requirement-coverages/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single section requirement coverage
     * @returns SectionRequirementCoverageRetrieval
     * @throws ApiError
     */
    public static async getSectionRequirementCoverage({
        uuid,
    }: {
        /** A UUID string identifying this section requirement coverage. **/
        uuid: string,
    }): Promise<SectionRequirementCoverageRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/section-requirement-coverages/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified section requirement coverage
     * @returns SectionRequirementCoverageUpdate
     * @throws ApiError
     */
    public static async updateSectionRequirementCoverage({
        uuid,
        data,
    }: {
        /** A UUID string identifying this section requirement coverage. **/
        uuid: string,
        data: SectionRequirementCoverageUpdate,
    }): Promise<SectionRequirementCoverageUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/section-requirement-coverages/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified section requirement coverage
     * @returns SectionRequirementCoverageUpdate
     * @throws ApiError
     */
    public static async partialUpdateSectionRequirementCoverage({
        uuid,
        data,
    }: {
        /** A UUID string identifying this section requirement coverage. **/
        uuid: string,
        data: SectionRequirementCoverageUpdate,
    }): Promise<SectionRequirementCoverageUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/section-requirement-coverages/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified section requirement coverage
     * @returns void
     * @throws ApiError
     */
    public static async destroySectionRequirementCoverage({
        uuid,
    }: {
        /** A UUID string identifying this section requirement coverage. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/section-requirement-coverages/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}