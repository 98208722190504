import React from 'react';
import { useLocation, Switch, Route, Link } from 'react-router-dom';
import { Typography } from 'antd';
import { capitalize } from 'lodash';

import PrivateRoute from 'components/private-route';
import ContentWithAttachments from 'components/attachments';

import { url } from 'routes';
import PolicyEditRoute, { numbering } from 'routes/policy/edit';

import { DenormalizedPolicy } from 'store/data';

interface Props {
  policy: DenormalizedPolicy;
  field: 'purpose' | 'scope' | 'background';
}

const { Title } = Typography;

const EditWrapper: React.FC<Props> = ({ policy, field }) => {
  const location = useLocation();

  return (
    <Switch key={field}>
      <PrivateRoute
        path={url('policy:edit', {
          policyId: policy.uuid,
          field,
        })}
        exact
        render={(props: any) => (
          <PolicyEditRoute
            {...props}
            match={{
              ...props.match,
              params: {
                policyId: policy.uuid,
                field,
              },
            }}
          />
        )}
      />
      <Route
        path={location.pathname}
        render={() => (
          <Link
            to={url('policy:edit', {
              policyId: policy.uuid,
              field,
            })}
            className="monospace section section__edit">
            <Title level={2}>
              <span className="section__heading monospace">
                {numbering[field]}. {capitalize(field)}
              </span>
            </Title>
            {policy[field] ? (
              <ContentWithAttachments
                content={policy[field] || ''}
                id={`${policy.uuid}_${field}`}
              />
            ) : (
              <i>No content</i>
            )}
          </Link>
        )}
      />
    </Switch>
  );
};

export default EditWrapper;
