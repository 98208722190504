import React, { useMemo, useState, useEffect } from 'react';
import { findIndex } from 'lodash';
import { Modal } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { url, useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/assets/form';

import { useDispatch, useSelector } from 'store/hooks';
import { DenormalizedAsset, selectEntities } from 'store/data';
import { selectDetail, detail } from 'store/common';

import { unflatten, flattenByKey } from 'utils';

const Detail: React.FC = () => {
  const dispatch = useDispatch();
  const { assetId } = useParams('asset');
  const asset = useSelector(state =>
    selectDetail<DenormalizedAsset>(state, 'asset', assetId as string)
  );

  useEffect(() => {
    if (asset.parent?.uuid) {
      dispatch(detail({ resolver: 'asset', uuid: asset.parent?.uuid }));
    }
  }, [asset.parent?.uuid, dispatch]);

  const assets = useSelector(state => selectEntities(state, 'assets', 'all'));

  const assetsAsTree = useMemo(
    () =>
      flattenByKey(
        unflatten(assets, item => item.parent?.uuid),
        'children'
      ),
    // eslint-disable-next-line
    [assets.length]
  );
  const currentAssetIndex = findIndex(assetsAsTree, r => r.uuid === assetId);

  const initialValues = {
    name: asset.name,
    asset_class: asset.asset_class?.uuid,
    description: asset.description,
    location: asset.location?.uuid,
    parent: asset.parent?.uuid,
    owner: asset.owner?.uuid,
    custodian: asset.custodian?.uuid,
    custom_metadata: asset.custom_metadata,
    automatically_managed: asset.automatically_managed,
    confidentiality_rating: asset.confidentiality_rating,
  };

  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const onClose = () => setVisible(false);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="80vw"
      style={{
        maxWidth: 900,
      }}
      forceRender={true}
      footer={null}
      afterClose={() => {
        history.push(url('assets'));
      }}>
      <GenericDetail
        showHeader={false}
        resolver="asset"
        listRoute="assets"
        createRoute="assets:create"
        detailIdParam="assetId"
        listTitle="Assets"
        listItemTitle={entity => entity.name}
        detailTitle={
          <>
            Edit asset
            <div style={{ fontSize: 14, fontWeight: 'normal' }}>
              {assetsAsTree[currentAssetIndex - 1] ? (
                <Link
                  to={url('asset', {
                    assetId: assetsAsTree[currentAssetIndex - 1].uuid,
                  })}>
                  &larr; prev
                </Link>
              ) : (
                <span style={{ color: '#ccc', cursor: 'default' }}>
                  &larr; prev
                </span>
              )}
              <span style={{ display: 'inline-block', width: 15 }} />
              {assetsAsTree[currentAssetIndex + 1] ? (
                <Link
                  to={url('asset', {
                    assetId: assetsAsTree[currentAssetIndex + 1].uuid,
                  })}>
                  next &rarr;
                </Link>
              ) : (
                <span style={{ color: '#ccc', cursor: 'default' }}>
                  next &rarr;
                </span>
              )}
            </div>
          </>
        }
        itemName="asset">
        {props => (
          <EditForm
            assetId={asset.uuid}
            initialValues={initialValues}
            {...props}
          />
        )}
      </GenericDetail>
    </Modal>
  );
};

export default Detail;
