/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationCreation } from '../models/LocationCreation';
import type { LocationListing } from '../models/LocationListing';
import type { LocationRetrieval } from '../models/LocationRetrieval';
import type { LocationUpdate } from '../models/LocationUpdate';
import { request as __request } from '../core/request';

export class LocationsService {

    /**
     * List locations
     * @returns any
     * @throws ApiError
     */
    public static async listLocations({
        slug,
        page,
        pageSize,
    }: {
        slug?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<LocationListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/locations/`,
            query: {
                'slug': slug,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new location object
     * @returns LocationCreation
     * @throws ApiError
     */
    public static async createLocation({
        data,
    }: {
        data: LocationCreation,
    }): Promise<LocationCreation> {
        const result = await __request({
            method: 'POST',
            path: `/locations/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single location
     * @returns LocationRetrieval
     * @throws ApiError
     */
    public static async getLocation({
        uuid,
    }: {
        /** A UUID string identifying this location. **/
        uuid: string,
    }): Promise<LocationRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/locations/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified location
     * @returns LocationUpdate
     * @throws ApiError
     */
    public static async updateLocation({
        uuid,
        data,
    }: {
        /** A UUID string identifying this location. **/
        uuid: string,
        data: LocationUpdate,
    }): Promise<LocationUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/locations/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified location
     * @returns LocationUpdate
     * @throws ApiError
     */
    public static async partialUpdateLocation({
        uuid,
        data,
    }: {
        /** A UUID string identifying this location. **/
        uuid: string,
        data: LocationUpdate,
    }): Promise<LocationUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/locations/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified location
     * @returns void
     * @throws ApiError
     */
    public static async destroyLocation({
        uuid,
    }: {
        /** A UUID string identifying this location. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/locations/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}