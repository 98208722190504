import React from 'react';
import { Redirect } from 'react-router-dom';

import { url } from 'routes';

const Overview: React.FC = () => {
  return <Redirect to={url('policies')} />;
};

export default Overview;
