/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetClassificationCreation } from '../models/AssetClassificationCreation';
import type { AssetClassificationListing } from '../models/AssetClassificationListing';
import type { AssetClassificationRetrieval } from '../models/AssetClassificationRetrieval';
import type { AssetClassificationUpdate } from '../models/AssetClassificationUpdate';
import { request as __request } from '../core/request';

export class AssetClassificationsService {

    /**
     * List asset classifications
     * @returns any
     * @throws ApiError
     */
    public static async listAssetClassifications({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<AssetClassificationListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/asset-classifications/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new asset classification object
     * @returns AssetClassificationCreation
     * @throws ApiError
     */
    public static async createAssetClassification({
        data,
    }: {
        data: AssetClassificationCreation,
    }): Promise<AssetClassificationCreation> {
        const result = await __request({
            method: 'POST',
            path: `/asset-classifications/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single asset classification
     * @returns AssetClassificationRetrieval
     * @throws ApiError
     */
    public static async getAssetClassification({
        uuid,
    }: {
        /** A UUID string identifying this asset classification. **/
        uuid: string,
    }): Promise<AssetClassificationRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/asset-classifications/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified asset classification
     * @returns AssetClassificationUpdate
     * @throws ApiError
     */
    public static async updateAssetClassification({
        uuid,
        data,
    }: {
        /** A UUID string identifying this asset classification. **/
        uuid: string,
        data: AssetClassificationUpdate,
    }): Promise<AssetClassificationUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/asset-classifications/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified asset classification
     * @returns AssetClassificationUpdate
     * @throws ApiError
     */
    public static async partialUpdateAssetClassification({
        uuid,
        data,
    }: {
        /** A UUID string identifying this asset classification. **/
        uuid: string,
        data: AssetClassificationUpdate,
    }): Promise<AssetClassificationUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/asset-classifications/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified asset classification
     * @returns void
     * @throws ApiError
     */
    public static async destroyAssetClassification({
        uuid,
    }: {
        /** A UUID string identifying this asset classification. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/asset-classifications/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}