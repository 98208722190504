export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const login = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => ({
  type: LOGIN,
  payload: {
    username,
    password,
  },
});

export type LoginAction = ReturnType<typeof login>;

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const logout = () => ({ type: LOGOUT });
