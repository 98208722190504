import React from 'react';

import { Link, Switch } from 'react-router-dom';

import Header from 'components/header';
import Layout from 'components/layout';

import { RouteWithSubRoutes, RouteInterface, url } from 'routes';
import RisksTable from 'routes/risks/table';
import RisksDashboard from 'routes/risks/dashboard';

import { useDispatch } from 'store/hooks';
import { list } from 'store/common';

import { useFetchEntities } from 'utils';

interface Props {
  routes: RouteInterface[];
}

const deps = [
  // 'requirementSets',
  'riskTreatmentActions',
  'assetClasses',
  'threatClasses',
  'vulnerabilityClasses',
  'roles',
].join('|');

const List: React.FC<Props> = ({ routes }) => {
  const dispatch = useDispatch();

  // const entities = useSelector(state => selectEntities(state, 'risks', 'all'));
  // const loadingEntities = useSelector(state =>
  //   selectLoadingList(state, 'risks', 'all')
  // );

  useFetchEntities(() => {
    dispatch(
      list({
        resolver: 'risks',
        paginationKey: 'all',
      })
    );
  }, [dispatch]);

  useFetchEntities(() => {
    deps.split('|').forEach(dep =>
      dispatch(
        list({
          resolver: dep as any,
          paginationKey: 'all',
        })
      )
    );
  }, [dispatch, deps]);

  return (
    <Layout>
      <Header documentTitle="Risks">
        <Link className="ant-btn" to={url('risks:create')}>
          Create risk
        </Link>
      </Header>
      <div className="table">
        <RisksDashboard />
        <RisksTable />
        <Switch>
          {routes.map(r => (
            <RouteWithSubRoutes key={r.path} {...r} />
          ))}
        </Switch>
      </div>
    </Layout>
  );
};

export default List;
