import React, { useState, useEffect } from 'react';
import { Form, Select, Modal, Row, Button } from 'antd';

import { selectEntities } from 'store/data';
import { detail, list, create } from 'store/common';
import { selectLoadingCreate } from 'store/loading';
import { useDispatch, useSelector } from 'store/hooks';

import { useDebounce, useLoadingFinishedEffect } from 'utils';
import { SectionRequirementCoverageCreation } from 'api';

import RequirementsSelect from 'components/requirement-select/requirements-select';

const { Option } = Select;

// Jonathan wanted this
let globalState: any = null;

const AddRequirement: React.FC<Record<string, any>> = ({ section }) => {
  const dispatch = useDispatch();
  const requirementSets = useSelector(state =>
    selectEntities(state, 'requirementSets', 'all')
  );
  const creating = useSelector(state =>
    selectLoadingCreate(state, 'coverage', section.uuid)
  );

  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState('');
  const [set, setRequirementSet] = useState(
    globalState || requirementSets[0]?.uuid
  );
  const [requirement, setRequirement] = useState<string[] | undefined>(
    undefined
  );

  const debouncedQuery = useDebounce(query, 300);

  useLoadingFinishedEffect(() => {
    setRequirementSet(globalState || requirementSets[0].uuid);
    setRequirement(undefined);
    setVisible(false);
  }, creating);

  useEffect(() => {
    globalState = set;
  }, [set]);

  useEffect(() => {
    if (debouncedQuery) {
      dispatch(
        list({
          resolver: 'requirements',
          paginationKey: debouncedQuery,
          extra: {
            identifier: debouncedQuery,
            set,
          },
        })
      );
    } else if (set) {
      dispatch(
        list({
          resolver: 'requirements',
          paginationKey: set,
          fetchOnlyOnePage: true,
          extra: {
            set,
          },
        })
      );
    }
  }, [dispatch, debouncedQuery, set]);

  const onChange = (val: string[]) => {
    setRequirement(val);
  };

  return (
    <>
      <Button style={{ marginLeft: 'auto' }} onClick={() => setVisible(true)}>
        Map requirement
      </Button>
      <Modal
        width={1000}
        visible={visible}
        onCancel={() => setVisible(false)}
        keyboard={false}
        title="Map requirement"
        footer={
          <Row justify="space-between">
            <Button
              key="cancel"
              style={{ marginRight: 'auto' }}
              onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button
              disabled={!requirement || !requirement.length}
              onClick={() => {
                requirement?.forEach(req =>
                  dispatch(
                    create({
                      resolver: 'coverage',
                      loadingKey: section.uuid,
                      data: {
                        requirement: req,
                        section: section.uuid,
                        coverage:
                          SectionRequirementCoverageCreation.coverage.UNKNOWN,
                        gap_description: '',
                      },
                      successActions: [
                        detail({
                          resolver: 'section',
                          uuid: section.uuid,
                        }),
                      ],
                    })
                  )
                );
              }}
              key="ok"
              type="primary"
              loading={creating.isFetching}>
              {creating.isFetching ? 'Adding...' : 'Add'}
            </Button>
          </Row>
        }>
        <Form layout="vertical">
          <Form.Item label="Requirement set">
            <Select
              autoFocus
              style={{ width: '100%' }}
              value={set}
              onChange={val => setRequirementSet(val)}>
              {requirementSets.map((s: any) => (
                <Option key={s.uuid} value={s.uuid}>
                  {s.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Requirement">
            <RequirementsSelect
              providesGuidance={true}
              onSearch={(v: string) => setQuery(v)}
              set={set}
              query={query}
              value={requirement}
              onChange={onChange}
              multiple
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddRequirement;
