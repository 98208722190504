import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { capitalize } from 'lodash';

import EditTitleAndContent from 'components/edit-title-and-content';

import { url } from 'routes';

import { useDispatch, useSelector } from 'store/hooks';
import { update } from 'store/common';
import { selectEntity } from 'store/data';
import { selectLoadingUpdate } from 'store/loading';

interface Props
  extends RouteComponentProps<{
    policyId: string;
    field: 'purpose' | 'scope' | 'background';
  }> {}

export const numbering = {
  background: '1.1',
  purpose: '1.2',
  scope: '1.3',
};

const PolicyEdit: React.FC<Props> = ({ match }) => {
  const dispatch = useDispatch();
  const field = match.params.field;
  const title = capitalize(field);
  const policy = useSelector(state =>
    selectEntity(state, 'policy', match.params.policyId)
  );
  const updating = useSelector(state =>
    selectLoadingUpdate(
      state,
      'policy',
      match.params.policyId,
      match.params.field
    )
  );

  return (
    <div className="section section--active">
      <EditTitleAndContent
        upload={true}
        title={`${numbering[field]}. ${title}`}
        titleEditingDisabled={true}
        content={policy[match.params.field] || ''}
        isSaving={updating.isFetching}
        onSave={({ content }) =>
          dispatch(
            update({
              uuid: policy.uuid,
              resolver: 'policy',
              patch: { [match.params.field]: content },
            })
          )
        }>
        <Link
          className="section__close"
          to={url('policy', { policyId: match.params.policyId })}>
          &times;
        </Link>
      </EditTitleAndContent>
    </div>
  );
};

export default PolicyEdit;
