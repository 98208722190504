import React from 'react';
import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/vulnerability-classes/form';

import { useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';

const Detail: React.FC = () => {
  const { vulnerabilityClassId } = useParams('vulnerability-class') as {
    vulnerabilityClassId: string;
  };
  const entity = useSelector(state =>
    selectEntity(state, 'vulnerabilityClass', vulnerabilityClassId)
  );

  const initialValues = {
    name: entity.name,
  };

  return (
    <>
      <GenericDetail
        resolver="vulnerabilityClass"
        listRoute="vulnerability-classes"
        detailIdParam="vulnerabilityClassId"
        listTitle="Vulnerability classes"
        createRoute="vulnerability-classes:create"
        listItemTitle={entity => entity.name}
        itemName="vulnerability class">
        {props => (
          <>
            <EditForm initialValues={initialValues} {...props} />
          </>
        )}
      </GenericDetail>
    </>
  );
};

export default Detail;
