import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedProcedure } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedProcedure>
      resolver="procedures"
      itemName="procedure"
      listItemTitle={entity => entity.name}
      listTitle="Procedures"
      detailRoute="procedure"
      detailIdParam="procedureId"
      createRoute="procedures:create"
      dependencies={['policies', 'roles']}
      routes={routes}
    />
  );
};

export default List;
