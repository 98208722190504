import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Select, Form, Input, Button } from 'antd';
import { values } from 'lodash';
import { ProcedureUpdate } from 'api';

import SectionsSelect from 'components/section-select';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';

import { useSelector } from 'store/hooks';
import { selectEntities } from 'store/data';

type Props = Omit<GenericFormProps, 'renderFields'>;

const { TextArea } = Input;

const EditForm: React.FC<Props> = props => {
  const roles = useSelector(state => selectEntities(state, 'roles', 'all'));

  return (
    <GenericForm
      {...props}
      renderFields={() => (
        <>
          <Form.Item required label="Name" name="name">
            <Input className="monospace" />
          </Form.Item>
          <Form.Item label="Implementation Status" name="implementation_status">
            <Select>
              {values(ProcedureUpdate.implementation_status).map(value => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item required name="responsible" label="Responsible">
            <Select showSearch optionFilterProp="children">
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Assists" name="assists">
            <Select showSearch optionFilterProp="children" mode="multiple">
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Consulted" name="consulted">
            <Select showSearch optionFilterProp="children" mode="multiple">
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Informed" name="informed">
            <Select showSearch optionFilterProp="children" mode="multiple">
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.List name="sections">
            {(fields, { add, remove }, { errors }) => (
              <>
                <div className="ant-col ant-form-item-label">
                  <span title="Sections">Sections</span>
                </div>
                {fields.map(field => (
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    key={field.key}>
                    <Form.Item shouldUpdate style={{ width: '60%' }} {...field}>
                      <SectionsSelect />
                    </Form.Item>
                    <button
                      onClick={() => remove(field.name)}
                      className="remove-field-button">
                      <MinusCircleOutlined />
                    </button>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}>
                    Add section
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item label="Input" name="description_input">
            <TextArea
              className="monospace"
              autoSize={{
                minRows: 3,
              }}
            />
          </Form.Item>
          <Form.Item label="Activity" required name="description_activity">
            <TextArea
              className="monospace"
              autoSize={{
                minRows: 3,
              }}
            />
          </Form.Item>
          <Form.Item label="Output" name="description_output">
            <TextArea
              className="monospace"
              autoSize={{
                minRows: 3,
              }}
            />
          </Form.Item>
        </>
      )}
    />
  );
};

export default EditForm;
