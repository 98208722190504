import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import expireIn from 'redux-persist-transform-expire-in';

const CURRENT_CACHE_VERSION = 2;

const expireTimeout = 12 * 60 * 60 * 1000; // expire in 12h
const expirationKey = 'expirationKey';

export const rootPersistConfig = {
  key: 'divio',
  storage,
  version: CURRENT_CACHE_VERSION,
  blacklist: ['router'],
  whitelist: ['data', 'entities'],
  transforms: [expireIn(expireTimeout, expirationKey, {})],
  stateReconciler: autoMergeLevel2,
  // async migrate(state: any, currentVersion: number) {
  //   // istanbul ignore else
  //   // if (isCachedStateMalformed()) {
  //   //   return;
  //   // }
  //   //
  //   // // istanbul ignore next
  //   // return createMigrate(migrations)(state, currentVersion);
  // },
};
