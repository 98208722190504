/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ThreatClassCreation } from '../models/ThreatClassCreation';
import type { ThreatClassListing } from '../models/ThreatClassListing';
import type { ThreatClassRetrieval } from '../models/ThreatClassRetrieval';
import type { ThreatClassUpdate } from '../models/ThreatClassUpdate';
import { request as __request } from '../core/request';

export class ThreatClassesService {

    /**
     * List threat classes
     * @returns any
     * @throws ApiError
     */
    public static async listThreatClasses({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ThreatClassListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/threat-classes/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new threat class object
     * @returns ThreatClassCreation
     * @throws ApiError
     */
    public static async createThreatClass({
        data,
    }: {
        data: ThreatClassCreation,
    }): Promise<ThreatClassCreation> {
        const result = await __request({
            method: 'POST',
            path: `/threat-classes/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single threat class
     * @returns ThreatClassRetrieval
     * @throws ApiError
     */
    public static async getThreatClass({
        uuid,
    }: {
        /** A UUID string identifying this threat class. **/
        uuid: string,
    }): Promise<ThreatClassRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/threat-classes/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified threat class
     * @returns ThreatClassUpdate
     * @throws ApiError
     */
    public static async updateThreatClass({
        uuid,
        data,
    }: {
        /** A UUID string identifying this threat class. **/
        uuid: string,
        data: ThreatClassUpdate,
    }): Promise<ThreatClassUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/threat-classes/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified threat class
     * @returns ThreatClassUpdate
     * @throws ApiError
     */
    public static async partialUpdateThreatClass({
        uuid,
        data,
    }: {
        /** A UUID string identifying this threat class. **/
        uuid: string,
        data: ThreatClassUpdate,
    }): Promise<ThreatClassUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/threat-classes/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified threat class
     * @returns void
     * @throws ApiError
     */
    public static async destroyThreatClass({
        uuid,
    }: {
        /** A UUID string identifying this threat class. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/threat-classes/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}