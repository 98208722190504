import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedVulnerabilityClass } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedVulnerabilityClass>
      resolver="vulnerabilityClasses"
      itemName="vulnerability class"
      listItemTitle={entity => entity.name}
      listTitle="Vulnerability classes"
      detailRoute="vulnerability-class"
      detailIdParam="vulnerabilityClassId"
      createRoute="vulnerability-classes:create"
      dependencies={[]}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default List;
