import React, { useEffect, useState } from 'react';
import { Typography, Select, Tag, Button, Dropdown, Menu } from 'antd';
import { Switch, useHistory, Link, useLocation } from 'react-router-dom';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';

import { BaseHeader } from 'components/header';

import { url, useParams, RouteWithSubRoutes, RouteInterface } from 'routes';
import Approve from 'routes/policy/versions/approve';
import Promote from 'routes/policy/versions/promote';
import VersionsList from 'routes/policy/versions/list';

import {
  useSelector,
  useValidateEntityExistence,
  useDispatch,
} from 'store/hooks';
import { selectEntity, downloadBuild } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const { Title } = Typography;

const PolicyBuilds: React.FC<Props> = ({ routes }) => {
  const dispatch = useDispatch();
  const params = useParams('policy:versions');
  const paramsVersions = useParams('policy:versions:builds');
  const paramsDetail = useParams('policy:versions:build');
  const location = useLocation();
  const policyId =
    paramsDetail.policyId || paramsVersions.policyId || params.policyId;
  const versionId = paramsVersions.versionId || paramsDetail.versionId;
  const [buildId, setBuildId] = useState(paramsDetail.buildId);

  const history = useHistory();
  const policy = useSelector(state =>
    selectEntity(state, 'policy', policyId as string)
  );
  const version = useSelector(state =>
    selectEntity(state, 'policyVersion', versionId as string)
  );
  const build = useSelector(state =>
    selectEntity(state, 'policyBuild', buildId || '')
  );

  const [isVersionsDropdownVisible, setVersionsDropdownVisible] =
    useState(false);

  useValidateEntityExistence({
    entity: policy,
    entityType: 'policy',
    redirectUrl: url('policies'),
  });

  const versionIds = version?.builds?.map(b => b.uuid).join('');

  useEffect(() => {
    setBuildId(undefined);
  }, [versionId]);

  useEffect(() => {
    if (!buildId && version?.builds?.length) {
      setBuildId(version?.builds[0].uuid as string);
    }
    // eslint-disable-next-line
  }, [buildId, versionIds]);

  useEffect(() => {
    if (
      policyId &&
      versionId &&
      buildId &&
      location.pathname !==
        url('policy:versions:build', { policyId, versionId, buildId })
    ) {
      history.replace(
        url('policy:versions:build', { policyId, versionId, buildId })
      );
    }
  }, [history, policyId, versionId, buildId, location.pathname]);

  return (
    <div className="build">
      <BaseHeader
        documentTitle={`${policy?.title} ${version?.version}${
          build.profile ? ` (${build.profile})` : ''
        }`}>
        <span className="build__title">
          <Title className="build__title-inner mb-0 text-overflow" level={4}>
            <span className="text-overflow pr-1">{policy?.title}</span>
            <Dropdown
              visible={isVersionsDropdownVisible}
              onVisibleChange={v => setVersionsDropdownVisible(v)}
              trigger={['click']}
              overlay={
                <Menu
                  style={{
                    width: 600,
                    maxHeight: 'calc(100vh - 200px)',
                    overflowY: 'auto',
                    background: 'white',
                  }}>
                  <div className="pt-1 pb-1">
                    <VersionsList
                      onClick={() => setVersionsDropdownVisible(false)}
                      selectedVersion={version}
                      policy={policy}
                    />
                  </div>
                </Menu>
              }>
              <Button>
                {version?.version} <DownOutlined />
              </Button>
            </Dropdown>
          </Title>
        </span>
        <span className="build__tags">
          {version?.status ? (
            <Tag color={version?.status === 'draft' ? 'error' : 'success'}>
              {version?.status}
            </Tag>
          ) : null}
          {version?.uuid === policy?.latest_version?.uuid ? (
            <Tag>latest version</Tag>
          ) : null}
        </span>
        <span className="ml-auto">
          <span className="text-overflow">Build: </span>
          {(version?.builds?.length || 0) > 1 ? (
            <Select
              style={{ minWidth: 100, maxWidth: 150 }}
              onChange={v => setBuildId(v)}
              value={buildId}>
              {version?.builds?.map(b => (
                <Select.Option key={b.uuid} value={b.uuid}>
                  {b.profile}
                </Select.Option>
              ))}
            </Select>
          ) : version?.builds && version.builds.length === 1 ? (
            <b>{version.builds[0].profile}</b>
          ) : null}
        </span>
        <span className="ml-2 flex-shrink-0">
          {version?.version && version?.version?.match(/^[.\d]+$/g) ? (
            version?.status === 'draft' ? (
              <Approve version={version} />
            ) : null
          ) : version?.status === 'draft' ? (
            <Promote version={version} />
          ) : null}
          {build.uuid ? (
            <Button
              onClick={() =>
                dispatch(
                  downloadBuild({
                    uuid: build.uuid,
                    fileName: `${policy?.title} v${version?.version}${
                      version?.status === 'draft' ? ` DRAFT` : ''
                    } (${build?.profile}).pdf`,
                  })
                )
              }>
              Download
            </Button>
          ) : null}
        </span>
        <Link
          className="ml-2 header__back"
          onClick={() => (history.length ? history.goBack() : null)}
          to={url('policy', { policyId: policyId as string })}>
          <CloseOutlined />
        </Link>
      </BaseHeader>
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={i} {...route} />;
        })}
      </Switch>
    </div>
  );
};

export default PolicyBuilds;
