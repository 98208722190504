/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VulnerabilityClassCreation } from '../models/VulnerabilityClassCreation';
import type { VulnerabilityClassListing } from '../models/VulnerabilityClassListing';
import type { VulnerabilityClassRetrieval } from '../models/VulnerabilityClassRetrieval';
import type { VulnerabilityClassUpdate } from '../models/VulnerabilityClassUpdate';
import { request as __request } from '../core/request';

export class VulnerabilityClassesService {

    /**
     * List vulnerability classes
     * @returns any
     * @throws ApiError
     */
    public static async listVulnerabilityClasses({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<VulnerabilityClassListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/vulnerability-classes/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new vulnerability class object
     * @returns VulnerabilityClassCreation
     * @throws ApiError
     */
    public static async createVulnerabilityClass({
        data,
    }: {
        data: VulnerabilityClassCreation,
    }): Promise<VulnerabilityClassCreation> {
        const result = await __request({
            method: 'POST',
            path: `/vulnerability-classes/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single vulnerability class
     * @returns VulnerabilityClassRetrieval
     * @throws ApiError
     */
    public static async getVulnerabilityClass({
        uuid,
    }: {
        /** A UUID string identifying this vulnerability class. **/
        uuid: string,
    }): Promise<VulnerabilityClassRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/vulnerability-classes/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified vulnerability class
     * @returns VulnerabilityClassUpdate
     * @throws ApiError
     */
    public static async updateVulnerabilityClass({
        uuid,
        data,
    }: {
        /** A UUID string identifying this vulnerability class. **/
        uuid: string,
        data: VulnerabilityClassUpdate,
    }): Promise<VulnerabilityClassUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/vulnerability-classes/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified vulnerability class
     * @returns VulnerabilityClassUpdate
     * @throws ApiError
     */
    public static async partialUpdateVulnerabilityClass({
        uuid,
        data,
    }: {
        /** A UUID string identifying this vulnerability class. **/
        uuid: string,
        data: VulnerabilityClassUpdate,
    }): Promise<VulnerabilityClassUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/vulnerability-classes/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified vulnerability class
     * @returns void
     * @throws ApiError
     */
    public static async destroyVulnerabilityClass({
        uuid,
    }: {
        /** A UUID string identifying this vulnerability class. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/vulnerability-classes/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}