import React, { useMemo, useCallback, useState } from 'react';
import { includes, some, values } from 'lodash';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

import { url } from 'routes';

import { useSelector } from 'store/hooks';
import {
  selectEntities,
  DenormalizedAsset,
  DenormalizedRole,
  DenormalizedAssetClass,
  DenormalizedLocation,
} from 'store/data';

import { unflatten, TreeRecord } from 'utils';

const sticky = { offsetHeader: -20, offsetScroll: -20 };

const AssetsTable: React.FC = React.memo(() => {
  const assets = useSelector(state => selectEntities(state, 'assets', 'all'));
  const assetClasses = useSelector(state =>
    selectEntities(state, 'assetClasses', 'all')
  );
  const locations = useSelector(state =>
    selectEntities(state, 'locations', 'all')
  );
  const roles = useSelector(state => selectEntities(state, 'roles', 'all'));
  const assetTypes = useSelector(state =>
    selectEntities(state, 'assetTypes', 'all')
  );

  const assetClassFilters = assetClasses.map(a => ({
    value: a.uuid,
    text: a.name,
  }));
  const locationFilters = locations.map(a => ({
    value: a.uuid,
    text: a.name,
  }));
  const roleFilters = roles.map(a => ({
    value: a.uuid,
    text: a.name,
  }));
  const assetTypesFilters = assetTypes.map(a => ({
    value: a.uuid,
    text: a.name,
  }));

  const assetsAsTree = useMemo(
    () => unflatten(assets, item => item.parent?.uuid),
    // eslint-disable-next-line
    [assets.length]
  );

  const columns = useMemo(
    () => [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        align: 'left',
        sorter: (a: DenormalizedAsset, b: DenormalizedAsset) =>
          a?.name && b?.name ? (a?.name > b?.name ? 1 : -1) : -1,
        render: (name: string, node: DenormalizedAsset) => {
          return <Link to={url('asset', { assetId: node.uuid })}>{name}</Link>;
        },
      },
      {
        key: 'asset_class',
        dataIndex: 'asset_class',
        title: 'Asset Class',
        width: 240,
        filters: assetClassFilters,
        sorter: (a: DenormalizedAsset, b: DenormalizedAsset) =>
          a?.asset_class?.name && b?.asset_class?.name
            ? a?.asset_class?.name > b?.asset_class?.name
              ? 1
              : -1
            : a.asset_class
            ? -1
            : 1,
        onFilter: (value: string, record: TreeRecord<DenormalizedAsset>) => {
          return (
            record?.asset_class?.uuid === value ||
            (record.children &&
              includes(
                (record?.children).map(r => r.asset_class?.uuid),
                value
              ))
          );
        },
        ellipsis: true,
        filterSearch: true,
        render: (assetClass: DenormalizedAssetClass | null) => {
          return assetClass ? (
            <Link to={url('asset-class', { assetClassId: assetClass.uuid })}>
              {assetClass.name}
            </Link>
          ) : (
            '-'
          );
        },
      },
      {
        key: 'asset_type',
        dataIndex: 'asset_type',
        title: 'Asset Type',
        filterSearch: true,
        width: 240,
        filters: assetTypesFilters,
        onFilter: (value: string, record: TreeRecord<DenormalizedAsset>) =>
          record?.asset_class?.asset_type?.uuid === value ||
          (record.children &&
            includes(
              (record?.children).map(r => r.asset_class?.asset_type?.uuid),
              value
            )),
        ellipsis: true,
        sorter: (a: DenormalizedAsset, b: DenormalizedAsset) =>
          a?.asset_class?.asset_type?.name && b?.asset_class?.asset_type?.name
            ? a?.asset_class?.asset_type?.name >
              b?.asset_class?.asset_type?.name
              ? 1
              : -1
            : a.asset_class?.asset_type
            ? -1
            : 1,
        render: (assetClass: DenormalizedAssetClass | null) => {
          return assetClass?.asset_type ? (
            <Link
              to={url('asset-type', {
                assetTypeId: assetClass.asset_type?.uuid,
              })}>
              {assetClass.asset_type.name}
            </Link>
          ) : (
            '-'
          );
        },
      },
      {
        key: 'location',
        dataIndex: 'location',
        title: 'Location',
        width: 150,
        filters: locationFilters,
        sorter: (a: DenormalizedAsset, b: DenormalizedAsset) =>
          a?.location?.name && b?.location?.name
            ? a?.location?.name > b?.location?.name
              ? 1
              : -1
            : a.location
            ? -1
            : 1,
        onFilter: (value: string, record: DenormalizedAsset) =>
          record?.location?.uuid === value,
        ellipsis: true,
        render: (location: DenormalizedLocation | null) => {
          return location ? (
            <Link to={url('location', { locationId: location.uuid })}>
              {location.name}
            </Link>
          ) : (
            '-'
          );
        },
      },
      {
        key: 'owner',
        dataIndex: 'owner',
        title: 'Owner',
        width: 100,
        sorter: (a: DenormalizedAsset, b: DenormalizedAsset) =>
          a?.owner?.name && b?.owner?.name
            ? a?.owner?.name > b?.owner?.name
              ? 1
              : -1
            : a.owner
            ? -1
            : 1,
        filters: roleFilters,
        onFilter: (value: string, record: DenormalizedAsset) =>
          record?.owner?.uuid === value,
        ellipsis: true,
        render: (role: DenormalizedRole | null) => {
          return role ? (
            <Link to={url('role', { roleId: role.uuid })}>{role.name}</Link>
          ) : (
            '-'
          );
        },
      },
      {
        key: 'custodian',
        dataIndex: 'custodian',
        title: 'Custodian',
        filters: roleFilters,
        sorter: (a: DenormalizedAsset, b: DenormalizedAsset) =>
          a?.custodian?.name && b?.custodian?.name
            ? a?.custodian?.name > b?.custodian?.name
              ? 1
              : -1
            : a.custodian
            ? -1
            : 1,
        onFilter: (value: string, record: DenormalizedAsset) =>
          record?.owner?.uuid === value,
        ellipsis: true,
        width: 140,
        render: (role: DenormalizedRole | null) => {
          return role ? (
            <Link to={url('role', { roleId: role.uuid })}>{role.name}</Link>
          ) : (
            '-'
          );
        },
      },
      // status
      // automatically managed
    ],
    // eslint-disable-next-line
    [
      assets.length,
      roles.length,
      assetClasses.length,
      locations.length,
      assetTypes.length,
    ]
  );

  const rowKey = useCallback(record => record.uuid, []);
  const [filters, setFilters] = useState(false);

  return (
    <Table
      className="table"
      rowKey={rowKey}
      tableLayout="fixed"
      bordered={false}
      size="small"
      indentSize={30}
      showHeader={true}
      defaultExpandAllRows={true}
      pagination={false}
      // the problem is that ant table doesn't go over
      // the children, so if you filter by something and
      // the row has a one child with correct filter value and 30 more
      // with incorrect - it will still display them, so here we flatten stuff
      // if filters are enabled
      dataSource={filters ? assets : assetsAsTree}
      onChange={(_, filters) => {
        setFilters(some(values(filters), (x: any) => !!x));
      }}
      columns={columns as any}
      sticky={sticky}
    />
  );
});

export default AssetsTable;
