/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProcedureUpdate = {
    readonly uuid?: string;
    name: string;
    sections?: Array<string>;
    implementation_status?: ProcedureUpdate.implementation_status;
    description_input?: string | null;
    description_activity: string;
    description_output?: string | null;
    schedule?: string | null;
    /**
     * Those responsible for the performance of the task.
     */
    responsible: string;
    /**
     * Those who assist in the completion of the task.
     */
    assists?: Array<string>;
    /**
     * Those whose opinions are sought; and with whom there is two-way communication.
     */
    consulted?: Array<string>;
    /**
     * Those who are kept up-to-date on progress; and with whom there is one-way communication.
     */
    informed?: Array<string>;
}

export namespace ProcedureUpdate {

    export enum implementation_status {
        UNKNOWN = 'unknown',
        PARTIAL = 'partial',
        FULL = 'full',
    }


}
