import React from 'react';
import { orderBy } from 'lodash';

import { Menu, Button, Row, Empty, Skeleton, Typography } from 'antd';
import { Link, Switch } from 'react-router-dom';

import Header from 'components/header';
import Layout, { LayoutRow, Sidebar, Content } from 'components/layout';

import {
  RouteWithSubRoutes,
  RouteInterface,
  url,
  useParams,
  RouteArgs,
} from 'routes';

import { useDispatch, useSelector } from 'store/hooks';
import { list, ListResolver } from 'store/common';
import { selectEntities } from 'store/data';
import { selectLastPage } from 'store/paginate';
import { selectLoadingList } from 'store/loading';

import { useFetchEntities } from 'utils';

const { Title } = Typography;

interface Props<T> {
  routes: RouteInterface[];
  dependencies: ListResolver[];
  detailRoute: keyof RouteArgs;
  createRoute: keyof RouteArgs | null;
  detailIdParam: string;
  listTitle: string;
  itemName: string;
  resolver: ListResolver;
  listItemTitle: (entity: T) => React.ReactNode;
  showListTitle?: boolean;
  orderBy?: keyof T;
  allPages?: boolean;
}

const List = <T extends unknown>({
  routes,
  dependencies,
  detailRoute,
  createRoute,
  detailIdParam,
  listTitle,
  itemName,
  resolver,
  listItemTitle,
  showListTitle = false,
  orderBy: order,
  allPages = false,
}: Props<T>) => {
  const dispatch = useDispatch();

  const params = useParams(detailRoute);
  const id = (params as any)[detailIdParam] as any;
  const entities = useSelector(state => selectEntities(state, resolver, 'all'));
  const pagination = useSelector(state =>
    selectLastPage(state.data[resolver], 'all')
  );
  const loadingEntities = useSelector(state =>
    selectLoadingList(state, resolver, 'all')
  );
  const deps = dependencies.join('|');

  useFetchEntities(() => {
    dispatch(
      list({
        resolver,
        paginationKey: 'all',
        fetchOnlyOnePage: !allPages,
      })
    );
  }, [dispatch, resolver]);

  useFetchEntities(() => {
    if (!deps.length) {
      return;
    }
    deps.split('|').forEach(dep =>
      dispatch(
        list({
          resolver: dep as any,
          paginationKey: 'all',
        })
      )
    );
  }, [dispatch, deps]);

  return (
    <Layout>
      {!id ? (
        <Header documentTitle={listTitle}>
          {createRoute ? (
            <Link className="ant-btn" to={url(createRoute, undefined)}>
              Create {itemName}
            </Link>
          ) : null}
        </Header>
      ) : null}
      <LayoutRow>
        <Sidebar>
          {showListTitle ? <Title level={5}>{listTitle}</Title> : null}
          <Menu
            selectedKeys={id ? [id] : undefined}
            style={{ marginLeft: -20, marginRight: -20 }}>
            {(order ? orderBy(entities, [order]) : entities).map(entity => (
              <Menu.Item
                style={{ padding: '0 24px' }}
                key={(entity as any).uuid}>
                <Link
                  to={url(detailRoute, {
                    [detailIdParam]: (entity as any).uuid,
                  } as any)}>
                  {listItemTitle(entity as T)}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
          {!pagination?.ids?.length && pagination?.isFetching ? (
            <>
              <Skeleton />
            </>
          ) : pagination?.nextPage ? (
            <Button
              onClick={() =>
                list({
                  resolver: resolver,
                  fetchOnlyOnePage: true,
                  paginationKey: 'all',
                  page: pagination?.nextPage,
                })
              }>
              Load more...
            </Button>
          ) : null}
        </Sidebar>
        <Content>
          <Switch>
            {routes.map(r => (
              <RouteWithSubRoutes key={r.path} {...r} />
            ))}
            <>
              {!loadingEntities.isFetching && !entities.length ? (
                <Row justify="center" style={{ height: '50vh' }} align="middle">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No entities">
                    {createRoute ? (
                      <Link
                        style={{ marginTop: 10 }}
                        className="ant-btn ant-btn-primary"
                        to={url(createRoute, undefined)}>
                        Create {itemName}
                      </Link>
                    ) : null}
                  </Empty>
                </Row>
              ) : null}
            </>
          </Switch>
        </Content>
      </LayoutRow>
    </Layout>
  );
};

export default List;
