/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityCreation } from '../models/ActivityCreation';
import type { ActivityListing } from '../models/ActivityListing';
import type { ActivityRetrieval } from '../models/ActivityRetrieval';
import type { ActivityUpdate } from '../models/ActivityUpdate';
import { request as __request } from '../core/request';

export class ActivitiesService {

    /**
     * List activities
     * @returns any
     * @throws ApiError
     */
    public static async listActivities({
        section,
        page,
        pageSize,
    }: {
        section?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ActivityListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/activities/`,
            query: {
                'section': section,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new activity object
     * @returns ActivityCreation
     * @throws ApiError
     */
    public static async createActivity({
        data,
    }: {
        data: ActivityCreation,
    }): Promise<ActivityCreation> {
        const result = await __request({
            method: 'POST',
            path: `/activities/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single activity
     * @returns ActivityRetrieval
     * @throws ApiError
     */
    public static async getActivity({
        uuid,
    }: {
        /** A UUID string identifying this activity. **/
        uuid: string,
    }): Promise<ActivityRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/activities/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified activity
     * @returns ActivityUpdate
     * @throws ApiError
     */
    public static async updateActivity({
        uuid,
        data,
    }: {
        /** A UUID string identifying this activity. **/
        uuid: string,
        data: ActivityUpdate,
    }): Promise<ActivityUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/activities/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified activity
     * @returns ActivityUpdate
     * @throws ApiError
     */
    public static async partialUpdateActivity({
        uuid,
        data,
    }: {
        /** A UUID string identifying this activity. **/
        uuid: string,
        data: ActivityUpdate,
    }): Promise<ActivityUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/activities/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified activity
     * @returns void
     * @throws ApiError
     */
    public static async destroyActivity({
        uuid,
    }: {
        /** A UUID string identifying this activity. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/activities/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}