import React from 'react';

import { RouteInterface } from 'routes';
import GenericListRoute from 'routes/generic/list';

import { DenormalizedViolation } from 'store/data';

interface Props {
  routes: RouteInterface[];
}

const List: React.FC<Props> = ({ routes }) => {
  return (
    <GenericListRoute<DenormalizedViolation>
      resolver="violations"
      itemName="violation"
      listItemTitle={entity => entity.label}
      listTitle="Violations"
      detailRoute="violation"
      detailIdParam="violationId"
      createRoute="violations:create"
      dependencies={[]}
      routes={routes}
      showListTitle={true}
    />
  );
};

export default List;
