/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserListing } from '../models/UserListing';
import type { UserRetrieval } from '../models/UserRetrieval';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * List users
     * @returns any
     * @throws ApiError
     */
    public static async listUsers({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<UserListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/users/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user object
     * @returns any
     * @throws ApiError
     */
    public static async createUser({
        data,
    }: {
        data: any,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/users/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single user
     * @returns UserRetrieval
     * @throws ApiError
     */
    public static async getUser({
        uuid,
    }: {
        uuid: string,
    }): Promise<UserRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/users/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified user
     * @returns any
     * @throws ApiError
     */
    public static async updateUser({
        uuid,
        data,
    }: {
        uuid: string,
        data: any,
    }): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/users/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified user
     * @returns any
     * @throws ApiError
     */
    public static async partialUpdateUser({
        uuid,
        data,
    }: {
        uuid: string,
        data: any,
    }): Promise<any> {
        const result = await __request({
            method: 'PATCH',
            path: `/users/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified user
     * @returns void
     * @throws ApiError
     */
    public static async destroyUser({
        uuid,
    }: {
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/users/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}