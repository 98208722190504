import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/threat-classes/form';

const Create: React.FC = () => {
  const initialValues = {
    name: '',
  };

  return (
    <GenericCreate
      resolver="threatClass"
      listResolver="threatClasses"
      createRoute="threat-classes:create"
      listRoute="threat-classes"
      detailRoute="threat-class"
      detailIdParam="threatClassId"
      listTitle="Threat classes"
      listItemTitle={entity => entity.name}
      itemName="threat class">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
