import React from 'react';
import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/definitions/form';

import { useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';

const Detail: React.FC = () => {
  const { definitionId } = useParams('definition') as {
    definitionId: string;
  };
  const entity = useSelector(state =>
    selectEntity(state, 'definition', definitionId)
  );

  const initialValues = {
    name: entity.name,
    slug: '',
    explanation: entity.explanation,
  };

  return (
    <>
      <GenericDetail
        resolver="definition"
        listRoute="definitions"
        detailIdParam="definitionId"
        listTitle="definitions"
        createRoute="definitions:create"
        listItemTitle={entity => entity.name}
        itemName="definition">
        {props => (
          <>
            <EditForm initialValues={initialValues} {...props} />
          </>
        )}
      </GenericDetail>
    </>
  );
};

export default Detail;
