import React from 'react';

const Logo: React.FC<React.SVGProps<any>> = props => {
  return (
    <svg viewBox="0 0 99.3 17.5" {...props}>
      <rect x="28.4" fill="#231F20" width="4.2" height="17.4" />
      <polygon
        fill="#231F20"
        points="57.2,0 50.2,12.7 42.8,0 38,0 48.6,17.5 51.5,17.5 61.6,0 "
      />
      <rect x="66.4" fill="#231F20" width="4.2" height="17.4" />
      <g>
        <path
          fill="#231F20"
          d="M97.9,1.2c-0.6-0.5-1.5-1.1-3.3-1.1H81.2c-1.8,0-2.7,0.6-3.3,1.1
		c-0.6,0.5-1.2,1.4-1.2,3v9.2c0,1.6,0.6,2.4,1.2,3c0.6,0.6,1.5,1.1,3.3,1.1h13.5c1.7,0,2.6-0.6,3.3-1.1c0.6-0.6,1.3-1.4,1.3-3V4.2
		C99.2,2.5,98.6,1.7,97.9,1.2 M94.9,12.7c0,0.8-0.8,1.4-1.8,1.4H82.8c-1,0-1.8-0.6-1.8-1.4V4.6c0-0.8,0.8-1.4,1.8-1.4h10.3
		c1,0,1.8,0.6,1.8,1.4V12.7z"
        />
        <path
          fill="#231F20"
          d="M0,0v17.4h18c1.7,0,2.6-0.6,3.3-1.1c0.6-0.6,1.3-1.4,1.3-3V4.1
		c0-1.6-0.6-2.5-1.2-3C20.8,0.6,19.9,0,18.1,0H0z M18.2,12.7c0,0.8-0.8,1.4-1.8,1.4H4.2v-1.4V4.6V3.2h12.1c1,0,1.8,0.6,1.8,1.4v8.1
		H18.2z"
        />
      </g>
    </svg>
  );
};

export default Logo;
