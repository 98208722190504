/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ViolationCreation } from '../models/ViolationCreation';
import type { ViolationListing } from '../models/ViolationListing';
import type { ViolationRetrieval } from '../models/ViolationRetrieval';
import type { ViolationUpdate } from '../models/ViolationUpdate';
import { request as __request } from '../core/request';

export class ViolationsService {

    /**
     * List violations
     * @returns any
     * @throws ApiError
     */
    public static async listViolations({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ViolationListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/violations/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new violation object
     * @returns ViolationCreation
     * @throws ApiError
     */
    public static async createViolation({
        data,
    }: {
        data: ViolationCreation,
    }): Promise<ViolationCreation> {
        const result = await __request({
            method: 'POST',
            path: `/violations/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single violation
     * @returns ViolationRetrieval
     * @throws ApiError
     */
    public static async getViolation({
        uuid,
    }: {
        /** A UUID string identifying this violation. **/
        uuid: string,
    }): Promise<ViolationRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/violations/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified violation
     * @returns ViolationUpdate
     * @throws ApiError
     */
    public static async updateViolation({
        uuid,
        data,
    }: {
        /** A UUID string identifying this violation. **/
        uuid: string,
        data: ViolationUpdate,
    }): Promise<ViolationUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/violations/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified violation
     * @returns ViolationUpdate
     * @throws ApiError
     */
    public static async partialUpdateViolation({
        uuid,
        data,
    }: {
        /** A UUID string identifying this violation. **/
        uuid: string,
        data: ViolationUpdate,
    }): Promise<ViolationUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/violations/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified violation
     * @returns void
     * @throws ApiError
     */
    public static async destroyViolation({
        uuid,
    }: {
        /** A UUID string identifying this violation. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/violations/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}