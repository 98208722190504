/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExceptionListing = {
    uuid: string;
    name: string;
    /**
     * Owner and responsible for the exception.
     */
    owner: string;
    /**
     * This role can review this exception.
     */
    reviewer: string;
    explanation?: string | null;
    review_schedule?: string | null;
    backend_class?: ExceptionListing.backend_class;
    backend_config?: any;
    readonly current_status?: string;
    readonly human_id?: string;
}

export namespace ExceptionListing {

    export enum backend_class {
        DIVIO_COMPLIANCE_EXCEPTIONS_BACKENDS_RISK_RISK_EXCEPTION_BACKEND = 'divio.compliance.exceptions.backends.risk.RiskExceptionBackend',
        DIVIO_COMPLIANCE_EXCEPTIONS_BACKENDS_VULNERABILITY_VULNERABILITY_EXCEPTION_BACKEND = 'divio.compliance.exceptions.backends.vulnerability.VulnerabilityExceptionBackend',
        DIVIO_COMPLIANCE_EXCEPTIONS_BACKENDS_REQUIREMENT_REQUIREMENT_EXCEPTION_BACKEND = 'divio.compliance.exceptions.backends.requirement.RequirementExceptionBackend',
    }


}
