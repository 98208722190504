import { Action } from 'redux';
import { denormalize, normalize } from 'normalizr';
import { keys, sortBy, isEmpty, toPairs, compact, mapValues } from 'lodash';

import { AppStateInterface } from 'store';
import * as schemas from 'store/schema';

import {
  ActivitiesService,
  AssetClassesService,
  AssetTypesService,
  AssetsService,
  LocationsService,
  SectionsService,
  PolicyGroupsService,
  RequirementSetsService,
  PoliciesService,
  RequirementsService,
  SectionRequirementCoveragesService,
  PolicyVersionsService,
  PolicyBuildsService,
  ProceduresService,
  RolesService,
  AssetClassificationsService,
  RisksService,
  VulnerabilityClassesService,
  ThreatClassesService,
  SchedulesService,
  UsersService,
  LabelsService,
  EvidenceService,
  CollectionRunsService,
  RiskTreatmentActionsService,
  ViolationsService,
  AcronymsService,
  DefinitionsService,
  ReferencesService,
  AttachmentsService,
} from 'api';

import { getErrorMessage } from 'utils';

// keys used here are used to lookup schemas, so make sure
// schema names used are exactly those
export const listGetters = {
  policyGroups: PolicyGroupsService.listPolicyGroups,
  requirementSets: RequirementSetsService.listRequirementSets,
  sections: SectionsService.listSections,
  policies: PoliciesService.listPolicies,
  requirements: RequirementsService.listRequirements,
  policyBuilds: PolicyBuildsService.listPolicyBuilds,
  policyVersions: PolicyVersionsService.listPolicyVersions,
  procedures: ProceduresService.listProcedures,
  roles: RolesService.listRoles,
  locations: LocationsService.listLocations,
  assetClasses: AssetClassesService.listAssetClasses,
  assets: AssetsService.listAssets,
  assetTypes: AssetTypesService.listAssetTypes,
  assetClassifications: AssetClassificationsService.listAssetClassifications,
  risks: RisksService.listRisks,
  vulnerabilityClasses: VulnerabilityClassesService.listVulnerabilityClasses,
  threatClasses: ThreatClassesService.listThreatClasses,
  schedules: SchedulesService.listSchedules,
  users: UsersService.listUsers,
  labels: LabelsService.listLabels,
  evidences: EvidenceService.listEvidence,
  collectionRuns: CollectionRunsService.listCollectionRuns,
  riskTreatmentActions: RiskTreatmentActionsService.listRiskTreatmentActions,
  violations: ViolationsService.listViolations,
  acronyms: AcronymsService.listAcronyms,
  definitions: DefinitionsService.listDefinitions,
  activities: ActivitiesService.listActivities,
  references: ReferencesService.listReferences,
  attachments: AttachmentsService.listAttachments,
} as const;

export const detailGetters = {
  section: SectionsService.getSection,
  policy: PoliciesService.getPolicy,
  policyGroup: PolicyGroupsService.getPolicyGroup,
  requirementSet: RequirementSetsService.getRequirementSet,
  requirement: RequirementsService.getRequirement,
  coverage: SectionRequirementCoveragesService.getSectionRequirementCoverage,
  policyBuild: PolicyBuildsService.getPolicyBuild,
  policyVersion: PolicyVersionsService.getPolicyVersion,
  procedure: ProceduresService.getProcedure,
  role: RolesService.getRole,
  location: LocationsService.getLocation,
  assetClass: AssetClassesService.getAssetClass,
  assetType: AssetTypesService.getAssetType,
  asset: AssetsService.getAsset,
  assetClassification: AssetClassificationsService.getAssetClassification,
  risk: RisksService.getRisk,
  threatClass: ThreatClassesService.getThreatClass,
  vulnerabilityClass: VulnerabilityClassesService.getVulnerabilityClass,
  schedule: SchedulesService.getSchedule,
  user: UsersService.getUser,
  label: LabelsService.getLabel,
  evidence: EvidenceService.getEvidence,
  collectionRun: CollectionRunsService.getCollectionRun,
  riskTreatmentAction: RiskTreatmentActionsService.getRiskTreatmentAction,
  violation: ViolationsService.getViolation,
  acronym: AcronymsService.getAcronym,
  definition: DefinitionsService.getDefinition,
  activity: ActivitiesService.getActivity,
  reference: ReferencesService.getReference,
  attachment: AttachmentsService.getAttachment,
} as const;

export const creators = {
  section: SectionsService.createSection,
  coverage: SectionRequirementCoveragesService.createSectionRequirementCoverage,
  procedure: ProceduresService.createProcedure,
  asset: AssetsService.createAsset,
  assetType: AssetTypesService.createAssetType,
  risk: RisksService.createRisk,
  role: RolesService.createRole,
  location: LocationsService.createLocation,
  schedule: SchedulesService.createSchedule,
  assetClassification: AssetClassificationsService.createAssetClassification,
  assetClass: AssetClassesService.createAssetClass,
  label: LabelsService.createLabel,
  collectionRun: CollectionRunsService.createCollectionRun,
  riskTreatmentAction: RiskTreatmentActionsService.createRiskTreatmentAction,
  threatClass: ThreatClassesService.createThreatClass,
  vulnerabilityClass: VulnerabilityClassesService.createVulnerabilityClass,
  violation: ViolationsService.createViolation,
  acronym: AcronymsService.createAcronym,
  definition: DefinitionsService.createDefinition,
  activity: ActivitiesService.createActivity,
  reference: ReferencesService.createReference,
  attachment: AttachmentsService.createAttachment,
};

export const destroyers = {
  section: SectionsService.destroySection,
  coverage:
    SectionRequirementCoveragesService.destroySectionRequirementCoverage,
  procedure: ProceduresService.destroyProcedure,
  asset: AssetsService.destroyAsset,
  assetType: AssetTypesService.destroyAssetType,
  risk: RisksService.destroyRisk,
  role: RolesService.destroyRole,
  location: LocationsService.destroyLocation,
  schedule: SchedulesService.destroySchedule,
  assetClassification: AssetClassificationsService.destroyAssetClassification,
  assetClass: AssetClassesService.destroyAssetClass,
  label: LabelsService.destroyLabel,
  riskTreatmentAction: RiskTreatmentActionsService.destroyRiskTreatmentAction,
  threatClass: ThreatClassesService.destroyThreatClass,
  vulnerabilityClass: VulnerabilityClassesService.destroyVulnerabilityClass,
  violation: ViolationsService.destroyViolation,
  acronym: AcronymsService.destroyAcronym,
  definition: DefinitionsService.destroyDefinition,
  activity: ActivitiesService.destroyActivity,
  reference: ReferencesService.destroyReference,
  attachment: AttachmentsService.destroyAttachment,
};

export const updaters = {
  section: SectionsService.partialUpdateSection,
  policy: PoliciesService.partialUpdatePolicy,
  coverage:
    SectionRequirementCoveragesService.partialUpdateSectionRequirementCoverage,
  procedure: ProceduresService.partialUpdateProcedure,
  asset: AssetsService.partialUpdateAsset,
  assetType: AssetTypesService.partialUpdateAssetType,
  risk: RisksService.partialUpdateRisk,
  role: RolesService.partialUpdateRole,
  location: LocationsService.partialUpdateLocation,
  schedule: SchedulesService.partialUpdateSchedule,
  assetClassification:
    AssetClassificationsService.partialUpdateAssetClassification,
  assetClass: AssetClassesService.partialUpdateAssetClass,
  label: LabelsService.partialUpdateLabel,
  riskTreatmentAction:
    RiskTreatmentActionsService.partialUpdateRiskTreatmentAction,
  threatClass: ThreatClassesService.partialUpdateThreatClass,
  vulnerabilityClass:
    VulnerabilityClassesService.partialUpdateVulnerabilityClass,
  violation: ViolationsService.partialUpdateViolation,
  acronym: AcronymsService.partialUpdateAcronym,
  definition: DefinitionsService.partialUpdateDefinition,
  activity: ActivitiesService.partialUpdateActivity,
  reference: ReferencesService.partialUpdateReference,
  attachment: AttachmentsService.partialUpdateAttachment,
};

export type ListResolver = keyof typeof listGetters;
export type UpdateResolver = keyof typeof updaters;
export type DetailResolver = keyof typeof detailGetters;
export type CreateResolver = keyof typeof creators;
export type DestroyResolver = keyof typeof destroyers;

const LIST = 'LIST';
const LIST_SUCCESS = 'LIST_SUCCESS';
const LIST_FAILURE = 'LIST_FAILURE';

const DETAIL = 'DETAIL';
const DETAIL_SUCCESS = 'DETAIL_SUCCESS';
const DETAIL_FAILURE = 'DETAIL_FAILURE';

const CREATE = 'CREATE';
const CREATE_SUCCESS = 'CREATE_SUCCESS';
const CREATE_FAILURE = 'CREATE_FAILURE';

const UPDATE = 'UPDATE';
const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
const UPDATE_FAILURE = 'UPDATE_FAILURE';

const DESTROY = 'DESTROY';
const DESTROY_SUCCESS = 'DESTROY_SUCCESS';
const DESTROY_FAILURE = 'DESTROY_FAILURE';

export const list = <T extends ListResolver>({
  paginationKey,
  resolver,
  page = 1,
  fetchOnlyOnePage = false,
  skipWorker = false,
  extra = {},
}: {
  paginationKey: string;
  resolver: T;
  page?: number;
  skipWorker?: boolean;
  fetchOnlyOnePage?: boolean;
  extra?: Partial<
    Omit<Parameters<typeof listGetters[T]>[0], 'page' | 'limit' | 'offset'>
  >;
}) => ({
  type: LIST,
  payload: {
    page,
    fetchOnlyOnePage,
    extra,
  },
  meta: {
    skipWorker,
    resolver,
    paginationKey,
  },
});
list.TYPE = LIST;
list.TYPES = [LIST, LIST_SUCCESS, LIST_FAILURE];
list.SUCCESS_TYPE = LIST_SUCCESS;
list.FAILURE_TYPE = LIST_FAILURE;

export const listSuccess = ({
  resolver,
  paginationKey,
  response,
  page,
  offset,
  nextPage,
}: {
  resolver: ListResolver;
  paginationKey: string;
  offset?: number;
  response: any[];
  page: number;
  nextPage?: number;
}) => ({
  type: LIST_SUCCESS,
  payload: {
    page,
    offset,
    nextPage,
    ...normalize(response, schemas[resolver]),
  },
  meta: {
    resolver,
    paginationKey,
  },
});

export const listFailure = ({
  resolver,
  paginationKey,
  error,
  page,
}: {
  resolver: ListResolver;
  paginationKey: string;
  error: Error;
  page: number;
}) => ({
  type: LIST_FAILURE,
  payload: {
    error: getErrorMessage(error),
    page,
  },
  error,
  meta: {
    resolver,
    paginationKey,
  },
});

export const detail = <T extends DetailResolver>({
  uuid,
  resolver,
  failSilently = false,
  skipWorker = false,
  extra = {},
  transformResponse,
}: {
  uuid: string;
  // schema name for denormalizing response
  resolver: T;
  failSilently?: boolean;
  skipWorker?: boolean;
  extra?: Partial<Omit<Parameters<typeof detailGetters[T]>[0], 'uuid'>>;
  transformResponse?: (
    response: Partial<Omit<Parameters<typeof detailGetters[T]>[0], 'uuid'>>
  ) => any;
}) => ({
  type: DETAIL,
  payload: { uuid, extra, failSilently, transformResponse },
  meta: {
    resolver,
    loadingKey: uuid,
    skipWorker,
    resolveKey: isEmpty(extra)
      ? // in case of same uuids, like application and legacyApplication
        `${resolver}_${uuid}`
      : // because order of keys isn't enforced in objects we need
        // to manually enforce it
        JSON.stringify([resolver, uuid, sortBy(toPairs(extra))]),
  },
});
detail.TYPE = DETAIL;
detail.TYPES = [DETAIL, DETAIL_SUCCESS, DETAIL_FAILURE];
detail.SUCCESS_TYPE = DETAIL_SUCCESS;
detail.FAILURE_TYPE = DETAIL_FAILURE;

export const detailSuccess = <T extends DetailResolver>({
  uuid,
  resolver,
  response,
  transformResponse,
}: {
  uuid: string;
  resolver: T;
  transformResponse?: (response: any) => any;
  response: ThenArg<ReturnType<typeof detailGetters[T]>>;
}) => ({
  type: DETAIL_SUCCESS,
  payload: {
    uuid,
    ...normalize(
      transformResponse ? transformResponse(response) : response,
      schemas[resolver]
    ),
  },
  meta: {
    resolver,
    loadingKey: uuid,
  },
});

export const detailFailure = ({
  uuid,
  resolver,
  error,
  failSilently,
}: {
  uuid: string;
  resolver: DetailResolver;
  error: Error;
  failSilently: boolean;
}) => ({
  type: DETAIL_FAILURE,
  payload: {
    uuid,
    error: getErrorMessage(error),
    failSilently,
  },
  error,
  meta: {
    resolver,
    loadingKey: uuid,
  },
});

export const create = <T extends CreateResolver>({
  resolver,
  loadingKey,
  data,
  successMessage,
  failSilently = false,
  successActions = [],
  redirectUrl,
}: {
  resolver: T;
  loadingKey: string;
  successMessage?: string;
  successActions?: (Action | ((uuid: string) => Action))[];
  failSilently?: boolean;
  redirectUrl?: string;
  data: Parameters<typeof creators[T]>[0]['data'];
}) => ({
  type: CREATE,
  payload: { data, successMessage, successActions, failSilently, redirectUrl },
  meta: {
    resolver,
    loadingKey,
  },
});
create.TYPE = CREATE;
create.TYPES = [CREATE, CREATE_SUCCESS, CREATE_FAILURE];
create.SUCCESS_TYPE = CREATE_SUCCESS;
create.FAILURE_TYPE = CREATE_FAILURE;

export const createSuccess = <T extends CreateResolver>({
  resolver,
  loadingKey,
  response,
}: {
  resolver: T;
  loadingKey: string;
  response: ThenArg<ReturnType<typeof creators[T]>>;
}) => ({
  type: CREATE_SUCCESS,
  payload: {
    response,
    ...normalize(response, schemas[resolver]),
  },
  meta: {
    resolver,
    loadingKey,
  },
});

export const createFailure = ({
  resolver,
  error,
  loadingKey,
  failSilently,
}: {
  resolver: CreateResolver;
  error: Error;
  loadingKey: string;
  failSilently: boolean;
}) => ({
  type: CREATE_FAILURE,
  payload: {
    error: getErrorMessage(error),
    failSilently,
  },
  error,
  meta: {
    resolver,
    loadingKey,
  },
});

export const destroy = ({
  resolver,
  uuid,
  successActions = [],
  successMessage = 'Deleted!',
}: {
  uuid: string;
  resolver: DestroyResolver;
  successMessage?: string;
  successActions?: Action[];
}) => ({
  type: DESTROY,
  payload: {
    uuid,
    successMessage,
    successActions,
  },
  meta: {
    resolver,
    loadingKey: uuid,
  },
});
destroy.TYPE = DESTROY;
destroy.TYPES = [DESTROY, DESTROY_SUCCESS, DESTROY_FAILURE];
destroy.SUCCESS_TYPE = DESTROY_SUCCESS;
destroy.FAILURE_TYPE = DESTROY_FAILURE;

export const destroySuccess = ({
  resolver,
  uuid,
}: {
  resolver: DestroyResolver;
  uuid: string;
}) => ({
  type: DESTROY_SUCCESS,
  payload: {
    uuid,
  },
  meta: {
    resolver,
    loadingKey: uuid,
  },
});

export const destroyFailure = ({
  resolver,
  error,
  uuid,
}: {
  resolver: DestroyResolver;
  error: Error;
  uuid: string;
}) => ({
  type: DESTROY_FAILURE,
  payload: {
    error: getErrorMessage(error),
    uuid,
  },
  error,
  meta: {
    resolver,
    loadingKey: uuid,
  },
});

export const update = <T extends UpdateResolver>({
  uuid,
  resolver,
  patch,
  successActions = [],
  successMessage = 'Saved!',
  transformResponse,
}: {
  uuid: string;
  resolver: T;
  patch: Partial<Parameters<typeof updaters[T]>[0]['data']>;
  transformResponse?: (
    response: Partial<Omit<Parameters<typeof updaters[T]>[0], 'uuid'>>
  ) => any;
  successActions?: Action[];
  successMessage?: string;
}) => ({
  type: UPDATE,
  payload: {
    uuid,
    patch: mapValues(patch, value =>
      typeof value === 'undefined' ? null : value
    ) as Partial<Parameters<typeof updaters[T]>[0]['data']>,
    transformResponse,
    successMessage,
    successActions,
  },
  meta: {
    resolver,
    loadingKey: uuid,
    fields: keys(patch),
  },
});
update.TYPE = UPDATE;
update.TYPES = [UPDATE, UPDATE_SUCCESS, UPDATE_FAILURE];
update.SUCCESS_TYPE = UPDATE_SUCCESS;
update.FAILURE_TYPE = UPDATE_FAILURE;

export const updateSuccess = <T extends UpdateResolver>({
  uuid,
  resolver,
  patch,
  response,
  transformResponse,
}: {
  uuid: string;
  resolver: T;
  patch: Partial<Parameters<typeof updaters[T]>[0]['data']>;
  response: ThenArg<ReturnType<typeof updaters[T]>>;
  transformResponse?: (response: any) => any;
}) => ({
  type: UPDATE_SUCCESS,
  payload: {
    uuid,
    patch,
    ...normalize(
      transformResponse ? transformResponse(response) : response,
      schemas[resolver]
    ),
  },
  meta: {
    resolver,
    loadingKey: uuid,
    fields: keys(patch),
  },
});

export const updateFailure = <T extends UpdateResolver>({
  uuid,
  resolver,
  patch,
  error,
  previousState,
}: {
  uuid: string;
  error: Error;
  resolver: T;
  patch: Partial<Parameters<typeof updaters[T]>[0]['data']>;
  previousState: Partial<ThenArg<ReturnType<typeof updaters[T]>>>;
}) => ({
  type: UPDATE_FAILURE,
  payload: {
    error: getErrorMessage(error),
    uuid,
    patch,
    previousState,
  },
  error,
  meta: {
    resolver,
    loadingKey: uuid,
    fields: keys(patch),
  },
});

export type ListAction = ReturnType<typeof list>;
export type ListSuccessAction = ReturnType<typeof listSuccess>;
export type DetailAction = ReturnType<typeof detail>;
export type DetailSuccessAction = ReturnType<typeof detailSuccess>;
export type CreateAction = ReturnType<typeof create>;
export type CreateSuccessAction = ReturnType<typeof createSuccess>;
export type DestroyAction = ReturnType<typeof destroy>;
export type DestroySuccessAction = ReturnType<typeof destroySuccess>;
export type DestroyFailureAction = ReturnType<typeof destroyFailure>;
export type UpdateAction = ReturnType<typeof update>;
export type UpdateSuccessAction = ReturnType<typeof updateSuccess>;
export type UpdateFailureAction = ReturnType<typeof updateFailure>;

export const selectList = <T>(
  state: AppStateInterface,
  schemaType: ListResolver,
  ids: string[]
): T[] => {
  return compact(denormalize(ids, schemas[schemaType], state.entities));
};

export const selectDetail = <T>(
  state: AppStateInterface,
  schemaType: DetailResolver,
  id: string
): T => denormalize(id, schemas[schemaType], state.entities);
