import React from 'react';
import { Select, Form, Input, Divider } from 'antd';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';

import { useEntities } from 'store/hooks';

type Props = Omit<GenericFormProps, 'renderFields'>;

const { TextArea } = Input;

const EditForm: React.FC<Props> = props => {
  const [roles] = useEntities('roles', 'all');
  const [schedules] = useEntities('schedules', 'all');

  return (
    <GenericForm
      {...props}
      renderFields={() => (
        <>
          <Form.Item required label="Title" name="title">
            <Input className="monospace" />
          </Form.Item>
          <Form.Item name="schedule" label="Schedule">
            <Select showSearch optionFilterProp="children">
              {schedules.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.identifier}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea autoSize={{ minRows: 3 }} className="monospace" />
          </Form.Item>
          <Divider />
          <Form.Item required name="responsible" label="Responsible">
            <Select optionFilterProp="children" showSearch>
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Assists" name="assists">
            <Select optionFilterProp="children" showSearch mode="multiple">
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Consulted" name="consulted">
            <Select optionFilterProp="children" showSearch mode="multiple">
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Informed" name="informed">
            <Select optionFilterProp="children" showSearch mode="multiple">
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}
    />
  );
};

export default EditForm;
