import { Action } from 'redux';
import {
  CollectionRunListing,
  CollectionRunRetrieval,
  PolicyExportToGit,
  LocationListing,
  LocationRetrieval,
  AssetListing,
  AssetRetrieval,
  AssetClassListing,
  AssetClassRetrieval,
  SectionRequirementCoverageListing,
  PolicyGroupListing,
  RequirementListing,
  RequirementRetrieval,
  RequirementSetListing,
  PolicySectionListing,
  PolicySectionRetrieval,
  PolicyListing,
  PolicyRetrieval,
  PolicyVersionListing,
  PolicyVersionRetrieval,
  PolicyBuildListing,
  PolicyBuildRetrieval,
  ProcedureListing,
  ProcedureRetrieval,
  RoleListing,
  RoleRetrieval,
  AssetClassificationListing,
  AssetClassificationRetrieval,
  ThreatClassListing,
  ThreatClassRetrieval,
  VulnerabilityClassListing,
  VulnerabilityClassRetrieval,
  RiskListing,
  RiskRetrieval,
  SectionRequirementCoverageRetrieval,
  ScheduleListing,
  ScheduleRetrieval,
  UserListing,
  UserRetrieval,
  AssetTypeListing,
  AssetTypeRetrieval,
  LabelListing,
  EvidenceListing,
  EvidenceRetrieval,
  Exhibit,
  RiskTreatmentActionListing,
  RiskTreatmentActionRetrieval,
  ViolationListing,
  ViolationRetrieval,
  AcronymListing,
  AcronymRetrieval,
  DefinitionListing,
  DefinitionRetrieval,
  ActivityListing,
  ActivityRetrieval,
  ReferenceListing,
  ReferenceRetrieval,
  AttachmentListing,
  AttachmentRetrieval,
} from 'api';

import { AppStateInterface } from 'store';
import {
  list,
  ListSuccessAction,
  ListResolver,
  destroy,
  DestroySuccessAction,
  selectList,
  selectDetail,
  DetailResolver,
} from 'store/common';
import paginate, {
  PaginationState,
  getIds,
  deleteIdFromPages,
} from 'store/paginate';
import { resolverToEntities } from 'store/entities';

import { getErrorMessage } from 'utils';

export type DenormalizedAttachment = Denormalized<
  AttachmentListing,
  AttachmentRetrieval
>;

export type DenormalizedReference = Denormalized<
  ReferenceListing,
  ReferenceRetrieval
>;

export type DenormalizedAcronym = Denormalized<
  AcronymListing,
  AcronymRetrieval,
  Partial<{
    definition: DenormalizedDefinition;
  }>
>;

export type DenormalizedDefinition = Denormalized<
  DefinitionListing,
  DefinitionRetrieval
>;

export type DenormalizedActivity = Denormalized<
  ActivityListing,
  ActivityRetrieval,
  Partial<{
    responsible: DenormalizedRole;
    schedule: DenormalizedSchedule;
    sections: DenormalizedSection[];
    assists: DenormalizedRole[];
    consulted: DenormalizedRole[];
    informed: DenormalizedRole[];
  }>
>;

export type DenormalizedViolation = Denormalized<
  ViolationListing,
  ViolationRetrieval
>;

export type DenormalizedRiskTreatmentAction = Denormalized<
  RiskTreatmentActionListing,
  RiskTreatmentActionRetrieval,
  Partial<{
    risks: DenormalizedRisk[];
    owner: DenormalizedRole;
  }>
>;

export type DenormalizedExhibit = Denormalized<
  Exhibit,
  void,
  {
    added_by: DenormalizedUser;
  }
>;
export type DenormalizedCollectionRun = Denormalized<
  CollectionRunListing,
  CollectionRunRetrieval,
  Partial<{
    evidence: DenormalizedEvidence;
    exhibits: DenormalizedExhibit[];
  }>
>;

export type DenormalizedEvidence = Denormalized<
  EvidenceListing,
  EvidenceRetrieval,
  Partial<{
    classification: DenormalizedAssetClassification;
    owner: DenormalizedRole;
  }>
>;

export type DenormalizedPolicyGroup = Denormalized<PolicyGroupListing>;

export type DenormalizedPolicyBuild = Denormalized<
  PolicyBuildListing,
  PolicyBuildRetrieval,
  Partial<{
    // policy: DenormalizedPolicy;
  }>
>;

export type DenormalizedPolicyVersion = Denormalized<
  PolicyVersionListing,
  PolicyVersionRetrieval,
  Partial<{
    builds: DenormalizedPolicyBuild[];
    // policy: DenormalizedPolicy;
  }>
>;

export type DenormalizedLabel = Denormalized<LabelListing>;

export type DenormalizedPolicy = Denormalized<
  PolicyListing,
  PolicyRetrieval,
  Partial<{
    group: DenormalizedPolicyGroup;
    current_version: DenormalizedPolicyVersion;
    references: DenormalizedReference[];
    latest_version: DenormalizedPolicyVersion;
    approver: DenormalizedUser;
    labels: DenormalizedLabel[];
    violation: DenormalizedViolation;
  }>
>;
export type DenormalizedRequirementSet = Denormalized<RequirementSetListing>;
export type DenormalizedRequirement = Denormalized<
  RequirementListing,
  RequirementRetrieval,
  Partial<{ set: DenormalizedRequirementSet }>
>;
export type DenormalizedCoverage = Denormalized<
  SectionRequirementCoverageListing,
  SectionRequirementCoverageRetrieval,
  Partial<{
    requirement_set?: DenormalizedRequirementSet;
    requirement?: DenormalizedRequirement;
  }>
>;
export type DenormalizedSection = Denormalized<
  PolicySectionListing,
  PolicySectionRetrieval,
  Partial<{ coverages: DenormalizedCoverage[] }>
>;

export type DenormalizedRole = Denormalized<RoleListing, RoleRetrieval>;

export type DenormalizedProcedure = Denormalized<
  ProcedureListing,
  ProcedureRetrieval,
  Partial<{
    sections: DenormalizedSection[];
    responsible: DenormalizedRole;
    informed: DenormalizedRole[];
    consulted: DenormalizedRole[];
    assists: DenormalizedRole[];
  }>
>;

export type DenormalizedAssetClassification = Denormalized<
  AssetClassificationListing,
  AssetClassificationRetrieval
>;

export type DenormalizedAssetClass = Denormalized<
  AssetClassListing,
  AssetClassRetrieval,
  Partial<{
    asset_type: DenormalizedAssetType;
  }>
>;

export type DenormalizedAssetType = Denormalized<
  AssetTypeListing,
  AssetTypeRetrieval
>;

export type DenormalizedLocation = Denormalized<
  LocationListing,
  LocationRetrieval
>;

export type DenormalizedSchedule = Denormalized<
  ScheduleListing,
  ScheduleRetrieval
>;

export type DenormalizedUser = Denormalized<UserListing, UserRetrieval>;

export type DenormalizedAsset = Denormalized<
  AssetListing,
  AssetRetrieval,
  Partial<{
    asset_class: DenormalizedAssetClass;
    asset_type: DenormalizedAssetType;
    location: DenormalizedLocation;
    parent: DenormalizedAsset;
    owner: DenormalizedRole;
    custodian: DenormalizedRole;
  }>
>;

export type DenormalizedThreatClass = Denormalized<
  ThreatClassListing,
  ThreatClassRetrieval
>;
export type DenormalizedVulnerabilityClass = Denormalized<
  VulnerabilityClassListing,
  VulnerabilityClassRetrieval
>;

export type DenormalizedRisk = Denormalized<
  RiskListing,
  RiskRetrieval,
  Partial<{
    requirements: DenormalizedRequirement[];
    threat_class: DenormalizedThreatClass;
    vulnerability_class: DenormalizedVulnerabilityClass;
    owner: DenormalizedRole;
  }>
>;

const MOVE_SECTION = 'MOVE_SECTION';
const MOVE_SECTION_SUCCESS = 'MOVE_SECTION_SUCCESS';
const MOVE_SECTION_FAILURE = 'MOVE_SECTION_FAILURE';

const EXPORT = 'EXPORT';
const EXPORT_SUCCESS = 'EXPORT_SUCCESS';
const EXPORT_FAILURE = 'EXPORT_FAILURE';

const DOWNLOAD_BUILD = 'DOWNLOAD_BUILD';
const DOWNLOAD_BUILD_SUCCESS = 'DOWNLOAD_BUILD_SUCCESS';
const DOWNLOAD_BUILD_FAILURE = 'DOWNLOAD_BUILD_FAILURE';

export const moveSection = ({
  where,
  dragNode,
  dropNode,
  policy,
}: {
  where: 'before' | 'after' | 'inside';
  dragNode: string;
  dropNode: string;
  policy: string;
}) => ({
  type: MOVE_SECTION,
  payload: {
    where,
    dragNode,
    dropNode,
    policy,
  },
});
moveSection.TYPE = MOVE_SECTION;
moveSection.TYPES = [MOVE_SECTION, MOVE_SECTION_SUCCESS, MOVE_SECTION_FAILURE];

export const moveSectionSuccess = ({
  where,
  dragNode,
  dropNode,
  policy,
}: {
  where: 'before' | 'after' | 'inside';
  dragNode: string;
  dropNode: string;
  policy: string;
}) => ({
  type: MOVE_SECTION_SUCCESS,
  payload: {
    where,
    dragNode,
    dropNode,
    policy,
  },
});

export const moveSectionFailure = ({
  where,
  dragNode,
  dropNode,
  error,
  policy,
}: {
  where: 'before' | 'after' | 'inside';
  dragNode: string;
  dropNode: string;
  policy: string;
  error: Error;
}) => ({
  type: MOVE_SECTION_FAILURE,
  payload: {
    where,
    dragNode,
    dropNode,
    error: getErrorMessage(error),
    policy,
  },
  error,
});

export type MoveSectionAction = ReturnType<typeof moveSection>;

export const downloadBuild = ({
  uuid,
  fileName,
}: {
  uuid: string;
  fileName: string;
}) => ({
  type: DOWNLOAD_BUILD,
  payload: { uuid, fileName },
});
downloadBuild.TYPE = DOWNLOAD_BUILD;
downloadBuild.TYPES = [
  DOWNLOAD_BUILD,
  DOWNLOAD_BUILD_SUCCESS,
  DOWNLOAD_BUILD_FAILURE,
];

export const downloadBuildSuccess = ({ uuid }: { uuid: string }) => ({
  type: DOWNLOAD_BUILD_SUCCESS,
  payload: { uuid },
});

export const downloadBuildFailure = ({ uuid }: { uuid: string }) => ({
  type: DOWNLOAD_BUILD_FAILURE,
  payload: { uuid },
});

export const requestApproval = ({ uuid }: { uuid: string }) => ({
  type: 'REQUEST_APPROVAL',
  payload: {
    uuid,
  },
});
requestApproval.TYPE = 'REQUEST_APPROVAL';
requestApproval.TYPES = [
  'REQUEST_APPROVAL',
  'REQUEST_APPROVAL_SUCCESS',
  'REQUEST_APPROVAL_FAILURE',
];
export const requestApprovalSuccess = ({ uuid }: { uuid: string }) => ({
  type: 'REQUEST_APPROVAL_SUCCESS',
  payload: { uuid },
});
export const requestApprovalFailure = ({ uuid }: { uuid: string }) => ({
  type: 'REQUEST_APPROVAL_FAILURE',
  payload: { uuid },
});

export const promoteVersion = ({
  uuid,
  version,
}: {
  uuid: string;
  version: string;
}) => ({
  type: 'PROMOTE_VERSION',
  payload: {
    uuid,
    version,
  },
});
promoteVersion.TYPE = 'PROMOTE_VERSION';
promoteVersion.TYPES = [
  'PROMOTE_VERSION',
  'PROMOTE_VERSION_SUCCESS',
  'PROMOTE_VERSION_FAILURE',
];
export const promoteVersionSuccess = ({
  uuid,
  version,
}: {
  uuid: string;
  version: string;
}) => ({
  type: 'PROMOTE_VERSION_SUCCESS',
  payload: { uuid, version },
});
export const promoteVersionFailure = ({
  uuid,
  version,
}: {
  uuid: string;
  version: string;
}) => ({
  type: 'PROMOTE_VERSION_FAILURE',
  payload: { uuid, version },
});

export type DownloadBuildAction = ReturnType<typeof downloadBuild>;

export const exportToGit = ({
  policyId,
  data,
  successMessage,
}: {
  policyId: string;
  data: PolicyExportToGit;
  successMessage?: string;
}) => ({
  type: EXPORT,
  payload: {
    policyId,
    data,
    successMessage,
  },
  meta: {
    loadingKey: policyId,
  },
});
exportToGit.TYPE = EXPORT;
exportToGit.TYPES = [EXPORT, EXPORT_SUCCESS, EXPORT_FAILURE];

export type ExportToGitAction = ReturnType<typeof exportToGit>;

export const exportToGitSuccess = ({ policyId }: { policyId: string }) => ({
  type: EXPORT_SUCCESS,
  payload: {
    policyId,
  },
  meta: {
    loadingKey: policyId,
  },
});

export const exportToGitFailure = ({
  policyId,
  error,
}: {
  error: any;
  policyId: string;
}) => ({
  type: EXPORT_FAILURE,
  payload: {
    policyId,
    error: getErrorMessage(error),
  },
  error,
  meta: {
    loadingKey: policyId,
  },
});

export const mapActivityToSection = ({
  activityId,
  sectionId,
}: {
  activityId: string;
  sectionId: string;
}) => ({
  type: 'MAP_ACTIVITY_TO_SECTION',
  payload: {
    activityId,
    sectionId,
  },
  meta: {
    loadingKey: sectionId,
  },
});
mapActivityToSection.TYPE = 'MAP_ACTIVITY_TO_SECTION';
mapActivityToSection.TYPES = [
  'MAP_ACTIVITY_TO_SECTION',
  'MAP_ACTIVITY_TO_SECTION_SUCCESS',
  'MAP_ACTIVITY_TO_SECTION_FAILURE',
];

export const mapActivityToSectionSuccess = ({
  activityId,
  sectionId,
}: {
  activityId: string;
  sectionId: string;
}) => ({
  type: 'MAP_ACTIVITY_TO_SECTION_SUCCESS',
  payload: {
    activityId,
    sectionId,
  },
  meta: {
    loadingKey: sectionId,
  },
});

export const mapActivityToSectionFailure = ({
  activityId,
  sectionId,
  error,
}: {
  activityId: string;
  sectionId: string;
  error: any;
}) => ({
  type: 'MAP_ACTIVITY_TO_SECTION_FAILURE',
  payload: {
    activityId,
    sectionId,
    error: getErrorMessage(error),
  },
  error,
  meta: {
    loadingKey: sectionId,
  },
});

export const unmapActivityFromSection = ({
  activityId,
  sectionId,
}: {
  activityId: string;
  sectionId: string;
}) => ({
  type: 'UNMAP_ACTIVITY_FROM_SECTION',
  payload: {
    activityId,
    sectionId,
  },
  meta: {
    loadingKey: sectionId,
  },
});
unmapActivityFromSection.TYPE = 'UNMAP_ACTIVITY_FROM_SECTION';
unmapActivityFromSection.TYPES = [
  'UNMAP_ACTIVITY_FROM_SECTION',
  'UNMAP_ACTIVITY_FROM_SECTION_SUCCESS',
  'UNMAP_ACTIVITY_FROM_SECTION_FAILURE',
];

export const unmapActivityFromSectionSuccess = ({
  activityId,
  sectionId,
}: {
  activityId: string;
  sectionId: string;
}) => ({
  type: 'UNMAP_ACTIVITY_FROM_SECTION_SUCCESS',
  payload: {
    activityId,
    sectionId,
  },
  meta: {
    loadingKey: sectionId,
  },
});

export const unmapActivityFromSectionFailure = ({
  activityId,
  sectionId,
  error,
}: {
  activityId: string;
  sectionId: string;
  error: any;
}) => ({
  type: 'UNMAP_ACTIVITY_FROM_SECTION_FAILURE',
  payload: {
    activityId,
    sectionId,
    error: getErrorMessage(error),
  },
  error,
  meta: {
    loadingKey: sectionId,
  },
});

export const mapRiskTreatmentActionToRisk = ({
  riskTreatmentActionId,
  riskId,
}: {
  riskTreatmentActionId: string;
  riskId: string;
}) => ({
  type: 'MAP_RTA_TO_RISK',
  payload: {
    riskTreatmentActionId,
    riskId,
  },
  meta: {
    loadingKey: riskId,
  },
});
mapRiskTreatmentActionToRisk.TYPE = 'MAP_RTA_TO_RISK';
mapRiskTreatmentActionToRisk.TYPES = [
  'MAP_RTA_TO_RISK',
  'MAP_RTA_TO_RISK_SUCCESS',
  'MAP_RTA_TO_RISK_FAILURE',
];

export const mapRiskTreatmentActionToRiskSuccess = ({
  riskTreatmentActionId,
  riskId,
}: {
  riskTreatmentActionId: string;
  riskId: string;
}) => ({
  type: 'MAP_RTA_TO_RISK_SUCCESS',
  payload: {
    riskTreatmentActionId,
    riskId,
  },
  meta: {
    loadingKey: riskId,
  },
});

export const mapRiskTreatmentActionToRiskFailure = ({
  riskTreatmentActionId,
  riskId,
  error,
}: {
  riskTreatmentActionId: string;
  riskId: string;
  error: any;
}) => ({
  type: 'MAP_RTA_TO_RISK_FAILURE',
  payload: {
    riskTreatmentActionId,
    riskId,
    error: getErrorMessage(error),
  },
  error,
  meta: {
    loadingKey: riskId,
  },
});

export const unmapRiskTreatmentActionFromRisk = ({
  riskTreatmentActionId,
  riskId,
}: {
  riskTreatmentActionId: string;
  riskId: string;
}) => ({
  type: 'UNMAP_RTA_FROM_RISK',
  payload: {
    riskTreatmentActionId,
    riskId,
  },
  meta: {
    loadingKey: riskId,
  },
});
unmapRiskTreatmentActionFromRisk.TYPE = 'UNMAP_RTA_FROM_RISK';
unmapRiskTreatmentActionFromRisk.TYPES = [
  'UNMAP_RTA_FROM_RISK',
  'UNMAP_RTA_FROM_RISK_SUCCESS',
  'UNMAP_RTA_FROM_RISK_FAILURE',
];

export const unmapRiskTreatmentActionFromRiskSuccess = ({
  riskTreatmentActionId,
  riskId,
}: {
  riskTreatmentActionId: string;
  riskId: string;
}) => ({
  type: 'UNMAP_RTA_FROM_RISK_SUCCESS',
  payload: {
    riskTreatmentActionId,
    riskId,
  },
  meta: {
    loadingKey: riskId,
  },
});

export const unmapRiskTreatmentActionFromRiskFailure = ({
  riskTreatmentActionId,
  riskId,
  error,
}: {
  riskTreatmentActionId: string;
  riskId: string;
  error: any;
}) => ({
  type: 'UNMAP_RTA_FROM_RISK_FAILURE',
  payload: {
    riskTreatmentActionId,
    riskId,
    error: getErrorMessage(error),
  },
  error,
  meta: {
    loadingKey: riskId,
  },
});

const handlePagination = paginate({
  types: list.TYPES,
});

export interface ListResolverToDenormalizedEntity {
  policyGroups: DenormalizedPolicyGroup;
  requirementSets: DenormalizedRequirementSet;
  sections: DenormalizedSection;
  policies: DenormalizedPolicy;
  requirements: DenormalizedRequirement;
  policyBuilds: DenormalizedPolicyBuild;
  policyVersions: DenormalizedPolicyVersion;
  procedures: DenormalizedProcedure;
  roles: DenormalizedRole;
  locations: DenormalizedLocation;
  assetClasses: DenormalizedAssetClass;
  assets: DenormalizedAsset;
  assetTypes: DenormalizedAssetType;
  assetClassifications: DenormalizedAssetClassification;
  risks: DenormalizedRisk;
  vulnerabilityClasses: DenormalizedVulnerabilityClass;
  threatClasses: DenormalizedThreatClass;
  schedules: DenormalizedSchedule;
  users: DenormalizedUser;
  labels: DenormalizedLabel;
  collectionRuns: DenormalizedCollectionRun;
  evidences: DenormalizedEvidence;
  riskTreatmentActions: DenormalizedRiskTreatmentAction;
  violations: DenormalizedViolation;
  acronyms: DenormalizedAcronym;
  definitions: DenormalizedDefinition;
  activities: DenormalizedActivity;
  references: DenormalizedReference;
  attachments: DenormalizedAttachment;
}

export interface DetailResolverToDenormalizedEntity {
  section: DenormalizedSection;
  policy: DenormalizedPolicy;
  policyGroup: DenormalizedPolicyGroup;
  requirementSet: DenormalizedRequirementSet;
  requirement: DenormalizedRequirement;
  coverage: DenormalizedCoverage;
  policyBuild: DenormalizedPolicyBuild;
  policyVersion: DenormalizedPolicyVersion;
  procedure: DenormalizedProcedure;
  role: DenormalizedRole;
  location: DenormalizedLocation;
  assetClass: DenormalizedAssetClass;
  assetType: DenormalizedAssetType;
  asset: DenormalizedAsset;
  assetClassification: DenormalizedAssetClassification;
  risk: DenormalizedRisk;
  threatClass: DenormalizedThreatClass;
  vulnerabilityClass: DenormalizedVulnerabilityClass;
  schedule: DenormalizedSchedule;
  user: DenormalizedUser;
  label: DenormalizedLabel;
  collectionRun: DenormalizedCollectionRun;
  evidence: DenormalizedEvidence;
  riskTreatmentAction: DenormalizedRiskTreatmentAction;
  violation: DenormalizedViolation;
  acronym: DenormalizedAcronym;
  definition: DenormalizedDefinition;
  activity: DenormalizedActivity;
  reference: DenormalizedReference;
  attachment: DenormalizedAttachment;
}

export const selectEntities = <T extends ListResolver>(
  state: AppStateInterface,
  resolver: T,
  paginationKey: string
) =>
  selectList<ListResolverToDenormalizedEntity[T]>(
    state,
    resolver,
    getIds(state.data[resolver], paginationKey)
  );

export const selectEntity = <T extends DetailResolver>(
  state: AppStateInterface,
  resolver: T,
  uuid: string
) => selectDetail<DetailResolverToDenormalizedEntity[T]>(state, resolver, uuid);

export type State = Record<ListResolver | 'coverages', PaginationState>;

const initialState = {
  policyGroups: {},
  requirementSets: {},
  sections: {},
  policies: {},
  requirements: {},
  policyBuilds: {},
  policyVersions: {},
  procedures: {},
  roles: {},
  locations: {},
  assetClasses: {},
  assets: {},
  assetClassifications: {},
  coverages: {},
  threatClasses: {},
  vulnerabilityClasses: {},
  risks: {},
  schedules: {},
  assetTypes: {},
  users: {},
  labels: {},
  evidences: {},
  collectionRuns: {},
  riskTreatmentActions: {},
  violations: {},
  acronyms: {},
  definitions: {},
  activities: {},
  references: {},
  attachments: {},
};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case list.SUCCESS_TYPE: {
      return {
        ...state,
        [(action as ListSuccessAction).meta.resolver]: handlePagination(
          state[(action as ListSuccessAction).meta.resolver as ListResolver],
          action
        ),
      };
    }

    case destroy.SUCCESS_TYPE: {
      const { meta, payload } = action as DestroySuccessAction;
      const entities = resolverToEntities[meta.resolver as DetailResolver];

      return {
        ...state,
        [entities]: deleteIdFromPages(state[entities], payload.uuid),
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
