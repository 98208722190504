/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PolicyBuild } from './PolicyBuild';

export type PolicyVersionListing = {
    uuid: string;
    policy: string;
    version: string;
    status?: PolicyVersionListing.status;
    readonly added_at?: string;
    builds: Array<PolicyBuild>;
}

export namespace PolicyVersionListing {

    export enum status {
        DRAFT = 'draft',
        PENDING = 'pending',
        APPROVED = 'approved',
    }


}
