import { schema } from 'normalizr';

export const policyGroup = new schema.Entity('policyGroups', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);

export const policyGroups = [policyGroup];

export const requirementSet = new schema.Entity('requirementSets', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);

export const requirementSets = [requirementSet];

export const requirement = new schema.Entity(
  'requirements',
  {
    set: requirementSet,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const requirements = [requirement];

export const coverage = new schema.Entity(
  'coverages',
  {
    requirement_set: requirementSet,
    requirement: requirement,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const section = new schema.Entity(
  'sections',
  {
    coverages: [coverage],
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const sections = [section];

export const violation = new schema.Entity('violations', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);

export const violations = [violation];

export const policyBuild = new schema.Entity('policyBuilds', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);

export const policyBuilds = [policyBuild];

export const policyVersion = new schema.Entity(
  'policyVersions',
  {
    builds: policyBuilds,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const policyVersions = [policyVersion];

export const user = new schema.Entity('users', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);
export const users = [user];

export const label = new schema.Entity('labels', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);

export const labels = [label];

export const reference = new schema.Entity('references', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);

export const references = [reference];

export const policy = new schema.Entity(
  'policies',
  {
    group: policyGroup,
    latest_version: policyVersion,
    current_version: policyVersion,
    approver: user,
    labels,
    violation,
    references,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const policies = [policy];

export const role = new schema.Entity('roles', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);

export const roles = [role];

export const procedure = new schema.Entity(
  'procedures',
  {
    sections,
    responsible: role,
    assists: roles,
    consulted: roles,
    informed: roles,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const procedures = [procedure];

export const location = new schema.Entity('locations', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);
export const locations = [location];

export const schedule = new schema.Entity('schedules', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);
export const schedules = [schedule];

export const assetClassification = new schema.Entity(
  'assetClassification',
  {},
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);
export const assetClassifications = [assetClassification];

export const assetType = new schema.Entity('assetTypes', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);
export const assetTypes = [assetType];

export const assetClass = new schema.Entity(
  'assetClasses',
  {
    asset_type: assetType,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);
export const assetClasses = [assetClass];

export const asset = new schema.Entity(
  'assets',
  {
    asset_class: assetClass,
    asset_type: assetType,
    owner: role,
    custodian: role,
    location,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);
export const assets = [asset];
asset.define({ parent: asset });

export const threatClass = new schema.Entity('threatClasses', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);
export const threatClasses = [threatClass];
export const vulnerabilityClass = new schema.Entity(
  'vulnerabilityClasses',
  {},
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);
export const vulnerabilityClasses = [vulnerabilityClass];

export const risk = new schema.Entity(
  'risks',
  {
    requirements,
    threat_class: threatClass,
    vulnerability_class: vulnerabilityClass,
    owner: role,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const risks = [risk];

export const evidence = new schema.Entity(
  'evidences',
  {
    owner: role,
    classification: assetClassification,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const evidences = [evidence];

export const exhibit = new schema.Entity(
  'exhibits',
  {
    added_by: user,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);
export const exhibits = [exhibit];

export const collectionRun = new schema.Entity(
  'collectionRuns',
  { exhibits, evidence },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const collectionRuns = [collectionRun];

export const riskTreatmentAction = new schema.Entity(
  'riskTreatmentActions',
  { owner: role, risks },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const riskTreatmentActions = [riskTreatmentAction];

export const definition = new schema.Entity('definitions', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);

export const definitions = [definition];

export const acronym = new schema.Entity('acronyms', { definition }, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);

export const acronyms = [acronym];

export const activity = new schema.Entity(
  'activities',
  {
    responsible: role,
    schedule,
    sections,
    assists: roles,
    consulted: roles,
    informed: roles,
  },
  {
    idAttribute: 'uuid',
    fallbackStrategy: (key: string) => {
      return {
        uuid: key,
      };
    },
  } as any
);

export const activities = [activity];

export const attachment = new schema.Entity('attachments', {}, {
  idAttribute: 'uuid',
  fallbackStrategy: (key: string) => {
    return {
      uuid: key,
    };
  },
} as any);
export const attachments = [attachment];
