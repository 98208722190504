/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EvidenceListing } from '../models/EvidenceListing';
import type { EvidenceRetrieval } from '../models/EvidenceRetrieval';
import { request as __request } from '../core/request';

export class EvidenceService {

    /**
     * List evidence
     * @returns any
     * @throws ApiError
     */
    public static async listEvidence({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<EvidenceListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/evidence/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single evidence
     * @returns EvidenceRetrieval
     * @throws ApiError
     */
    public static async getEvidence({
        uuid,
    }: {
        /** A UUID string identifying this evidence. **/
        uuid: string,
    }): Promise<EvidenceRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/evidence/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}