/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PolicyExportToGit } from '../models/PolicyExportToGit';
import type { PolicyListing } from '../models/PolicyListing';
import type { PolicyMove } from '../models/PolicyMove';
import type { PolicyMoveInside } from '../models/PolicyMoveInside';
import type { PolicyRetrieval } from '../models/PolicyRetrieval';
import type { PolicyUpdate } from '../models/PolicyUpdate';
import { request as __request } from '../core/request';

export class PoliciesService {

    /**
     * List policies
     * @returns any
     * @throws ApiError
     */
    public static async listPolicies({
        group,
        title,
        page,
        pageSize,
    }: {
        group?: string,
        title?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<PolicyListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/policies/`,
            query: {
                'group': group,
                'title': title,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single policy
     * @returns PolicyRetrieval
     * @throws ApiError
     */
    public static async getPolicy({
        uuid,
    }: {
        /** A UUID string identifying this policy. **/
        uuid: string,
    }): Promise<PolicyRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/policies/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified policy
     * @returns PolicyUpdate
     * @throws ApiError
     */
    public static async updatePolicy({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy. **/
        uuid: string,
        data: PolicyUpdate,
    }): Promise<PolicyUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/policies/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified policy
     * @returns PolicyUpdate
     * @throws ApiError
     */
    public static async partialUpdatePolicy({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy. **/
        uuid: string,
        data: PolicyUpdate,
    }): Promise<PolicyUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/policies/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicyExportToGit
     * @throws ApiError
     */
    public static async exportToGitPolicy({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy. **/
        uuid: string,
        data: PolicyExportToGit,
    }): Promise<PolicyExportToGit> {
        const result = await __request({
            method: 'POST',
            path: `/policies/${uuid}/export_to_git/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicyMove
     * @throws ApiError
     */
    public static async moveAfterPolicy({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy. **/
        uuid: string,
        data: PolicyMove,
    }): Promise<PolicyMove> {
        const result = await __request({
            method: 'POST',
            path: `/policies/${uuid}/move_after/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicyMove
     * @throws ApiError
     */
    public static async moveBeforePolicy({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy. **/
        uuid: string,
        data: PolicyMove,
    }): Promise<PolicyMove> {
        const result = await __request({
            method: 'POST',
            path: `/policies/${uuid}/move_before/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicyMoveInside
     * @throws ApiError
     */
    public static async moveInsidePolicy({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy. **/
        uuid: string,
        data: PolicyMoveInside,
    }): Promise<PolicyMoveInside> {
        const result = await __request({
            method: 'POST',
            path: `/policies/${uuid}/move_inside/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}