/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RiskUpdate = {
    readonly uuid?: string;
    title: string;
    /**
     * Owner or contact of the risk.
     */
    owner: string;
    asset_classes: Array<string>;
    affected_parties: Array<string>;
    /**
     * Requirements which are associated with this risk.
     */
    requirements?: Array<string>;
    threat_class: string;
    vulnerability_class: string;
    impact_description: string;
    initial_probability_rating?: RiskUpdate.initial_probability_rating;
    initial_impact_rating?: RiskUpdate.initial_impact_rating;
    current_probability_rating?: RiskUpdate.current_probability_rating;
    current_impact_rating?: RiskUpdate.current_impact_rating;
    treatment_description: string;
    treatment_type?: RiskUpdate.treatment_type;
    residual_probability_rating?: RiskUpdate.residual_probability_rating;
    residual_impact_rating?: RiskUpdate.residual_impact_rating;
    notes?: string | null;
    readonly initial_risk_rating?: string;
    readonly current_risk_rating?: string;
    readonly residual_risk_rating?: string;
}

export namespace RiskUpdate {

    export enum initial_probability_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }

    export enum initial_impact_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }

    export enum current_probability_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }

    export enum current_impact_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }

    export enum treatment_type {
        UNKNOWN = 'unknown',
        MITIGATE = 'mitigate',
        ACCEPT = 'accept',
        AVOID = 'avoid',
        TRANSFER = 'transfer',
    }

    export enum residual_probability_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }

    export enum residual_impact_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }


}
