/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AssetUpdate = {
    readonly uuid?: string;
    name: string;
    description?: string | null;
    asset_class: string;
    /**
     * Rating of this specific asset. It can not exceed the confidentiality of its asset class or its potential parents. If the value is unknown, the value of the asset class applies.
     */
    confidentiality_rating?: AssetUpdate.confidentiality_rating;
    /**
     * The parent asset. This allows nesting of assets.
     */
    parent?: string | null;
    /**
     * Physical location
     */
    location?: string | null;
    /**
     * Owner or contact of the asset.
     */
    owner?: string | null;
    custodian?: string | null;
    custom_metadata?: any;
    automatically_managed?: boolean;
    /**
     * Indicates the class name of the collector from which this asset was collected.
     */
    collected_by?: string | null;
    /**
     * Date and time when the asset was identified as deleted from the external source.
     */
    archived_at?: string | null;
}

export namespace AssetUpdate {

    /**
     * Rating of this specific asset. It can not exceed the confidentiality of its asset class or its potential parents. If the value is unknown, the value of the asset class applies.
     */
    export enum confidentiality_rating {
        '_0' = 0,
        '_1' = 1,
        '_3' = 3,
        '_5' = 5,
        '_7' = 7,
        '_10' = 10,
    }


}
