import React from 'react';
import { Select, Form, Input, Slider } from 'antd';
import { AssetClassUpdate } from 'api';
import slugify from 'slugify';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';

import { useSelector } from 'store/hooks';
import { selectEntities } from 'store/data';

import { convertRatingToMarks } from 'utils';

type Props = Omit<GenericFormProps, 'renderFields'>;

const { TextArea } = Input;

const EditForm: React.FC<Props> = props => {
  const assetTypes = useSelector(state =>
    selectEntities(state, 'assetTypes', 'all')
  );

  const confidentialityRating = convertRatingToMarks(
    AssetClassUpdate.confidentiality_rating
  );
  const integrityRating = convertRatingToMarks(
    AssetClassUpdate.integrity_rating
  );
  const availabilityRating = convertRatingToMarks(
    AssetClassUpdate.availability_rating
  );

  return (
    <GenericForm
      {...props}
      renderFields={({ form }) => (
        <>
          <Form.Item required label="Name" name="name">
            <Input className="monospace" />
          </Form.Item>

          <Form.Item
            required
            label="Slug"
            name="slug"
            dependencies={['name']}
            shouldUpdate={(prevValues, curValues) => {
              if (prevValues.name !== curValues.name) {
                form.setFieldsValue({
                  ...curValues,
                  slug: slugify(curValues.name).toLowerCase(),
                });
              }

              return prevValues.name !== curValues.name;
            }}>
            <Input readOnly className="monospace" />
          </Form.Item>

          <Form.Item required label="Description" name="description">
            <TextArea className="monospace" />
          </Form.Item>

          <Form.Item required name="asset_type" label="Asset type">
            <Select showSearch optionFilterProp="children" allowClear>
              {assetTypes.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="confidentiality_rating"
            label="Confidentiality rating">
            <Slider max={10} step={null} marks={confidentialityRating} />
          </Form.Item>

          <Form.Item name="integrity_rating" label="Integrity rating">
            <Slider max={10} step={null} marks={integrityRating} />
          </Form.Item>

          <Form.Item name="availability_rating" label="Availability rating">
            <Slider max={10} step={null} marks={availabilityRating} />
          </Form.Item>
        </>
      )}
    />
  );
};

export default EditForm;
