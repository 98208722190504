import React from 'react';
import { Action } from 'redux';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/activities/form';

interface Props {
  showHeader?: boolean;
  backTo?: string;
  successRedirect?: string;
  successActions?: (Action | ((uuid: string) => Action))[];
}

const Create: React.FC<Props> = ({
  showHeader = true,
  backTo,
  successRedirect,
  successActions,
}) => {
  const initialValues = {
    title: '',
    responsible: null,
    informed: [],
    consulted: [],
    assists: [],
    description: '',
    schedule: null,
  };

  return (
    <GenericCreate
      showHeader={showHeader}
      backTo={backTo}
      successRedirect={successRedirect}
      successActions={successActions}
      resolver="activity"
      listResolver="activities"
      createRoute="activities:create"
      listRoute="activities"
      detailRoute="activity"
      detailIdParam="activityId"
      listTitle="Activities"
      listItemTitle={entity => entity.title}
      itemName="activity">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
