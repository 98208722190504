/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReferenceCreation } from '../models/ReferenceCreation';
import type { ReferenceListing } from '../models/ReferenceListing';
import type { ReferenceRetrieval } from '../models/ReferenceRetrieval';
import type { ReferenceUpdate } from '../models/ReferenceUpdate';
import { request as __request } from '../core/request';

export class ReferencesService {

    /**
     * List references
     * @returns any
     * @throws ApiError
     */
    public static async listReferences({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ReferenceListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/references/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new reference object
     * @returns ReferenceCreation
     * @throws ApiError
     */
    public static async createReference({
        data,
    }: {
        data: ReferenceCreation,
    }): Promise<ReferenceCreation> {
        const result = await __request({
            method: 'POST',
            path: `/references/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single reference
     * @returns ReferenceRetrieval
     * @throws ApiError
     */
    public static async getReference({
        uuid,
    }: {
        /** A UUID string identifying this reference. **/
        uuid: string,
    }): Promise<ReferenceRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/references/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified reference
     * @returns ReferenceUpdate
     * @throws ApiError
     */
    public static async updateReference({
        uuid,
        data,
    }: {
        /** A UUID string identifying this reference. **/
        uuid: string,
        data: ReferenceUpdate,
    }): Promise<ReferenceUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/references/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified reference
     * @returns ReferenceUpdate
     * @throws ApiError
     */
    public static async partialUpdateReference({
        uuid,
        data,
    }: {
        /** A UUID string identifying this reference. **/
        uuid: string,
        data: ReferenceUpdate,
    }): Promise<ReferenceUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/references/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified reference
     * @returns void
     * @throws ApiError
     */
    public static async destroyReference({
        uuid,
    }: {
        /** A UUID string identifying this reference. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/references/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}