/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RiskTreatmentActionRetrieval = {
    uuid: string;
    title: string;
    owner: string;
    deadline: string;
    description: string;
    risks: Array<string>;
    status?: RiskTreatmentActionRetrieval.status;
    type?: RiskTreatmentActionRetrieval.type;
    readonly human_id?: string;
}

export namespace RiskTreatmentActionRetrieval {

    export enum status {
        PLANNED = 'planned',
        PROGRESS = 'progress',
        IMPLEMENTED = 'implemented',
    }

    export enum type {
        FULL = 'full',
        IMPROVEMENT = 'improvement',
    }


}
