import React, { useState } from 'react';
import { find, differenceBy, without, orderBy } from 'lodash';
import { Tag, Select } from 'antd';
import { Loading3QuartersOutlined, PlusOutlined } from '@ant-design/icons';

import { DenormalizedLabel, selectEntities } from 'store/data';
import { useSelector, useDispatch } from 'store/hooks';
import { update } from 'store/common';
import { selectLoadingUpdate } from 'store/loading';

interface Props {
  labels: DenormalizedLabel[];
  policyId: string;
}

const EditableTagGroup: React.FC<Props> = ({ policyId, labels }) => {
  const dispatch = useDispatch();
  const allLabels = useSelector(state =>
    selectEntities(state, 'labels', 'all')
  );
  const options = differenceBy(allLabels, labels, 'uuid').map(l => ({
    label_search: `${l.scope ? `${l.scope}:` : ''}${l.value}`,
    label: (
      <Tag color={l.color}>
        {l.scope ? `${l.scope}:` : ''}
        {l.value}
      </Tag>
    ),
    value: l.uuid,
  }));
  const [inputVisible, setInputVisible] = useState(false);
  const loading = useSelector(state =>
    selectLoadingUpdate(state, 'policy', policyId, 'labels')
  );

  const showInput = () => setInputVisible(true);
  const handleInputChange = (v: string) => {
    const label = find(allLabels, { uuid: v });

    if (!label) {
      return;
    }

    dispatch(
      update({
        resolver: 'policy',
        uuid: policyId,
        patch: {
          labels: [...labels.map(l => l.uuid), v],
        },
      })
    );
    setInputVisible(false);
  };
  const handleClose = (label: DenormalizedLabel) => {
    dispatch(
      update({
        resolver: 'policy',
        uuid: policyId,
        patch: {
          labels: [
            ...without(
              labels.map(l => l.uuid),
              label.uuid
            ),
          ],
        },
      })
    );
  };

  return (
    <div style={{ marginBottom: 10 }}>
      {orderBy(labels, ['scope', 'value']).map(tag => (
        <Tag
          color={tag.color}
          key={tag.uuid}
          style={{ marginBottom: 7, verticalAlign: 'top' }}
          closable={true}
          onClose={() => handleClose(tag)}>
          {tag.scope ? `${tag.scope}:` : ''}
          {tag.value}
        </Tag>
      ))}
      {loading.isFetching ? (
        <span
          style={{
            display: 'inline-flex',
            height: 22,
            width: 22,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Loading3QuartersOutlined spin={true} />
        </span>
      ) : null}
      {inputVisible && (
        <Select
          showSearch
          optionFilterProp="label_search"
          size="small"
          style={{ width: '100%' }}
          onChange={handleInputChange}
          options={options}
        />
      )}
      {!inputVisible && options.length ? (
        <div>
          <Tag className="site-tag-plus" onClick={showInput}>
            <PlusOutlined /> add label
          </Tag>
        </div>
      ) : null}
    </div>
  );
};

export default EditableTagGroup;
