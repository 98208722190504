import React from 'react';
import { SketchPicker } from 'react-color';

interface Props {
  onChange?: any;
  value?: any;
}

const ColorPicker: React.FC<Props> = ({ value, onChange }) => {
  return (
    <SketchPicker
      styles={{
        default: {
          picker: {
            boxShadow: 'none',
            padding: 0,
            width: 260,
          },
        },
      }}
      presetColors={[]}
      color={value}
      onChange={color => {
        if (color) {
          onChange(
            color.hex + Math.round((color?.rgb?.a || 1) * 255).toString(16)
          );
        } else {
          onChange('#000000');
        }
      }}
    />
  );
};

export default ColorPicker;
