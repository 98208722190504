/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequirementListing } from '../models/RequirementListing';
import type { RequirementRetrieval } from '../models/RequirementRetrieval';
import { request as __request } from '../core/request';

export class RequirementsService {

    /**
     * List requirements
     * @returns any
     * @throws ApiError
     */
    public static async listRequirements({
        set,
        identifier,
        uuid,
        providesGuidance,
        page,
        pageSize,
    }: {
        set?: string,
        identifier?: string,
        uuid?: Array<string>,
        providesGuidance?: boolean,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<RequirementListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/requirements/`,
            query: {
                'set': set,
                'identifier': identifier,
                'uuid': uuid,
                'provides_guidance': providesGuidance,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single requirement
     * @returns RequirementRetrieval
     * @throws ApiError
     */
    public static async getRequirement({
        uuid,
    }: {
        /** A UUID string identifying this requirement. **/
        uuid: string,
    }): Promise<RequirementRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/requirements/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}