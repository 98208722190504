import React, { useEffect } from 'react';
import { Descriptions, Typography, List, Skeleton } from 'antd';
import { Link, Switch } from 'react-router-dom';
import { orderBy } from 'lodash';

import Header from 'components/header';
import DateTooltip, { formatDate } from 'components/date';

import { url, useParams, RouteWithSubRoutes, RouteInterface } from 'routes';

import { useEntity, useDispatch, useEntities } from 'store/hooks';
import { list, detail } from 'store/common';

const { Title } = Typography;

interface Props {
  routes: RouteInterface[];
}

const Detail: React.FC<Props> = ({ routes }) => {
  const { evidenceId } = useParams('evidence');
  const dispatch = useDispatch();
  const [evidence, { loadingDetail }] = useEntity(
    'evidence',
    evidenceId as string
  );
  const [collectionRuns, loadingCollectionRuns] = useEntities(
    'collectionRuns',
    evidenceId as string
  );

  useEffect(() => {
    if (!evidenceId) {
      return;
    }

    dispatch(
      detail({
        resolver: 'evidence',
        uuid: evidenceId,
      })
    );

    dispatch(
      list({
        resolver: 'collectionRuns',
        paginationKey: evidenceId,
        extra: {
          evidence: evidenceId,
        },
      })
    );
  }, [evidenceId, dispatch]);

  return (
    <div>
      <Header documentTitle={`${evidence.title} - Evidence`} />
      <Title level={2}>
        {evidence.title || (
          <Skeleton paragraph={{ rows: 1, className: 'mb-0' }} title={false} />
        )}
      </Title>
      <Descriptions
        className="mb-3"
        labelStyle={{ width: 200, verticalAlign: 'top' }}
        column={1}
        bordered>
        <Descriptions.Item label="Owner">
          {evidence.owner?.name || (
            <Skeleton
              paragraph={{ rows: 1, className: 'mb-0' }}
              title={false}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Classification">
          {evidence.classification?.label || (
            <Skeleton
              paragraph={{ rows: 1, className: 'mb-0' }}
              title={false}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {!evidence.description && loadingDetail.isFetching ? (
            <Skeleton
              paragraph={{ rows: 1, className: 'mb-0' }}
              title={false}
            />
          ) : (
            evidence.description
          )}
        </Descriptions.Item>
      </Descriptions>
      <Title level={3}>Collection runs</Title>
      <List
        bordered
        loading={
          loadingCollectionRuns.isFetching && !collectionRuns.length
            ? { style: { height: 166 } }
            : false
        }
        className="mb-2"
        dataSource={orderBy(collectionRuns, ['collected_at'], ['desc'])}
        renderItem={run => (
          <List.Item>
            {run.collected_at ? (
              <Link
                to={url('evidence:collection-run', {
                  evidenceId: evidence.uuid,
                  collectionRunId: run.uuid,
                })}>
                <DateTooltip date={run.collected_at}>
                  {formatDate(run.collected_at)}
                </DateTooltip>
              </Link>
            ) : null}
          </List.Item>
        )}
      />
      <Link
        className="ant-btn ant-btn-primary"
        to={url('evidence:collection-run:create', {
          evidenceId: evidence.uuid,
        })}>
        Create collection run
      </Link>
      <Switch>
        {routes.map(r => (
          <RouteWithSubRoutes key={r.name} {...r} />
        ))}
      </Switch>
    </div>
  );
};

export default Detail;
