/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleCreation } from '../models/RoleCreation';
import type { RoleListing } from '../models/RoleListing';
import type { RoleRetrieval } from '../models/RoleRetrieval';
import type { RoleUpdate } from '../models/RoleUpdate';
import { request as __request } from '../core/request';

export class RolesService {

    /**
     * List roles
     * @returns any
     * @throws ApiError
     */
    public static async listRoles({
        slug,
        page,
        pageSize,
    }: {
        slug?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<RoleListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/roles/`,
            query: {
                'slug': slug,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new role object
     * @returns RoleCreation
     * @throws ApiError
     */
    public static async createRole({
        data,
    }: {
        data: RoleCreation,
    }): Promise<RoleCreation> {
        const result = await __request({
            method: 'POST',
            path: `/roles/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single role
     * @returns RoleRetrieval
     * @throws ApiError
     */
    public static async getRole({
        uuid,
    }: {
        /** A UUID string identifying this role. **/
        uuid: string,
    }): Promise<RoleRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/roles/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified role
     * @returns RoleUpdate
     * @throws ApiError
     */
    public static async updateRole({
        uuid,
        data,
    }: {
        /** A UUID string identifying this role. **/
        uuid: string,
        data: RoleUpdate,
    }): Promise<RoleUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/roles/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified role
     * @returns RoleUpdate
     * @throws ApiError
     */
    public static async partialUpdateRole({
        uuid,
        data,
    }: {
        /** A UUID string identifying this role. **/
        uuid: string,
        data: RoleUpdate,
    }): Promise<RoleUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/roles/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified role
     * @returns void
     * @throws ApiError
     */
    public static async destroyRole({
        uuid,
    }: {
        /** A UUID string identifying this role. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/roles/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}