import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

interface Props {
  hasModifiedFields: boolean;
}

const FormChangePrompt: React.FC<Props> = ({ hasModifiedFields }) => {
  useEffect(() => {
    if (hasModifiedFields) {
      window.onbeforeunload = (e: any) => {
        const confirmationMessage =
          'This message is not going to be shown anyway';

        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      };
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [hasModifiedFields]);

  return (
    <Prompt
      when={hasModifiedFields}
      message={() => 'Are you sure?\n\nChanges that you made may not be saved.'}
    />
  );
};

export default FormChangePrompt;
