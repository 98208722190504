import React, { useState, useRef, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button, Row, Input, message } from 'antd';

import FormChangePrompt from 'components/form-change-prompt';

import { uploadFile } from 'store/api';

import { getErrorMessage } from 'utils';
import { AttachmentsService } from 'api';

const { TextArea } = Input;
const { Dragger } = Upload;

interface Props {
  title: string;
  content: string;
  onSave: ({ title, content }: { title: string; content: string }) => void;
  titleEditingDisabled?: boolean;
  isSaving: boolean;
  titlePrefix?: string;
  upload?: boolean;
}

const EditTitleAndContent: React.FC<Props> = ({
  title: initialTitle,
  titleEditingDisabled = false,
  content: initialContent,
  onSave,
  isSaving,
  titlePrefix = null,
  children,
  upload = false,
}) => {
  const textAreaRef = useRef<any>();
  const [content, setContent] = useState<string>(initialContent || '');
  const [title, setTitle] = useState<string>(initialTitle || '');
  const isSaveEnabled = content !== initialContent || title !== initialTitle;

  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle]);

  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  useEffect(() => {
    textAreaRef.current?.focus({
      cursor: 'end',
    });
  }, []);

  const textarea = (
    <TextArea
      ref={textAreaRef}
      className="monospace"
      placeholder="Content goes here"
      onClick={e => e.stopPropagation()}
      onChange={e => {
        e.stopPropagation();
        setContent(e.target.value);
      }}
      onKeyUp={e => {
        e.stopPropagation();
        if (e.ctrlKey && e.key === 'Enter' && isSaveEnabled) {
          onSave({ title, content });
        }
      }}
      value={content}
      autoSize={{
        minRows: 2,
        maxRows: Math.floor(window.innerHeight - 92 - 150) / 22,
      }}
    />
  );

  const uploadFn = async ({ file, onError, onSuccess }: any) => {
    try {
      const response = await uploadFile({
        data: file as any,
        headers: {
          'Content-Disposition': `attachment; filename=${(file as any).name}`,
        },
      });

      (onSuccess as any)();
      const r = await AttachmentsService.createAttachment({
        data: {
          file: response.uuid as string,
          name: response.filename as string,
          custom_metadata: {},
        },
      });

      const fileFormat = (r.name.match(/\.[a-zA-Z0-9]+$/) || [])[0];

      const imageTag = `.. image:: ${r.uuid}${fileFormat}`;
      const textArea = textAreaRef.current?.resizableTextArea?.textArea;
      const cursorPosition = textArea.selectionStart;

      setContent(content => {
        if (!cursorPosition) {
          return `${content}${imageTag}`;
        }
        const textBeforeCursorPosition = content.substring(0, cursorPosition);
        const textAfterCursorPosition = content.substring(
          cursorPosition,
          content.length
        );

        return `${textBeforeCursorPosition}${imageTag}${textAfterCursorPosition}`;
      });
    } catch (e) {
      message.error(getErrorMessage(e));
      (onError as any)();
    }
  };

  return (
    <>
      <FormChangePrompt hasModifiedFields={isSaveEnabled} />
      <div className="section__heading">
        <Input
          className="monospace"
          prefix={titlePrefix}
          disabled={titleEditingDisabled}
          value={title}
          onChange={e => setTitle(e.target.value)}
          onKeyUp={e => {
            if (e.ctrlKey && e.key === 'Enter' && isSaveEnabled) {
              onSave({ title, content });
            }
          }}
        />
        {children}
      </div>
      {upload ? (
        <>
          <Dragger
            openFileDialogOnClick={false}
            multiple={true}
            customRequest={uploadFn}
            itemRender={() => null}>
            {textarea}
          </Dragger>
          <Upload
            multiple={true}
            customRequest={uploadFn}
            itemRender={() => null}>
            <Button
              title="Upload image"
              style={{
                position: 'absolute',
                bottom: 0,
                right: isSaving ? 96 : 76,
              }}>
              <UploadOutlined />
            </Button>
          </Upload>
        </>
      ) : (
        textarea
      )}
      <Row justify="space-between" style={{ marginTop: upload ? -5 : 15 }}>
        <Button
          disabled={!isSaveEnabled}
          onClick={() => {
            setTitle(initialTitle || '');
            setContent(initialContent || '');
          }}>
          Discard changes
        </Button>
        <Button
          style={{ marginLeft: 'auto' }}
          loading={isSaving}
          onClick={() => onSave({ title, content })}
          type="primary"
          disabled={!isSaveEnabled}>
          Save
        </Button>
      </Row>
    </>
  );
};

export default EditTitleAndContent;
