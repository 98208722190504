import React from 'react';
import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/acronyms/form';

import { useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';

const Detail: React.FC = () => {
  const { acronymId } = useParams('acronym') as {
    acronymId: string;
  };
  const entity = useSelector(state =>
    selectEntity(state, 'acronym', acronymId)
  );

  const initialValues = {
    slug: entity.slug,
    acronym: entity.acronym,
    acronym_plural: entity.acronym_plural,
    extended: entity.extended,
    extended_plural: entity.extended_plural,
    definition: entity.definition?.uuid,
  };

  return (
    <>
      <GenericDetail
        resolver="acronym"
        listRoute="acronyms"
        detailIdParam="acronymId"
        listTitle="Acronyms"
        createRoute="acronyms:create"
        listItemTitle={entity => entity.acronym}
        itemName="acronym">
        {props => (
          <>
            <EditForm initialValues={initialValues} {...props} />
          </>
        )}
      </GenericDetail>
    </>
  );
};

export default Detail;
