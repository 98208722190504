/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ChangelogEntry = {
    ticket_id: string;
    change_type: ChangelogEntry.change_type;
    message: string;
}

export namespace ChangelogEntry {

    export enum change_type {
        CHANGE = 'change',
        QA = 'qa',
        REVIEW = 'review',
    }


}
