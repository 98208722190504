import React from 'react';
import { Helmet } from 'react-helmet-async';
import { includes } from 'lodash';
import classNames from 'classnames';
import { Button, Layout, Menu, Space } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { CaretDownOutlined, ExportOutlined } from '@ant-design/icons';
import { DJANGO_ADMIN_URL } from 'config';

import Logo from 'components/logo';

import { url } from 'routes';

import { useDispatch } from 'store/hooks';
import { logout } from 'store/user';

import './header.scss';

const { Header } = Layout;

interface Props {
  documentTitle?: string;
}

export const BaseHeader: React.FC<Props> = ({ documentTitle, children }) => {
  return (
    <>
      <Helmet
        defer={false}
        title={documentTitle}
        titleTemplate="%s | Compliance manager"
        defaultTitle="Compliance manager"
      />
        <Header className="header">{children}</Header>
    </>
  );
};

const MenuTitle: React.FC<{ subRoutes: string[]; route: string }> = ({
  children,
  subRoutes,
  route,
}) => {
  return (
    <span
      className={classNames('ant-menu-item pl-0 pr-0 ml-0 mr-0', {
        'ant-menu-item-selected selected': includes(subRoutes, route),
      })}>
      {children}
      <CaretDownOutlined className="mr-0" />
    </span>
  );
};

const PageHeader: React.FC<Props> = ({ documentTitle, children }) => {
  const location = useLocation();
  const route = location.pathname.substr(1).split('/')[0];
  const dispatch = useDispatch();

  return (
    <BaseHeader documentTitle={documentTitle}>
      <div className="header__inner">
        <div className="header__title">
          <h1 className="header__logo">
            <Logo height={15} />
            <span className="header__logo-text">Compliance manager</span>
          </h1>
        </div>
      <div>
        <Menu
          selectedKeys={[route]}
          mode="horizontal"
          style={{ marginRight: 'auto' }}>
          <Menu.SubMenu
            key="assets"
            title={
              <MenuTitle
                route={route}
                subRoutes={[
                  'assets',
                  'asset-classes',
                  'asset-classifications',
                  'asset-types',
                ]}>
                <NavLink className="mr-1" key="assets" to={url('assets')}>
                  Assets
                </NavLink>
              </MenuTitle>
            }>
            <Menu.Item key="asset-classes">
              <NavLink to={url('asset-classes')}>Asset classes</NavLink>
            </Menu.Item>
            <Menu.Item key="asset-classifications">
              <NavLink to={url('asset-classifications')}>
                Asset classifications
              </NavLink>
            </Menu.Item>
            <Menu.Item key="asset-types">
              <NavLink to={url('asset-types')}>Asset types</NavLink>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="risks"
            title={
              <MenuTitle
                route={route}
                subRoutes={[
                  'risks',
                  'risk-treatment-actions',
                  'vulnerability-classes',
                  'threat-classes',
                ]}>
                <NavLink className="mr-1" key="risks" to={url('risks')}>
                  Risks
                </NavLink>
              </MenuTitle>
            }>
            <Menu.Item key="risk-treatment-actions">
              <NavLink to={url('risk-treatment-actions')}>
                Risk Treatment Actions
              </NavLink>
            </Menu.Item>
            <Menu.Item key="vulnerability-classes">
              <NavLink to={url('vulnerability-classes')}>
                Vulnerability Classes
              </NavLink>
            </Menu.Item>
            <Menu.Item key="threat-classes">
              <NavLink to={url('threat-classes')}>Threat Classes</NavLink>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="policies">
            <NavLink to={url('policies')}>Policies</NavLink>
          </Menu.Item>
          <Menu.Item key="procedures">
            <NavLink to={url('procedures')}>Procedures</NavLink>
          </Menu.Item>
          <Menu.Item key="evidence">
            <NavLink to={url('evidences')}>Evidence</NavLink>
          </Menu.Item>
          <Menu.SubMenu
            key="more"
            title={
              <Space>
                <span>More</span>
                <CaretDownOutlined className="mr-0" />
              </Space>
            }>
            <Menu.Item key="references">
              <NavLink to={url('references')}>References</NavLink>
            </Menu.Item>
            <Menu.Item key="activities">
              <NavLink to={url('activities')}>Activities</NavLink>
            </Menu.Item>
            <Menu.Item key="acronyms">
              <NavLink to={url('acronyms')}>Acronyms</NavLink>
            </Menu.Item>
            <Menu.Item key="definitions">
              <NavLink to={url('definitions')}>Definitions</NavLink>
            </Menu.Item>
            <Menu.Item key="labels">
              <NavLink to={url('labels')}>Labels</NavLink>
            </Menu.Item>
            <Menu.Item key="roles">
              <NavLink to={url('roles')}>Roles</NavLink>
            </Menu.Item>
            <Menu.Item key="locations">
              <NavLink to={url('locations')}>Locations</NavLink>
            </Menu.Item>
            <Menu.Item key="schedules">
              <NavLink to={url('schedules')}>Schedules</NavLink>
            </Menu.Item>
            <Menu.Item key="violations">
              <NavLink to={url('violations')}>Violations</NavLink>
            </Menu.Item>
            <Menu.Item key="users">
              <NavLink to={url('users')}>Users</NavLink>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="admin" danger={true} icon={<ExportOutlined />}>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={DJANGO_ADMIN_URL}>
                Admin
              </a>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
      </div>
      {children}
      <Button type="primary" onClick={() => dispatch(logout())}>
        Logout
      </Button>
    </BaseHeader>
  );
};

export default PageHeader;
