import React, { useState } from 'react';
import { Modal } from 'antd';
import { RiskCreation } from 'api';
import { useHistory } from 'react-router';

import { url } from 'routes';
import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/risks/form';

const Create: React.FC = () => {
  const initialValues = {
    title: '',
    owner: null,
    // requirements: [],
    threat_class: null,
    vulnerability_class: null,
    impact_description: '',
    initial_probability_rating: RiskCreation.initial_probability_rating._0,
    initial_impact_rating: RiskCreation.initial_impact_rating._0,
    current_probability_rating: RiskCreation.current_probability_rating._0,
    current_impact_rating: RiskCreation.current_impact_rating._0,
    treatment_description: '',
    treatment_type: RiskCreation.treatment_type.UNKNOWN,
    residual_probability_rating: RiskCreation.residual_probability_rating._0,
    residual_impact_rating: RiskCreation.residual_impact_rating._0,
    notes: '',
    asset_classes: [],
    affected_parties: [],
  };

  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const onClose = () => setVisible(false);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="80vw"
      style={{
        maxWidth: 900,
      }}
      forceRender={true}
      footer={null}
      afterClose={() => {
        history.push(url('risks'));
      }}>
      <GenericCreate
        resolver="risk"
        listResolver="risks"
        createRoute="risks:create"
        listRoute="risks"
        showHeader={false}
        detailRoute="risk"
        detailIdParam="riskId"
        listTitle="Risks"
        listItemTitle={entity => `${entity.human_id}. ${entity.title}`}
        itemName="risk">
        {props => <EditForm initialValues={initialValues} {...props} />}
      </GenericCreate>
    </Modal>
  );
};

export default Create;
