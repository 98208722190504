import { put, call } from 'redux-saga/effects';
import { message } from 'antd';
import { push } from 'connected-react-router';
import * as ls from 'local-storage';
import * as Sentry from '@sentry/react';

import { url } from 'routes';

import { persistor } from 'store';
import { loginSuccess, LoginAction } from 'store/user';

import { JwtService } from 'api';

export const logoutWorker = function* () {
  if (persistor) {
    yield call(persistor.pause);
    yield call(persistor.flush);
    yield call(persistor.purge);
    localStorage.clear();
  }

  Sentry.configureScope(scope => scope.setUser(null));

  yield put(push(url('login', undefined)));
};

export const loginWorker = function* (action: LoginAction) {
  try {
    // TODO reenable this once origin and headers get sorted out
    // OpenAPI.WITH_CREDENTIALS = true;

    const res: {
      access: string;
      refresh: string;
    } = yield call([JwtService, 'jwtTokenCreate'], {
      data: action.payload,
    });

    ls.set('auth', {
      accessToken: res.access,
      refreshToken: res.refresh,
      tokenRefreshedAt: Date.now(),
    });
    yield put(loginSuccess());
    Sentry.setUser({ username: action.payload.username });

    yield put(push(url('overview', undefined)));
  } catch (e) {
    message.error(e.body?.detail || e.message);
  }
};
