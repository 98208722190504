import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/violations/form';

const Create: React.FC = () => {
  const initialValues = {
    label: '',
    explanation: '',
  };

  return (
    <GenericCreate
      resolver="violation"
      listResolver="violations"
      createRoute="violations:create"
      listRoute="violations"
      detailRoute="violation"
      detailIdParam="violationId"
      listTitle="Violations"
      listItemTitle={entity => entity.label}
      itemName="violation">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
