import React from 'react';
import { Form, Input } from 'antd';
import slugify from 'slugify';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';

type Props = Omit<GenericFormProps, 'renderFields'>;

const { TextArea } = Input;

const EditForm: React.FC<Props> = props => {
  return (
    <GenericForm
      {...props}
      renderFields={({ form }) => (
        <>
          <Form.Item required label="Name" name="name">
            <Input className="monospace" />
          </Form.Item>
          <Form.Item
            required
            label="Slug"
            name="slug"
            dependencies={['name']}
            shouldUpdate={(prevValues, curValues) => {
              if (prevValues.name !== curValues.name) {
                form.setFieldsValue({
                  ...curValues,
                  slug: slugify(curValues.name).toLowerCase(),
                });
              }

              return prevValues.name !== curValues.name;
            }}>
            <Input readOnly className="monospace" />
          </Form.Item>
          <Form.Item required label="Explanation" name="explanation">
            <TextArea className="monospace" autoSize />
          </Form.Item>
        </>
      )}
    />
  );
};

export default EditForm;
