import React from 'react';
import { Action } from 'redux';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { capitalize } from 'lodash';
import { ArrowLeftOutlined } from '@ant-design/icons';

import Header from 'components/header';

import { url, urlPattern, RouteArgs } from 'routes';

import { useDispatch, useSelector } from 'store/hooks';
import { create, list, DetailResolver, ListResolver } from 'store/common';
import { selectLoadingCreate } from 'store/loading';

const { Title } = Typography;

interface Props {
  resolver: DetailResolver;
  listRoute: keyof RouteArgs;
  detailRoute: keyof RouteArgs;
  createRoute: keyof RouteArgs;
  itemName: string;
  listTitle: string;
  detailIdParam: string;
  listItemTitle: (x: any) => string;
  listResolver: ListResolver;
  children: (props: any) => React.ReactNode;
  showHeader?: boolean;
  backTo?: string;
  successRedirect?: string;
  successActions?: (Action | ((uuid: string) => Action))[];
}

const Create: React.FC<Props> = ({
  listResolver,
  detailIdParam,
  detailRoute,
  children,
  resolver,
  itemName,
  createRoute,
  showHeader = true,
  backTo,
  successActions = [],
  successRedirect = urlPattern(detailRoute).replace(
    `:${detailIdParam}`,
    ':uuid'
  ),
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(state =>
    selectLoadingCreate(state, resolver as any)
  );

  return (
    <>
      {showHeader ? (
        <Header documentTitle={`Create ${itemName}`}>
          <Link className="ant-btn" to={url(createRoute, undefined)}>
            Create {itemName}
          </Link>
        </Header>
      ) : null}
      <Title level={2}>
        {backTo ? (
          <Link className="header__back" to={backTo}>
            <ArrowLeftOutlined />
          </Link>
        ) : null}
        Create {itemName}
      </Title>
      {children({
        hasReset: false,
        preventLeaving: false,
        loading: loading.isFetching,
        onSubmit: (values: any) =>
          dispatch(
            create({
              resolver: resolver as any,
              loadingKey: 'all',
              data: values,
              successMessage: `${capitalize(itemName)} created.`,
              redirectUrl: successRedirect,
              successActions: [
                list({
                  resolver: listResolver,
                  paginationKey: 'all',
                  fetchOnlyOnePage: true,
                }),
                ...successActions,
              ],
            })
          ),
      })}
    </>
  );
};

export default Create;
