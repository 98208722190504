import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import ContentWithAttachments from 'components/attachments';
import Tag from 'components/tag';

import { url, useParams } from 'routes';

import { DenormalizedSection } from 'store/data';

import './section.scss';

const { Title } = Typography;

interface OwnProps {
  section: DenormalizedSection;
}

const Section: React.FC<OwnProps> = ({ section }) => {
  const headerLevel = Math.min(
    ((section.numbering as string).match(/\./g) || []).length + 1,
    5
  );
  const { policyId } = useParams('policy');

  return (
    <Link
      className="section section__edit"
      to={
        url('section', {
          policyId: policyId as string,
          sectionId: section.uuid,
        }) +
        '#' +
        section.uuid
      }>
      <Title level={headerLevel as any}>
        <span className="section__heading monospace">
          {section.numbering}. {section.title}
        </span>
      </Title>
      <div className="monospace">
        {section.content ? (
          <ContentWithAttachments content={section.content} id={section.uuid} />
        ) : (
          <i>No content</i>
        )}
      </div>
      {section.coverages?.length ? (
        <div className="monospace" style={{ marginTop: 10 }}>
          {section.coverages.map(coverage => (
            <Tag
              color={coverage.requirement_set?.background_color}
              key={coverage.uuid}>
              {coverage.requirement_identifier}
            </Tag>
          ))}
        </div>
      ) : null}
    </Link>
  );
};

export default Section;
