/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SectionRequirementCoverageUpdate = {
    coverage?: SectionRequirementCoverageUpdate.coverage;
    gap_description?: string;
}

export namespace SectionRequirementCoverageUpdate {

    export enum coverage {
        UNKNOWN = 'unknown',
        NONE = 'none',
        PARTIAL = 'partial',
        FULL = 'full',
    }


}
