import React from 'react';
import { Select, Row, Col } from 'antd';
import { uniqBy } from 'lodash';

import Tag from 'components/tag';

import { selectEntities, selectEntity } from 'store/data';
import { useSelector } from 'store/hooks';

const { Option } = Select;

const RequirementsSelect: React.FC<{
  query: string;
  onSearch: any;
  value: any;
  multiple?: boolean;
  set: any;
  onChange: any;
  providesGuidance?: boolean;
}> = ({
  query,
  onSearch,
  value,
  onChange,
  multiple = false,
  set,
  providesGuidance = false,
}) => {
  const reqQuerySet = query || set;
  const selected = useSelector(state =>
    selectEntity(state, 'requirement', value)
  );
  const requirements = useSelector(state =>
    selectEntities(state, 'requirements', reqQuerySet)
  );

  const choices =
    selected && !multiple
      ? uniqBy([selected, ...requirements], 'uuid')
      : uniqBy(requirements, 'uuid');

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      onSearch={onSearch}
      value={value}
      mode={multiple ? 'multiple' : undefined}
      searchValue={query}
      filterOption={false}
      notFoundContent={null}
      optionLabelProp="label"
      onChange={onChange}>
      {choices.map(r => (
        <Option
          key={r.uuid}
          label={r.identifier}
          value={r.uuid}
          disabled={providesGuidance && !r.provides_guidance}>
          <Row justify="space-between">
            <Col>
              <Row aria-label={r.identifier}>
                {r.identifier}{' '}
                {providesGuidance && !r.provides_guidance
                  ? '(does not provide guidance)'
                  : null}
              </Row>
              <Row>
                <span style={{ color: 'rgba(0,0,0,0.4)' }}>{r.title}</span>
              </Row>
            </Col>
            <Col>
              <Tag color={r.set?.background_color}>{r.type}</Tag>
            </Col>
          </Row>
        </Option>
      ))}
    </Select>
  );
};

export default RequirementsSelect;
