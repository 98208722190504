/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequirementSetListing } from '../models/RequirementSetListing';
import type { RequirementSetRetrieval } from '../models/RequirementSetRetrieval';
import { request as __request } from '../core/request';

export class RequirementSetsService {

    /**
     * List requirement sets
     * @returns any
     * @throws ApiError
     */
    public static async listRequirementSets({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<RequirementSetListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/requirement-sets/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single requirement set
     * @returns RequirementSetRetrieval
     * @throws ApiError
     */
    public static async getRequirementSet({
        uuid,
    }: {
        /** A UUID string identifying this requirement set. **/
        uuid: string,
    }): Promise<RequirementSetRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/requirement-sets/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}