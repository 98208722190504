import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Form, Button } from 'antd';

import FormChangePrompt from 'components/form-change-prompt';
import FormFooter from 'components/form-footer';

import { cleanupFieldValues } from 'utils';

export interface Props<
  Values extends Record<
    string,
    null | boolean | string | number | undefined
  > = any
> {
  initialValues: Values;
  loading?: boolean;
  onSubmit: (values: any) => void;
  hasReset?: boolean;
  preventLeaving?: boolean;
  entityId?: string;
  renderFields: ({
    fields,
    form,
    onFieldsChange,
  }: {
    fields: any;
    form: any;
    onFieldsChange: () => void;
  }) => React.ReactNode;
}

const EditForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  loading,
  hasReset = true,
  preventLeaving = true,
  children,
  renderFields,
}) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState<any>();
  const hasModifiedFields = fields
    ? !isEqual(cleanupFieldValues(fields), cleanupFieldValues(initialValues))
    : false;
  const key = JSON.stringify(initialValues);

  useEffect(() => {
    form.resetFields();
    setFields(undefined);
  }, [key, form]);

  return (
    <>
      {preventLeaving ? (
        <FormChangePrompt hasModifiedFields={hasModifiedFields} />
      ) : null}
      <Form
        key={key}
        layout="vertical"
        onFieldsChange={() => {
          setFields(form.getFieldsValue(true));
        }}
        initialValues={initialValues}
        form={form}
        onFinish={(values: Partial<any>) => {
          onSubmit(values);
        }}>
        {renderFields({
          fields,
          form,
          onFieldsChange: () => setFields(form.getFieldsValue(true)),
        })}
        <FormFooter>
          <Button
            loading={loading}
            type="primary"
            disabled={!hasModifiedFields}
            htmlType="submit">
            Submit
          </Button>
          {hasReset ? (
            <Button
              onClick={() => {
                setFields(undefined);
                form.resetFields();
              }}>
              Reset
            </Button>
          ) : null}
          {children}
        </FormFooter>
      </Form>
    </>
  );
};

export default EditForm;
