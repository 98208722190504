/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PolicyVersionListing } from '../models/PolicyVersionListing';
import type { PolicyVersionPromotion } from '../models/PolicyVersionPromotion';
import type { PolicyVersionRequestApproval } from '../models/PolicyVersionRequestApproval';
import type { PolicyVersionRetrieval } from '../models/PolicyVersionRetrieval';
import type { ZohoCompletionWebhook } from '../models/ZohoCompletionWebhook';
import { request as __request } from '../core/request';

export class PolicyVersionsService {

    /**
     * List policy versions
     * @returns any
     * @throws ApiError
     */
    public static async listPolicyVersions({
        policy,
        page,
        pageSize,
    }: {
        policy?: string,
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<PolicyVersionListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/policy-versions/`,
            query: {
                'policy': policy,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Handler for webhooks sent by Zoho upon document signing completion.
     * @returns ZohoCompletionWebhook
     * @throws ApiError
     */
    public static async zohoCompletionPolicyVersions({
        data,
    }: {
        data: ZohoCompletionWebhook,
    }): Promise<ZohoCompletionWebhook> {
        const result = await __request({
            method: 'POST',
            path: `/policy-versions/zoho-completion/`,
            body: data,
        });
        return result.body;
    }

    /**
     * Get the details of a single policy version
     * @returns PolicyVersionRetrieval
     * @throws ApiError
     */
    public static async getPolicyVersion({
        uuid,
    }: {
        /** A UUID string identifying this policy version. **/
        uuid: string,
    }): Promise<PolicyVersionRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/policy-versions/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicyVersionPromotion
     * @throws ApiError
     */
    public static async promotePolicyVersion({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy version. **/
        uuid: string,
        data: PolicyVersionPromotion,
    }): Promise<PolicyVersionPromotion> {
        const result = await __request({
            method: 'POST',
            path: `/policy-versions/${uuid}/promote/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * @returns PolicyVersionRequestApproval
     * @throws ApiError
     */
    public static async requestApprovalPolicyVersion({
        uuid,
        data,
    }: {
        /** A UUID string identifying this policy version. **/
        uuid: string,
        data: PolicyVersionRequestApproval,
    }): Promise<PolicyVersionRequestApproval> {
        const result = await __request({
            method: 'POST',
            path: `/policy-versions/${uuid}/request_approval/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}