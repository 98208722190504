import { isArray, isString, fromPairs, some } from 'lodash';

import { AppStateInterface } from 'store';
import {
  create,
  list,
  detail,
  destroy,
  CreateResolver,
  ListResolver,
  DetailResolver,
  DestroyResolver,
  update,
  UpdateResolver,
} from 'store/common';

interface LoadingArgs {
  types: string[];
  mapActionToKey: (a: any) => string | string[];
}

export interface LoadingItemState {
  isFetching: boolean;
  error: string | null;
}

interface LoadingState {
  [key: string]: LoadingItemState;
}

const defaultState = { isFetching: false, error: null };
Object.freeze(defaultState);

const baseSelectLoading = (
  state: AppStateInterface,
  actionType: keyof AppStateInterface['loading'],
  key?: string
) => {
  if (!key) {
    return defaultState;
  }

  const loadingStates = state.loading[actionType];

  if (!loadingStates) {
    return defaultState;
  }

  return loadingStates[key] || defaultState;
};

export const selectLoading = (
  state: AppStateInterface,
  actionType: keyof AppStateInterface['loading'],
  key?: string,
  field?: string
) =>
  baseSelectLoading(state, actionType, key && field ? `${key}_${field}` : key);

export const selectLoadingDetail = (
  state: AppStateInterface,
  resolver: DetailResolver,
  key?: string
) => selectLoading(state, detail.TYPE, resolver, key);

export const selectLoadingList = (
  state: AppStateInterface,
  resolver: ListResolver,
  key?: string
) => selectLoading(state, list.TYPE, resolver, key);

export const selectLoadingCreate = (
  state: AppStateInterface,
  resolver: CreateResolver,
  key?: string
) => selectLoading(state, create.TYPE, resolver, key);

export const selectLoadingDestroy = (
  state: AppStateInterface,
  resolver: DestroyResolver,
  key?: string
) => selectLoading(state, destroy.TYPE, resolver, key);

export const selectLoadingUpdate = (
  state: AppStateInterface,
  resolver: UpdateResolver,
  key?: string,
  field?: string
) => selectLoading(state, update.TYPE, `${resolver}_${key}`, field);

/**
 * In a situation when one would can have multiple different update actions
 * based on which fields are being saved this can consistently create
 * the loading keys so you can track saving separate fields via separate requests
 */
export const mapUpdateActionToKey = (loadingKey: string, fields: string[]) => [
  loadingKey,
  ...fields.map(f => `${loadingKey}_${f}`),
];

// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.
const loading = ({ types, mapActionToKey }: LoadingArgs) => {
  const [requestType, successType, failureType] = types;

  const updateLoading = (
    state: {
      isFetching: boolean;
      error: string | null;
    } = {
      isFetching: false,
      error: null,
    },
    action: any
  ) => {
    switch (action.type) {
      case requestType:
        return {
          ...state,
          isFetching: true,
          error: null,
        };
      case successType:
        return {
          ...state,
          isFetching: false,
          error: null,
        };
      case failureType:
        return {
          ...state,
          isFetching: false,
          error: action.payload.error,
        };
      // istanbul ignore next
      default:
        return state;
    }
  };

  return (state: LoadingState = {}, action: any) => {
    // Update pagination by key
    switch (action.type) {
      case requestType:
      case successType:
      case failureType: {
        const key = mapActionToKey(action);

        if (
          typeof key === 'undefined' ||
          (typeof key !== 'string' &&
            !isArray(key) &&
            some(key, ms => !isString(ms)))
        ) {
          throw new Error(
            `Expected key to be a string or an array of strings, got ${typeof key}: ${key} via ${
              action.type
            }`
          );
        }

        if (typeof key === 'string') {
          return {
            ...state,
            [key]: updateLoading(state[key], action),
          };
        } else {
          return {
            ...state,
            ...fromPairs(
              (key as string[]).map(subkey => [
                subkey,
                updateLoading(state[subkey], action),
              ])
            ),
          };
        }
      }

      default: {
        return state;
      }
    }
  };
};

export default loading;
