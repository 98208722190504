import React from 'react';
import { AssetClassCreation } from 'api';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/asset-classes/form';

const Create: React.FC = () => {
  const initialValues = {
    name: '',
    slug: '',
    description: '',
    confidentiality_rating: AssetClassCreation.confidentiality_rating._0,
    integrity_rating: AssetClassCreation.integrity_rating._0,
    availability_rating: AssetClassCreation.availability_rating._0,
    asset_type: null,
  };

  return (
    <GenericCreate
      resolver="assetClass"
      listResolver="assetClasses"
      createRoute="asset-classes:create"
      listRoute="asset-classes"
      detailRoute="asset-class"
      detailIdParam="assetClassId"
      listTitle="Asset classes"
      listItemTitle={entity => entity.name}
      itemName="asset class">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
