import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as ls from 'local-storage';

import { url } from 'routes';

interface OwnProps {
  render: any;
  [key: string]: any;
}

type Props = OwnProps;

const PrivateRoute = React.memo(({ render, ...rest }: Props): JSX.Element => {
  // FIXME this happens too many times
  const auth: any = ls.get('auth');

  const isAuthenticated = !!auth?.accessToken;

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isAuthenticated === true ? (
          render(props)
        ) : (
          <Redirect strict to={url('login', undefined)} />
        )
      }
    />
  );
});

export default PrivateRoute;
