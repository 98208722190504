import React from 'react';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/acronyms/form';

const Create: React.FC = () => {
  const initialValues = {
    slug: '',
    acronym: '',
    acronym_plural: '',
    extended: '',
    extended_plural: '',
    definition: null,
  };

  return (
    <GenericCreate
      resolver="acronym"
      listResolver="acronyms"
      createRoute="acronyms:create"
      listRoute="acronyms"
      detailRoute="acronym"
      detailIdParam="acronymId"
      listTitle="Acronyms"
      listItemTitle={entity => entity.acronym}
      itemName="acronym">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
