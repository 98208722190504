import { request } from 'api/core/request';
import { UploadedFileCreation } from 'api';

// reason this has to be custom is because
// the schema is wrong and what is generated by the api
// client is unfortunately unusable
export async function uploadFile({
  data,
  headers,
}: {
  headers: { 'Content-Disposition': string };
  data: File;
}): Promise<UploadedFileCreation> {
  const result = await request({
    method: 'POST',
    path: `/uploaded-files/`,
    body: data,
    headers,
    errors: {
      401: `Unauthorized`,
    },
  });
  return result.body;
}
