import React from 'react';
import { Form, Input, Button, Row } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { login } from 'store/user';
import { useDispatch } from 'store/hooks';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const onFinish = async (values: { username: string; password: string }) => {
    dispatch(login(values));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <HelmetProvider>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Helmet title="Login - Compliance manager" />
        <Form
          {...layout}
          name="basic"
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true }]}>
            <Input autoComplete="username" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true }]}>
            <Input.Password autoComplete="current-password" />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </HelmetProvider>
  );
};

export default Login;
