import React, { useState, useEffect } from 'react';
import {
  Skeleton,
  Button,
  Select,
  Form,
  Input,
  Descriptions,
  Row,
  Col,
  Collapse,
  Popconfirm,
} from 'antd';
import classNames from 'classnames';

import Tag from 'components/tag';

import { useSelector, useDispatch } from 'store/hooks';
import { DenormalizedCoverage } from 'store/data';
import { update, destroy, detail } from 'store/common';
import {
  selectLoadingUpdate,
  selectLoadingDestroy,
  selectLoadingDetail,
} from 'store/loading';

import { SectionRequirementCoverageListing } from 'api';

import { useLoadingFinishedEffect } from 'utils';

import './coverage.scss';

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;

interface Props {
  coverage: DenormalizedCoverage;
  onDelete: () => void;
  expanded?: boolean;
}

const Coverage: React.FC<Props> = ({
  coverage,
  onDelete,
  expanded: initialExpanded = false,
}) => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(initialExpanded);
  const loadingDetail = useSelector(state =>
    selectLoadingDetail(state, 'requirement', coverage.requirement?.uuid)
  );
  const updating = useSelector(state =>
    selectLoadingUpdate(state, 'coverage', coverage.uuid, 'gap_description')
  );

  const destroying = useSelector(state =>
    selectLoadingDestroy(state, 'coverage', coverage.uuid)
  );

  useEffect(() => {
    setExpanded(initialExpanded);
  }, [initialExpanded]);

  useLoadingFinishedEffect(() => {
    onDelete();
  }, destroying);

  useEffect(() => {
    if (expanded && coverage.requirement?.uuid) {
      dispatch(
        detail({
          resolver: 'requirement',
          uuid: coverage.requirement?.uuid,
        })
      );
    }
  }, [expanded, dispatch, coverage.requirement?.uuid]);

  return (
    <Collapse
      onChange={() => setExpanded(!expanded)}
      activeKey={expanded ? [coverage.uuid] : []}>
      <Panel
        className="coverage"
        key={coverage.uuid}
        header={
          <span className="coverage__title">
            <Tag color={coverage.requirement_set?.background_color}>
              {coverage.requirement_identifier}
            </Tag>
            <span className="coverage__requirement">
              {coverage.requirement?.title} (
              {coverage.requirement_set?.short_name})
            </span>
            <span
              className={classNames('coverage__coverage', {
                [`coverage__coverage--${coverage.coverage}`]: coverage.coverage,
              })}>
              {coverage.coverage}
            </span>
          </span>
        }>
        <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
          <Col span={8}>
            <Descriptions size="small" bordered={true} column={1}>
              <Descriptions.Item label="ID">
                {coverage.requirement?.identifier}
              </Descriptions.Item>
              <Descriptions.Item label="Type">
                {coverage.requirement?.type}
              </Descriptions.Item>
              <Descriptions.Item label="Set">
                {coverage.requirement_set?.name}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col
            style={{
              whiteSpace: 'pre-wrap',
            }}
            span={16}>
            {coverage.requirement?.description ? (
              coverage.requirement.description
            ) : loadingDetail.isFetching ? (
              <Skeleton />
            ) : (
              'No description'
            )}
          </Col>
        </Row>

        <Form
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            gap_description: coverage.gap_description || '',
            coverage: coverage.coverage,
          }}
          onFinish={(values: {
            gap_description: string;
            coverage: SectionRequirementCoverageListing['coverage'];
          }) => {
            dispatch(
              update({
                resolver: 'coverage',
                uuid: coverage.uuid,
                patch: values,
              })
            );
          }}>
          <Form.Item name="coverage" label="Coverage">
            <Select>
              {(['unknown', 'full', 'partial', 'none'] as const).map(val => (
                <Option key={val} value={val}>
                  {val}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="gap_description" label="Gap description">
            <TextArea
              className="monospace"
              autoSize={{
                minRows: 2,
                maxRows: 20,
              }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Row justify="space-between">
              <Button
                loading={updating.isFetching}
                type="primary"
                htmlType="submit">
                Submit
              </Button>
              <Popconfirm
                title="Are you sure you want to delete requirement mapping?"
                okButtonProps={{ danger: true }}
                placement="bottomRight"
                onConfirm={() =>
                  dispatch(
                    destroy({
                      resolver: 'coverage',
                      uuid: coverage.uuid,
                    })
                  )
                }
                okText="Yes, delete"
                cancelText="No">
                <Button
                  style={{ marginLeft: 'auto' }}
                  danger
                  loading={destroying.isFetching}>
                  Delete
                </Button>
              </Popconfirm>
            </Row>
          </Form.Item>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default Coverage;
