import { all, spawn, call, take, cancel } from 'redux-saga/effects';
import * as ls from 'local-storage';

import { LOGIN, LOGOUT, LoginAction } from 'store/user';
import { loginWorker, logoutWorker } from 'store/sagas/login';
import commonSagas from 'store/sagas/common';
import dataSagas from 'store/sagas/data';
import errorsSagas from 'store/sagas/errors';

export default function* rootSaga() {
  while (true) {
    const auth: any = ls.get('auth');

    const isAuthenticated = !!auth?.accessToken;

    if (isAuthenticated) {
      const spawns: any[] = yield all(
        [...commonSagas, ...dataSagas, ...errorsSagas].map((s: any) =>
          spawn(function* () {
            yield call(s);
          })
        )
      );

      yield take(LOGOUT);

      yield all(spawns.map(s => cancel(s)));
      yield call(logoutWorker);
    } else {
      const loginAction: LoginAction = yield take(LOGIN);

      yield call(loginWorker, loginAction);
    }
  }
}
