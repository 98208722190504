import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import { compact } from 'lodash';

import { useEntities, useDispatch } from 'store/hooks';
import { list } from 'store/common';

import { replaceString } from 'utils';

const imageTagRegExp =
  /(\.\. (?:image|figure):: (?:.*?\/)?[a-zA-Z0-9]+\.(?:png|jpg|jpeg|webp))/gi;
const uuidRegExp = /([a-zA-Z0-9]+)\.(?:png|jpg|jpeg|webp)$/i;

export const useAttachments = (content: string, paginationKey: string) => {
  const images = content.matchAll(imageTagRegExp);
  const dispatch = useDispatch();

  const arr = images ? Array.from(images) : [];
  const uuids = compact(arr.map(match => match[0].match(uuidRegExp)?.[1]));

  const [attachments] = useEntities('attachments', paginationKey);
  const urls = attachments.reduce(
    (memo, a) => ({ ...memo, [a.uuid]: a.url as string }),
    {} as Record<string, string>
  );

  useEffect(() => {
    if (uuids.length) {
      dispatch(
        list({
          resolver: 'attachments',
          paginationKey,
          extra: {
            uuid: uuids,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [uuids.join('')]);

  return {
    urls,
    attachments,
  };
};

interface Props {
  content: string;
  id: string;
}

const ContentWithAttachments: React.FC<Props> = ({ content, id }) => {
  const { urls } = useAttachments(content, id);

  return (
    <>
      {replaceString(content, imageTagRegExp, match => {
        const id = match.match(uuidRegExp)?.[1];
        const maybeImage = id && urls[id];

        return maybeImage ? (
          <Tooltip
            overlayStyle={{ minWidth: 500, maxWidth: 500 }}
            title={
              <img
                alt=""
                src={
                  (maybeImage.startsWith('/media')
                    ? 'https://compliance.local.aldryn.net'
                    : '') + maybeImage
                }
                style={{ maxWidth: '100%' }}
              />
            }>
            {match}
          </Tooltip>
        ) : (
          match
        );
      })}
    </>
  );
};

export default ContentWithAttachments;
