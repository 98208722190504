/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScheduleCreation } from '../models/ScheduleCreation';
import type { ScheduleListing } from '../models/ScheduleListing';
import type { ScheduleRetrieval } from '../models/ScheduleRetrieval';
import type { ScheduleUpdate } from '../models/ScheduleUpdate';
import { request as __request } from '../core/request';

export class SchedulesService {

    /**
     * List schedules
     * @returns any
     * @throws ApiError
     */
    public static async listSchedules({
        page,
        pageSize,
    }: {
        /** A page number within the paginated result set. **/
        page?: number,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ScheduleListing>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/schedules/`,
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Create a new schedule object
     * @returns ScheduleCreation
     * @throws ApiError
     */
    public static async createSchedule({
        data,
    }: {
        data: ScheduleCreation,
    }): Promise<ScheduleCreation> {
        const result = await __request({
            method: 'POST',
            path: `/schedules/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Get the details of a single schedule
     * @returns ScheduleRetrieval
     * @throws ApiError
     */
    public static async getSchedule({
        uuid,
    }: {
        /** A UUID string identifying this schedule. **/
        uuid: string,
    }): Promise<ScheduleRetrieval> {
        const result = await __request({
            method: 'GET',
            path: `/schedules/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Replace the specified schedule
     * @returns ScheduleUpdate
     * @throws ApiError
     */
    public static async updateSchedule({
        uuid,
        data,
    }: {
        /** A UUID string identifying this schedule. **/
        uuid: string,
        data: ScheduleUpdate,
    }): Promise<ScheduleUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/schedules/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Partially update the specified schedule
     * @returns ScheduleUpdate
     * @throws ApiError
     */
    public static async partialUpdateSchedule({
        uuid,
        data,
    }: {
        /** A UUID string identifying this schedule. **/
        uuid: string,
        data: ScheduleUpdate,
    }): Promise<ScheduleUpdate> {
        const result = await __request({
            method: 'PATCH',
            path: `/schedules/${uuid}/`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

    /**
     * Delete the specified schedule
     * @returns void
     * @throws ApiError
     */
    public static async destroySchedule({
        uuid,
    }: {
        /** A UUID string identifying this schedule. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/schedules/${uuid}/`,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}