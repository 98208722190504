import React from 'react';
import { ProcedureCreation } from 'api';

import GenericCreate from 'routes/generic/create';
import EditForm from 'routes/procedures/form';

interface Props {
  showHeader?: boolean;
  backTo?: string;
  successRedirect?: string;
}

const Create: React.FC<Props> = ({
  showHeader = true,
  backTo,
  successRedirect,
}) => {
  const initialValues = {
    name: '',
    sections: [],
    description_input: '',
    description_activity: '',
    description_output: '',
    informed: [],
    consulted: [],
    assists: [],
    responsible: null,
    implementation_status: ProcedureCreation.implementation_status.UNKNOWN,
  };

  return (
    <GenericCreate
      showHeader={showHeader}
      backTo={backTo}
      successRedirect={successRedirect}
      resolver="procedure"
      listResolver="procedures"
      createRoute="procedures:create"
      listRoute="procedures"
      detailRoute="procedure"
      detailIdParam="procedureId"
      listTitle="Procedures"
      listItemTitle={entity => entity.name}
      itemName="procedure">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericCreate>
  );
};

export default Create;
