/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RiskTreatmentActionCreation = {
    readonly uuid?: string;
    title: string;
    owner: string;
    deadline: string;
    description: string;
    risks: Array<string>;
    status?: RiskTreatmentActionCreation.status;
    type?: RiskTreatmentActionCreation.type;
}

export namespace RiskTreatmentActionCreation {

    export enum status {
        PLANNED = 'planned',
        PROGRESS = 'progress',
        IMPLEMENTED = 'implemented',
    }

    export enum type {
        FULL = 'full',
        IMPROVEMENT = 'improvement',
    }


}
