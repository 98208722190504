import React from 'react';
import { Form, Input } from 'antd';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';

type Props = Omit<GenericFormProps, 'renderFields'>;

const EditForm: React.FC<Props> = props => {
  return (
    <GenericForm
      {...props}
      renderFields={() => (
        <>
          <Form.Item required label="Name" name="name">
            <Input className="monospace" />
          </Form.Item>
        </>
      )}
    />
  );
};

export default EditForm;
