import React from 'react';

import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/threat-classes/form';

import { useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';

const Detail: React.FC = () => {
  const { threatClassId } = useParams('threat-class') as {
    threatClassId: string;
  };
  const entity = useSelector(state =>
    selectEntity(state, 'threatClass', threatClassId)
  );

  const initialValues = {
    name: entity.name,
  };

  return (
    <>
      <GenericDetail
        resolver="threatClass"
        listRoute="threat-classes"
        detailIdParam="threatClassId"
        listTitle="Threat classes"
        createRoute="threat-classes:create"
        listItemTitle={entity => entity.name}
        itemName="threat class">
        {props => (
          <>
            <EditForm initialValues={initialValues} {...props} />
          </>
        )}
      </GenericDetail>
    </>
  );
};

export default Detail;
