import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Modal, List, Tag } from 'antd';
import { Link } from 'react-router-dom';

import DateTooltip, { formatDate } from 'components/date';

import { useParams, url, RouteInterface } from 'routes';
import Approve from 'routes/policy/versions/approve';
import Promote from 'routes/policy/versions/promote';

import { list } from 'store/common';
import { selectEntity, selectEntities } from 'store/data';
import { useDispatch, useSelector } from 'store/hooks';

interface Props {
  routes: RouteInterface[];
}

const Versions: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { policyId } = useParams('policy') as { policyId: string };

  const policy = useSelector(state => selectEntity(state, 'policy', policyId));
  const versions = useSelector(state =>
    selectEntities(state, 'policyVersions', policyId)
  );

  useEffect(() => {
    dispatch(
      list({
        resolver: 'policyVersions',
        paginationKey: policy.uuid,
        extra: {
          policy: policy.uuid,
        },
      })
    );
  }, [dispatch, policy.uuid]);

  const history = useHistory();
  const [visible, setVisible] = useState(true);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      width={1000}
      title="Versions"
      visible={visible}
      onCancel={onClose}
      forceRender={true}
      footer={null}
      afterClose={() => {
        history.push(url('policy', { policyId }));
      }}>
      <List>
        {versions.map(policyVersion => (
          <List.Item
            key={policyVersion.uuid}
            actions={policyVersion.builds?.map(b => (
              <Link
                className="ant-btn"
                to={url('policy:versions:build', {
                  policyId,
                  versionId: policyVersion.uuid,
                  buildId: b.uuid,
                })}>
                {b.profile}
              </Link>
            ))}>
            <List.Item.Meta
              title={
                <>
                  Version {policyVersion.version}
                  <Tag
                    style={{ marginLeft: 10 }}
                    color={
                      policyVersion.status === 'draft' ? 'error' : 'success'
                    }>
                    {policyVersion.status}
                  </Tag>
                  {policyVersion.version &&
                  policyVersion.version?.match(/^[.\d]+$/g) ? (
                    policyVersion.status === 'draft' ? (
                      <Approve version={policyVersion} />
                    ) : null
                  ) : policyVersion.status === 'draft' ? (
                    <Promote version={policyVersion} />
                  ) : null}
                </>
              }
              description={
                <>
                  Added at{' '}
                  {policyVersion.added_at ? (
                    <DateTooltip
                      placement="right"
                      date={policyVersion.added_at}>
                      {formatDate(policyVersion.added_at)}
                    </DateTooltip>
                  ) : null}
                </>
              }
            />
          </List.Item>
        ))}
      </List>
    </Modal>
  );
};

export default Versions;
