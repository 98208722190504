import React, { useEffect, useState } from 'react';
import { values, filter, includes, find } from 'lodash';
import {
  Form,
  Select,
  Input,
  Slider,
  Typography,
  Divider,
  Skeleton,
} from 'antd';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

// import RequirementSelect from 'components/requirement-select';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';
import { ratingFilters } from 'routes/risks/dashboard/heatmap';
import Table from 'routes/risk-treatment-actions/table';

import { useSelector, useEntities, useDispatch } from 'store/hooks';
import { list } from 'store/common';
import { selectEntities } from 'store/data';

import {
  // cleanupFieldValues,
  convertRatingToMarks,
  presentableValue,
} from 'utils';
import { RiskTreatmentActionListing, RiskUpdate } from 'api';

const { TextArea } = Input;
const { Title } = Typography;

type Props = Omit<GenericFormProps, 'renderFields'> & { riskId?: string };

const EditForm: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const { initialValues, riskId } = props;
  const [treatment_type, setTreatmentType] = useState(
    initialValues.treatment_type
  );
  const [riskTreatmentActions, loading] = useEntities(
    'riskTreatmentActions',
    'all'
  );
  const completedRTAs = filter(
    riskTreatmentActions,
    rta =>
      includes(
        rta.risks?.map(r => r.uuid),
        riskId
      ) && rta.status === RiskTreatmentActionListing.status.IMPLEMENTED
  );

  const upcomingRTAs = filter(
    riskTreatmentActions,
    rta =>
      includes(
        rta.risks?.map(r => r.uuid),
        riskId
      ) && rta.status !== RiskTreatmentActionListing.status.IMPLEMENTED
  );

  const roles = useSelector(state => selectEntities(state, 'roles', 'all'));
  const threatClasses = useSelector(state =>
    selectEntities(state, 'threatClasses', 'all')
  );
  const assetClasses = useSelector(state =>
    selectEntities(state, 'assetClasses', 'all')
  );
  const vulnerabilityClasses = useSelector(state =>
    selectEntities(state, 'vulnerabilityClasses', 'all')
  );

  const riskProbabilityRating = convertRatingToMarks(
    RiskUpdate.initial_probability_rating
  );
  const riskImpactRating = convertRatingToMarks(
    RiskUpdate.initial_impact_rating
  );
  const currentRiskProbabilityRating = convertRatingToMarks(
    RiskUpdate.current_probability_rating
  );
  const currentRiskImpactRating = convertRatingToMarks(
    RiskUpdate.current_impact_rating
  );
  const residualRiskProbabilityRating = convertRatingToMarks(
    RiskUpdate.residual_probability_rating
  );
  const residualRiskImpactRating = convertRatingToMarks(
    RiskUpdate.residual_impact_rating
  );
  useEffect(() => {
    if (!props.riskId) {
      return;
    }

    dispatch(
      list({
        resolver: 'riskTreatmentActions',
        paginationKey: 'all',
      })
    );
  }, [dispatch, props.riskId]);

  return (
    <GenericForm
      preventLeaving={false}
      {...props}
      renderFields={({ form, onFieldsChange }) => {
        return (
          <>
            <Form.Item required label="Title" name="title">
              <Input className="monospace" />
            </Form.Item>

            <Form.Item required name="owner" label="Owner">
              <Select optionFilterProp="children" showSearch>
                {roles.map(r => (
                  <Select.Option key={r.uuid} value={r.uuid}>
                    {r.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Notes" name="notes">
              <TextArea className="monospace" />
            </Form.Item>

            <Divider />

            <Title level={3}>Scenario definition</Title>

            <Form.Item required name="asset_classes" label="Asset class">
              <Select optionFilterProp="children" showSearch mode="multiple">
                {assetClasses.map(r => (
                  <Select.Option key={r.uuid} value={r.uuid}>
                    {r.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item required name="threat_class" label="Threat class">
              <Select optionFilterProp="children" showSearch>
                {threatClasses.map(r => (
                  <Select.Option key={r.uuid} value={r.uuid}>
                    {r.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              required
              name="vulnerability_class"
              label="Vulnerability class">
              <Select optionFilterProp="children" showSearch>
                {vulnerabilityClasses.map(r => (
                  <Select.Option key={r.uuid} value={r.uuid}>
                    {r.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              required
              label="Impact description"
              name="impact_description">
              <TextArea className="monospace" />
            </Form.Item>

            <Divider />

            <Title level={3}>Analysis</Title>

            <Form.Item
              required
              name="affected_parties"
              label="Affected parties">
              <Select optionFilterProp="children" showSearch mode="multiple">
                {roles.map(r => (
                  <Select.Option key={r.uuid} value={r.uuid}>
                    {r.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item shouldUpdate={true}>
              {() => (
                <Form.Item
                  name="initial_probability_rating"
                  label="Initial probability rating">
                  <Slider
                    onChange={v => {
                      if (!completedRTAs.length) {
                        form.setFieldsValue({ current_probability_rating: v });
                      }
                    }}
                    max={10}
                    step={null}
                    marks={riskProbabilityRating}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item shouldUpdate={true}>
              {() => (
                <Form.Item
                  name="initial_impact_rating"
                  label="Initial impact rating">
                  <Slider
                    onChange={v => {
                      if (!completedRTAs.length) {
                        form.setFieldsValue({ current_impact_rating: v });
                      }
                    }}
                    max={10}
                    step={null}
                    marks={riskImpactRating}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item shouldUpdate label="Initial overall risk rating">
              {({ getFieldValue }) => {
                const initialProbabilityRating = getFieldValue(
                  'initial_probability_rating'
                );
                const initialImpactRating = getFieldValue(
                  'initial_impact_rating'
                );
                const rating = initialProbabilityRating * initialImpactRating;
                const r = find(
                  ratingFilters,
                  ({ value }) => rating >= value[0] && rating < value[1]
                );

                return (
                  <span className="ant-form-text">
                    {rating || '---'} {r?.text ? `(${r.text})` : null}
                  </span>
                );
              }}
            </Form.Item>

            {riskId ? (
              <div className="mb-2">
                <Title level={5}>Completed risk treatment actions</Title>
                {!completedRTAs.length && loading.isFetching ? (
                  <Skeleton />
                ) : (
                  <Table
                    onUnmap={() => {
                      if (completedRTAs.length - 1 === 0) {
                        form.setFieldsValue({
                          current_probability_rating: form.getFieldValue(
                            'initial_probability_rating'
                          ),
                        });
                        form.setFieldsValue({
                          current_impact_rating: form.getFieldValue(
                            'initial_impact_rating'
                          ),
                        });
                        setTimeout(() => onFieldsChange());
                      }
                    }}
                    riskId={riskId}
                    riskTreatmentActions={completedRTAs}
                  />
                )}
              </div>
            ) : null}

            <div style={completedRTAs.length ? {} : { display: 'none' }}>
              <Form.Item shouldUpdate>
                {() => (
                  <Form.Item
                    rules={[
                      form => {
                        return {
                          type: 'integer',
                          max: form.getFieldValue('initial_probability_rating'),
                          error: false,
                          message: `Current probability rating shouldn't be higher than initial.`,
                          warningOnly: true,
                        };
                      },
                    ]}
                    name="current_probability_rating"
                    label="Current probability rating">
                    <Slider
                      max={10}
                      step={null}
                      marks={currentRiskProbabilityRating}
                    />
                  </Form.Item>
                )}
              </Form.Item>

              <Form.Item
                rules={[
                  form => {
                    return {
                      type: 'integer',
                      max: form.getFieldValue('initial_impact_rating'),
                      error: false,
                      message: `Current impact rating shouldn't be higher than initial.`,
                      warningOnly: true,
                    };
                  },
                ]}
                shouldUpdate
                name="current_impact_rating"
                label="Current impact rating">
                <Slider max={10} step={null} marks={currentRiskImpactRating} />
              </Form.Item>

              <Form.Item shouldUpdate label="Current overall risk rating">
                {({ getFieldValue }) => {
                  const probabilityRating = getFieldValue(
                    'current_probability_rating'
                  );
                  const impactRating = getFieldValue('current_impact_rating');
                  const rating = probabilityRating * impactRating;
                  const r = find(
                    ratingFilters,
                    ({ value }) => rating >= value[0] && rating < value[1]
                  );

                  return (
                    <span className="ant-form-text">
                      {rating || '---'} {r?.text ? `(${r.text})` : null}
                    </span>
                  );
                }}
              </Form.Item>
            </div>

            <Divider />
            <Title level={3}>Treatment</Title>
            <Form.Item
              required
              name="treatment_type"
              rules={[
                {
                  type: 'enum',
                  enum: upcomingRTAs.length
                    ? [RiskUpdate.treatment_type.MITIGATE]
                    : values(RiskUpdate.treatment_type),
                  message: `Treatment type can only be mitigate if there are planned RTAs`,
                },
              ]}
              label="Treatment type">
              <Select
                value={treatment_type}
                onChange={v => setTreatmentType(v)}>
                {values(RiskUpdate.treatment_type).map(v => (
                  <Select.Option key={v} value={v}>
                    {presentableValue(v)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              required
              label="Treatment description"
              name="treatment_description">
              <TextArea autoSize className="monospace" />
            </Form.Item>

            {riskId ? (
              <div className="mb-2">
                <Title level={5}>Risk treatment actions</Title>
                {!upcomingRTAs.length && loading.isFetching ? (
                  <Skeleton />
                ) : (
                  <Table
                    disableAdding={
                      treatment_type !== RiskUpdate.treatment_type.MITIGATE
                    }
                    upcoming={true}
                    riskId={riskId}
                    riskTreatmentActions={upcomingRTAs}
                    onUnmap={() => {
                      if (upcomingRTAs.length - 1 === 0) {
                        form.setFieldsValue({
                          residual_probability_rating: form.getFieldValue(
                            'current_probability_rating'
                          ),
                        });
                        form.setFieldsValue({
                          residual_impact_rating: form.getFieldValue(
                            'current_impact_rating'
                          ),
                        });
                        setTimeout(() => onFieldsChange());
                      }
                    }}
                  />
                )}
              </div>
            ) : null}
            {/*
          <Form.List name="requirements">
            {(fields, { add, remove }, { errors }) => (
              <>
                <div className="ant-col ant-form-item-label">
                  <span title="Requirements">Requirements</span>
                </div>
                {fields.map(field => (
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    key={field.key}>
                    <Form.Item shouldUpdate style={{ width: '60%' }} {...field}>
                      <RequirementSelect />
                    </Form.Item>
                    <button
                      onClick={() => remove(field.name)}
                      className="remove-field-button">
                      <MinusCircleOutlined />
                    </button>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}>
                    Add requirement
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
            */}

            <div style={upcomingRTAs.length ? {} : { display: 'none' }}>
              <Form.Item
                rules={[
                  form => {
                    return {
                      type: 'integer',
                      max: upcomingRTAs.length
                        ? form.getFieldValue('current_probability_rating')
                        : form.getFieldValue('initial_probability_rating'),
                      error: false,
                      message: `Residual probability rating shouldn't be higher than current.`,
                      warningOnly: true,
                    };
                  },
                ]}
                name="residual_probability_rating"
                label="Residual probabillity rating">
                <Slider
                  max={10}
                  step={null}
                  marks={residualRiskProbabilityRating}
                />
              </Form.Item>

              <Form.Item
                rules={[
                  form => {
                    return {
                      type: 'integer',
                      max: upcomingRTAs.length
                        ? form.getFieldValue('current_impact_rating')
                        : form.getFieldValue('initial_impact_rating'),
                      error: false,
                      message: `Residual impact rating shouldn't be higher than current.`,
                      warningOnly: true,
                    };
                  },
                ]}
                name="residual_impact_rating"
                label="Residual impact rating">
                <Slider max={10} step={null} marks={residualRiskImpactRating} />
              </Form.Item>

              <Form.Item shouldUpdate label="Residual overall risk rating">
                {({ getFieldValue }) => {
                  const probabilityRating = getFieldValue(
                    'residual_probability_rating'
                  );
                  const impactRating = getFieldValue('residual_impact_rating');
                  const rating = probabilityRating * impactRating;
                  const r = find(
                    ratingFilters,
                    ({ value }) => rating >= value[0] && rating < value[1]
                  );

                  return (
                    <span className="ant-form-text">
                      {rating || '---'} {r?.text ? `(${r.text})` : null}
                    </span>
                  );
                }}
              </Form.Item>
            </div>
          </>
        );
      }}
    />
  );
};

export default EditForm;
