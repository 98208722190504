import React from 'react';
import classNames from 'classnames';
import { Tag } from 'antd';

interface Props {
  color?: string | undefined;
}

const CustomTag: React.FC<Props> = ({ color, children }) => (
  <Tag
    className={classNames('coverage__tag', {
      'coverage__tag--black-text': color === 'ffffff',
    })}
    color={color ? `#${color}` : undefined}>
    {children}
  </Tag>
);

export default CustomTag;
