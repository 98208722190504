import React from 'react';
import { Slider, Switch, Form, Select, Input } from 'antd';
import { AssetUpdate } from 'api';

import Debug from 'components/debug';
import AssetSelect from 'components/asset-select';

import GenericForm, { Props as GenericFormProps } from 'routes/generic/form';

import { useSelector } from 'store/hooks';
import { selectEntities, selectEntity } from 'store/data';

import { convertRatingToMarks } from 'utils';

type Props = Omit<GenericFormProps, 'renderFields'> & { assetId?: string };

const { TextArea } = Input;

const EditForm: React.FC<Props> = props => {
  const { initialValues, assetId } = props;

  const asset = useSelector(state =>
    selectEntity(state, 'asset', assetId || '')
  );
  const roles = useSelector(state => selectEntities(state, 'roles', 'all'));
  const assetClasses = useSelector(state =>
    selectEntities(state, 'assetClasses', 'all')
  );
  const locations = useSelector(state =>
    selectEntities(state, 'locations', 'all')
  );
  const confidentialityRating = convertRatingToMarks(
    AssetUpdate.confidentiality_rating
  );

  return (
    <GenericForm
      {...props}
      renderFields={() => (
        <>
          <Form.Item required label="Name" name="name">
            <Input className="monospace" />
          </Form.Item>
          <Form.Item required name="asset_class" label="Asset Class">
            <Select showSearch optionFilterProp="children">
              {assetClasses.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea
              className="monospace"
              autoSize={{
                minRows: 3,
              }}
            />
          </Form.Item>
          <Form.Item label="Parent asset" name="parent">
            <AssetSelect except={assetId} />
          </Form.Item>
          <Form.Item name="location" label="Location">
            <Select showSearch optionFilterProp="children">
              {locations.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item required name="owner" label="Owner">
            <Select showSearch optionFilterProp="children">
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="custodian" label="Custodian">
            <Select showSearch optionFilterProp="children" allowClear>
              {roles.map(r => (
                <Select.Option key={r.uuid} value={r.uuid}>
                  {r.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            className="mb-2"
            name="confidentiality_rating"
            help={
              <>
                Rating of this specific asset. It can not exceed the
                confidentiality of its asset class or its potential parents.{' '}
                <br />
                If the value is unknown, the value of the asset class applies.
              </>
            }
            label="Confidentiality rating">
            <Slider
              max={
                Math.min(
                  asset?.parent?.asset_class?.confidentiality_rating || 10,
                  asset?.asset_class?.confidentiality_rating || 10
                ) || 10
              }
              step={null}
              marks={confidentialityRating}
            />
          </Form.Item>

          <Form.Item
            valuePropName="checked"
            name="automatically_managed"
            label="Automatically managed">
            <Switch />
          </Form.Item>

          {initialValues.custom_metadata ? (
            <Form.Item label="Custom metadata">
              <Debug src={initialValues.custom_metadata} />
            </Form.Item>
          ) : null}
        </>
      )}
    />
  );
};

export default EditForm;
