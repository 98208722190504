import React, { useEffect } from 'react';
import { ProcedureUpdate } from 'api';
import { useParams } from 'react-router';

import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/procedures/form';

import { detail, selectDetail } from 'store/common';
import { DenormalizedProcedure } from 'store/data';
import { useDispatch, useSelector } from 'store/hooks';

interface Props {
  showHeader?: boolean;
  backTo?: string;
}

const Detail: React.FC<Props> = ({ showHeader = true, backTo }) => {
  const dispatch = useDispatch();
  // here we use the react router one because it takes _current_ route and not a predetermined route as ours
  const { procedureId } = useParams<{ procedureId: string }>();
  const procedure = useSelector(state =>
    selectDetail<DenormalizedProcedure>(
      state,
      'procedure',
      procedureId as string
    )
  );

  const sectionIds = (procedure.sections?.map(s => s.uuid) || []).join('|');

  useEffect(() => {
    if (!sectionIds) {
      return;
    }
    sectionIds.split('|').map(id =>
      dispatch(
        detail({
          resolver: 'section',
          uuid: id,
        })
      )
    );
  }, [dispatch, sectionIds]);

  const initialValues = {
    name: procedure.name,
    sections: procedure.sections?.map(s => s.uuid) || [],
    description_input: procedure.description_input,
    description_activity: procedure.description_activity,
    description_output: procedure.description_output,
    informed: procedure.informed?.map(s => s.uuid) || [],
    consulted: procedure.consulted?.map(s => s.uuid) || [],
    assists: procedure.assists?.map(s => s.uuid) || [],
    responsible: procedure.responsible?.uuid,
    implementation_status:
      procedure.implementation_status ||
      ProcedureUpdate.implementation_status.UNKNOWN,
  };

  return (
    <GenericDetail
      backTo={backTo}
      showHeader={showHeader}
      resolver="procedure"
      listRoute="procedures"
      detailIdParam="procedureId"
      listTitle="Procedures"
      createRoute="procedures:create"
      listItemTitle={entity => entity.name}
      itemName="procedure">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericDetail>
  );
};

export default Detail;
