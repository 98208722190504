import React, { useEffect, useState } from 'react';
import { findIndex } from 'lodash';
import { Modal } from 'antd';
import { Link, Switch } from 'react-router-dom';

import { RouteInterface, RouteWithSubRoutes, url, useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/risks/form';

import { DenormalizedRisk, selectEntities } from 'store/data';
import { list, selectDetail } from 'store/common';
import { useDispatch, useSelector } from 'store/hooks';
import { useHistory } from 'react-router';

interface Props {
  routes: RouteInterface[];
}

const Detail: React.FC<Props> = ({ routes }) => {
  const dispatch = useDispatch();
  const { riskId } = useParams('risk');
  const risk = useSelector(state =>
    selectDetail<DenormalizedRisk>(state, 'risk', riskId as string)
  );
  const risks = useSelector(state => selectEntities(state, 'risks', 'all'));
  const requirementIds = (risk.requirements?.map(s => s.uuid) || []).join('|');

  const currentRiskIndex = findIndex(risks, r => r.uuid === riskId);

  useEffect(() => {
    if (!requirementIds) {
      return;
    }
    dispatch(
      list({
        resolver: 'requirements',
        paginationKey: risk.uuid,
        extra: {
          uuid: requirementIds.split('|'),
        },
      })
    );
  }, [dispatch, requirementIds, risk.uuid]);

  const initialValues = {
    title: risk.title,
    owner: risk.owner?.uuid,
    // requirements: risk.requirements?.map(r => r.uuid) || [],
    threat_class: risk.threat_class?.uuid,
    vulnerability_class: risk.vulnerability_class?.uuid,
    impact_description: risk.impact_description,
    initial_probability_rating: risk.initial_probability_rating,
    initial_impact_rating: risk.initial_impact_rating,
    current_probability_rating: risk.current_probability_rating,
    current_impact_rating: risk.current_impact_rating,
    treatment_type: risk.treatment_type,
    treatment_description: risk.treatment_description,
    residual_probability_rating: risk.residual_probability_rating,
    residual_impact_rating: risk.residual_impact_rating,
    notes: risk.notes,
    initial_risk_rating: risk.initial_risk_rating,
    residual_risk_rating: risk.residual_risk_rating,
    current_risk_rating: risk.current_risk_rating,
    asset_classes: risk.asset_classes,
    affected_parties: risk.affected_parties,
  };

  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const onClose = () => setVisible(false);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="80vw"
      style={{
        maxWidth: 900,
      }}
      forceRender={true}
      footer={null}
      afterClose={() => {
        history.push(url('risks'));
      }}>
      <GenericDetail
        resolver="risk"
        listRoute="risks"
        detailIdParam="riskId"
        showHeader={false}
        detailTitle={
          <>
            Edit risk {risk.human_id}
            <div style={{ fontSize: 14, fontWeight: 'normal' }}>
              {risks[currentRiskIndex - 1] ? (
                <Link
                  to={url('risk', {
                    riskId: risks[currentRiskIndex - 1].uuid,
                  })}>
                  &larr; prev
                </Link>
              ) : (
                <span style={{ color: '#ccc', cursor: 'default' }}>
                  &larr; prev
                </span>
              )}
              <span style={{ display: 'inline-block', width: 15 }} />
              {risks[currentRiskIndex + 1] ? (
                <Link
                  to={url('risk', {
                    riskId: risks[currentRiskIndex + 1].uuid,
                  })}>
                  next &rarr;
                </Link>
              ) : (
                <span style={{ color: '#ccc', cursor: 'default' }}>
                  next &rarr;
                </span>
              )}
            </div>
          </>
        }
        listTitle="Risks"
        createRoute="risks:create"
        listItemTitle={entity => `${entity.human_id}. ${entity.title}`}
        itemName="risk">
        {props => (
          <>
            <EditForm
              riskId={risk.uuid}
              initialValues={initialValues}
              {...props}
            />
          </>
        )}
      </GenericDetail>
      <Switch>
        {routes.map(r => (
          <RouteWithSubRoutes key={r.path} {...r} />
        ))}
      </Switch>
    </Modal>
  );
};

export default Detail;
