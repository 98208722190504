import React from 'react';

import { useParams } from 'routes';
import GenericDetail from 'routes/generic/detail';
import EditForm from 'routes/asset-types/form';

import { useSelector } from 'store/hooks';
import { selectEntity } from 'store/data';

const Detail: React.FC = () => {
  const { assetTypeId } = useParams('asset-type');
  const entity = useSelector(state =>
    selectEntity(state, 'assetType', assetTypeId as string)
  );

  const initialValues = {
    name: entity.name,
    slug: entity.slug,
    description: entity.description,
  };

  return (
    <GenericDetail
      resolver="assetType"
      listRoute="asset-types"
      createRoute="asset-types:create"
      detailIdParam="assetTypeId"
      listTitle="Asset types"
      listItemTitle={entity => entity.name}
      itemName="asset type">
      {props => <EditForm initialValues={initialValues} {...props} />}
    </GenericDetail>
  );
};

export default Detail;
