import React from 'react';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';

import DateTooltip from 'components/date';

import { url } from 'routes';

import { DenormalizedPolicyVersion } from 'store/data';

interface Props {
  policyId: string;
  version: DenormalizedPolicyVersion | null;
}

const Builds: React.FC<Props> = ({ policyId, version }) => {
  const [defaultBuild, ...builds] = version?.builds || [];

  return (
    <div style={{ display: 'flex' }}>
      {version?.version}
      {defaultBuild && policyId && version?.uuid && version.version ? (
        <Dropdown.Button
          size="small"
          style={{ marginLeft: 14, marginRight: 20 }}
          trigger={['click']}
          overlay={
            <Menu>
              {builds.length ? (
                builds.map(b => (
                  <Menu.Item key={b.uuid}>
                    <Link
                      to={url('policy:versions:build', {
                        policyId,
                        versionId: version.uuid as string,
                        buildId: b.uuid,
                      })}>
                      <DateTooltip
                        title={`Version ${version?.version} added at`}
                        date={version.added_at as string}
                        key={b.uuid}>
                        {b.profile}
                      </DateTooltip>
                    </Link>
                  </Menu.Item>
                ))
              ) : (
                <Menu.Item disabled>No other builds</Menu.Item>
              )}
            </Menu>
          }>
          <DateTooltip
            title={`Version ${version?.version} added at`}
            date={version.added_at as string}
            key={defaultBuild.uuid}>
            <Link
              to={url('policy:versions:build', {
                policyId,
                versionId: version.uuid as string,
                buildId: defaultBuild.uuid,
              })}>
              {defaultBuild.profile}
            </Link>
          </DateTooltip>
        </Dropdown.Button>
      ) : null}
    </div>
  );
};

export default Builds;
